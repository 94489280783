import axios from "axios";

const token = GetToken()

const config = {
  headers: {
    'Content-Type': 'application/json',
    'x-auth-token': token,
  },
  withCredentials: true
};

export async function get(url) {
  return axios
  .get(url, config)
  .then(response => response )

}

export async function post(url, data) {
  //return axios.post(url, data, config);
  return axios
    .post(url, data, config,
      {transformRequest: data => data}
    )
    .then(response => response)
}

export async function post2(url, data, config) {
  const additionalHeaders = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };

  const headers = data instanceof FormData ? additionalHeaders : config;

  return axios
    .post(url, data, headers)
    .then(response => response)
    .catch(error => {
      console.error('Error posting data:', error);
      throw error;
    });
}

export async function put(url, data) {
  return axios
    .put(url, data, config)
    .then(response => response)
}

export async function del(url, data) {
  return axios
    .delete(url, {
      ...config,
      data: data
    })
    .then(response => response)
    .catch(error => {
      console.error('Error deleting payment type:', error);
      throw error;
    });
}

function GetToken() {
  const currentUser = localStorage.getItem("authUser");
  if (currentUser !== null && currentUser !== "") {
      var user = JSON.parse(currentUser);
      if (user != null) {
          return user.token;
      }
      else {
          return null;
      }
  }
  else {
      return null;
  }
}
