import React, { useEffect, useState } from "react";
import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback, FormGroup } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { registerUser, apiError } from "../../store/actions";
import profileImg from "../../assets/images/profile-img.png";
//import { ValidationForm, Checkbox, TextInput, SelectGroup, FileInput } from 'react-bootstrap4-form-validation';
import Header from "layouts/VerticalLayout/Header";
import axios from 'axios';
import Select from 'react-select';
import AlertModal from "../../components/Common/alertModal";
import ErrorAlertModal from "../../components/Common/errorAlertModal";
//import s3 from '../../helpers/aws-config';


const Profile = (props) => {
    document.title = "Register | Lab";
    const dispatch = useDispatch();
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);
    const [profile, setProfile] = useState([]);
    const [CityValue, setCityValue] = useState(null);
    const [StateValue, setStateValue] = useState(null);
    const [edit, setEdit] = useState(false);
    const [selectedValue, setSelectedValue] = useState(null);
    const [picture, setPicture] = useState(null);
    const [editImgUrl, setEditImgUrl] = useState("");
    const [imageUrl, setImageUrl] = useState("");
    const [description, setDescription] = useState("");
    const [alertModal, setAlertModal] = useState(false);
  const [errorAlertModal, setErrorAlertModal] = useState(false);
  const [response, setResponse] = useState(false);

    const [cid, setCid] = useState(
        localStorage.getItem('companyId'),

    );
    console.log(cid)

    const assignImage = (event) => {
        if (event.target.files[0]) {
          setPicture(event.target.files[0]);
        }
      };

      const handleFileUpload = async () => {
        if (!picture) {
          console.error('No file selected');
          return;
        }
        const params = {
          Bucket: 'temple-donation',
          Key: picture.name,
          Body: picture,
        };

        try {

          const data = await s3.upload(params).promise();
          setImageUrl(data.Location);
          console.log('File uploaded successfully:', data);
        } catch (error) {
          console.error('Error uploading file:', error);

        }
      };

    const fetchCompany = async (cid) => {
        try {

            const response = await axios.post('/api/company/get', { cid });

            profileSet(response.data.data)
            console.log(response.data.data)
            return response.data.data;
        } catch (error) {

            console.error('Error fetching company:', error);
            throw error;
        }
    };
    const updateCompany = async (data) => {
        try {

            const response = await axios.post('/api/company/update', { cid, data });
            setResponse("ProfileUpdated Sucessfully")
            setAlertModal(true)

            console.log("updated successfull")
            return true;
        } catch (error) {
            setResponse("Failed to Update Profile")
            setErrorAlertModal(true)
            console.error('Error Updating company:', error);
            throw error;
        }
    };

    const handleCitySelect = (selectedOption) => {

        setCityValue(selectedOption);
        console.log(selectedOption);
    }
    const profileSet = arg => {
        const profileReq = arg;

        setProfile({
            id: profileReq._id,
            name: profileReq.labName,
            code: profileReq.labCode,
            email: profileReq.email,
            mobileNo: profileReq.mobileNo,
            state: profileReq.state,
            city: profileReq.city,
            pincode: profileReq.pincode

        });

    };
    useEffect(() => {
        fetchCompany(cid)
    }, [alertModal])


    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: profile ? profile.email : "",
            name: profile ? profile.name : "",
            code: profile ? profile.code : "",
            mobileNo: profile ? profile.mobileNo : "",
            country: "India",
            state: profile ? profile.state : "",
            city: profile ? profile.city : "",
            pincode: profile ? profile.pincode : ""
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please Enter Your Lab Name"),
            code: Yup.string().required("Please Enter Your Lab Code"),
            email: Yup.string().required("Please Enter Your Email"),
            mobileNo: Yup.string().required("Please Enter Your Mobile Number"),
            pincode: Yup.string().required("Please Enter Your Pincode"),
        }),
        onSubmit: (values) => {
            update(values);
        },
    });
    const update = (values) => {
        if (picture != null) {
            handleFileUpload(picture);
          }
          else {
            setImageUrl(editImgUrl);
          }

        const data = {

            labName: values.name,
            email: values.email,
            labCode: values.code,
            mobileNo: values.mobileNo,
            country: values.country,
            state: StateValue.label,
            city: CityValue.label,
            pincode: values.pincode,
            isActive: true,
            description: description,
            // Images: [
            //     {
            //       imageUrl: imageUrl,
            //       imageDescription: description
            //     }
            //   ]

        };
        updateCompany(data).then((res) => {
            console.log("success")
        }).catch(err => {
            setErrorMessage("Profile not updated");
        });
    };
    const { user, registrationError, loading } = useSelector((state) => ({
        user: state.Account.user,
        registrationError: state.Account.registrationError,
        loading: state.Account.loading,
    }));
    useEffect(() => {
        dispatch(apiError(""));
    }, []);
    return (
        <React.Fragment>
            <AlertModal
        show={alertModal}
        onOkClick={() => {
          fetchCompany(cid)
          setAlertModal(false);
        }}
        onCloseClick={() => setAlertModal(false)}
        res={response}
      />
      <ErrorAlertModal
       show={errorAlertModal}
       onOkClick={() =>{ fetchCompany(cid) ,setErrorAlertModal(false) }}
       onCloseClick={() => setErrorAlertModal(false)}
       res={response}
      />
            <Header />
            <div className="home-btn d-none d-sm-block">
                <Link to="/" className="text-dark">
                    <i className="bx bx-home-alt h2" />
                </Link>
            </div>
            <div className="account-pages my-5 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden">
                                <div className="bg-primary bg-soft">
                                    <Row>
                                        <Col className="col-7">
                                            <div className="text-primary p-4">
                                                <h3 className="text-primary">My Profile</h3>
                                            </div>
                                        </Col>
                                        {/* <Col className="col-5 align-self-end">
                                            <img src={profileImg} alt="" className="img-fluid" />
                                        </Col> */}
                                    </Row>
                                </div>
                                <CardBody className="pt-0">
                                    <div>
                                        {/* <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img src={logoImg} alt="" className="rounded-circle" height="34" />
                        </span>
                      </div>
                    </Link> */}
                                    </div>
                                    <div className="p-2">

                                        <Form
                                            className="form-horizontal"
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                validation.handleSubmit();
                                                return false;
                                            }}
                                        >
                                            {errorMessage &&
                                                <Alert color="danger">
                                                    {errorMessage}
                                                </Alert>
                                            }
                                            {/* <div className="mb-3">

                                                <Label htmlFor="image">Image</Label>
                                                {edit ? (
                                                    <Input
                                                        //required
                                                        className="form-control"
                                                        //errorMessage={{ required: "Defects Image Is Required" }}
                                                        type="file"
                                                        name="image"
                                                        placeholder="image"
                                                        onChange={(e) => assignImage(e)}
                                                    />
                                                ) : (
                                                    <Input
                                                        required
                                                        className="form-control"
                                                        errorMessage={{ required: "Patient Image Is Required" }}
                                                        type="file"
                                                        name="patient-image"
                                                        placeholder="patient image"
                                                        onChange={(e) => assignImage(e)}
                                                    />
                                                )}

                                                {picture != null && (
                                                    <img
                                                        className="img-thumbnail defect-image"
                                                        src={URL.createObjectURL(picture)}
                                                    />
                                                )}
                                                {edit && picture === null ? (
                                                    <img
                                                        className="img-thumbnail defect-image"
                                                        src={editImgUrl}
                                                    />
                                                ) : (
                                                    ""
                                                )}
                                                </div> */}
                                                <div className="mb-3">
                                                    <Label className="form-label"> Name</Label>
                                                    <Input
                                                        id="name"
                                                        name="name"
                                                        className="form-control"
                                                        placeholder="Enter name"
                                                        type="name"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.name || ""}
                                                        invalid={validation.touched.name && validation.errors.name ? true : false}
                                                    />
                                                    {validation.touched.name && validation.errors.name ? (
                                                        <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <Label className="form-label">code</Label>
                                                    <Input
                                                        name="code"
                                                        type="code"
                                                        placeholder="Enter code"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.code || ""}
                                                        invalid={
                                                            validation.touched.code && validation.errors.code ? true : false
                                                        }
                                                    />
                                                    {validation.touched.code && validation.errors.code ? (
                                                        <FormFeedback type="invalid">{validation.errors.code}</FormFeedback>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <Label className="form-label">Email</Label>
                                                    <Input
                                                        name="email"
                                                        type="email"
                                                        placeholder="Enter email"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.email || ""}
                                                        invalid={
                                                            validation.touched.email && validation.errors.email ? true : false
                                                        }
                                                    />
                                                    {validation.touched.email && validation.errors.email ? (
                                                        <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <Label className="form-label">Mobile Number</Label>
                                                    <Input
                                                        name="mobileNo"
                                                        className="form-control"
                                                        placeholder="Enter mobileNo"
                                                        type="mobileNo" // Changed type to "text" to make the password visible
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.mobileNo || ""}
                                                        invalid={validation.touched.mobileNo && validation.errors.mobileNo}
                                                    />
                                                    {validation.touched.mobileNo && validation.errors.mobileNo ? (
                                                        <FormFeedback type="invalid">{validation.errors.mobileNo}</FormFeedback>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <Label className="form-label">Country</Label>
                                                    <Input
                                                        type="select"
                                                        name="country"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.country || ""}
                                                        invalid={
                                                            validation.touched.country && validation.errors.country
                                                        }
                                                    >
                                                        <option values="India">India</option>
                                                    </Input>
                                                    {validation.touched.country && validation.errors.country && (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.country}
                                                        </FormFeedback>
                                                    )}
                                                    <div className="mb-3">
                                                        <Label className="form-label">City</Label>
                                                        <Select
                                                            id="city"
                                                            name="city"
                                                            options={cityList.map(city => ({ value: city, label: city }))}
                                                            placeholder="choose.."

                                                            isClearable
                                                            isSearchable
                                                            classNamePrefix="custom-select"

                                                            value={CityValue}
                                                            onChange={(selectedOption) => {

                                                                // validation.setFieldValue(CityValue, selectedOption ? selectedOption.city : '');
                                                                handleCitySelect(selectedOption);
                                                            }}
                                                            styles={{
                                                                menu: provided => ({ ...provided, top: '100%', position: 'absolute', width: '100%', zIndex: 999 }),
                                                            }}
                                                            onBlur={validation.handleBlur}
                                                            invalid={
                                                                validation.touched.city && validation.errors.city
                                                            }

                                                        />
                                                        {validation.touched.city && validation.errors.city && (
                                                            <div className="text-danger">{validation.errors.city}</div>
                                                        )}
                                                    </div>
                                                    <div className="mb-3">
                                                        <Label className="form-label">Pincode</Label>
                                                        <Input
                                                            name="pincode"
                                                            type="number"
                                                            placeholder="Insert pincode"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.pincode || ""}
                                                            invalid={
                                                                validation.touched.pincode &&
                                                                    validation.errors.pincode
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        {validation.touched.pincode &&
                                                            validation.errors.pincode ? (
                                                            <FormFeedback type="invalid">
                                                                {validation.errors.pincode}
                                                            </FormFeedback>
                                                        ) : null}


                                                    </div>

                                                </div>
                                                <div className="mt-4">
                                                    <button className="btn btn-primary btn-block " type="submit">
                                                        Save
                                                    </button>
                                                </div>


                                        </Form>

                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};
export default Profile;