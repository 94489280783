import React from 'react';
import * as moment from "moment";

const CategoryName = (cell) => {
    return cell.value ? cell.value : '';
};
const Type = (cell) => {
    return cell.value ? cell.value : '';
};


export {
    CategoryName,
    Type,
};