import React from 'react';
import { Link } from 'react-router-dom';
import * as moment from "moment";
import { Badge } from 'reactstrap';

const formateDate = (date, format) => {
    const dateFormat = format ? format : "DD MMM Y";
    const date1 = moment(new Date(date)).format(dateFormat);
    return date1;
};
const toLowerCase1 = str => {
    return (
      str === "" || str === undefined ? "" : str.toLowerCase()
    );
  };

const Name = (cell) => {
    return cell.value ? cell.value : '';
};

const Logo = (cell) => {
    return cell.value ? cell.value : '';
}

const Address = (cell) => {
    return cell.value ? cell.value : '';
}

const ContactNo = (cell) => {
    return cell.value ? cell.value : '';
}

const Email = (cell) => {
    return cell.value ? cell.value : '';
}

const WebSite = (cell) => {
    return cell.value ? cell.value : '';
}

const IsActiveStatus = (cell) => {
    return cell.value !== undefined ? `${cell.value}` : '';
};

export {
    Name,
    Logo,
    Address,
    ContactNo,
    Email,
    WebSite,
    IsActiveStatus
};