import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../../components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";

import { useFormik } from "formik";
import { addTax, getTax, updateTax, deleteTax } from "../../../middleware/backent_helper";
import { TaxName,Tax } from "./TaxMasterCol";
import messages from '../../../utils/constants/messages';
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import DeleteModal from "../../../components/Common/DeleteModal";
import AlertModal from "../../../components/Common/alertModal";
import ErrorAlertModal from "../../../components/Common/errorAlertModal";
//redux
import { useSelector, useDispatch } from "react-redux";

const TaxMaster = props => {
  //meta title
  document.title = "Tax | Settings ";

  const dispatch = useDispatch();
  const [TaxList, setTaxList] = useState([]);
  const [Taxes, setTaxes] = useState();
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [errorAlertModal, setErrorAlertModal] = useState(false);
  const [masterName, setMasterName] = useState("Tax");
  const [response, setResponse] = useState(false);
  const [hid, setHid] = useState(() => {
    const storedHotelId = localStorage.getItem('hotelId')
    return storedHotelId ? storedHotelId.replace(/"/g, '') : '';
  });
  const [dbName, setDbName] = useState(
    localStorage.getItem('dbName')
  )

  const columns = useMemo(
    () => [
      {
        Header: "S.No",
        Cell: ({ row, rows }) => {
          return (
            <div className="table-cell text-center">
              {rows.length - row.index}
            </div>
          );
        },
      },
      {
        Header: "Tax Name",
        accessor: "taxName",
        filterable: true,
        Cell: cellProps => {
          return (
            <div className="table-cell text-center">
              <TaxName {...cellProps} />
            </div>
          );

        },
      },
      {
        Header: "Tax(%)",
        accessor: "taxPercentage",
        filterable: true,
        Cell: cellProps => {
          return (
            <div className="table-cell text-center">
              <Tax {...cellProps} />
            </div>
          );

        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="table-body actions">
              <div className="actions">
                <Link
                  to="#"
                  className="text-success"
                  onClick={() => {
                    const TaxData = cellProps.row.original;
                    handleTaxClicks(TaxData);
                  }}
                >
                  <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </Link>
                <Link
                  to="#"
                  className="text-danger"
                  onClick={() => {
                    const userData = cellProps.row.original;
                    onClickDelete(userData);
                  }}
                >
                  <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </div>
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    finalList()
  }, [])

  const finalList = () => {
    getTax(hid, dbName)
      .then((res) => {
        setTaxList(res.data.data);
      });
  };

  const clearFormValues = () => {
    validation.resetForm();
  };

  const toggle = () => {
    if (modal) {
      setModal(false);
      setTaxes(null);
      clearFormValues();

    } else {
      setModal(true);
    }
  };

  const handleTaxClicks = arg => {
    const tax = arg;

    setTaxes({
      id: tax.id,
      hotelId: hid,
      dbName: dbName,
      taxName: tax.taxName,
      taxPercentage: tax.taxPercentage,
    });
    setIsEdit(true);
    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = users => {
    setTaxes(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    deleteTax(Taxes , dbName)
    .then((res) => {
      getTax(hid, dbName), finalList()
      setResponse(messages.success.deleteDetails(masterName))
      setAlertModal(true)
    })
    .catch((err) => {
      if(err.response.status == 400) {
        setResponse(messages.failed.cannotbeDeleted(masterName))
      } else {
        setResponse(messages.failed.updateFailed(masterName))
      }
      setErrorAlertModal(true)
    });
    onPaginationPageChange(1);
    setDeleteModal(false);
    setTaxes(null);
  };

  const handleTaxClick = () => {
    setIsEdit(false);
    toggle();
  };

  //last two digit fix function
  const formatToTwoDecimalPlaces = (value) => {
    const number = parseFloat(value);
    return isNaN(number) ? "" : number.toFixed(2);
  };

  const handleBlur = (event) => {
    validation.handleBlur(event);
    const formattedValue = formatToTwoDecimalPlaces(event.target.value);
    validation.setFieldValue(event.target.name, formattedValue);
  };

  const validation = useFormik({

    initialValues: {

      taxName: Taxes ? Taxes.taxName : "",
      taxPercentage: Taxes ? Taxes.taxPercentage : "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({

      taxName: Yup.string().required("Please Enter TaxName"),
      taxPercentage: Yup.string().required("Please Enter TaxPercentage"),

    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updatedTaxData = { ...Taxes, ...values };
        updateTax(updatedTaxData)
          .then((res) => {
            setResponse(messages.success.updatedDetails(masterName))
            setAlertModal(true)
          })
          .catch((err) => {
            if (err.response.status === 401) {
              setResponse(messages.failed.alreadyInUse(masterName))
            } else {
              setResponse(messages.failed.updateFailed(masterName))
            }
            setErrorAlertModal(true)
          });
        toggle();
      } else {
        dispatch(post_Tax(values));
      }
    }
  });
  const post_Tax = (values) => {
    const data = JSON.stringify({

      taxName: values.taxName,
      taxPercentage: values.taxPercentage,
      isActive: true,
      hotelId: hid,
      dbName: dbName
    });
    addTax(data).then((res) => {
      if (res.data.success) {
        setResponse(messages.success.createdMessage(masterName))
        setAlertModal(true)
      }
    }).catch(err => {
      if (err.response.status === 404) {
        setResponse(messages.failed.AlreadyExists(masterName))
      } else {
        setResponse(messages.failed.createFailed(masterName))
      }
      setErrorAlertModal(true)
    });
    toggle();
  };



  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => {setTaxes(null), setDeleteModal(false) }}
      />
      <AlertModal
        show={alertModal}
        onOkClick={() => {
          finalList();
          setAlertModal(false);
        }}
        onCloseClick={() => setAlertModal(false)}
        res={response}
      />
      <ErrorAlertModal
        show={errorAlertModal}
        onOkClick={() => { finalList(), setErrorAlertModal(false) }}
        onCloseClick={() => setErrorAlertModal(false)}
        res={response}
      />

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Settings" breadcrumbItem="Manage Tax" />
          <Row>
            <Col lg="12">

                  <TableContainer
                    columns={columns}
                    data={TaxList}
                    isGlobalFilter={true}
                    isAddTaxMasterList={true}
                    handleTaxMasterClicks={handleTaxClick}
                    customPageSize={10}
                    className="custom-header-css"
                  />

                  <Modal className="modal-lg" isOpen={modal} toggle={toggle} backdrop="static" keyboard={false}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Edit Tax" : "Add Tax"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >

                        <Row>
                          <Col xs={6}>
                            <div className="mb-3">
                              <Label className="form-label">Tax Name</Label>
                              <Input
                                name="taxName"
                                type="text"
                                placeholder="TaxName"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.taxName || ""}
                                invalid={
                                  validation.touched.taxName &&
                                    validation.errors.taxName
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.taxName &&
                                validation.errors.taxName ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.taxName}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col>
                            <div className="mb-3">
                              <Label className="form-label">Tax %</Label>
                              <Input
                                name="taxPercentage"
                                type="number"
                                placeholder="Tax Percentage"
                                onChange={validation.handleChange}
                                onBlur={handleBlur}
                                value={validation.values.taxPercentage || ""}
                                invalid={
                                  validation.touched.taxPercentage &&
                                    validation.errors.taxPercentage
                                    ? true
                                    : false
                                }
                                min="0"
                              />
                              {validation.touched.taxPercentage &&
                                validation.errors.taxPercentage ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.taxPercentage}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                       <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-primary save-user w-md me-3"
                              >
                                {!!isEdit ? "Update" : "Save"}
                              </button>
                              <button
                                type="button"
                                className="btn btn-secondary w-md "
                                onClick={toggle}
                              >
                                Cancel
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>

            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};


export default withRouter(TaxMaster);
