import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col
} from "reactstrap";


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";

const Dashboard = props => {
  const [modal, setmodal] = useState(false);
  const [subscribemodal, setSubscribemodal] = useState(false);

  const { chartsData } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData
  }));
  // const token = props.location.state.token;
  const [token, setToken] = useState(
    localStorage.getItem('token')
);


  useEffect(() => {
    setTimeout(() => {
      setSubscribemodal(true);
    }, 2000);
  }, []);

  const [periodData, setPeriodData] = useState([]);
  const [periodType, setPeriodType] = useState("yearly");

  useEffect(() => {
    setPeriodData(chartsData);
  }, [chartsData]);

  //meta title
  document.title = "StayPro | Dashboard";

  return (
    <React.Fragment>

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            breadcrumbItem={props.t("Dashboard")}
          />

          <Row>
            <Col xl="4">
            </Col>
            <Col xl="8">
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
};

export default withTranslation()(Dashboard);
