import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../../components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import Select from 'react-select';
import { useFormik } from "formik";
import { createInventory, getInventory, updateInventory, deleteInventory } from "../../../middleware/backent_helper";
import {  InventryName,
    AvailableQuantity,
    ReOrderQuantity,
    Cost } from "./inventoryStockCol";
import messages from '../../../utils/constants/messages';
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import DeleteModal from "../../../components/Common/DeleteModal";
import AlertModal from "../../../components/Common/alertModal";
import ErrorAlertModal from "../../../components/Common/errorAlertModal";
//redux
import { useSelector, useDispatch } from "react-redux";

const InventoryStock = props => {

  //meta title
  document.title = "Inventory Stock | Accounts ";

  const dispatch = useDispatch();
  const [Inventory, setInventory] = useState();
  const [InventoryList, setInventoryList] = useState([]);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [errorAlertModal, setErrorAlertModal] = useState(false);
  const [masterName, setMasterName] = useState("Inventory Stock");
  const [response, setResponse] = useState(false);
  const [hid, setHid] = useState(() => {
    const storedHotelId = localStorage.getItem('hotelId')
    return storedHotelId ? storedHotelId.replace(/"/g, '') : '';
  });

  const [dbName, setDbName] = useState(
    localStorage.getItem('dbName')
  )

   const columns = useMemo(
    () => [
      {
        Header: "S.No",
        Cell: ({ row, rows }) => {
          return (
            <div  className="table-cell text-center">
              {rows.length - row.index}
            </div>
          );
        },
      },

      {
        Header: "Inventory Name",
        accessor: "name",
        filterable: true,
        Cell: cellProps => {
          return (
            <div  className="table-cell text-center">
              <InventryName {...cellProps} />
            </div>
          )
        },
      },

      {
        Header: "Available Quantity",
        accessor: "availableQuantity",
        filterable: true,
        Cell: cellProps => {
          return (
            <div  className="table-cell text-center">
              <AvailableQuantity {...cellProps} />
            </div>
          )
        },
      },

      {
        Header: "Re Order Quantity",
        accessor: "reOrderQuantity",
        filterable: true,
        Cell: cellProps => {
          return (
            <div  className="table-cell text-center">
              <ReOrderQuantity {...cellProps} />
            </div>
          )
        },
      },



      {
        Header: "Cost",
        accessor: "cost",
        filterable: true,
        Cell: cellProps => {
          return (
            <div  className="table-cell text-center">
              <Cost {...cellProps} />
            </div>
          )
        },
      },


      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div  className="table-body actions">
              <div className="actions">
                <Link
                  to="#"
                  className="text-success"
                  onClick={() => {
                    const CategoryData = cellProps.row.original;
                    handle_CategoryClick(CategoryData);
                  }}
                >
                  <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </Link>
                <Link
                  to="#"
                  className="text-danger"
                  onClick={() => {
                    const userData = cellProps.row.original;
                    onClickDelete(userData);
                  }}
                >
                  <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </div>
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    finalList()
  }, [])

  const finalList = () => {
    getInventory(hid , dbName)
      .then((res) => {
        setInventoryList(res.data.data);
    });
  };

  const clearFormValues = () => {
    validation.resetForm();
  };

  const toggle = () => {
    if (modal) {
      setModal(false);
      setInventory(null);
      clearFormValues();

    } else {
      setModal(true);
    }
  };

  const handle_CategoryClick = arg => {
    const inventory_data = arg;
    setInventory({
      id: inventory_data.id,
      name: inventory_data.name,
      availableQuantity: inventory_data.availableQuantity,
      reOrderQuantity: inventory_data.reOrderQuantity,
      cost: inventory_data.cost,
      hotelId: hid,
      dbName:dbName,
    });
    setIsEdit(true);
    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };


  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = category => {
    setInventory(category);
    setDeleteModal(true);
  };

  const handleDeleteCategory = () => {
    deleteInventory(Inventory , dbName)
    .then((res) => {
      getInventory(hid , dbName), finalList()
      setResponse(messages.success.deleteDetails(masterName))
      setAlertModal(true)
    })
    .catch((err) => {
      if(err.response.status == 400) {
        setResponse(messages.failed.cannotbeDeleted(masterName))
      } else {
        setResponse(messages.failed.updateFailed(masterName))
      }
      setErrorAlertModal(true)
    });
    onPaginationPageChange(1);
    setDeleteModal(false);
    setInventory(null);
  };

  const handleInventoryStockClick = () => {
    setIsEdit(false);
    toggle();
  };

  const validation = useFormik({

    initialValues: {
      name: Inventory ? Inventory.name : "",
      availableQuantity: Inventory ? Inventory.availableQuantity : "",
      reOrderQuantity: Inventory ? Inventory.reOrderQuantity : "",
      cost: Inventory ? Inventory.cost : "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Inventory Name"),
      availableQuantity: Yup.string().required(
        "Please Enter Available Quantity"
      ),
      reOrderQuantity: Yup.string().required("Please Enter Re Order Quantity"),
      cost: Yup.string().required("Please Enter Cost"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updatedCategoryData = { ...Inventory, ...values };
        updateInventory(updatedCategoryData)
          .then((res) => {
            setResponse(messages.success.updatedDetails(masterName))
            setAlertModal(true)
          })
          .catch((err) => {
            if (err.response.status === 401) {
              setResponse(messages.failed.alreadyInUse(masterName))
            } else {
              setResponse(messages.failed.updateFailed(masterName))
            }
            setErrorAlertModal(true)
          });
        toggle();
      } else {
        dispatch(post_Category(values));
      }
    }
  });
  const post_Category = (values) => {
    const data = JSON.stringify({
      name: values.name,
      availableQuantity: values.availableQuantity,
      reOrderQuantity: values.reOrderQuantity,
      cost: values.cost,
      hotelId: hid,
      dbName:dbName
    });
    createInventory(data).then((res) => {
      if (res.data.success) {
        setResponse(messages.success.createdMessage(masterName))
        setAlertModal(true)
      }
    }).catch(err => {
      if(err.response.status === 404) {
        setResponse(messages.failed.AlreadyExists(masterName))
      } else {
        setResponse(messages.failed.createFailed(masterName))
      }
      setErrorAlertModal(true)
    });
    toggle();
  };

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteCategory}
        onCloseClick={() => {setInventory(null), setDeleteModal(false) }}
      />
      <AlertModal
        show={alertModal}
        onOkClick={() => {
          finalList();
          setAlertModal(false);
        }}
        onCloseClick={() => setAlertModal(false)}
        res={response}
      />
      <ErrorAlertModal
        show={errorAlertModal}
        onOkClick={() => { finalList(), setErrorAlertModal(false) }}
        onCloseClick={() => setErrorAlertModal(false)}
        res={response}
      />

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Accounts" breadcrumbItem="Inventory Stock" />
          <Row>
            <Col lg="12">

                  <TableContainer
                    columns={columns}
                    data={InventoryList}
                    isGlobalFilter={true}
                    isAddInventoryStockList={true}
                    handleInventoryStockClick={handleInventoryStockClick}
                    customPageSize={10}
                    className="custom-header-css"
                  />

                  <Modal className="modal-lg" isOpen={modal} toggle={toggle} backdrop="static" keyboard={false}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Edit Inventory Stock" : "Add Inventory Stock"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >

                          <Row>
                          <Col xs={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Inventory Name
                              </Label>
                              <Input
                                name="name"
                                type="text"
                                placeholder="Inventory Name"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ""}
                                invalid={
                                  validation.touched.name &&
                                  validation.errors.name
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.name &&
                              validation.errors.name ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.name}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          <Col xs={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Available Quantity
                              </Label>
                              <Input
                                name="availableQuantity"
                                type="number"
                                placeholder="Available Quantity"
                                onChange={validation.handleChange}
                                // onBlur={handleBlur}
                                value={
                                  validation.values.availableQuantity || ""
                                }
                                invalid={
                                  validation.touched.availableQuantity &&
                                  validation.errors.availableQuantity
                                    ? true
                                    : false
                                }
                                min="0"
                              />
                              {validation.touched.availableQuantity &&
                              validation.errors.availableQuantity ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.availableQuantity}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          </Row>
                          <Row>
                          <Col xs={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Re Order Quantity
                              </Label>
                              <Input
                                name="reOrderQuantity"
                                type="number"
                                placeholder="Re Order Quantity"
                                onChange={validation.handleChange}
                                // onBlur={handleBlur}
                                value={validation.values.reOrderQuantity || ""}
                                invalid={
                                  validation.touched.reOrderQuantity &&
                                  validation.errors.reOrderQuantity
                                    ? true
                                    : false
                                }
                                min="0"
                              />
                              {validation.touched.reOrderQuantity &&
                              validation.errors.reOrderQuantity ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.reOrderQuantity}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col>
                            <div className="mb-3">
                              <Label className="form-label">Cost</Label>
                              <Input
                                name="cost"
                                type="number"
                                placeholder="Cost"
                                onChange={validation.handleChange}
                                // onBlur={handleBlur}
                                value={validation.values.cost || ""}
                                invalid={
                                  validation.touched.cost &&
                                  validation.errors.cost
                                    ? true
                                    : false
                                }
                                min="0"
                              />
                              {validation.touched.cost &&
                              validation.errors.cost ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.cost}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                            <button type="submit" className="btn btn-primary w-md me-3">
                                {isEdit ? 'Update' : 'Save'}
                            </button>
                              <button
                                type="button"
                                className="btn btn-secondary w-md "
                                onClick={toggle}
                              >
                                Cancel
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>

            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};


export default withRouter(InventoryStock);
