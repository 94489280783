import React, { useEffect, useState, useMemo } from "react"
import {
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import CancelReservation from "../../components/Common/CancelReservation";
//i18n
import { withTranslation } from "react-i18next"
import { getAllReservation, cancelReservation } from "../../middleware/backent_helper";
import TableContainer from "../../components/Common/TableContainer";
import messages from '../../utils/constants/messages';

const ViewReservation = props => {
  document.title = "StayPro | View Reservation"

  const [hid, setHid] = useState(() => {
    const storedHotelId = localStorage.getItem('hotelId')
    return storedHotelId ? storedHotelId.replace(/"/g, '') : '';
  });
  const [dbName, setDbName] = useState(
    localStorage.getItem('dbName')
  );
  const [masterName, setMasterName] = useState("Reservation");
  const [reservationList, setReservationList] = useState([]);
  const [reservationData, setReservationData] = useState([]);
  const [cancelModal, setCancelModal] = useState(false);
  const [response, setResponse] = useState(false);
  const [errorAlertModal, setErrorAlertModal] = useState(false);

  const columns = useMemo(
    () => [
      {
        Header: "S.No",
        Cell: ({ row, rows }) => {
          return (
            <div className="table-body">
              {rows.length - row.index}
            </div>
          );
        },
      },

      {
        Header: "Guest Name",
        accessor: "GuestName",
        filterable: true,
        Cell: cellProps => {
          return (
            <div className="table-body">
              {cellProps.value}
            </div>
          );
        },
      },

      {
        Header: "Mobile No",
        accessor: "mobileNo",
        filterable: true,
        Cell: cellProps => {
          return (
            <div className="table-body">
              {cellProps.value}
            </div>
          );
        },
      },

      {
        Header: "City",
        accessor: "city",
        filterable: true,
        Cell: cellProps => {
          return (
            <div className="table-cell text-center">
              {cellProps.value}
            </div>
          );
        },
      },

      {
        Header: "Country",
        accessor: "country",
        filterable: true,
        Cell: cellProps => {
          return (
            <div className="table-cell text-center">
              {cellProps.value}
            </div>
          );
        },
      },

      {
        Header: "Check-In",
        accessor: "checkInDate",
        filterable: true,
        Cell: cellProps => {
            const date = new Date(cellProps.value);
            const day = date.getDate().toString().padStart(2, '0');
            const month = date.toLocaleString('default', { month: 'short' });
            const year = date.getFullYear();
            const formattedDate = `${day}-${month}-${year}`;
          return (
            <div className="table-cell text-center">
              {formattedDate}
            </div>
          );
        },
      },

      {
        Header: "Check-Out",
        accessor: "checkOutDate",
        filterable: true,
        Cell: cellProps => {
            const date = new Date(cellProps.value);
            const day = date.getDate().toString().padStart(2, '0');
            const month = date.toLocaleString('default', { month: 'short' });
            const year = date.getFullYear();
            const formattedDate = `${day}-${month}-${year}`;

          return (
            <div className="table-cell text-center">
              {formattedDate}
            </div>
          );
        },
      },

      {
        Header: "Advance",
        accessor: "advanceAmount",
        filterable: true,
        Cell: cellProps => {
          return (
            <div className="table-cell text-center">
              {cellProps.value}
            </div>
          );
        },
      },

      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="table-body actions">
              <div className="actions">
                <Link
                  to="#"
                  className="text-success"
                  onClick={() => {
                    const data = cellProps.row.original;
                    ViewReservation(data);
                  }}
                >
                  <i className="mdi mdi-eye font-size-18" id="Viewtooltip" />
                  <UncontrolledTooltip placement="top" target="Viewtooltip">
                    View
                  </UncontrolledTooltip>
                </Link>
                <Link
                  to="#"
                  className="text-danger"
                  onClick={() => {
                    const data = cellProps.row.original;
                    onPrint(data);
                  }}
                >
                  <i className="mdi mdi-printer-settings font-size-18" id="printtooltip" />
                  <UncontrolledTooltip placement="top" target="printtooltip">
                    Print
                  </UncontrolledTooltip>
                </Link>
                <Link
                  to="#"
                  className="text-success"
                  onClick={() => {
                    const data = cellProps.row.original;
                    onEditClick(data);
                  }}
                >
                  <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Convert
                  </UncontrolledTooltip>
                </Link>
                <Link
                  to="#"
                  className="text-danger"
                  onClick={() => {
                    const data = cellProps.row.original;
                    handleCancelClick(data);
                  }}
                >
                  <i className="mdi mdi-cancel font-size-18" id="deletetooltip" />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Cancel
                  </UncontrolledTooltip>
                </Link>
              </div>
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    getReservationList()
  }, []);

  const getReservationList = () => {
    getAllReservation(hid, dbName)
    .then((res) => {
        setReservationList(res.data.data);
    });
  }

  const ViewReservation = (data) => {
  };

  const onPrint = (data) => {
  };

  const onEditClick = (data) => {
  };

  const onCancelReservation = () => {
    cancelReservation(hid, dbName, reservationData)
    .then((res) => {
        getReservationList()
        setResponse(messages.success.CancelSuccessFull(masterName))
    })
    .catch((err) => {
        if(err.response.status == 400) {
          setResponse(messages.failed.CancelCannotBeDone(masterName))
        } else {
          setResponse(messages.failed.CancelFailed(masterName))
        }
        setErrorAlertModal(true)
    });
    setCancelModal(false);
    setReservationData(null);
  };

  const handleCancelClick = data => {
    setReservationData(data);
    setCancelModal(true);
  };

  const handleViewReservation = () => {
  };

  return (
    <React.Fragment>
        <CancelReservation
            show={cancelModal}
            onDeleteClick={onCancelReservation}
            onCloseClick={() => {setReservationData(null), setCancelModal(false) }}
        />
        <div className="page-content">
            <Container fluid>
                {/* Render Breadcrumbs */}
            <Breadcrumbs title="Reservation" breadcrumbItem="Manage Reservation" />
            <Row>
                <Col lg="12">
                    <TableContainer
                        columns={columns}
                        data={reservationList}
                        isGlobalFilter={true}
                        handleViewReservation={handleViewReservation}
                        customPageSize={10}
                        className="custom-header-css"
                    />
                </Col>
            </Row>
            </Container>
        </div>
    </React.Fragment>
  )
}

export default withTranslation()(ViewReservation)
