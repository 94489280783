import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  roomTypeIdGet,
  roomIdGet,
  addRoomMapping,
  getAllRoomMapping,
  updateRoomMaps,
  deleteRoomMap,
  roomTypeDetailsGet,
} from "../../../middleware/backent_helper";
import { RoomId, RoomTypeId } from "./roomMappingCol";
import messages from '../../../utils/constants/messages';
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import AlertModal from "components/Common/alertModal";
import ErrorAlertModal from "components/Common/errorAlertModal";
import Select from "react-select";
//redux
import { useDispatch } from "react-redux";
import { conformsTo } from "lodash";

const RoomMapping = (props) => {
  //meta title
  document.title = "Room Map | Settings";
  const customSelectStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#2687F9' : 'white', // Change background color on hover
      color: state.isFocused ? 'white' : 'inherit', // Change text color on hover
    }),
  };

  const dispatch = useDispatch();
  const [roomMap, setRoomMap] = useState();
  const [mappingList, setRoomMappingList] = useState([]);
  const [ModuleList, setModuleList] = useState([]);
  const [RoomTypeList, setRoomTypeList] = useState([]);
  const [roomTypeDetails, setRoomTypeDetails] = useState([]);
  const [RoomList, setRoomList] = useState([]);
  const [selectedRoomId, setSelectedRoomId] = useState(null);
  const [selectedRoomType, setSelectedRoomType] = useState(null);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [errorAlertModal, setErrorAlertModal] = useState(false);
  const [masterName, setMasterName] = useState("Room Mapping");
  const [response, setResponse] = useState(false);
  const [hid, setHid] = useState(() => {
    const storedHotelId = localStorage.getItem('hotelId')
    return storedHotelId ? storedHotelId.replace(/"/g, '') : '';
  });
  const [dbName, setDbName] = useState(
    localStorage.getItem('dbName')
  )
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    setIsActive(isActive);
  }, [isActive]);

  const toggleactive = () => {
    setIsActive((prevState) => !prevState);
  };

  const handleCheckboxChange = (e) => {
    setIsActive(e.target.checked);
    validation.setFieldValue("isActive", e.target.checked);
  };

  const handleCheckboxChange1 = (e) => {
    const isChecked = e.target.checked;
    setIsActive(isChecked);
    validation.setFieldValue("isActive", isChecked);
  };

  const columns = useMemo(
    () => [
      {
        Header: "S.No",
        Cell: ({ row, rows }) => {
          return (
            <div className="text-center table-cell" >
              {rows.length - row.index}
            </div>
          );
        },
      },
      {
        Header: "Room No",
        accessor: "roomNo",
        filterable: true,
        Cell: (cellProps) => {
          return (
            <div className="table-cell text-center">
              {cellProps.value}
            </div>
          );

        },
      },
      {
        Header: "Room Type",
        accessor: "Rooms",

        filterable: true,
        Cell: (cellProps) => {
          return (
            <div className="table-cell text-center">
              {cellProps.value.map(room => room.title).join(", ")}
            </div>
          );

        },
      },

      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <div className="text-center">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const room_mapping_Data = cellProps.row.original;
                  handleMappingClick(room_mapping_Data);
                  console.log(room_mapping_Data);
                }}
              >
                <i className="mdi mdi-pencil  font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    RoomsList();
    finalList();
    GetRoomTypeList();
  }, []);

  const finalList = () => {
    getAllRoomMapping(dbName).then((res) => {
      setRoomMappingList(res.data.data);
    });
  };

  //RoomNo Get List
  const RoomsList = () => {
    roomIdGet(dbName).then((res) => {
      setRoomList(res.data.data);
    });
  };

  //RoomType Get List
  const GetRoomTypeList = () => {
    roomTypeIdGet(dbName).then((res) => {
      setRoomTypeList(res.data.data);
    });
  };

  const clearFormValues = () => {
    validation.resetForm();
  };

  const toggle = () => {
    if (modal) {
      setModal(false);
      setRoomMap(null);
      resetRoomIdField();
      resetRoomTypeIdField();
      setIsActive(true);
      clearFormValues();
    } else {
      setModal(true);
    }
  };

  const resetRoomIdField = () => {
    setSelectedRoomId(null);
    validation.setFieldValue("roomId", "");
  };

  const resetRoomTypeIdField = () => {
    setSelectedRoomType(null);
    validation.setFieldValue("roomTypeId", "");
  };

  const handleMappingClick = (arg) => {
    const Map = arg;
    const activeRoomType = [];

    Map.Rooms.forEach((room, id) => {
      if (room.isActive === 1) {
        activeRoomType.push({ id, ...room });
        setSelectedRoomId({ value: Map.id, label: Map.roomNo });
        setSelectedRoomType({ value: room.roomTypeId, label: room.title });
      }
    });

    try {
      roomTypeDetailsGet(Map.id, dbName)
        .then((res) => {
          setRoomTypeDetails(res.data.data);
          setRoomMap({
            id: Map.id,
            roomTypeId: Map.Rooms[0].roomTypeId,
            roomId: Map.id,
            isActive: Map.Rooms[0].isActive,
            hotelId: hid,
            dbName: dbName,
          });
          setIsEdit(true);
          toggle();
        })
        .catch((error) => {
          console.error("Error fetching room type details:", error);
        });
    } catch (error) {
      console.error("Error fetching room type details:", error);
    }
  };

  var node = useRef();
  const onPaginationPageChange = (page) => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const handleRoomMappingClicks = () => {
    setIsEdit(false);
    toggle();
  };

  const validation = useFormik({
    initialValues: {
      roomId: roomMap ? roomMap.roomId : "",
      roomTypeId: roomMap ? roomMap.roomTypeId : "",
      isActive: roomMap ? roomMap.isActive : true,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      roomId: Yup.string().required("Please Select Your Room"),
      roomTypeId: Yup.string().required("Please Select Your RoomType"),
      // isActive: Yup.boolean(),
    }),
    onSubmit: (values) => {
      values.isActive = isActive;
      if (isEdit) {
        const update_Room_Map_Data = { ...roomMap, ...values };

        updateRoomMaps(update_Room_Map_Data)
          .then((res) => {
            setResponse(messages.success.updatedDetails(masterName))
            setAlertModal(true);
          })
          .catch((err) => {
            setResponse(messages.failed.updateFailed(masterName))
            setErrorAlertModal(true);
          });
        toggle();

      } else {
        dispatch(Add_Mapping(values));
      }
    },
  });

  const Add_Mapping = (values) => {
    const data = JSON.stringify({
      roomId: values.roomId,
      roomTypeId: values.roomTypeId,
      isActive: isActive,
      hotelId: hid,
      dbName: dbName,
    });
    addRoomMapping(data)
      .then((res) => {
        if (res.data.success) {
          setResponse(messages.success.createdMessage(masterName))
          setAlertModal(true);
        }
      }).catch(err => {
        if (err.response.status === 404) {
          setResponse(messages.failed.AlreadyExists(masterName))
        } else {
          setResponse(messages.failed.createFailed(masterName))
        }
        setErrorAlertModal(true)
      });
    RoomsList();
    getAllRoomMapping(dbName);
    finalList();
    toggle();
  };

  const handleRoomTypeChange = (selectedOption) => {
    setSelectedRoomType(selectedOption);
    validation.setFieldValue("roomTypeId", selectedOption ? selectedOption.value : "");

    if (isEdit == true) {

      const selectedRoomTypeId = selectedOption.value;

      const selectedRoomTypeDetails = roomTypeDetails.filter(mapping => mapping.roomTypeId === selectedRoomTypeId);

      if (selectedRoomTypeDetails.length > 0) {
        const isActive = selectedRoomTypeDetails[0].isActive;
        setIsActive(isActive);
        validation.setFieldValue("isActive", isActive);
      } else {
        setIsActive(false);
        validation.setFieldValue("isActive", false);
      }

    }
  };

  const handleRoomChange = (selectedOption) => {
    setSelectedRoomId(selectedOption);
    validation.setFieldValue("roomId", selectedOption ? selectedOption.value : "");
  };

  return (
    <React.Fragment>
      <AlertModal
        show={alertModal}
        onOkClick={() => {
          finalList();
          setAlertModal(false);
        }}
        onCloseClick={() => setAlertModal(false)}
        res={response}
      />
      <ErrorAlertModal
        show={errorAlertModal}
        onOkClick={() => {
          finalList();
          setErrorAlertModal(false);
        }}
        onCloseClick={() => setErrorAlertModal(false)}
        res={response}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Settings" breadcrumbItem="Room Mapping Details" />
          <Row>
            <Col lg={12}>

              <TableContainer
                columns={columns}
                data={mappingList}
                isGlobalFilter={true}
                isAddRoomMapList={true}
                handleRoomMappingClicks={handleRoomMappingClicks}
                customPageSize={10}
                className="custom-header-css"
              />
              <Modal className="modal-lg" isOpen={modal} toggle={toggle} backdrop="static" keyboard={false}>
                <ModalHeader toggle={toggle} tag="h4">
                  {!!isEdit ? "Edit Mapping" : "Add Mapping"}
                </ModalHeader>
                <ModalBody>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <Row>
                      <Col xs={6}>
                        <div className="mb-3">
                          <Label className="form-label">Room No</Label>
                          <Select
                            id="roomId"
                            name="roomId"
                            placeholder="Select Room"
                            value={selectedRoomId}
                            onChange={handleRoomChange}
                            options={RoomList.map((type) => ({
                              value: type.id,
                              label: type.roomNo,
                            }))}
                            classNamePrefix="react-select"
                            className={`react-select-container ${validation.touched.roomId && validation.errors.roomId
                              ? "is-invalid"
                              : ""
                              }`}
                            isClearable
                            isSearchable
                            isDisabled={isEdit}
                            styles={{
                              menu: (provided) => ({
                                ...provided,
                                top: "100%",
                                position: "absolute",
                                width: "100%",
                                zIndex: 999,
                              }),
                            }}
                          />
                          {validation.touched.roomId && validation.errors.roomId ? (
                            <FormFeedback type="invalid">
                              {validation.errors.roomId}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className="mb-3">
                          <Label className="form-label">Room Type </Label>
                          <Select
                            id="roomTypeId"
                            name="roomTypeId"
                            placeholder="Select Room Type"
                            value={selectedRoomType}
                            onChange={handleRoomTypeChange}
                            options={RoomTypeList.map((type) => ({
                              value: type.id,
                              label: type.title,
                            }))}
                            classNamePrefix="react-select"
                            isClearable
                            isSearchable
                            styles={customSelectStyles} // Apply custom styles here
                            styles1={{
                              menu: (provided) => ({
                                ...provided,
                                top: "100%",
                                position: "absolute",
                                width: "100%",
                                zIndex: 999,
                              }),
                            }}
                            className={`react-select-container ${validation.touched.roomTypeId && validation.errors.roomTypeId
                              ? "is-invalid"
                              : ""
                              }`}
                          />
                          {validation.touched.roomTypeId && validation.errors.roomTypeId ? (
                            <FormFeedback type="invalid">
                              {validation.errors.roomTypeId}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col xs={12}>
                        {!isEdit ? (

                          <div className="form-check form-switch form-switch-md mb-3">
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              id="isActive"
                              name="isActive"
                              checked={validation.values.isActive}
                              onChange={handleCheckboxChange1}
                              onClick={toggleactive}
                            />
                            <Label className="form-check-label" htmlFor="isActive">
                              Default
                            </Label>
                            {validation.touched.isActive && validation.errors.isActive ? (
                              <FormFeedback type="invalid">
                                {validation.errors.isActive}
                              </FormFeedback>
                            ) : null}
                          </div>
                        ) : (
                          <div className="form-check form-switch form-switch-md mb-3">
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              id="isActive"
                              name="isActive"
                              checked={isActive}
                              onChange={handleCheckboxChange}
                              onClick={toggleactive}
                            />
                            <Label className="form-check-label" htmlFor="isActive">
                              Default Status
                            </Label>
                            {validation.touched.isActive && validation.errors.isActive ? (
                              <FormFeedback type="invalid">
                                {validation.errors.isActive}
                              </FormFeedback>
                            ) : null}
                          </div>
                        )}
                        {!!isEdit && roomMap && (
                          <table className="mt-4 table">
                            <thead>
                              <tr>
                                <th>S.No</th>
                                <th>Room Type</th>
                                <th>Default</th>
                              </tr>
                            </thead>

                            <tbody>

                              {Array.isArray(roomTypeDetails) && roomTypeDetails.length > 0 && (
                                <React.Fragment>
                                  {roomTypeDetails.map((mapping, index) => (
                                    <tr key={index}>
                                      <td>{index + 1}</td>
                                      <td>{mapping.title}</td>
                                      <td>{mapping.isActive ? "Yes" : "No"}</td>
                                    </tr>
                                  ))}
                                </React.Fragment>
                              )}


                            </tbody>
                          </table>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="text-end">

                          {!isEdit ? (
                            <button type="submit" className="btn btn-primary  w-md me-3">
                              Save
                            </button>
                          ) : (
                            <button type="submit" className="btn btn-primary   w-md me-3">
                              Update
                            </button>

                          )}
                          <button
                            type="button"
                            className="btn btn-secondary w-md "
                            onClick={toggle}
                          >
                            Cancel
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </ModalBody>
              </Modal>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(RoomMapping);
