import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../../components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { addOnlinePortalType, getPortalType, updatePortalType ,deletePortalType  } from "../../../middleware/backent_helper";
import { PortalTypeName,PortalTypeGSTNO,PortalTypePercentage,GstPercentage,TcsPercentage } from "./OnlinePortalCol";
import messages from '../../../utils/constants/messages';
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import DeleteModal from "../../../components/Common/DeleteModal";
import AlertModal from "../../../components/Common/alertModal";
import ErrorAlertModal from "../../../components/Common/errorAlertModal";
//redux
import { useSelector, useDispatch } from "react-redux";

const OnlinePortalTypes = props => {
  //meta title
  document.title = "Online Portal Types | Settings ";

  const dispatch = useDispatch();
  const [PortalList, setPortalList] = useState([]);
  const [Portals, setPortal] = useState();
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [errorAlertModal, setErrorAlertModal] = useState(false);
  const [masterName, setMasterName] = useState("Online Portal");
  const [response, setResponse] = useState(false);
  const [hid, setHid] = useState(() => {
    const storedHotelId = localStorage.getItem('hotelId')
    return storedHotelId ? storedHotelId.replace(/"/g, '') : '';
  });
  const [dbName, setDbName] = useState(
    localStorage.getItem('dbName')
  )

  const columns = useMemo(
    () => [
      {
        Header: "S.No",
        Cell: ({ row, rows }) => {
          return (
            <div  className="table-cell text-center">
              {rows.length - row.index}
            </div>
          );
        },
      },

      {
        Header: "Portal Type Name",
        accessor: "portalTypeName",
        filterable: true,
        Cell: cellProps => {
          return (
            <div  className="table-cell text-center">
              <PortalTypeName {...cellProps} />

            </div>
          );

        },
      },

      {
        Header: "Portal Type GST No",
        accessor: "gstNo",
        filterable: true,
        Cell: cellProps => {
          return (
            <div  className="table-cell text-center">
              <PortalTypeGSTNO {...cellProps} />

          </div>
          );

        },
      },

      {
        Header: "Portal Type (%)",
        accessor: "portalPercentage",
        filterable: true,
        Cell: cellProps => {
          return (
            <div  className="table-cell text-center">
             <PortalTypePercentage {...cellProps} />
            </div>
          );

        },
      },

      {
        Header: "GST (%)",
        accessor: "gstPercentage",
        filterable: true,
        Cell: cellProps => {
          return (
            <div  className="table-cell text-center">
             <GstPercentage {...cellProps} />
            </div>
          );

        },
      },

      {
        Header: "TCS (%)",
        accessor: "tcsPercentage",
        filterable: true,
        Cell: cellProps => {
          return (
            <div  className="table-cell text-center">
              <TcsPercentage {...cellProps} />
            </div>
          );

        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div  className="table-body actions">
              <div className="actions">
                <Link
                  to="#"
                  className="text-success"
                  onClick={() => {
                    const PortalData = cellProps.row.original;
                    handlePortalClicks(PortalData);
                  }}
                >
                  <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </Link>
                <Link
                  to="#"
                  className="text-danger"
                  onClick={() => {
                    const portalTypeData = cellProps.row.original;
                    onClickDelete(portalTypeData);
                  }}
                >
                  <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </div>
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    finalList()
  }, [])

  const finalList = () => {
    getPortalType(hid , dbName)
      .then((res) => {
        setPortalList(res.data.data);
      });
  };

  const clearFormValues = () => {
    validation.resetForm();
  };

  const toggle = () => {
    if (modal) {
      setModal(false);
      setPortal(null);
      clearFormValues();
    } else {
      setModal(true);
    }
  };

  const handlePortalClicks = arg => {
    const portal = arg;
    setPortal({
      id: portal.id,
      portalTypeName: portal.portalTypeName,
      portalPercentage: portal.portalPercentage,
      gstNo: portal.gstNo,
      gstPercentage: portal.gstPercentage,
      tcsPercentage: portal.tcsPercentage,
      hotelId: hid,
      dbName: dbName,
    });
    setIsEdit(true);
    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = users => {
    setPortal(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    deletePortalType(Portals , dbName)
    .then((res) => {
      getPortalType(hid, dbName), finalList()
      setResponse(messages.success.deleteDetails(masterName))
      setAlertModal(true)
    })
    .catch((err) => {
      if(err.response.status == 400) {
        setResponse(messages.failed.cannotbeDeleted(masterName))
      } else {
        setResponse(messages.failed.updateFailed(masterName))
      }
      setErrorAlertModal(true)
    });
    onPaginationPageChange(1);
    setDeleteModal(false);
    setPortal(null);
  };

  const handlePortalTypeClick = () => {
    setIsEdit(false);
    toggle();
  };

  //last two digit fix function
  const formatToTwoDecimalPlaces = (value) => {
    const number = parseFloat(value);
    return isNaN(number) ? "" : number.toFixed(2);
  };

  const handleBlur = (event) => {
    validation.handleBlur(event);
    const formattedValue = formatToTwoDecimalPlaces(event.target.value);
    validation.setFieldValue(event.target.name, formattedValue);
  };

  const validation = useFormik({

    initialValues: {
      portalTypeName: Portals ? Portals.portalTypeName : "",
      portalPercentage: Portals ? Portals.portalPercentage : "",
      gstNo: Portals ? Portals.gstNo : "",
      gstPercentage: Portals ? Portals.gstPercentage : "",
      tcsPercentage: Portals ? Portals.tcsPercentage : "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      portalTypeName: Yup.string().required("Please Enter From Portal Type Name"),
      portalPercentage: Yup.string().required("Please Enter Portal Percentage"),
      gstNo: Yup.string().required("Please Enter GST No"),
      gstPercentage: Yup.string().required("Please Enter GST Percentage"),
      tcsPercentage: Yup.string().required("Please Enter TCS Percentage"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updatedPortalTypeData = { ...Portals, ...values };
        updatePortalType(updatedPortalTypeData)
          .then((res) => {
            setResponse(messages.success.updatedDetails(masterName))
            setAlertModal(true)
          })
          .catch((err) => {
            if (err.response.status === 401) {
              setResponse(messages.failed.alreadyInUse(masterName))
            } else {
              setResponse(messages.failed.updateFailed(masterName))
            }
            setErrorAlertModal(true)
          });
        toggle();
      } else {
        dispatch(post_Portal(values));
      }
    }
  });
  const post_Portal = (values) => {
    const data = JSON.stringify({
        portalTypeName: values.portalTypeName,
        portalPercentage: values.portalPercentage,
        gstNo: values.gstNo,
        gstPercentage: values.gstPercentage,
        tcsPercentage: values.tcsPercentage,
        hotelId: hid,
        dbName: dbName,
        isActive: true,
    });
    addOnlinePortalType(data).then((res) => {
      if (res.data.success) {
        setResponse(messages.success.createdMessage(masterName))
        setAlertModal(true)
      }
    }).catch(err => {
      if(err.response.status === 404) {
        setResponse(messages.failed.AlreadyExists(masterName))
      } else {
        setResponse(messages.failed.createFailed(masterName))
      }
      setErrorAlertModal(true)
    });
    toggle();
  };

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => { setPortal(null), setDeleteModal(false) }}
      />
      <AlertModal
        show={alertModal}
        onOkClick={() => {
          finalList();
          setAlertModal(false);
        }}
        onCloseClick={() => setAlertModal(false)}
        res={response}
      />
      <ErrorAlertModal
       show={errorAlertModal}
       onOkClick={() =>{ finalList() ,setErrorAlertModal(false) }}
       onCloseClick={() => setErrorAlertModal(false)}
       res={response}
      />

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Settings" breadcrumbItem="Manage Online Portal Types" />
          <Row>
            <Col lg="12">

                  <TableContainer
                    columns={columns}
                    data={PortalList}
                    isGlobalFilter={true}
                    isAddPortalTypeList={true}
                    handlePortalTypeClicks={handlePortalTypeClick}
                    customPageSize={10}
                    className="custom-header-css"
                  />

                  <Modal  className="modal-lg" isOpen={modal} toggle={toggle}  backdrop="static" keyboard={false}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Edit Portal Type" : "Add Portal Type"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >

                            <Row>
                              <Col xs={6}>

                            <div className="mb-3">
                              <Label className="form-label">Portal Type Name</Label>
                              <Input
                                name="portalTypeName"
                                type="text"
                                placeholder="Portal Type Name"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.portalTypeName || ""}
                                invalid={
                                  validation.touched.portalTypeName &&
                                    validation.errors.portalTypeName
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.portalTypeName &&
                                validation.errors.portalTypeName ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.portalTypeName}
                                </FormFeedback>
                              ) : null}
                            </div>
                            </Col>
                            <Col xs={6}>

                            <div className="mb-3">
                              <Label className="form-label">Portal Percent (%)</Label>
                              <Input
                                name="portalPercentage"
                                type="number"
                                placeholder="Poral Percentage"
                                onChange={validation.handleChange}
                                onBlur={handleBlur}
                                value={validation.values.portalPercentage || ""}
                                invalid={
                                  validation.touched.portalPercentage &&
                                    validation.errors.portalPercentage
                                    ? true
                                    : false
                                }
                                min="0"
                              />
                              {validation.touched.portalPercentage &&
                                validation.errors.portalPercentage ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.portalPercentage}
                                </FormFeedback>
                              ) : null}
                            </div>
                            </Col>
                            </Row>
                            <Row>
                            <Col xs={6}>
                            <div className="mb-3">
                              <Label className="form-label">Portal Type GST No</Label>
                              <Input
                                name="gstNo"
                                type="text"
                                placeholder="Gst No"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.gstNo || ""}
                                invalid={
                                  validation.touched.gstNo &&
                                    validation.errors.gstNo
                                    ? true
                                    : false
                                }

                              />
                              {validation.touched.gstNo &&
                                validation.errors.gstNo ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.gstNo}
                                </FormFeedback>
                              ) : null}
                            </div>
                            </Col>
                            <Col>
                            <div className="mb-3">
                              <Label className="form-label">Portal Type GST (%)</Label>
                              <Input
                                name="gstPercentage"
                                type="number"
                                placeholder="Gst Percentage"
                                onChange={validation.handleChange}
                                onBlur={handleBlur}
                                value={validation.values.gstPercentage || ""}
                                invalid={
                                  validation.touched.gstPercentage &&
                                    validation.errors.gstPercentage
                                    ? true
                                    : false
                                }
                                min="0"
                              />
                              {validation.touched.gstPercentage &&
                                validation.errors.gstPercentage ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.gstPercentage}
                                </FormFeedback>
                              ) : null}
                            </div>
                             </Col>
                             </Row>
                             <Row>

                            <Col>
                            <div className="mb-3">
                              <Label className="form-label">TCS Percent (%)</Label>
                              <Input
                                name="tcsPercentage"
                                type="number"
                                placeholder="TCS Percentage"
                                onChange={validation.handleChange}
                                onBlur={handleBlur}
                                value={validation.values.tcsPercentage || ""}
                                invalid={
                                  validation.touched.tcsPercentage &&
                                    validation.errors.tcsPercentage
                                    ? true
                                    : false
                                }
                                min="0"
                              />
                              {validation.touched.tcsPercentage &&
                                validation.errors.tcsPercentage ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.tcsPercentage}
                                </FormFeedback>
                              ) : null}
                            </div>
                             </Col>
                             </Row>
                          <Row>
                          <Col>
                          <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-primary save-user w-md me-3"
                              >
                                {!!isEdit ? "Update" : "Save"}
                              </button>
                              <button
                                type="button"
                                className="btn btn-secondary w-md "
                                onClick={toggle}
                              >
                                Cancel
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>

            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};


export default withRouter(OnlinePortalTypes);
