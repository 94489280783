const TransactionDate = (cell) => {
    return cell.value ? cell.value : '';
};

const Expenses = (cell) => {
    return cell.value ? cell.value : '-';
};

const Cash = (cell) => {
    const cash = cell.row.original.inventoryCash;
    if (cash === null) {
        return cell.value ? cell.value: '';
    } else if (cash !== undefined) {
        return cell.row.original.inventoryCash ? cell.row.original.inventoryCash.toFixed(2) : ''
    } else {
        return cell.value ? cell.value : '';
    }
};

const Remarks = (cell) => {
    return cell.value ? cell.value : '-';
};

const Description = ({ cell }) => {
    const bookingId = cell.row.original.BookingId;
    if (bookingId === null) {
        return "Inventory Sales without room";
    } else if (bookingId !== undefined) {
        return `Inventory Sales for room ${bookingId}`;
    } else {
        return cell.value ? cell.value : '-';
    }
};

export {
    TransactionDate,
    Description,
    Expenses,
    Cash,
    Remarks,
};