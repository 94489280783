import React, { useEffect, useState } from "react";
import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { registerUser, apiError } from "../../store/actions";
import { userRegister } from "../../middleware/backent_helper";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Register = (props) => {
  document.title = "Register | Hotel";
  const dispatch = useDispatch();
  const [showOTP, setShowOTP] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null); // State to manage error message
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      username: "",
      password: "",
      hotel:"",
      number:""
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      username: Yup.string().required("Please Enter Your Username"),
      password: Yup.string().required("Please Enter Your Password"),
      hotel: Yup.string().required("Please Enter Your Hotel Name"),
      number: Yup.string().required("Please Enter Your Phone Number"),
    }),
    onSubmit: (values) => {
      dispatch(POST_REGISTER(values));
    },
  });
  const POST_REGISTER = (values) => {
    const data = JSON.stringify({
      email: values.email,
      password: values.password,
      role: "hotel",
      name:values.username,
      isActive: true,
      hotelName: values.hotel,
      mobileNo:values.number
    });
    userRegister(data).then((res) => {
      toast.success("Register successfully.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        onClose: () => {
          window.location.href = `/plan-detail`;
        }

      });
    }).catch(err => {
      setErrorMessage("Hotel already exists");
    });
  };
  const { user, registrationError, loading } = useSelector((state) => ({
    user: state.Account.user,
    registrationError: state.Account.registrationError,
    loading: state.Account.loading,
  }));
  useEffect(() => {
    dispatch(apiError(""));
  }, []);
  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home-alt h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Register</h5>
                        <p>Get your register details now.</p>
                      </div>
                    </Col>

                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    {/* <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img src={logoImg} alt="" className="rounded-circle" height="34" />
                        </span>
                      </div>
                    </Link> */}
                  </div>
                  <div className="p-2">

                      <Form
                        className="form-horizontal"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        {errorMessage &&
                          <Alert color="danger">
                            {errorMessage}
                          </Alert>
                        }
                        <div className="mb-3">
                          <Label className="form-label">Hotel Name</Label>
                          <Input
                            name="hotel"
                            type="text"
                            placeholder="Enter Hotel Name"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.hotel || ""}
                            invalid={
                              validation.touched.hotel && validation.errors.hotel ? true : false
                            }
                          />
                          {validation.touched.hotel && validation.errors.hotel ? (
                            <FormFeedback type="invalid">{validation.errors.hotel}</FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label className="form-label">Email</Label>
                          <Input
                            id="email"
                            name="email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={validation.touched.email && validation.errors.email ? true : false}
                          />
                          {validation.touched.email && validation.errors.email ? (
                            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label className="form-label">Username</Label>
                          <Input
                            name="username"
                            type="text"
                            placeholder="Enter username"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.username || ""}
                            invalid={
                              validation.touched.username && validation.errors.username ? true : false
                            }
                          />
                          {validation.touched.username && validation.errors.username ? (
                            <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label className="form-label">Phone Number</Label>
                          <Input
                            name="number"
                            type="text"
                            placeholder="Enter Phone Number"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.number || ""}
                            invalid={
                              validation.touched.number && validation.errors.number ? true : false
                            }
                          />
                          {validation.touched.number && validation.errors.number ? (
                            <FormFeedback type="invalid">{validation.errors.number}</FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label className="form-label">Password</Label>
                          <Input
                            name="password"
                            type="password"
                            placeholder="Enter Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password || ""}
                            invalid={
                              validation.touched.password && validation.errors.password ? true : false
                            }
                          />
                          {validation.touched.password && validation.errors.password ? (
                            <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                        <Label className="form-label">Confirm Password</Label>
                        <Input
                          name="confirmPassword"
                          className="form-control"
                          placeholder="Confirm new password"
                          type="text" // Changed type to "text" to make the password visible
                          // onChange={validation.handleChange}
                          // onBlur={validation.handleBlur}
                          // value={validation.values.confirmPassword || ""}
                          invalid={validation.touched.confirmPassword && validation.errors.confirmPassword}
                        />
                        {validation.touched.confirmPassword && validation.errors.confirmPassword ? (
                          <FormFeedback type="invalid">{validation.errors.confirmPassword}</FormFeedback>
                        ) : null}
                      </div>
                        <div className="mt-4">
                          <button className="btn btn-primary btn-block " type="submit">
                            Register
                          </button>
                        </div>
                        <div className="mt-4 text-center">
                          <p className="mb-0">
                            By registering you agree to the Hotel{" "}
                            <Link to="#" className="text-primary">
                              Terms of Use
                            </Link>
                          </p>
                        </div>
                      </Form>



                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Already have an account ?{" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    {" "}
                    Login
                  </Link>{" "}
                </p>

              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default Register;