import React, { useState, useEffect } from "react";
import { Row, Col, Alert, Card, CardBody, Container, FormFeedback, Input, Label, Form } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";
import * as Yup from "yup";
import { useFormik } from "formik";
import 'react-toastify/dist/ReactToastify.css';
import { getHotelUser, updatePassword } from "../../middleware/backent_helper";
import messages from '../../utils/constants/messages';
//Import Breadcrumb
import AlertModal from "components/Common/alertModal";
import ErrorAlertModal from "components/Common/errorAlertModal";

const ChangePassword = props => {
  document.title = "Reset Password | Hotel";

  const navigate = useNavigate();
  const [alertModal, setAlertModal] = useState(false);
  const [errorAlertModal, setErrorAlertModal] = useState(false);
  const [response, setResponse] = useState(false);
  const [email, setEmail] = useState(
    localStorage.getItem('email')
  );
  const Email = email.replace(/"/g, "");
  const [hid, setHid] = useState(() => {
    const storedHotelId = localStorage.getItem('hotelId')
    return storedHotelId ? storedHotelId.replace(/"/g, '') : '';
  });
  const [dbName, setDbName] = useState(
    localStorage.getItem('dbName')
  )
  const [hotelUser, setHotelUser] = useState([]);
  const [changePassword, setMasterName] = useState("Password");

  useEffect(() => {
    finalList()
  }, [])

  const finalList = () => {
    getHotelUser(hid, dbName)
      .then((res) => {
        setHotelUser(res.data.data);
      });
  };

  const clearFormValues = () => {
    validation.resetForm();
  };

  const toggle = () => {
    setHotelUser(null);
    clearFormValues();
  }

  const redirectpage = () => {
    navigate(-1);
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      currentPassword: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      currentPassword: Yup.string().required("Please Enter Your Old Password"),
      password: Yup.string().required("Please Enter Your New Password")
        .min(8, 'Password must be at least 8 characters long')
        .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
        .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
        .matches(/[0-9]/, 'Password must contain at least one number')
        .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Please confirm your new password'),
    }),
    onSubmit: async (values) => {
      updatePassword(hid, dbName, values)
        .then((res) => {
          setResponse(messages.success.changePassword(changePassword))
          setAlertModal(true)
        })
        .catch((err) => {
          if (err.response.status === 400) {
            setResponse(messages.failed.passwordNotMatch(changePassword))
          } 
          else {
            setResponse(messages.failed.passwordUpdateFailed(changePassword))
          }
          setErrorAlertModal(true)
        });
      toggle();
    }
  });
  return (
    <React.Fragment>
      <AlertModal
        show={alertModal}
        onOkClick={() => { setAlertModal(false), redirectpage() }}
        onCloseClick={() => { setAlertModal(false), redirectpage() }}
        res={response}
      />
      <ErrorAlertModal
        show={errorAlertModal}
        onOkClick={() => { finalList(), setErrorAlertModal(false) }}
        onCloseClick={() => setErrorAlertModal(false)}
        res={response}
      />
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home-alt h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row>
            <Col>
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <div>
                    <div className=" mb-4">
                      <h5 className="text-primary mt-5">Change Password</h5>
                    </div>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">Current Password</Label>
                        <Input
                          name="currentPassword"
                          className="form-control"
                          placeholder="Enter Current Password"
                          type="password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.currentPassword || ""}
                          invalid={validation.touched.currentPassword && validation.errors.currentPassword}
                        />
                        {validation.touched.currentPassword && validation.errors.currentPassword ? (
                          <FormFeedback type="invalid">{validation.errors.currentPassword}</FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">New Password</Label>
                        <Input
                          name="password"
                          className="form-control"
                          placeholder="Enter new password"
                          type="password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ""}
                          invalid={validation.touched.password && validation.errors.password}
                        />
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Confirm Password</Label>
                        <Input
                          name="confirmPassword"
                          className="form-control"
                          placeholder="Confirm new password"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.confirmPassword || ""}
                          invalid={validation.touched.confirmPassword && validation.errors.confirmPassword}
                        />
                        {validation.touched.confirmPassword && validation.errors.confirmPassword ? (
                          <FormFeedback type="invalid">{validation.errors.confirmPassword}</FormFeedback>
                        ) : null}
                      </div>
                      <Row className="mb-3">
                        <Col className="text-end">
                          <button
                            className="btn btn-primary w-md "
                            type="submit"
                          >
                            Update Password
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ChangePassword);