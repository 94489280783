import * as moment from "moment";

const AssetName = (cell) => {
    return cell.value ? cell.value : '';
};

const AvailableQuantity = (cell) => {
    return cell.value ? cell.value : '';
};

const Cost = (cell) => {
    return cell.value ? cell.value : '';
};


export {
    AssetName,
    AvailableQuantity,
    Cost,
};