import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../../components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { PaymentTypeList } from "./PaymentTypeCol";
import messages from '../../../utils/constants/messages';
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import DeleteModal from "../../../components/Common/DeleteModal";
import AlertModal from "../../../components/Common/alertModal";
import ErrorAlertModal from "../../../components/Common/errorAlertModal";
//redux
import { useSelector, useDispatch } from "react-redux";
import { createPaymentType, getPaymentTypes, deletePaymentType } from "../../../middleware/backent_helper";

const PaymentType = props => {
  //meta title
  document.title = "PaymentType | Accounts ";

  const dispatch = useDispatch();
  const [paymentTypeList, setPaymentTypes] = useState([]);
  const [paymentTypeData, setPaymentTypesData] = useState([]);
  const [Category, setCategory] = useState();
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [errorAlertModal, setErrorAlertModal] = useState(false);
  const [masterName, setMasterName] = useState("PaymentType");
  const [response, setResponse] = useState(false);
  const [hid, setHid] = useState(() => {
    const storedHotelId = localStorage.getItem('hotelId')
    return storedHotelId ? storedHotelId.replace(/"/g, '') : '';
  });
  const [dbName, setDbName] = useState(
    localStorage.getItem('dbName')
  )


  const columns = useMemo(
    () => [
      {
        Header: "S.No",
        Cell: ({ row, rows }) => {
          return (
            <div className="table-cell text-center">
              {rows.length - row.index}
            </div>
          );
        },
      },

      {
        Header: "PaymentType",
        accessor: "paymentType",
        filterable: true,
        Cell: cellProps => {
          return (
            <div className="table-cell text-center" >
              <PaymentTypeList {...cellProps} />
            </div>
          );
        },
      },

      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="text-center">
              <Link
                to="#"
                className="text-center text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  onClickDelete(userData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    getPaymentTypeslist()
  }, [])

  const getPaymentTypeslist = () => {
    getPaymentTypes(hid, dbName)
      .then((res) => {
        setPaymentTypes(res.data.data);
      });
  };

  const clearFormValues = () => {
    validation.resetForm();
  };

  const toggle = () => {
    if (modal) {
      setModal(false);
      setCategory(null);
      clearFormValues();
    } else {
      setModal(true);
    }
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = data => {
    setPaymentTypesData(data);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    deletePaymentType(paymentTypeData, dbName)
    .then((res) => {
      getPaymentTypes(hid, dbName), getPaymentTypeslist()
      setResponse(messages.success.deleteDetails(masterName))
      setAlertModal(true)
    })
    .catch((err) => {
      if(err.response.status == 400) {
        setResponse(messages.failed.cannotbeDeleted(masterName))
      } else {
        setResponse(messages.failed.updateFailed(masterName))
      }
      setErrorAlertModal(true)
    });
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handlePaymentTypeClick = () => {
    setIsEdit(false);
    toggle();
  };

  const validation = useFormik({
    initialValues: {
      PaymentTypeName: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      PaymentTypeName: Yup.string().required("Fields Should not be empty"),

    }),
    onSubmit: (values) => {
      if (isEdit) {
        // WE Don't need edit option for the payment Type
        // const updatedPaymentTypeData = { ...paymentTypeData, ...values };
        // updateCategory(updatedPaymentTypeData)
        //   .then((res) => {
        //     setResponse(messages.success.updatedDetails(masterName))
        //     setAlertModal(true)
        //   })
        //   .catch((err) => {
        //     setResponse(messages.failed.updateFailed(masterName))
        //     setErrorAlertModal(true)
        //   });
        // toggle();
      } else {
        dispatch(Create_payment_type(values));
      }
    }
  });
  const Create_payment_type = (values) => {
    const data = JSON.stringify({
      isActive: true,
      hotelId: hid,
      paymentType: values.PaymentTypeName,
      hotelId: hid,
      dbName: dbName
    });
    createPaymentType(data).then((res) => {
      if (res.data.success) {
        setResponse(messages.success.createdMessage(masterName))
        setAlertModal(true)
      }
    }).catch(err => {
      if (err.response.status === 404) {
        setResponse(messages.failed.AlreadyExists(masterName))
      } else {
        setResponse(messages.failed.createFailed(masterName))
      }
      setErrorAlertModal(true)
    });
    toggle();
  };

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <AlertModal
        show={alertModal}
        onOkClick={() => {
          getPaymentTypeslist();
          setAlertModal(false);
        }}
        onCloseClick={() => setAlertModal(false)}
        res={response}
      />
      <ErrorAlertModal
        show={errorAlertModal}
        onOkClick={() => { getPaymentTypeslist(), setErrorAlertModal(false) }}
        onCloseClick={() => setErrorAlertModal(false)}
        res={response}
      />

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Accounts" breadcrumbItem="Payment Type" />
          <Row>
            <Col lg="12">

              <TableContainer
                columns={columns}
                data={paymentTypeList}
                isGlobalFilter={true}
                isAddPaymentTypeList={true}
                handlePaymentTypeClick={handlePaymentTypeClick}
                customPageSize={10}
                className="custom-header-css"
              />

              <Modal isOpen={modal} toggle={toggle} backdrop="static" keyboard={false}>
                <ModalHeader toggle={toggle} tag="h4">
                  {!!isEdit ? "Edit Payment Type" : "Add Payment Type"}
                </ModalHeader>
                <ModalBody>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <Row>
                      <Col xs={12}>
                        <div className="mb-3">
                          <Label className="form-label">Payment Type</Label>
                          <Input
                            name="PaymentTypeName"
                            type="text"
                            placeholder="Payment Type"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.PaymentTypeName || ""}
                            invalid={
                              validation.touched.PaymentTypeName &&
                                validation.errors.PaymentTypeName
                                ? true
                                : false
                            }
                          />
                          {validation.touched.PaymentTypeName &&
                            validation.errors.PaymentTypeName ? (
                            <FormFeedback type="invalid">
                              {validation.errors.PaymentTypeName}
                            </FormFeedback>
                          ) : null}
                        </div>

                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="text-end">
                          <button
                            type="submit"
                            className="btn btn-primary save-user me-3 w-md"
                          >
                            Save
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary w-md "
                            onClick={toggle}
                          >
                            Cancel
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </ModalBody>
              </Modal>

            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};


export default withRouter(PaymentType);
