const Description = (cell) => {
    return cell.value ? cell.value : '';
};

const InventoryDates = (cell) => {
    return cell.value ? cell.value : '';
};

const InventoryCash = (cell) => {
    return cell.value ? cell.value : '';
};

const Remarks = (cell) => {
    return cell.value ? cell.value : '';
};

export {
    Description,
    InventoryDates,
    InventoryCash,
    Remarks
};