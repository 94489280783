import React, { useEffect, useState } from "react";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Form, FormGroup, Label, Input, FormFeedback, InputGroup, InputGroupText } from 'reactstrap';
import { hotelRegister, getAllHotels, updateHotal, deleteUser, getCountries, getStates, getCities, getPlans } from "../../middleware/backent_helper";
import PlanCard from './planCard';
import { resizeImage } from '../../utils/imageUtils';
import Select from "react-select";
import { useRef } from 'react';
import generatePassword from '../../../src/utils/constants/password';

const HotelForm = ({ isEdit, isOpen, onClose, currentStep, handleNext, handlePrevious, hotels, currency, parentHandleSubmit, isEditSetting , currencyPlan}) => {
  if (isOpen) return null;

  const [countriesList, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [statesList, setStatesList] = useState([]);
  const [selectedState, setSelectedState] = useState('');
  const [getstateKey, setstateKey] = useState([])
  const [citiesList, setCitiesList] = useState([]);
  const [selectedCities, setSelectedCities] = useState('');
  const [CountryCodePin, setCountryCode] = useState({ Countryflag: '', phoneCode: '' });
  const [planList, setPlanList] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState('');
  const [password, setPassword] = useState('');
  const [resizedImage, setResizedImage] = useState();

  useEffect(() => {
    getCountries()
      .then((res) => {
        setCountries(res.data.data)
        if (hotels && hotels.country) {
          const foundCountry = res.data.data.find(country => country.name === hotels.country);
          if (foundCountry) {
            setSelectedCountry({ label: foundCountry.name, value: foundCountry });
            validation.setFieldValue('country', foundCountry.name);
          }
        }
      })
  }, [hotels])

  useEffect(() => {
    getPlans()
      .then((res) => {
        setPlanList(res.data.data)
      });

    if (selectedCountry != null) {
      const fetchStates = async () => {
        try {
          const stateKey = getCountryKey(selectedCountry.label);
          const checkCountryCode = getCountryCode(selectedCountry.label);

          if (checkCountryCode) {
            setCountryCode({ Countryflag: checkCountryCode.Countryflag, phoneCode: checkCountryCode.phoneCode })
            validation.setFieldValue('countryCode', checkCountryCode.phoneCode);
          }
          getStates(stateKey).then((res) => {
            setStatesList(res.data.data);

            if (hotels && hotels.state) {
              const foundState = res.data.data.find(state => state.name === hotels.state);
              if (foundState) {
                setSelectedState({ label: foundState.name, value: foundState });
                validation.setFieldValue('state', foundState.name);
              }
            }
          });
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
      fetchStates();
    }
  }, [selectedCountry])

  const getCountryKey = (CountryKey) => {
    const state = countriesList.find(s => s.name === CountryKey);
    return state ? state.iso2 : null;
  }

  const getCountryCode = (CountryKey) => {
    const state = countriesList.find(s => s.name === CountryKey);
    const flag = state ? state.country_flag : null;
    const phCode = state ? state.phone_code : null;

    const flagCode = {
      Countryflag: flag,
      phoneCode: phCode
    }
    return flagCode;
  }

  useEffect(() => {
    if (selectedState && selectedState.label) {
      const fetchCities = async () => {
        try {
          const stateKey = getStateKey(selectedState.label);
          setstateKey(stateKey)

          getCities(stateKey)
            .then((res) => {
              setCitiesList(res.data.data);
              if (hotels && hotels.city) {
                const foundCity = res.data.data.find(city => city.name.trim().toLowerCase() === hotels.city.trim().toLowerCase());
                if (foundCity) {
                  setSelectedCities({ label: foundCity.name, value: foundCity });
                  validation.setFieldValue('city', foundCity.name);
                }
              }
            })
        } catch (error) {
          console.error('Error fetching city:', error);
        }
      };
      fetchCities();
    }
  }, [selectedState]);

  const getStateKey = (stateName) => {
    const state = statesList.find(s => s.name === stateName);
    return state ? state.name : null;
  };

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    if (selectedOption && selectedOption.label) {
      validation.setFieldValue('country', selectedOption.label);
    } else {
      setSelectedState([]);
      setSelectedCities([]);
      setStatesList([]);
      setCitiesList([]);
    }
  };

  const handleStateChange = (selectedOption) => {
    setSelectedState(selectedOption)
    if (selectedOption && selectedOption.label) {
      validation.setFieldValue('state', selectedOption.label);
    } else {
      validation.setFieldValue('state', '');
    }
  }


  useEffect(() => {
    if (hotels && hotels.city) {
      const foundCity = citiesList.find(city => city.name === hotels.city);
      if (foundCity) {
        setSelectedCities({ label: foundCity.name, value: foundCity });
      }
    }
  }, [hotels, citiesList]);

  const handleCityChange = (selectedOption) => {
    setSelectedCities(selectedOption)
    if (selectedOption && selectedOption.label) {
      validation.setFieldValue('city', selectedOption.label);
    } else {
      validation.setFieldValue('city', '');
    }
  }

  const handlePasswordSubmit = async (newPassword) => {
    if (hotels && hotels.password) {
      const storedPassword = hotels.password;
      if (newPassword !== storedPassword) {
        try {
          const isMatch = await bcrypt.compare(newPassword, storedPassword);
          if (!isMatch) {
            return false;
          } else {
            return true;
          }
        } catch (error) {
          console.error('Error comparing passwords:', error);
        }
      };
    }
  };

  const handleSelectChange = (event) => {
    const selectedPlanName = event;
    setSelectedPlan(selectedPlanName);
    validation.setFieldValue('planId', selectedPlanName);
  };
  const handlegenerateddPassword = (event) => {
    generatePassword().then((newPassword) => {
      validation.setFieldValue('password', newPassword);
      setPassword(newPassword);
    });
  };

  const handleFileChange = async (event) => {
    validation.setFieldError('logo', '');
    if (event) {
      if (event.size > 5242880) {
        validation.setFieldValue('logo', event);
      } else {
        try {
          const resizedFile = await resizeImage(event, 200, 80);
          setResizedImage(resizedFile);
          validation.setFieldValue('logo', resizedFile);
        } catch (error) {
          validation.setFieldError('logo', 'Error resizing image.');
        }
      }
    }
  };

  const Step1Schema = Yup.object().shape({
    hotelName: Yup.string().required('Hotel name is required'),
    hotelEmail: Yup.string().matches(
      /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
      "Please Enter Valid Email").required('Email is required'),
    address1: Yup.string().required('Address is required').max(20, 'Address must not exceed 50 characters'),
    country: Yup.string().required('Country is required'),
    state: Yup.string().required('State is required'),
    city: Yup.string().required('City is required'),
    countryCode: Yup.string().required(),
    postalCode: Yup.string().matches(/^\d+$/, 'Postal Should be in the numbers').required('Postal Code is required'),
    contactNo1: Yup.string().matches(/^\d+$/, 'Invalid phone number format').required('ContactNo1 is required'),
    logo: Yup.mixed()
      .test('fileSize', 'File size is too large, must be less than 5MB.', (value) => {
        return !value || value.size <= 5242880;
      })
  });

  const Step2Schema = Yup.object().shape({
    name: Yup.string().required('User name is required'),
    email: Yup.string().matches(
      /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
      "Please Enter Valid Email").required('Email is required'),
      password: Yup.string()
      .when('$isEdit', {
        is: true, // If in edit mode
        then: Yup.string(), // Validation rule when in edit mode (not required)
        otherwise: Yup.string().required('Password is required') // Validation rule otherwise
      }),
    // password: Yup.string()
    //   .required('Password is required'),
    password: Yup.string().when('isEdit', {
      is: false,
      then: Yup.string().required('Password is required'),
      otherwise: Yup.string().notRequired(),
    }),
    mobileNo1: Yup.string().matches(/^\d+$/, 'Invalid phone number format').required('ContactNo is required'),
    mobileNo2: Yup.string().matches(/^\d+$/, 'Invalid phone number format'),
  });

  const validation = useFormik({
    initialValues: {
      // Step1
      hotelName: hotels ? hotels.hotelName : "",
      hotelEmail: hotels ? hotels.hotelEmail : "",
      address1: hotels ? hotels.address1 : "",
      address2: hotels ? hotels.address2 : "",
      address3: hotels ? hotels.address3 : "",
      country: hotels ? hotels.country : "",
      state: hotels ? hotels.state : "",
      city: hotels ? hotels.city : "",
      postalCode: hotels ? hotels.postalCode : "",
      countryCode: "",
      contactNo1: hotels ? hotels.contactNo1 : "",
      contactNo2: hotels ? hotels.contactNo2 : "",
      taxId: hotels ? hotels.taxId : "",
      website: hotels ? hotels.website : "",
      logo: "",
      // Step2
      name: hotels ? hotels.name : "",
      email: hotels ? hotels.email : "",
      password: "",
      mobileNo1: hotels ? hotels.mobileNo1 : "",
      mobileNo2: hotels ? hotels.mobileNo2 : "",
      // Step3
      planId: hotels ? hotels.planId : "",
      isActive: true //default should be true
    },
    enableReinitialize: true,
    validationSchema: currentStep === 0 ? Step1Schema : Step2Schema,
    onSubmit: (values) => {
      if (currentStep === 1) {
        if (isEdit) {
          handleNext();
        }

        else {

          if (hotels && hotels.password) {
            handlePasswordSubmit(values.password).then((isValid) => {
              if (isValid) {
              handleNext();
              } else {

                validation.setFieldError('password', 'Password does not match');
              }
            });
          } else {

            if (!values.password) {
              validation.setFieldError('password', 'Password is required');
            } else {
              handleNext();
            }
            // if (password == "") {

            //   validation.setFieldError('password', );
            // }
            // else {
            //   handleNext();
            // }
          }
        }


      } else if (currentStep === 0) {
        handleNext()
      } else if (currentStep === 2) {
        // values.isEdit = true;
        parentHandleSubmit(values);
        validation.resetForm();
      }
    },
  });

  const handleFormSubmit = (e) => {
    e.preventDefault();
    validation.handleSubmit()
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <div className="tab-pane active" style={{maxWidth: '1250px', margin: 'auto'}} >
            <div className="row">
              <div className="col-lg-6">
                <div className="mb-3">
                  <FormGroup>
                    <Label className="form-label">Hotel Name</Label>
                    <Input
                      name="hotelName"
                      className="form-control"
                      placeholder="Enter Your Hotel Name"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.hotelName}
                      invalid={validation.touched.hotelName && validation.errors.hotelName ? true : false}
                    />
                    {validation.touched.hotelName && validation.errors.hotelName ? (
                      <FormFeedback type="invalid">{validation.errors.hotelName}</FormFeedback>
                    ) : null}
                  </FormGroup>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-3">
                  <FormGroup>
                    <Label className="form-label">Hotel Email</Label>
                    <Input
                      name="hotelEmail"
                      className="form-control"
                      placeholder="Enter Your Email"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.hotelEmail}
                      invalid={validation.touched.hotelEmail && validation.errors.hotelEmail ? true : false}
                    />
                    {validation.touched.hotelEmail && validation.errors.hotelEmail ? (
                      <FormFeedback type="invalid">{validation.errors.hotelEmail}</FormFeedback>
                    ) : null}
                  </FormGroup>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="mb-3">
                  <FormGroup>
                    <Label className="form-label">Address1</Label>
                    <Input
                      name="address1"
                      className="form-control"
                      placeholder="Enter Your Address"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.address1}
                      invalid={validation.touched.address1 && validation.errors.address1 ? true : false}
                    />
                    {validation.touched.address1 && validation.errors.address1 ? (
                      <FormFeedback type="invalid">{validation.errors.address1}</FormFeedback>
                    ) : null}
                  </FormGroup>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-3">
                  <FormGroup>
                    <Label className="form-label">Address2</Label>
                    <Input
                      name="address2"
                      className="form-control"
                      placeholder="Enter Your Address"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.address2 || ""}
                    />
                  </FormGroup>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-3">
                  <FormGroup>
                    <Label className="form-label">Address3</Label>
                    <Input
                      name="address3"
                      className="form-control"
                      placeholder="Enter Your Address"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.address3}
                      invalid={validation.touched.address3 && validation.errors.address3 ? true : false}
                    />
                    {validation.touched.address3 && validation.errors.address3 ? (
                      <FormFeedback type="invalid">{validation.errors.address3}</FormFeedback>
                    ) : null}
                  </FormGroup>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-3">
                  <FormGroup>
                    <Label className="form-label">Country</Label>
                    <Select
                      name="country"
                      placeholder="Select Country"
                      classNamePrefix="custom-select"
                      className={`react-select-container ${validation.touched.country && validation.errors.country
                        ? 'is-invalid'
                        : ''
                        }`}
                      options={countriesList.map((country) => ({
                        label: country.name,
                        value: country,
                      }))}
                      value={selectedCountry}
                      onChange={handleCountryChange}
                      isClearable
                      isSearchable
                      styles={{
                        menu: (provided) => ({
                          ...provided,
                          top: "100%",
                          position: "absolute",
                          width: "100%",
                          zIndex: 999,
                        }),
                      }}
                    />
                    {validation.touched.country && validation.errors.country && (
                      <FormFeedback type="invalid">{validation.errors.country}</FormFeedback>
                    )}
                  </FormGroup>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-3">
                  <FormGroup>
                    <Label className="form-label">State</Label>
                    <br />
                    <Select
                      name="state"
                      placeholder="Select State"
                      classNamePrefix="react-select"
                      className={`react-select-container ${validation.touched.state && validation.errors.state
                        ? 'is-invalid'
                        : ''
                        }`}
                      value={selectedState}
                      onChange={handleStateChange}
                      options={statesList.map((state) => ({
                        label: state.name,
                        value: state
                      }))}
                      isClearable
                      isSearchable
                      styles={{
                        menu: (provided) => ({
                          ...provided,
                          top: "100%",
                          position: "absolute",
                          width: "100%",
                          zIndex: 999,
                        }),
                      }}
                    />
                    {validation.touched.state && validation.errors.state && (
                      <FormFeedback type="invalid">{validation.errors.state}</FormFeedback>
                    )}
                  </FormGroup>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-3">
                  <FormGroup>
                    <Label className="form-label">City</Label>
                    <br />
                    <Select
                      name="city"
                      placeholder="Select City"
                      className={`react-select-container ${validation.touched.city && validation.errors.city
                        ? 'is-invalid'
                        : ''
                        }`}
                      value={selectedCities}
                      onChange={handleCityChange}
                      options={citiesList.map((city) => ({
                        label: city.name,
                        value: city
                      }))}
                      isClearable
                      isSearchable
                      styles={{
                        menu: (provided) => ({
                          ...provided,
                          top: "100%",
                          position: "absolute",
                          width: "100%",
                          zIndex: 999,
                        }),
                      }}
                    />
                    {validation.touched.city && validation.errors.city && (
                      <div className="text-danger">{validation.errors.city}</div>
                    )}
                  </FormGroup>
                </div>

              </div>
              <div className="col-lg-6">
                <div className="mb-3">
                  <FormGroup>
                    <Label className="form-label">Postal Code</Label>
                    <Input
                      name="postalCode"
                      type="text"
                      placeholder="Enter Your Postal Code"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.postalCode || ""}
                      invalid={
                        validation.touched.postalCode &&
                          validation.errors.postalCode
                          ? true
                          : false
                      }
                    />
                    {validation.touched.postalCode &&
                      validation.errors.postalCode ? (
                      <FormFeedback type="invalid">
                        {validation.errors.postalCode}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-3">
                  <FormGroup>
                    <Label className="form-label">CountryCode</Label>
                    <InputGroup>

                      {CountryCodePin && CountryCodePin.Countryflag && (
                        <InputGroupText>
                          {CountryCodePin.Countryflag}
                        </InputGroupText>
                      )}

                      <Input
                        name="countryCode"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.countryCode || (CountryCodePin && typeof CountryCodePin.phoneCode === 'string' && CountryCodePin.phoneCode.startsWith("+") ? CountryCodePin.phoneCode : (CountryCodePin && CountryCodePin.phoneCode ? (("+") + CountryCodePin.phoneCode) : "") || "Select a Country")}
                        invalid={
                          validation.touched.countryCode &&
                          validation.errors.countryCode && !CountryCodePin
                        }
                        disabled={CountryCodePin}
                      />
                    </InputGroup>

                    {validation.touched.countryCode &&
                      validation.errors.countryCode ? (
                      <FormFeedback type="invalid">
                        {validation.errors.countryCode}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-3">
                  <FormGroup>
                    <Label className="form-label">ContactNo1</Label>
                    <Input
                      name="contactNo1"
                      type="text"
                      pattern="\d{1,12}"
                      placeholder="Enter Your Contact Number"
                      onChange={(e) => {
                        const { value } = e.target;
                        if (value.length <= 12 && /^\d*$/.test(value)) {
                          validation.handleChange(e);
                        }
                      }}
                      onBlur={validation.handleBlur}
                      value={validation.values.contactNo1 || ""}
                      invalid={
                        validation.touched.contactNo1 &&
                          validation.errors.contactNo1
                          ? true
                          : false
                      }
                    />
                    {validation.touched.contactNo1 &&
                      validation.errors.contactNo1 ? (
                      <FormFeedback type="invalid">
                        {validation.errors.contactNo1}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-3">
                  <FormGroup>
                    <Label className="form-label">ContactNo2</Label><span style={{ fontWeight: 'lighter', color: '#bbb' }}> (optional) </span>
                    <Input
                      name="contactNo2"
                      type="text"
                      pattern="\d{1,12}"
                      placeholder="Enter Your Contact Number"
                      onChange={(e) => {
                        const { value } = e.target;
                        if (value.length <= 12 && /^\d*$/.test(value)) {
                          validation.handleChange(e);
                        }
                      }}
                      onBlur={validation.handleBlur}
                      value={validation.values.contactNo2 || ""}
                      invalid={
                        validation.touched.contactNo2 &&
                          validation.errors.contactNo2
                          ? true
                          : false
                      }
                    />
                    {validation.touched.contactNo2 &&
                      validation.errors.contactNo2 ? (
                      <FormFeedback type="invalid">
                        {validation.errors.contactNo2}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-3">
                  <FormGroup>
                    <Label className="form-label">Tax Id</Label>
                    <Input
                      name="taxId"
                      type="text"
                      placeholder="Enter Your ID"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.taxId || ""}
                      invalid={
                        validation.touched.taxId &&
                          validation.errors.taxId
                          ? true
                          : false
                      }
                    />
                    {validation.touched.taxId &&
                      validation.errors.taxId ? (
                      <FormFeedback type="invalid">
                        {validation.errors.taxId}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-3">
                  <FormGroup>
                    <Label className="form-label">Website</Label>
                    <Input
                      name="website"
                      type="text"
                      placeholder="Enter Your Site"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.website || ""}
                      invalid={
                        validation.touched.website &&
                          validation.errors.website
                          ? true
                          : false
                      }
                    />
                    {validation.touched.website &&
                      validation.errors.website ? (
                      <FormFeedback type="invalid">
                        {validation.errors.website}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="mb-3">
                  <Label className="form-label">Logo</Label>
                  <div className="input-group mb-3">
                    <Input
                      id="inputGroupFile01"
                      name="logo"
                      type="file"
                      accept="image/*"
                      className={`form-control ${validation.touched.logo && validation.errors.logo ? 'is-invalid' : ''
                        }`}
                      onChange={(event) => {
                        validation.setFieldError('logo', '');
                        handleFileChange(event.currentTarget.files[0])
                      }}
                      onBlur={validation.handleBlur}
                    />
                    {/* <label className="input-group-text" htmlFor="inputGroupFile01">Upload</label> */}
                  </div>
                  <p className="invalid-control" style={{ color: 'rgba(0,0,0,0.4)' }}>
                    Thumbnail size: 80px (H) x 250px (W)
                  </p>
                  {validation.errors.logo && validation.touched.logo ? (
                    <div className="error">{validation.errors.logo}</div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        );
      case 1:
        return (
          <div className="tab-pane active" style={{maxWidth: '1250px', margin: 'auto'}}>
            <div className="row">
              <div className="col-lg-6">
                <div className="mb-3">
                  <FormGroup>
                    <Label className="form-label">User Name</Label>
                    <Input
                      name="name"
                      type="text"
                      placeholder="Enter Your name"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.name || ""}
                      disabled={isEditSetting}
                      invalid={
                        validation.touched.name &&
                          validation.errors.name
                          ? true
                          : false
                      }

                    />
                    {validation.touched.name &&
                      validation.errors.name ? (
                      <FormFeedback type="invalid">
                        {validation.errors.name}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-3">
                  <FormGroup>
                    <Label className="form-label">Email</Label>
                    <Input
                      name="email"
                      className="form-control"
                      placeholder="Enter Your Email"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.email}
                      invalid={validation.touched.email && validation.errors.email ? true : false}
                    />
                    {validation.touched.email && validation.errors.email ? (
                      <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                    ) : null}
                  </FormGroup>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="mb-3">
                  <FormGroup>
                    <Label className="form-label">MobileNo</Label>
                    <Input
                      name="mobileNo1"
                      type="text"
                      pattern="\d{1,12}"
                      placeholder="Enter Your Contact Number"
                      onChange={(e) => {
                        const { value } = e.target;
                        if (value.length <= 12 && /^\d*$/.test(value)) {
                          validation.handleChange(e);
                        }
                      }}
                      onBlur={validation.handleBlur}
                      value={validation.values.mobileNo1 || ""}
                      invalid={
                        validation.touched.mobileNo1 &&
                          validation.errors.mobileNo1
                          ? true
                          : false
                      }
                    />
                    {validation.touched.mobileNo1 &&
                      validation.errors.mobileNo1 ? (
                      <FormFeedback type="invalid">
                        {validation.errors.mobileNo1}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-3">
                  <FormGroup>
                    <Label className="form-label">Phone Number</Label><span style={{ fontWeight: 'lighter', color: '#bbb' }}> (optional) </span>
                    <Input
                      name="mobileNo2"
                      type="text"
                      pattern="\d{1,12}"
                      placeholder="Enter Your Contact Number"
                      onChange={(e) => {
                        const { value } = e.target;
                        if (value.length <= 12 && /^\d*$/.test(value)) {
                          validation.handleChange(e);
                        }
                      }}
                      onBlur={validation.handleBlur}
                      value={validation.values.mobileNo2 || ""}
                      invalid={
                        validation.touched.mobileNo2 &&
                          validation.errors.mobileNo2
                          ? true
                          : false
                      }
                    />
                    {validation.touched.mobileNo2 &&
                      validation.errors.mobileNo2 ? (
                      <FormFeedback type="invalid">
                        {validation.errors.mobileNo2}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </div>
              </div>
            </div>
            {!isEdit && (
              <div className="row">
                <div className="col-lg-6">
                  <div className="mb-3">
                    <FormGroup>
                      <Label className="form-label">Password</Label>
                      <Input
                        name="password"
                        className={`form-control ${validation.touched.password && validation.errors.password ? 'is-invalid' : ''
                          }`}
                        // className="form-control"
                        placeholder="Enter Your Password"
                        type="text"
                        value={validation.values.password}
                        onChange={(e) => {
                          validation.handleChange(e);
                          // handlePasswordChange(e);
                        }}
                        onBlur={validation.handleBlur}
                      // invalid={validation.touched.password && validation.errors.password ? true : false}
                      />
                      {validation.touched.password && validation.errors.password && (
                        <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                      )}
                    </FormGroup>
                  </div>
                </div>
                {!isEdit && (
                  <div className="col-lg-6 d-flex align-items-end">
                    <div className="mb-3">
                      <button type="button" className="btn btn-primary save-user w-md" onClick={handlegenerateddPassword}>
                        Generate Password
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        );
      case 2:
        return (
          <div className="tab-pane active" style={{maxWidth: '1250px', margin: 'auto'}} >
            <div className="row">
              <div className="col-lg-12">
                <div className="mb-3">
                  <br></br>
                  <PlanCard onSelect={(handleSelectChange)} selectedPlan={hotels} isEditSetting={isEditSetting} currency= {currency} currencyPlan={currencyPlan}/>
                </div>

              </div>

            </div>
          </div>
        );
      default:
        return 'Unknown Step';
    }
  };

  return (
    <div className="card" style={{maxWidth: '1600px', margin: 'auto', marginBottom: '24px'}} >
      <div className="card-body">
        <Form onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
        >
          <div className="wizard clearfix">
            <div className="steps clearfix" >
              <ul className="steps-container">
                <li className={`nav-item ${currentStep === 0 ? 'current' : ''}`}>
                  <a className={`nav-item ${currentStep === 0 ? 'current' : ''}`}>
                    <span className="number">1</span>
                  </a>
                  Hotel Details
                  <div className="connector"></div>
                </li>
                <li className={`nav-item ${currentStep === 1 ? 'current' : ''}`}>
                  <a className={`nav-item ${currentStep === 1 ? 'current' : ''}`}>
                    <span className="number">2</span>
                  </a>
                  User Details
                  <div className="connector"></div>
                </li>
                <li className={`nav-item ${currentStep === 2 ? 'current' : ''}`}>
                  <a className={`nav-item ${currentStep === 2 ? 'current' : ''}`}>
                    <span className="number">3</span>
                  </a>
                  Plan Details
                </li>

              </ul>
            </div>
          </div>
          <br />
          <div className="content clearfix">
            <div className="tab-content body">
              {getStepContent(currentStep)}
            </div>
          </div>
          <div className="modal-footer">
            <div className="col-lg-12">
              <div className="d-flex justify-content-between align-items-right mb-3 flex-wrap">
                <div className="form-check form-switch mb-3"></div>
                <div className="ml-auto d-flex" style={{ gap: '10px' }}>
                  {currentStep > 0 && <button type="button" className="btn btn-primary save-user w-md" onClick={handlePrevious}>
                    Previous
                  </button>
                  }
                  <button type="submit" className="btn btn-primary save-user w-md" size="lg" onClick={(e) => handleFormSubmit(e)}>
                    {currentStep === 2 ? 'Finish' : 'Next'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default HotelForm;
