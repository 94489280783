import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../../components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";

import { useFormik } from "formik";
import { addIdentity,getIdentity, updateIdentity, deleteIdentity  } from "../../../middleware/backent_helper";
import { IdentityName } from "./IdentityDetailsMasterCol";
import messages from '../../../utils/constants/messages';
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import DeleteModal from "../../../components/Common/DeleteModal";
import AlertModal from "../../../components/Common/alertModal";
import ErrorAlertModal from "../../../components/Common/errorAlertModal";
//redux
import { useSelector, useDispatch } from "react-redux";

const IdentityDetailsMaster = props => {
  //meta title
  document.title = "Identity | Settings ";

  const dispatch = useDispatch();
  const [IdentityList, setIdentityList] = useState([]);
  const [Identity, setIdentity] = useState();
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [errorAlertModal, setErrorAlertModal] = useState(false);
  const [masterName, setMasterName] = useState("Identity");
  const [response, setResponse] = useState(false);
  const [hid, setHid] = useState(() => {
    const storedHotelId = localStorage.getItem('hotelId')
    return storedHotelId ? storedHotelId.replace(/"/g, '') : '';
  });
  const [dbName, setDbName] = useState(
    localStorage.getItem('dbName')
  )

  const columns = useMemo(
    () => [
      {
        Header: "S.No",
        Cell: ({ row, rows }) => {
          return (
            <div className="table-cell text-center">
              {rows.length - row.index}
            </div>
          );
        },
      },

      {
        Header: "Identity Name",
        accessor: "identityName",
        filterable: true,
        Cell: cellProps => {
          return (
            <div className="table-cell text-center">
              <IdentityName {...cellProps} />
            </div>
          );

        },
      },

      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="table-body actions">
              <div className="actions">
                <Link
                  to="#"
                  className="text-success"
                  onClick={() => {
                    const IdentityData = cellProps.row.original;
                    handleIdentityClicks(IdentityData);
                  }}
                >
                  <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </Link>
                <Link
                  to="#"
                  className="text-danger"
                  onClick={() => {
                    const IdentityData = cellProps.row.original;
                    onClickDelete(IdentityData);
                  }}
                >
                  <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </div>
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    finalList()
  }, [])

  const finalList = () => {
   getIdentity(hid, dbName)
      .then((res) => {
        setIdentityList(res.data.data);
      });
  };

  const clearFormValues = () => {
    validation.resetForm();
  };

  const toggle = () => {
    if (modal) {
      setModal(false);
      setIdentity(null);
      clearFormValues();
    } else {
      setModal(true);
    }
  };

  const handleIdentityClicks = arg => {
    const identity = arg;
    setIdentity({
      id: identity.id,
      identityName: identity.identityName,
      hotelId: hid,
      dbName: dbName
    });
    setIsEdit(true);
    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };


  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = users => {
    setIdentity(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    deleteIdentity(Identity, dbName)
    .then((res) => {
      getIdentity(hid, dbName), finalList()
      setResponse(messages.success.deleteDetails(masterName))
      setAlertModal(true)
    })
    .catch((err) => {
      if(err.response.status == 400) {
        setResponse(messages.failed.cannotbeDeleted(masterName))
      } else {
        setResponse(messages.failed.updateFailed(masterName))
      }
      setErrorAlertModal(true)
    });
    onPaginationPageChange(1);
    setDeleteModal(false);
    setIdentity(null);
  };

  const handleIdentityClick = () => {
    setIsEdit(false);
    toggle();
  };

  const validation = useFormik({

    initialValues: {
      identityName: Identity ? Identity.identityName : "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      identityName: Yup.string().required("Please Enter Identity Name"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updatedIdentityData = { ...Identity, ...values };
        updateIdentity(updatedIdentityData)
          .then((res) => {
            setResponse(messages.success.updatedDetails(masterName))
            setAlertModal(true)
          })
          .catch((err) => {
            if (err.response.status === 401) {
              setResponse(messages.failed.alreadyInUse(masterName))
            } else {
              setResponse(messages.failed.updateFailed(masterName))
            }
            setErrorAlertModal(true)
          });
        toggle();
      } else {
        dispatch(post_Identity(values));
      }
    }
  });
  const post_Identity = (values) => {
    const data = JSON.stringify({
      identityName: values.identityName,
      hotelId: hid,
      dbName: dbName
    });
    addIdentity(data).then((res) => {
      if (res.data.success) {
        setResponse(messages.success.createdMessage(masterName))
        setAlertModal(true)
      }
    }).catch(err => {
      if(err.response.status === 404) {
        setResponse(messages.failed.AlreadyExists(masterName))
      } else {
        setResponse(messages.failed.createFailed(masterName))
      }
      setErrorAlertModal(true)
    });
    toggle();
  };

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => {setIdentity(null), setDeleteModal(false) }}
      />
      <AlertModal
        show={alertModal}
        onOkClick={() => {
          finalList();
          setAlertModal(false);
        }}
        onCloseClick={() => setAlertModal(false)}
        res={response}
      />
      <ErrorAlertModal
       show={errorAlertModal}
       onOkClick={() =>{ finalList() ,setErrorAlertModal(false) }}
       onCloseClick={() => setErrorAlertModal(false)}
       res={response}
      />

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Settings" breadcrumbItem="Manage Identity Category" />
          <Row>
            <Col lg="12">

                  <TableContainer
                    columns={columns}
                    data={IdentityList}
                    isGlobalFilter={true}
                    isAddIdentityList={true}
                    handleIdentityClicks={handleIdentityClick}
                    customPageSize={10}
                    className="custom-header-css"
                  />

                  <Modal  isOpen={modal} toggle={toggle} backdrop="static" keyboard={false}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Edit Identity Name" : "Add Identity Name"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >

                        <Row>
                          <Col xs={12}>
                            <div className="mb-3">
                              <Label className="form-label">Identity Name</Label>
                              <Input
                                name="identityName"
                                type="text"
                                placeholder="IdentityName"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.identityName || ""}
                                invalid={
                                  validation.touched.identityName &&
                                    validation.errors.identityName
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.identityName &&
                                validation.errors.identityName ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.identityName}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-primary save-user w-md me-3"
                              >
                                {!!isEdit ? "Update" : "Save"}
                              </button>
                              <button
                                type="button"
                                className="btn btn-secondary w-md "
                                onClick={toggle}
                              >
                                Cancel
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>

            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};


export default withRouter(IdentityDetailsMaster);
