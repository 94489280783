import React from 'react';
import * as moment from "moment";



const PaymentTypeList = (cell) => {
    return cell.value ? cell.value : '';
};

export {
    PaymentTypeList,
   };