import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Card,
  CardBody,
  Table,
} from "reactstrap"

import {
  format,
  startOfWeek,
  addDays,
  isSameDay,
  startOfYesterday,
  isPast,
} from "date-fns"

import bookings from "../../data/booking.json"
import { el } from "date-fns/locale"

const BookingView2 = props => {
  const [range, setRange] = useState({
    startDate: startOfYesterday(new Date()),
    endDate: addDays(startOfYesterday(new Date()), 6),
  })

  const renderDays = () => {
    const days = []
    let startDate = startOfYesterday(new Date())
    for (let i = 0; i < 7; i++) {
      days.push(
        <th className="text-center fs-6 text" key={i}>
          <span
            className={
              isSameDay(addDays(startDate, i), new Date())
                ? `current-date`
                : isPast(addDays(startDate, i), new Date())
                ? "past-date"
                : ""
            }
          >
            {format(addDays(startDate, i), "eee dd").toUpperCase()}
          </span>
        </th>
      )
    }

    return <>{days}</>
  }

  const renderBookings = () => {
    const book = []
    let startDate = startOfYesterday(new Date())
    for (let i = 0; i < 7; i++) {
      let newDate = addDays(startDate, i);
      book.push(
        <div key={i} className="schedule-items">
          {renderScheduleItem(newDate)}
        </div>
      )
    }
    return <td className="p-0" colSpan={7}>
      <Row className="m-0 schedule-row">
        {book}
      </Row>
    </td>
  }
  const renderScheduleItem =(newDate) =>{
    const item = [];
    bookings.map((blist, index) => {
      if(isSameDay(blist.booked, newDate))
        {
          const width = (200 * blist.bookedDays) - 20 +"px";
          item.push (
            <div style={{width: width}} className="schedule-detail">
              <div className="booked-legend">
                <div className="text text-center">{blist.guestName}</div>
              </div>
            </div>
          )
        }
    })
    return <>{item}</>
   
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xl="12" className="card card-body">
              <Table className="schedule-table" bordered>
                <thead>
                  <tr>
                    <th className="text-center border schedule-sidepanel">
                      <div className="room-detail text-center">
                        <div className="fs-5 text fw-semibold text-primary"></div>
                        <div className="sub-room-detail"></div>
                      </div>
                    </th>
                    {renderDays()}
                  </tr>
                </thead>

                <tbody>
                  {/* <tr>
                    <td className="text-center border schedule-sidepanel">
                      <div className="room-detail text-center">
                        <div className="fs-5 text fw-semibold text-primary">
                          1001
                        </div>
                        <div className="sub-room-detail">{`max 4 guest | ₹3750.00`}</div>
                      </div>
                    </td>
                    <td></td>
                    <td>
                      <div className="fs-6 text">
                        <div className="booked-legend">
                          <div className="text text-center">Murugan</div>
                        </div>
                      </div>
                    </td>
                    <td></td>
                    <td></td>
                    <td>
                        <div className="booked-legend">
                          <div className="text text-center">Murugan</div>
                        </div>
                        <div className="booked-legend booked-legend-start">
                          <div className="text text-center">Murugan</div>
                        </div>
                    </td>
                    <td></td>
                    <td></td>
                  </tr> */}
                  <tr>
                    <td className="text-center border schedule-sidepanel">
                      <div className="room-detail text-center">
                        <div className="fs-5 text fw-semibold text-primary">1002</div>
                        <div className="sub-room-detail">{`max 4 guest | ₹3750.00`}</div>
                      </div>
                    </td>
                    {renderBookings()}
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default BookingView2


// <td>
//                       <div style={{overflow: 'visible', width: "200%"}}>
//                         <a className="booked-legend text-center">
//                         <div className="text"> Ram Lakshman</div>
//                         </a>
//                       </div>
//                     </td>
//                     <td>
//                     <div>
//                         <a className="booked-legend text-center">
//                         <div className="text"> Murugan</div>
//                         </a>
//                       </div>
//                     </td>