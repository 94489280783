import React from 'react';
import { Link } from 'react-router-dom';
import * as moment from "moment";
import { Badge } from 'reactstrap';

const UserName = (cell) => {
    return cell.value ? cell.value : '';
};

const UserEmail = (cell) => {
    return cell.value ? cell.value : '';
}

const IsActiveStatus = (cell) => {
    return cell.value !== undefined ? `${cell.value}` : '';
};

export {
    UserName,
    UserEmail,
    IsActiveStatus
};