import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getCustomerDetails } from "../../middleware/backent_helper";
import { Card, CardBody, Button } from 'reactstrap'; // Import Card and Button from Reactstrap

const PrintComponent = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [customerData, setCustomerData] = useState([]);
  const [dbName, setDbName] = useState(localStorage.getItem('dbName'));

  useEffect(() => {
    getCustomerDetails(dbName, queryParams.get('Id'), queryParams.get('HotelId'))
      .then((res) => {
        if (res.data.data && res.data.data.length > 0) {
          setCustomerData(res.data.data); // Assuming res.data contains customer details array
        } else {
          console.log('Customer details not found.');
        }
      })
      .catch((error) => {
        console.error('Error fetching customer details:', error);
      });
  }, [dbName, queryParams]);

  // Ensure customerData has loaded and contains necessary properties
  if (!customerData || customerData.length === 0) {
    return <div>No customer data available.</div>;
  }

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Card className="shadow">
          <CardBody>
            <h4 className=" font-size-16">
              Customer Details
            </h4>
            <div className="mb-4">
              {/* Add any logos or additional elements here if needed */}
            </div>
            <div className="row">
              <div className="col-sm-6">
                <address>
                  <strong>Name:</strong> {customerData[0].guest_name}
                  <br />
                  <strong>Nationality:</strong> {customerData[0].nationality}
                  <br />
                  <strong>State:</strong> {customerData[0].state}
                  <br />
                  <strong>Proof Number:</strong> {customerData[0].proof_no}
                  <br />
                  <strong>Gender:</strong> {customerData[0].gender}
                </address>
              </div>
              <div className="col-sm-6 text-sm-end">
                <address>
                  <strong>City:</strong> {customerData[0].city}
                  <br />
                  <strong>Vehicle Number:</strong> {customerData[0].vehicle_no}
                  <br />
                  <strong>Email:</strong> {customerData[0].email}
                  <br />
                  <strong>Company Name:</strong> {customerData[0].company_name}
                  <br />
                  <strong>Pincode:</strong> {customerData[0].pincode}
                  <br />
                  <strong>Age:</strong> {customerData[0].age}
                  <br />
                  <strong>Country:</strong> {customerData[0].country}
                  <br />
                  <strong>Proof Type:</strong> {customerData[0].proof_type}
                </address>
              </div>
            </div>
            <div className="d-print-none">
              <div className="float-end">
                <Button className="btn btn-success me-2" onClick={() => window.print()}>
                  <i className="fa fa-print" /> Print
                </Button>
                <Button className="btn btn-primary w-md">
                  Send
                </Button>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default PrintComponent;
