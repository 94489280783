import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../../components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { testCategory, categoryList, updateCategory ,deleteCategory } from "../../../middleware/backent_helper";
import { RoomNumber, Status } from "./WifiPasswordCol";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import DeleteModal from "../../../components/Common/DeleteModal";
import AlertModal from "../../../components/Common/alertModal";
import ErrorAlertModal from "../../../components/Common/errorAlertModal";

//redux
import { useSelector, useDispatch } from "react-redux";

const WifiPassword = props => {

  //meta title
  document.title = "Wifi Password | Hotel ";

  const dispatch = useDispatch();
  const [Category, setCategory] = useState();

  const [CategoryList, setCategoryList] = useState([]);
  //   console.log(userList);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [errorAlertModal, setErrorAlertModal] = useState(false);
  const [response, setResponse] = useState(false);
  const [cid, setCid] = useState(
    localStorage.getItem('companyId')
  );

  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    setIsActive(isActive);
  }, [isActive]);

  const toggleactive = () => {
    setIsActive(prevState => !prevState);
  };
  const handleCheckboxChange = () => {
    toggleactive();
  };
  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },

      {
        Header: "Wifi Password",
        accessor: "name",
        filterable: true,
        Cell: cellProps => {
          return <RoomNumber {...cellProps} />;
        },
      },

      {
        Header: "Active Status",
        accessor: "isActive",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              {" "}
              <Status {...cellProps} />{" "}
            </>
          );
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const CategoryData = cellProps.row.original;
                  handleUserClick(CategoryData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  onClickDelete(userData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    finalList()
  }, [])

  const finalList = () => {
    // const data = JSON.stringify({

    //   companyId: cid,
    // });
    const companyId = cid.replace(/"/g, '');

    categoryList(companyId)
      .then((res) => {

        setCategoryList(res.data.data);

      });
  };

  const clearFormValues = () => {
    validation.resetForm();
  };

  const toggle = () => {
    if (modal) {
      setModal(false);
      setCategory(null);
      clearFormValues();
    } else {
      setModal(true);
    }
  };



  const handleUserClick = arg => {
    const user = arg;

    setCategory({
      id: user._id,
      name: user.name,
      // isActive: user.isActive,


    });
    setIsEdit(true);

    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = users => {
    setCategory(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {

    deleteCategory(Category).then(  categoryList() , finalList())
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleCategoryClick = () => {
    // setUserList([]);
    setIsEdit(false);
    toggle();
  };

  const keyField = "id";

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    initialValues: {
      name: Category ? Category.name : "",

      isActive: Category ? Category.isActive : true,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Your Name"),

      isActive: Yup.boolean().oneOf([true], 'Please confirm if the user is active'),
    }),
    onSubmit: (values) => {
      console.log(values);

      if (isEdit) {

        const updatedCategoryData = { ...Category, ...values };


        updateCategory(updatedCategoryData)
          .then((res) => {
            setResponse("Test Category Updated Sucessfully")
            setAlertModal(true)
          })
          .catch((err) => {
            setResponse("Failed to Update Test Category")
            setErrorAlertModal(true)
          });

        toggle();
      } else {
        console.log("values", values);
        dispatch(Test_Category(values));
      }
    }
  });
  const Test_Category = (values) => {
    const data = JSON.stringify({
      name: values.name,
      companyId: cid,
      isActive: isActive,

    });
    testCategory(data).then((res) => {
      if (res.data.success) {
        setResponse("Test Category Registered Sucessfully")
        setAlertModal(true)
      }
    }).catch(err => {
      setResponse("Test Category Already Exist")
      setErrorAlertModal(true)
    });

    toggle();

  };



  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <AlertModal
        show={alertModal}
        onOkClick={() => {
          finalList();
          setAlertModal(false);
        }}
        onCloseClick={() => setAlertModal(false)}
        res={response}
      />
      <ErrorAlertModal
       show={errorAlertModal}
       onOkClick={() =>{ finalList() ,setErrorAlertModal(false) }}
       onCloseClick={() => setErrorAlertModal(false)}
       res={response}
      />

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Hotel" breadcrumbItem="Wifi Password" />
          <Row>
            <Col lg="12">

                  <TableContainer
                    columns={columns}
                    data={CategoryList}
                    isGlobalFilter={true}
                    isAddCategoryList={true}
                    handleCategoryClick={handleCategoryClick}
                    customPageSize={10}
                    className="custom-header-css"
                  />

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Edit Rooms" : "Add Rooms"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>
                            <div className="mb-3">
                              <Label className="form-label">Room Number</Label>
                              <Input
                                name="name"
                                type="text"
                                placeholder="Insert Name"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ""}
                                invalid={
                                  validation.touched.name &&
                                    validation.errors.name
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.name &&
                                validation.errors.name ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.name}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="form-check form-switch form-switch-md mb-3">
                              <input
                                type="checkbox"
                                name="isActive"
                                className="form-check-input"
                                id="isActive"
                                checked={isActive}
                                onChange={handleCheckboxChange}
                                onClick={toggleactive}
                              />
                              <label className="form-check-label" htmlFor="customSwitchsizemd">
                                Active
                              </label>
                              </div>



                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-primary save-user"
                              >
                                Save
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>

            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};


export default withRouter(WifiPassword);
