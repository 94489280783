import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "../../../components/Common/withRouter";
import { loginUser, logoutUser, socialLogin } from "../../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import '../../../assets/scss/ProfileMenu.scss'; 

const ProfileMenu = props => {
  const dispatch = useDispatch();
  const [menu, setMenu] = useState(false);
  const [username, setUsername] = useState("");

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        setUsername(obj.name);
      } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
        setUsername(obj.user.name);
      }
    }
  }, [props.success]);

  const handleLogout = () => {
    dispatch(logoutUser());
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="profile-menu-container"
      >
        <DropdownToggle
          className="btn header-item profile-menu-button"
          id="page-header-user-dropdown"
          tag="button"
        >
          <span className="profile-menu-username ms-2 me-1">{username}</span>
          <i className="mdi mdi-chevron-down profile-menu-icon" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end profile-menu-dropdown">
          <DropdownItem 
            tag={Link} 
            to="/reset_password" 
            className="profile-menu-item"
          >
            <i className="bx bxs-user profile-menu-item-icon me-1" />
            {props.t("Change Password")}
          </DropdownItem>
          <div className="dropdown-divider" />
          <Link 
            to="/" 
            className="dropdown-item profile-menu-item profile-menu-item-logout" 
            onClick={handleLogout}
          >
            <i 
              className="bx bx-power-off profile-menu-item-logout-icon me-1" 
            />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
};

const mapStatetoProps = state => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
