import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN,CLEAR_LOGIN_ERROR } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess,clearLoginError } from "./actions";
import { userLogin, logoutlogApiRequest, clearToken } from "../../../middleware/backent_helper"

function* loginUser({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      var data = JSON.stringify(user);
      const response = yield call(userLogin, data)

      if (response.data.success) {
        localStorage.setItem("authUser", JSON.stringify(response.data))
        localStorage.setItem("token", response.data.token)
        localStorage.setItem("hotelId", JSON.stringify(response.data.user.hotelId))
        localStorage.setItem("email", JSON.stringify(response.data.user.email))
        localStorage.setItem("role", response.data.user.roleId)
        localStorage.setItem("dbName", response.data.user.dbName)
        yield put(loginSuccess(response))
        history('/dashboard');
      } else {
        yield put(apiError(response.data.error))
      }
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

function* logoutUser({ payload: { history } }) {

  try {
    const dbName = localStorage.getItem('dbName');
    localStorage.removeItem("authUser");
    localStorage.removeItem("token");
    localStorage.removeItem("hotelId");
    localStorage.removeItem("email");
    localStorage.removeItem("role");
    localStorage.removeItem("dbName");

    // pass the log details
    const data = {
      action: 'Logout',
      dbName,
    };
    //  logout Log request
    yield call(logoutlogApiRequest, data);

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout);
      yield put(logoutUserSuccess(response));
    }
    // else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
    //   clearToken();
    //   // yield put(logoutUserSuccess(response));
    //   // const response = yield call(fireBaseBackend.logout);
    //   // const response = yield call(fireBaseBackend.logout);
    //   // const response = yield call(postJwtLogin, data)
    //   // const response = yield call(logoutAPI);
    //   // yield put(clearUserData());
    //   // response.data.success
    //   // yield put(logoutUserSuccess());
    //   // yield put(apiError(response.data.success))
    //   yield put(push("/login"));
    //   history('/login');
    // }
    else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      clearToken();
      yield put(clearLoginError());
    }
    history.push('/login');

  } catch (error) {
    // yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
