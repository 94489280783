import React from 'react';
import '../../assets/scss/reservationNotify.scss'; // Import the CSS file
import moment from 'moment';


const NotifyReservation = ({ notifyReservation }) => {
    return (
        <div className="cardReserv notificationReserv">
            <div className="contentReserv">
                {notifyReservation.map((reservation, index) => (
                    <React.Fragment key={index}>
                        <p className='paraReserv'>
                            {`Advance Booking ${moment(reservation.bookingDate).format('DD-MM-YYYY')} - ${reservation.guestName} - ${reservation.city} - ${reservation.mobileNo} - ${reservation.roomType} (${reservation.roomCount} Rooms)`}

                        </p>

                    </React.Fragment>
                ))}
            </div>
        </div>
    );
};

export default NotifyReservation;