import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Button,
  Table,
} from "reactstrap"

import {
  format,
  startOfWeek,
  startOfYesterday,
  endOfMonth,
  endOfWeek,
  addDays,
  isSameDay,
  isSameMonth,
  isPast,
  parse,
  isEqual,
} from "date-fns"

import bookingList from "../../data/sample.json"
//i18n
import { withTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux"

const BookingView = props => {
  document.title = "StayPro | Booking View"

  useEffect(() => {
    setTimeout(() => {}, [])
  })

  const onPreviousDay = () => {}

  const renderDayButton = () => {
    return (
      //   <Row className="text-center">
      //     <Col className="text-center">
      //       <a className="text-secondary" onClick={() => onPreviousDay()}>
      //         <i className="fas fa-angle-left fs-2"></i>
      //       </a>
      //     </Col>
      //     <Col className="text-center">
      //       <Button
      //         className="btn btn-info text-white pr-2 mt-1 pl-2"
      //         onClick={() => onPreviousDay()}
      //       >
      //         Today
      //       </Button>
      //     </Col>
      //     <Col className="text-center">
      //       <a className="text-secondary" onClick={() => onPreviousDay()}>
      //         <i className="fas fa-angle-right fs-2"></i>
      //       </a>
      //     </Col>
      //   </Row>
      <></>
    )
  }

  const renderDays = () => {
    const days = []
    let startDate = startOfYesterday(new Date())
    for (let i = 0; i < 7; i++) {
      days.push(
        <th className="text-center fs-6 text border" key={i}>
          <span
            className={
              isSameDay(addDays(startDate, i), new Date())
                ? `current-date`
                : isPast(addDays(startDate, i), new Date())
                ? "past-date"
                : ""
            }
          >
            {format(addDays(startDate, i), "eee dd").toUpperCase()}
          </span>
        </th>
      )
    }

    return <>{days}</>
  }

  const renderschedules = () => {
    const schedulesRow = []
    bookingList.roomDetails.forEach(rooms => {
      schedulesRow.push(
        <tr key={rooms.roomId} className="border" >
          <td className="text-center border schedule-sidepanel">
            <div className="sub-room-detail text-primary">
              {rooms.roomType.toUpperCase()}
            </div>
            <div className="fs-6 text fw-semibold">{rooms.roomNo}</div>
            <div className="sub-room-detail">{`max ${rooms.maxGuest} guest | ₹${rooms.tariff}`}</div>
          </td>
          {returnScheduleColumns(rooms)}
        </tr>
      )
    })
    return <>{schedulesRow}</>
  }

  const returnScheduleColumns = (rooms) => {
    const schedules = []
    let startDate = startOfYesterday(new Date())
    for (let i = 0; i < 7; i++) {
      const date = addDays(startDate, i)

      if (rooms.bookingDetails != null) {
        const bookingList = rooms.bookingDetails;
        bookingList.forEach(bookings => {
         const bookDate = new Date(bookings.booked)
          if (isSameDay(bookDate, date)) {

            const colspan = bookings.bookedDays + 1
            schedules.push(
              <td
              colspan={colspan}
                className="text-center border fs-5 text"
                key={i + bookings.bookingId}
              >
                <div className="booked-legend">
                  <div className="text"> {bookings.guestName}</div>
                </div>
              </td>
            )
            i = i + colspan - 1
          }
          else {
            schedules.push(
              <td className="text-center border fs-5 text" key={i}>
                <div></div>
              </td>
            )
          }
        })
      }
    }
    return <>{schedules}</>
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xl="12" className="card card-body">
              <Table className="schedule-table">
                <thead>
                  <tr>
                    <th className="text-center border schedule-sidepanel">
                      {renderDayButton()}
                    </th>
                    {renderDays()}
                  </tr>
                </thead>
                <tbody>
                  {renderschedules()}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

BookingView.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(BookingView)
