import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../../components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import PrintComponent from "../../../components/customer/FormComponent";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledTooltip
} from "reactstrap";
import { getAllCustomer, addCustomer, deleteCustomer ,editCustomer, deleteCustomerImage } from "../../../middleware/backent_helper";
import { GuestName, MobileNo, Address, City, CompanyName } from "./CustomerMasterCol";
import CustomerForm from "../../../components/customer/customerForm";
import messages from '../../../utils/constants/messages';
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import DeleteModal from "../../../components/Common/DeleteModal";
import AlertModal from "../../../components/Common/alertModal";
import ErrorAlertModal from "../../../components/Common/errorAlertModal";
//redux
import { useSelector, useDispatch } from "react-redux";

const CustomerMaster = props => {
  //meta title
  document.title = "Customer | Settings ";

  const dispatch = useDispatch();
  const [customerData, setCustomerData] = useState();
  // console.log(customerData);
  const [CustomersList, setCustomersList] = useState([]);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [errorAlertModal, setErrorAlertModal] = useState(false);
  const [masterName, setMasterName] = useState("Customer");
  const [response, setResponse] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [hid, setHid] = useState(() => {
    const storedHotelId = localStorage.getItem('hotelId')
    return storedHotelId ? storedHotelId.replace(/"/g, '') : '';
  });
  const [dbName, setDbName] = useState(
    localStorage.getItem('dbName')
  )

  const columns = useMemo(
    () => [
      {
        Header: "S.No",
        Cell: ({ row, rows }) => {
          return (
            <div  className="table-cell text-center">
              {rows.length - row.index}
            </div>
          );
        },
      },

      {
        Header: "Guest Name",
        accessor: "guest_name",
        filterable: true,
        Cell: cellProps => {
          return (
            <div  className="table-cell text-center">
              <GuestName {...cellProps} />
            </div>
          );
        },

      },

      {
        Header: "Mobile",
        accessor: "mobile_no",
        filterable: true,
        Cell: cellProps => {
          return (
            <div  className="table-cell text-center">
              <MobileNo {...cellProps} />
            </div>
          );

        },
      },

      {
        Header: "Address",
        accessor: "address",
        filterable: true,
        Cell: cellProps => {
          return (
            <div  className="table-cell text-center">
              <Address {...cellProps} />
            </div>
          );

        },
      },

      {
        Header: "City",
        accessor: "city",
        filterable: true,
        Cell: cellProps => {
          return (
            <div  className="table-cell text-center">
              <City {...cellProps} />
            </div>
          );

        },
      },

      {
        Header: "Company Name",
        accessor: "company_name",
        filterable: true,
        Cell: cellProps => {
          return (
            <div  className="table-cell text-center">
              <CompanyName {...cellProps} />
            </div>
          );

        },
      },

      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div  className="table-body actions">
              <div className="actions">
                <Link
                  to="#"
                  className="text-success"
                  onClick={() => {
                    const CustomerData = cellProps.row.original;
                    handleCustomerEdit(CustomerData);
                  }}
                >
                  <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </Link>
                <Link
                  to="#"
                  className="text-success"
                  onClick={() => {
                    const CustomerData = cellProps.row.original;
                    handleCustomerPrint(CustomerData);
                  }}
                >
                  <i className=" fas fa-print text-primary font-size-18" id="printtooltip" />
                  <UncontrolledTooltip placement="top" target="printtooltip">
                    View Print
                  </UncontrolledTooltip>
                </Link>
                {/* <Link
                  to="#"
                  className="text-danger"
                  onClick={() => {
                    const userData = cellProps.row.original;
                    onClickDelete(userData);
                  }}
                >
                  <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link> */}
              </div>
            </div>
          );
        },
      },
    ],
    []
  );

  const handleCustomerPrint = (CustomerData) => {

    console.log('Printing customer data:', CustomerData);
    const { _id, hotelId } = CustomerData;


    const queryParams = new URLSearchParams({
      Id: _id,
      HotelId: hotelId

    });


    window.open(`/print-customer?${queryParams.toString()}`, '_blank', 'noopener,noreferrer');
  };

  // const handleCustomerPrint = (CustomerData) => {
  //   // alert(JSON.stringify(CustomerData));
  //   console.log('Printing customer data:', CustomerData);
  //   window.open(`/print-customer`, '_blank', 'noopener,noreferrer');
  //   window.history.replaceState({CustomerDataa: CustomerData }, '', `/print-customer`);
  // };






  useEffect(() => {
    getAllCutomers()
  }, [])

  const getAllCutomers = () => {
    getAllCustomer(hid , dbName)
      .then((res) => {
        setCustomersList(res.data.data);
    });
  };

  const getAllCutomersAfterEdit = () => {
    getAllCutomers();
  }



  const toggle = () => {
    if (modal) {
      setModal(false);
      setCustomerData(null);
    } else {
      setModal(true);
    }
  };

  const handleCustomerEdit = arg => {

    const customerData = arg;

    setCustomerData({
      id: customerData._id,
      guest_name: customerData.guest_name,
      mobile_no: customerData.mobile_no,
      nationality: customerData.nationality,
      proof_type: customerData.proof_type,
      state: customerData.state,
      proof_no: customerData.proof_no,
      gender: customerData.gender,
      city: customerData.city,
      vehicle_no: customerData.vehicle_no,
      address: customerData.address,
      email: customerData.email,
      company_name: customerData.company_name,
      pincode: customerData.pincode,
      age: customerData.age,
      proof_photo: customerData.proof_photo,
      country: customerData.country,
      dbName:dbName,
      hotelId:hid
    });
    setIsEdit(true);
    setIsModalOpen(true);
    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = customer => {
    setCustomerData(customer);
    setDeleteModal(true);
  };

  const handleDeleteCustomer = () => {
    deleteCustomer(customerData, dbName).then(  getAllCustomer(hid , dbName) , getAllCutomers())
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleNewCustomerClick = () => {
    setIsModalOpen(true);
    setIsEdit(false);
    toggle();
  };

  const createFormData = (values) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      for (const key in values) {
        if (values.hasOwnProperty(key)) {
          const value = values[key];
          if (Array.isArray(value)) {
            value.forEach(file => {
              formData.append(key, file);

            });
          } else {
            formData.append(key, value);
          }
        }
      }
      resolve(formData);
    });
  };

  const POST_REGISTER = async (values) => {
    try {
      const formData = await createFormData(values);
      const response = await addCustomer(formData,hid , dbName);
      setResponse(messages.success.createdMessage(masterName));
      setAlertModal(true);
      getAllCustomer(hid , dbName);
      toggle();
    } catch (err) {
      setResponse(messages.failed.createFailed(masterName))
      setErrorAlertModal(true);
    }
  };

  const handleSubmit = async (values) => {
    if (isEdit) {
      const updatedHotelData = { ...customerData, ...values };
      const formData = await createFormData(updatedHotelData);
      editCustomer(formData , hid , dbName)
        .then((res) => {
          setResponse(messages.success.updatedDetails(masterName))
          setAlertModal(true)
        })
        .catch((err) => {
          setResponse(messages.failed.updateFailed(masterName))
          setErrorAlertModal(true)
        });
        toggle();
    } else {
      dispatch(POST_REGISTER(values));
    }
    handleClose();
  };

  const customerImageDelete = (value) => {
    if(value) {
      deleteCustomerImage(value)
      .then((res) => {
        setResponse(messages.success.imageDeleted);
        setAlertModal(true)
      })
      .catch((err) => {
        setResponse(messages.success.imageFailed);
        setErrorAlertModal(true)
      });
    }
  }

  const handleClose = () => {
    toggle()
    setIsModalOpen(false);
  };

  const onClosed = () => {
    setCurrentStep(0)
  }

  const openModal = () => {
    setIsModalOpen(true);
  };

  return (
    <React.Fragment>

      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteCustomer}
        onCloseClick={() => setDeleteModal(false)}
      />
      <AlertModal
        show={alertModal}
        onOkClick={() => {
          getAllCutomers();
          setAlertModal(false);
        }}
        onCloseClick={() => setAlertModal(false)}
        res={response}
      />
      <ErrorAlertModal
       show={errorAlertModal}
       onOkClick={() =>{ getAllCutomers() ,setErrorAlertModal(false) }}
       onCloseClick={() => setErrorAlertModal(false)}
       res={response}
      />

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Settings" breadcrumbItem="Customer" />
          <Row>
            <Col lg="12">

                  <TableContainer
                    columns={columns}
                    data={CustomersList}
                    isGlobalFilter={true}
                    isAddCustomer={true}
                    handleNewCustomerClick={handleNewCustomerClick}
                    customPageSize={10}
                    className="custom-header-css"
                  />

                  <Modal isOpen={modal}  size="lg" toggle={toggle}  backdrop="static" keyboard={false}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Edit Customer" : "Add Customer"}
                    </ModalHeader>
                    <ModalBody>
                      <CustomerForm
                        isOpen={isModalOpen}
                        isEdit={isEdit}
                        onClose={handleClose}
                        parentHandleSubmit={handleSubmit}
                        customerImageDelete={customerImageDelete}
                        customers={customerData}
                        toggle={toggle}
                        getAllCutomers={getAllCutomersAfterEdit}
                      />
                    </ModalBody>
                  </Modal>

            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};


export default withRouter(CustomerMaster);
