const generatePassword = async () => {
    const symbols = '!@#$%^&*()_+[]{}|;:,.<>?';
    const numbers = '0123456789';
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    const allChars = symbols + numbers + letters;
    let newPassword = '';

    newPassword += symbols[Math.floor(Math.random() * symbols.length)];
    newPassword += numbers[Math.floor(Math.random() * numbers.length)];
    newPassword += letters[Math.floor(Math.random() * letters.length)];

    for (let i = 3; i < 12; i++) {
      newPassword += allChars[Math.floor(Math.random() * allChars.length)];
    }

    newPassword = newPassword.split('').sort(() => 0.5 - Math.random()).join('');
    return newPassword;
   
  }

  export default generatePassword;