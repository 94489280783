// PlanCard.js
import React, { useEffect, useState } from 'react';
import { getSelectedplan } from "../../middleware/backent_helper"
import { Row, Col, Card, CardBody, CardTitle, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { getModules, getPlans } from "../../middleware/backent_helper"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import "../../assets/scss/plan.scss"

const PlanCard = ({ onSelect, selectedPlan, isEditSetting, currency, currencyPlan }) => {
  const [plans, setPlans] = useState([]);
  const [isHovered, setIsHovered] = useState(Array(plans.length).fill(false));
  const [isSelected, setIsSelected] = useState(Array(plans.length).fill(false));

  useEffect(() => {
    // Fetch plans from API
    const fetchPlans = async () => {
      try {
        const plansResponse = await getPlans(); // Assuming getPlans() is an async function that fetches plans
        setPlans(plansResponse.data.data); // Assuming plansResponse.data is an array of plan objects
        setIsHovered(Array(plansResponse.data.data.length).fill(false)); // Initialize isHovered state based on plans length
        setIsSelected(Array(plansResponse.data.data.length).fill(false)); // Initialize isSelected state based on plans length
      } catch (error) {
        console.error('Error fetching plans:', error);
      }
    };
    fetchPlans();
  }, []);

  useEffect(() => {
    if (plans.length > 0) {
      const initialSelectedState = plans.map(plan => plan.id === selectedPlan?.planId);
      setIsSelected(initialSelectedState);
    }
  }, [plans, selectedPlan]);

  const handleMouseEnter = (index) => {
    const newHoveredState = [...isHovered];
    newHoveredState[index] = true;
    setIsHovered(newHoveredState);
  };

  const handleMouseLeave = (index) => {
    const newHoveredState = [...isHovered];
    newHoveredState[index] = false;
    setIsHovered(newHoveredState);
  };

  const handleSelectClick = (index, planId) => {

    const getPlanId = plans.find(plan => plan.id === planId)?.id || null;
    const newSelectedState = isSelected.map((selected, i) => (i === index ? !selected : false));
    setIsSelected(newSelectedState);
    if (newSelectedState[index]) {
      onSelect(getPlanId);
    } else {
      onSelect(null);
    }

  };

  const [modalOpen, setModalOpen] = useState(false);

  const handleUpgradeClick = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };




  if (isEditSetting) {
    const selectedPlan = plans.find((plan, index) => isSelected[index]);
    const filteredPlans = plans.filter((plan) => plan.id !== selectedPlan);

    return (
      <>
        <Row className="d-flex justify-content-center align-items-stretch">
          {selectedPlan && (
            <Col md="3" key={selectedPlan.planId} className="d-flex">
              <Card
                onMouseEnter={() => handleMouseEnter(plans.indexOf(selectedPlan))}
                onMouseLeave={() => handleMouseLeave(plans.indexOf(selectedPlan))}
                className="cardStyle d-flex flex-column flex-grow-1 cards "
              ><style jsx>{`
                .cards ::before {content: "";position: absolute;top: 0;left: 0;width: 100%;height: 10px; background: linear-gradient(to right, #6a0dad, #ff4b5c); 
                 border-radius: 10px 10px 0 0;z-index: 1;
                }
                  .cardStyle{
                   box-shadow: 1px 1.5px 6px rgba(0, 0, 0, 0.2);
                  }
              `}</style>
                <CardBody className="d-flex flex-column h-100">
                  <CardTitle
                    tag="h4"
                    className="elementTitle card-header text-center bg-transparent mb-2 "
                    style={{ fontSize: '1.50rem', fontWeight: 'bold', color: '#333', opacity: 0.7, marginTop: '1px' }}

                  >
                    {selectedPlan.planName}
                  </CardTitle>
                  <div className="text-center mb-2">
                    <div className="d-flex flex-column align-items-center mb-2">
                      <div className="d-flex justify-content-center align-items-baseline">
                        <span className='elementCurrency'> {currency.match(/\(([^)]+)\)/)[1]}</span>
                        <span className='elementPrice'>
                          {selectedPlan.yearlyCost.split('/')[0]}
                        </span>
                        <span className='elementYear'>
                          /year {selectedPlan.yearlyCost.split('/')[1]}
                        </span>
                      </div>

                    </div>
                  </div>
                  <ul className="mb-4 list-unstyled text-center flex-grow-1">
                    {selectedPlan.menus && selectedPlan.menus.length > 0 ? (
                      selectedPlan.menus.map((menu, menuIndex) => (
                        <li className="d-flex align-items-center justify-content-center mb-2" key={menuIndex}>
                          <svg
                            className="flex-shrink-0 text-success me-2 styleTest"

                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                          <span className='elementTEst'>{menu}</span>
                        </li>
                      ))
                    ) : (
                      <li className="text-center">
                        <div className='menusStyle'>
                          <FontAwesomeIcon icon={faCheckCircle} className="text-danger fontStyle" />
                          <span className='elementmenuTest'>
                            No menu available.
                          </span>
                        </div>
                        <div className='menusStyle'>
                          <FontAwesomeIcon icon={faCheckCircle} className="text-danger fontStyle" />
                          <span className='elementmenuTest'>
                            Please check back later.
                          </span>
                        </div>
                        <div className='menusStyle'>
                          <FontAwesomeIcon icon={faCheckCircle} className="text-danger fontStyle" />
                          <span className='elementmenuTest'>
                            We apologize for that.
                          </span>
                        </div>
                        <div className='menusStyle'>
                          <FontAwesomeIcon icon={faCheckCircle} className="text-danger fontStyle" />
                          <span className='elementmenuTest'>
                            Our team is working on it.
                          </span>
                        </div>
                        <div className='menusStyle'>
                          <FontAwesomeIcon icon={faCheckCircle} className="text-danger fontStyle" />
                          <span className='elementmenuTest'>
                            Thank you for patience.
                          </span>
                        </div>
                      </li>
                    )}
                  </ul>
                  <div className="mt-auto w-100">
                    <a
                      className="upgradePlan btn btn-primary w-100 py-2 d-flex align-items-center justify-content-center"
                      href="javascript:void(0)"
                      key={plans.indexOf(selectedPlan)}
                      onClick={handleUpgradeClick}

                    >
                      Upgrade Plan
                    </a>
                  </div>
                </CardBody>

              </Card>

            </Col>
          )}
        </Row>


        <Modal
          isOpen={modalOpen}
          toggle={handleModalClose}
          style={{
            position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', maxWidth: '65%', maxHeight: '100%', overflowY: 'auto', margin: '0 auto',
            borderRadius: '10px', boxShadow: '0 0 15px rgba(0, 0, 0, 0.3)', transition: 'opacity 0.5s ease-in-out', opacity: modalOpen ? 1 : 0,

          }}
        >
          <ModalBody
            className={`modal-body ${modalOpen ? 'open' : ''}`}
          >
            <Row className="d-flex justify-content-center align-items-stretch" >
              <CardTitle
                className="card-header bg-transparent text-center"
                style={{ color: '#333', opacity: 0.8, fontSize: '25px', marginBottom: '30px', marginTop: '10px', }}
              >
                Upgrade Your Plan
              </CardTitle>
              <button
                type="button"
                className="close-style close"
                onClick={handleModalClose}
                style={{ fontSize: '1rem', }}


              >

              </button>
              {filteredPlans.map((plan, index) => (
                <Col md="3" key={plan.planId} className="d-flex">
                  <Card


                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={() => handleMouseLeave(index)}
                    className="d-flex flex-column flex-grow-1 cards card-modal"
                  > <style jsx>{`
                    
                    @media (max-width: 1300.98px) {
                      .modal-body .row {flex-direction: column;}
                      .modal-body .col-md-3 { width: 100%;}}
                     .card-modal{box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2)}
                      
                  `}</style>
                    <CardBody
                      className="d-flex flex-column h-100 body-card"

                    >
                      <CardTitle
                        tag="h4"
                        className="card-header text-center bg-transparent mb-2"
                        style={{
                          fontSize: '1.40rem', fontWeight: 'bold', color: '#333', opacity: 0.8,
                        }}
                      >
                        {plan.planName}
                      </CardTitle>
                      <div className="text-center mb-2">
                        <div className="d-flex flex-column align-items-center mb-2">
                          <div className="d-flex justify-content-center align-items-baseline text-center">
                            <span className='elementCurrency'>{currency.match(/\(([^)]+)\)/)[1]}</span>
                            <span className='elementPrice'>
                              {plan.yearlyCost.split('/')[0]}
                            </span>
                            <span className='elementYear'>
                              /year {plan.yearlyCost.split('/')[1]}
                            </span>
                          </div>
                        </div>
                      </div>
                      <ul className="mb-4 list-unstyled text-center flex-grow-1">
                        {plan.menus && plan.menus.length > 0 ? (
                          plan.menus.map((menu, menuIndex) => (
                            <li
                              className="d-flex align-items-center justify-content-center mb-2"
                              key={menuIndex}
                            >
                              <svg
                                className="flex-shrink-0 text-success me-2"
                                style={{ width: '20px', height: '20px' }}
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                  clipRule="evenodd"
                                ></path>
                              </svg>
                              <span className='elementTEst'>{menu}</span>
                            </li>
                          ))
                        ) : (
                          <li className="text-center">
                            <div className='menusStyle'>
                              <FontAwesomeIcon icon={faCheckCircle} className="text-danger fontStyle" />
                              <span className='elementmenuTest'>
                                No menu available.
                              </span>
                            </div>
                            <div className='menusStyle'>
                              <FontAwesomeIcon icon={faCheckCircle} className="text-danger fontStyle" />
                              <span className='elementmenuTest'>
                                Please check back later.
                              </span>
                            </div>
                            <div className='menusStyle'>
                              <FontAwesomeIcon icon={faCheckCircle} className="text-danger fontStyle" />
                              <span className='elementmenuTest'>
                                We apologize for that.
                              </span>
                            </div>
                            <div className='menusStyle'>
                              <FontAwesomeIcon icon={faCheckCircle} className="text-danger fontStyle" />
                              <span className='elementmenuTest'>
                                Our team is working on .
                              </span>
                            </div>
                            <div className='menusStyle'>
                              <FontAwesomeIcon icon={faCheckCircle} className="text-danger fontStyle" />
                              <span className='elementmenuTest'>
                                Thank you for patience.
                              </span>
                            </div>
                          </li>
                        )}
                      </ul>
                      <div className="mt-auto w-100">
                        <a
                          className="btn btn-primary w-100 py-2 d-flex align-items-center justify-content-center"
                          href="javascript:void(0)"
                          key={index}
                          onClick={() => handleSelectClick(index, plan.id)}
                          style={{
                            marginBottom: '6px', fontSize: '0.95rem', backgroundColor: 'rgb(175, 83, 193)', border: 'none', // Remove border
                            transition: 'transform 0.2s ease', // Scale transition
                          }}
                        >
                          {isSelected[index] ? 'Selected' : 'Choose Plan'}
                          {!isSelected[index] && (
                            <span className='span-style'>→</span>
                          )}
                        </a>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
          </ModalBody>

          <Button
            color="secondary"
            onClick={handleModalClose}
            className='upgrade-button-style'
            style={{
              marginRight: '470px', marginLeft: '470px', marginBottom: '30px',marginTop:'25px', transition: 'background-color 0.3s ease',
              backgroundColor: 'rgb(245 29 29 / 67%)', borderRadius: '7px', border: 'none', maxWidth: '500px', display: 'flex', justifyContent: 'center',
              alignItems: 'center', padding: '10px 50px',
            }}

          >
            Upgrade
          </Button>

        </Modal>


      </>
    )
  }





  return (
    <Row className="d-flex justify-content-center align-items-stretch">
      <CardTitle
        className="card-header bg-transparent text-center"
        style={{ color: '#333', opacity: 0.7, fontSize: '30px', marginBottom: '20px' }} // Decreased opacity for the header
      >
        Choose Your Plan
      </CardTitle>
      {plans.map((plan, index) => (
        <Col md="3" key={plan.planId} className="d-flex">
          <Card

            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={() => handleMouseLeave(index)}
            className="d-flex flex-column flex-grow-1 cards cardStyle"
          ><style jsx>{`
            .cards ::before {content: "";position: absolute;top: 0;left: 0;width: 100%;height: 10px; background: linear-gradient(to right, #6a0dad, #ff4b5c); 
             border-radius: 10px 10px 0 0;z-index: 1;
            }
              .cardStyle{
               box-shadow: 1px 1.5px 6px rgba(0, 0, 0, 0.2);
              }
          `}</style>
            <CardBody className="d-flex flex-column h-100">
              <CardTitle
                tag="h4"
                className="card-header text-center bg-transparent mb-2 elementTitle"
                style={{ fontSize: '1.40rem' }}
              >
                {plan.planName}
              </CardTitle>
              <div className="text-center mb-2">
                <div className="d-flex flex-column align-items-center mb-2">
                  <div className="d-flex justify-content-center align-items-baseline">
                    <span className='elementCurrency'> {currencyPlan ? currencyPlan.match(/\(([^)]+)\)/)[1] : '₹'} </span>
                    <span className='elementPrice'>
                      {plan.yearlyCost.split('/')[0]}
                    </span>
                    <span className='elementYear'>
                      /year {plan.yearlyCost.split('/')[1]}
                    </span>
                  </div>

                </div>
              </div>
              <ul className="mb-4 list-unstyled text-center flex-grow-1">
                {plan.menus && plan.menus.length > 0 ? (
                  plan.menus.map((menu, menuIndex) => (
                    <li className="d-flex align-items-center justify-content-center mb-2" key={menuIndex}>
                      <svg
                        className="flex-shrink-0 text-success me-2"
                        style={{ width: '20px', height: '20px' }}
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <span className='elementTEst'>{menu}</span>
                    </li>
                  ))
                ) : (
                  <li className="text-center">
                    <div className='menusStyle'>
                      <FontAwesomeIcon icon={faCheckCircle} className="text-danger fontStyle" />
                      <span className='elementmenuTest'>
                        No menu available.
                      </span>
                    </div>
                    <div className='menusStyle'>
                      <FontAwesomeIcon icon={faCheckCircle} className="text-danger fontStyle" />
                      <span className='elementmenuTest'>
                        Please check back later.
                      </span>
                    </div>
                    <div className='menusStyle'>
                      <FontAwesomeIcon icon={faCheckCircle} className="text-danger fontStyle" />
                      <span className='elementmenuTest'>
                        We apologize for that.
                      </span>
                    </div>
                    <div className='menusStyle'>
                      <FontAwesomeIcon icon={faCheckCircle} className="text-danger fontStyle" />
                      <span className='elementmenuTest'>
                        Our team is working on .
                      </span>
                    </div>
                    <div className='menusStyle'>
                      <FontAwesomeIcon icon={faCheckCircle} className="text-danger fontStyle" />
                      <span className='elementmenuTest'>
                        Thank you for patience.
                      </span>
                    </div>
                  </li>
                )}
              </ul>
              <div className="mt-auto w-100">
                <a
                  className="btn btn-primary w-100 py-2 d-flex align-items-center justify-content-center button-class"
                  href="javascript:void(0)"
                  key={index}
                  onClick={() => handleSelectClick(index, plan.id)}

                >
                  {isSelected[index] ? 'Selected' : 'Choose Plan'}
                  {!isSelected[index] && (
                    <span className='span-style'>→</span>
                  )}
                </a>
              </div>
            </CardBody>
          </Card>
        </Col>
      ))}
    </Row>
  );
};


export default PlanCard;
