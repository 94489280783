import React, { useState, useEffect } from "react";
import {
    Modal,
    ModalBody,
    Form,
    Row,
    Col,
    Label,
    Card,
    CardBody,
    CardHeader,
    FormGroup,
    Table,
    Input
} from "reactstrap";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { get_record_history, getRoomServiceData, getGuestChargeData, getAdvanceData } from '../../middleware/backent_helper';
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import '../../assets/scss/plan.scss'

const RoomHistory = ({ show, onCloseClick, bookingId }) => {
    const [hid, setHid] = useState(() => {
        const storedHotelId = localStorage.getItem('hotelId');
        return storedHotelId ? storedHotelId.replace(/"/g, '') : '';
    });
    const [dbName, setDbName] = useState(localStorage.getItem('dbName'));
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();
    const [guestDetails, setGuestDetails] = useState();
    const [employeeList, setEmployeeList] = useState([]);
    const [roomServiceData, setRoomServiceData] = useState({});
    const [advanceData, setAdvanceData] = useState({});
    const [isRefund, setIsRefund] = useState(false);
    const [totalData, setTotalData] = useState(0);
    const [totalLessAdvance, setTotalLessAdvance] = useState(0);
    const [GuestCharge, setGuestCharges] = useState({});

    const navigate = useNavigate();

    const handleButtonClick = (path) => {
        navigate(`/manage_room_service/${bookingId}`);
    };

    useEffect(() => {
        if (bookingId) {
            get_record_history(hid, dbName, bookingId)
                .then((res) => {
                    setGuestDetails(res.data.data);
                });
        }
    }, [bookingId]);

    const validation = useFormik({
        initialValues: {
            servicePersonId: "",
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            servicePersonId: Yup.string().required("Please Choose service person"),
        }),
        onSubmit: (values) => {
            Get_record(values);
            validation.resetForm();
        }
    });

    const Get_record = (values) => {
        const servicePerson = values.servicePersonId;
        const FromDate = fromDate;
        const ToDate = toDate;

        get_record(dbName, servicePerson, FromDate, ToDate).then((res) => {
            setEmployeeList(res.data.data);
        });
    };

    const getDatesBetween = (startDate, endDate) => {
        const dates = [];
        const startMoment = moment(startDate);
        const endMoment = moment(endDate);
        while (startMoment.isSameOrBefore(endMoment)) {
            dates.push(startMoment.format('YYYY-MM-DD')); // Format the date as a string
            startMoment.add(1, 'day');
        }
        return dates;
    };

    const datesBetween = getDatesBetween(
        new Date(guestDetails?.CheckInDate),
        moment().toDate()
    );

    useEffect(() => {
        const promises = datesBetween.map((date) => {
            return getRoomServiceData(date, dbName, bookingId).then((response) => {
                return { [date]: response.data };
            });
        });
        Promise.all(promises).then((results) => {
            const roomServiceData = results.reduce((acc, current) => {
                return { ...acc, ...current };
            }, {});
            setRoomServiceData(roomServiceData);
        });

        const promisesAdvance = datesBetween.map((date) => {
            return getAdvanceData(date, dbName, bookingId).then((response) => {
                return { [date]: response.data };
            });
        });
        Promise.all(promisesAdvance).then((results) => {
            const advanceData = results.reduce((acc, current) => {
                return { ...acc, ...current };
            }, {});
            setAdvanceData(advanceData);
        });
        const promisesGuestCharges = datesBetween.map((date) => {
            return getGuestChargeData(date, dbName, bookingId).then((response) => {
                return { [date]: response.data };
            });
        });
        Promise.all(promisesGuestCharges).then((results) => {
            const guestChargesData = results.reduce((acc, current) => {
                return { ...acc, ...current };
            }, {});
            setGuestCharges(guestChargesData);

        });
    }, [guestDetails]);

    const [cumulativeGuestTariff, setCumulativeGuestTariff] = useState({});
    const [guestTariffss, setGuestTariffss] = useState(0);

    
    useEffect(() => {
        if (guestDetails && datesBetween.length > 0) {
            // Calculate total charges
            const totalCharges = datesBetween.reduce((acc, date, index) => {
                if (index === datesBetween.length - 1) {
                    const roomRent = guestDetails.Tariff;
                    const gst = guestDetails.Gst;
                    const totalRoomService = Object.values(roomServiceData).reduce((acc, currentValue) => acc + (currentValue.data.TotalCash || 0), 0);
                    const previousRoomRent = index > 0 ? guestDetails.Tariff * index : 0;
                    const previousGst = index > 0 ? guestDetails.Gst * index : 0;
                    const lastCumulativeTariff = cumulativeGuestTariff[datesBetween[datesBetween.length - 1]] || 0;
                    return acc + roomRent + gst + totalRoomService + previousRoomRent + previousGst + lastCumulativeTariff;
                }
                return acc;
            }, 0);
    
            setTotalData(totalCharges);
    
            // Calculate total advance
            const totalAdvance = Object.values(advanceData).reduce((acc, currentValue) => {
                const advanceAmount = currentValue.data.AdvanceAmount;
                // Check if advanceAmount is a valid number
                if (advanceAmount > 0) {
                    return acc + advanceAmount;
                }
                console.warn(`Unexpected advance amount: ${advanceAmount}`);
                return acc;
            }, 0);
    
            setTotalLessAdvance(totalAdvance);
    
            // Calculate balance
            const balance = totalCharges - totalAdvance;
            setIsRefund(totalAdvance > totalCharges); // Set isRefund based on whether total advances exceed total charges
    
            console.log("Total Charges: ", totalCharges);
            console.log("Total Advances: ", totalAdvance);
            console.log("Balance: ", balance);
        }
    }, [roomServiceData, advanceData, guestDetails, datesBetween, cumulativeGuestTariff]);
    
    

   


    useEffect(() => {
        let runningTotal = 0; // Initialize cumulative total
        let lastNonZeroTotal = 0; // Track the last non-zero total
        let lastAddedValue = 0; // Track the last added value to handle incremental addition correctly

        const cumulativeTariff = datesBetween.reduce((acc, date, index) => {
            const currentGuestTariff = GuestCharge[date] ? GuestCharge[date].data.Guest_Tariff : 0;

            if (index === 0) {
                // For the first date, use the currentGuestTariff directly
                runningTotal = currentGuestTariff;
                lastNonZeroTotal = currentGuestTariff;
                lastAddedValue = currentGuestTariff;
                setGuestTariffss(currentGuestTariff)
            } else if (currentGuestTariff !== 0) {
                setGuestTariffss(currentGuestTariff)
                // For subsequent dates with non-zero tariff, add the currentGuestTariff to lastNonZeroTotal
                runningTotal = lastNonZeroTotal + currentGuestTariff;
                lastNonZeroTotal = runningTotal;
                lastAddedValue = currentGuestTariff;
            } else {
                // For dates with zero tariff, increment runningTotal by lastAddedValue
                runningTotal = lastNonZeroTotal + lastAddedValue;
                lastNonZeroTotal = runningTotal;
            }

            acc[date] = runningTotal; // Set the cumulative total for the current date
            return acc;
        }, {});

        setCumulativeGuestTariff(cumulativeTariff); // Update state with cumulative values
    }, [GuestCharge]);





    return (
        <React.Fragment>
            <Modal size="lg" isOpen={show} toggle={onCloseClick} centered={true}>
                <ModalBody className="px-4 py-5">
                    <button type="button" onClick={onCloseClick} className="btn-close position-absolute end-0 top-0 m-3"></button>
                    <h5 className="mb-4">Guest History</h5>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                    >

                        {/* Guest Details Card */}
                        <Card className="mb-4">
                            <CardHeader className="grey-background text-black fw-bold">
                                Guest Details
                            </CardHeader>
                            <CardBody>
                                <Row className="mb-3">
                                    <Col md={4}>
                                        <div><strong>Check-In:</strong> {guestDetails?.CheckInDate}</div>
                                    </Col>
                                    <Col md={4}>
                                        <div><strong>Check-Out:</strong> {guestDetails?.CheckOutDate}</div>
                                    </Col>
                                    <Col md={4}>
                                        <div><strong>Room Detail:</strong> {`${guestDetails?.RoomNo} (${guestDetails?.RoomType})`}</div>
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col md={4}>
                                        <div><strong>Tariff:</strong> {guestDetails?.Tariff}</div>
                                    </Col>
                                    <Col md={4}>
                                        <div><strong>Name:</strong> {guestDetails?.Name}</div>
                                    </Col>
                                    <Col md={4}>
                                        <div><strong>Mobile:</strong> {guestDetails?.Mobile}</div>
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col md={4}>
                                        <div><strong>Address:</strong> {guestDetails?.Address}</div>
                                    </Col>

                                    <Col md={4}>
                                        <div><strong>Guest Tariff</strong> {guestTariffss}
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div><strong>GRC No:</strong> {
                                            Math.max(...datesBetween.filter((date) => advanceData[date] && advanceData[date].data.GRCNo !== 0).map((date) => (
                                                advanceData[date].data.GRCNo
                                            )))
                                        }</div>
                                    </Col>
                                </Row>
                                <Row>

                                    <Col md={4}>
                                        <div><strong>Pax (M+F+Kids):</strong> {`${guestDetails?.Male}+${guestDetails?.Female}+${guestDetails?.Kids}`}</div>
                                    </Col>

                                </Row>
                            </CardBody>
                        </Card>

                        {/* Transaction Details Card */}
                        <Card className="mb-4">
                            <CardHeader className="grey-background text-black fw-bold">
                                Transaction Details
                            </CardHeader>
                            <CardBody>
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th className="text-center">Date</th>
                                            <th className="text-center">Room Rent</th>
                                            <th className="text-center">Guest Charges</th>
                                            <th className="text-center">GST</th>
                                            <th className="text-center">Room Service</th>
                                            <th className="text-center">Less Advance</th>
                                            <th className="text-center">Method of payment</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {datesBetween.map((date, index) => (
                                            <tr key={index}>
                                                <td className="text-center">{moment(date).format('DD-MM-YYYY')}</td>
                                                <td className="text-center">
                                                    {index === 0 ? guestDetails.Tariff : (guestDetails.Tariff * (index + 1))}
                                                </td>
                                                <td className="text-center" key={index}>
                                                    {cumulativeGuestTariff[date] || 0}
                                                </td>
                                                <td className="text-center">
                                                    {index === 0 ? guestDetails.Gst : (guestDetails.Gst * (index + 1))}
                                                </td>
                                                <td className="text-center">
                                                    {roomServiceData[date] ? roomServiceData[date].data.TotalCash : 0}
                                                </td>
                                                <td className="text-center">
                                                    {advanceData[date] ? advanceData[date].data.AdvanceAmount : 0}
                                                </td>
                                                <td className="text-center">
                                                    {advanceData[date] ? advanceData[date].data.paymentType : 0}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>

                        {/* Payment Details Card */}
                        <Card>
                            <CardHeader className="grey-background text-black fw-bold">
                                Payment Details
                            </CardHeader>
                            <CardBody>
                                <Row className="mb-2">
                                    <Col md={3}>
                                        <div><strong>Total:</strong> {totalData}</div>
                                    </Col>
                                    <Col md={3}>
                                        <div><strong>Balance:</strong> {Math.abs(totalData - totalLessAdvance)}</div>
                                    </Col>

                                    <Col md={2}>
                                        <div><strong>Amount:</strong></div>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup check inline>
                                            <Label check>
                                                <Input
                                                    type="radio"
                                                    name="amountType"
                                                    value="Recieved"
                                                    checked={!isRefund}
                                                    onChange={() => setIsRefund(false)}
                                                />
                                                Received
                                            </Label>
                                        </FormGroup>
                                        <FormGroup check inline>
                                            <Label check>
                                                <Input
                                                    type="radio"
                                                    name="amountType"
                                                    value="Refund"
                                                    checked={isRefund}
                                                    onChange={() => setIsRefund(true)}
                                                />
                                                Refund
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>

                        {/* Button Row */}
                        <Row className="mt-3">
                            <Col className="col-1 mx-4">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => handleButtonClick('/manage_room_service')}
                                >
                                    Service
                                </button>
                            </Col>
                            <Col className="col-2 mx-0">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                >
                                    Print
                                </button>
                            </Col>
                        </Row>

                    </Form>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default RoomHistory;
