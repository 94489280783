import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody, FormGroup, Label, Input, Button, CardHeader, Form, FormFeedback, Modal, ModalHeader, ModalBody, ModalFooter, Table } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import BlockModal from "components/Booking/block";
import { useNavigate } from 'react-router-dom';
import Select from "react-select";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from "react-redux";
import { getGuestDetils, getWithoutCheckinRoomsAndType, getRoomService, getSalesDetails, getInventory, getPaymentTypes, getRooms, post_service_inventory } from "../../../middleware/backent_helper";
import { useLocation, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import AlertModal from "../../../components/Common/alertModal";
import ErrorAlertModal from "../../../components/Common/errorAlertModal";
import messages from '../../../utils/constants/messages';


const ManageRoomService = () => {
    document.title = "StayPro | Room Service";
    const [serviceDate, setServiceDate] = useState(new Date());
    const [proofTypes, setProofTypes] = useState([]);
    const [selectedProofType, setselectedProofType] = useState('');
    const [selectedPayment, setselectedPayment] = useState('');
    const [paymentTypeList, setPaymentTypes] = useState([]);
    const [roomsList, setRoomsList] = useState([]);
    const [selectedWifi, setSelectedWifi] = useState(null);
    const [roomsAndtypeList, setRoomAndTypeList] = useState([]);
    const [selectedRoomType, setSelectedRoomType] = useState(null);
    const [filteredRoomTypes, setFilteredRoomTypes] = useState([]);
    const [alertModal, setAlertModal] = useState(false);
    const [errorAlertModal, setErrorAlertModal] = useState(false);
    const [selectedcheckoutBy, setselectedCheckouBy] = useState('');
    const [GuestDetails, setGuestDetails] = useState([]);
    const [response, setResponse] = useState(false);
    const [masterName, setMasterName] = useState("Room Service");


    const [hid, setHid] = useState(() => {
        const storedHotelId = localStorage.getItem('hotelId')
        return storedHotelId ? storedHotelId.replace(/"/g, '') : '';
    });
    const [dbName, setDbName] = useState(
        localStorage.getItem('dbName')
    )
    const navigate = useNavigate();
    const dispatch = useDispatch();


    const handleBackClick = () => {

        navigate(`/booking`);
    };

    const [selectedTest, setSelectedTest] = useState(null);

    const handleItemName = selectedOption => {
        setSelectedTest(selectedOption);
        validation.setFieldValue('itemName', selectedOption ? selectedOption.value : '');
    };



    // Handle room type change
    const handleRoomTypeChange = (selectedType) => {
        setSelectedRoomType(selectedType);
        validation.setFieldValue('roomTypeIds', selectedType ? selectedType.value : '');
    };

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const { bookingId } = useParams();


    useEffect(() => {
        getGuestDetils(hid, dbName, bookingId)
            .then((res) => {

                setGuestDetails(res.data.data);
            });
    }, [])

    const [InventoryList, setInventoryList] = useState([]);
    const [serviceList, setServiceList] = useState([]);

    useEffect(() => {
        getSalesDetails(dbName, bookingId, hid)
            .then((res) => {
                setServiceList(res.data.data);
            });
    }, [])
    useEffect(() => {
        getInventory(hid, dbName)
            .then((res) => {
                setInventoryList(res.data.data);
            });
    }, [])

    useEffect(() => {
        getPaymentTypes(hid, dbName)
            .then((res) => {
                setPaymentTypes(res.data.data);
            });
    }, [])

    useEffect(() => {
        getWithoutCheckinRoomsAndType(hid, dbName)
            .then((res) => {

                setRoomAndTypeList(res.data.data);
            });
    }, [])

    // useEffect(() => {
    //     getRoomService(hid, dbName)
    //         .then((res) => {
    //             setRoomServiceList(res.data.data);
    //         });
    // }, [])





    const handleProofTypeChange = selectedOption => {
        setselectedProofType(selectedOption)
        validation.setFieldValue('proofType', selectedOption ? selectedOption.value : '');

    }



    const handlePaymentTypeChange = selectedOption => {
        setselectedPayment(selectedOption)
        validation.setFieldValue('payment', selectedOption ? selectedOption.value : '');

    }

    const handleCheckoutByChange = selectedOption => {
        setselectedCheckouBy(selectedOption)
        validation.setFieldValue('checkout_by', selectedOption ? selectedOption.value : '');

    }





    useEffect(() => {
        getRooms(hid, dbName)
            .then((res) => {

                setRoomsList(res.data.data);
            });
    }, [])



    const calculateDaysBetween = (checkInDate, checkOutDate) => {
        const checkIn = new Date(checkInDate);
        const checkOut = new Date(checkOutDate);

        // Calculate the difference in time (milliseconds)
        const diffTime = Math.abs(checkOut - checkIn);

        // Convert milliseconds to days
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        return diffDays;
    };


    const calculateTotalTariff = (tariff, checkInDate, checkOutDate) => {
        const days = calculateDaysBetween(checkInDate, checkOutDate);
        const totalDays = days === 0 ? 1 : days;
        return tariff * totalDays;
    };


    const validation = useFormik({
        initialValues: {


        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            // serviceDate: Yup.date().required("Please Select Date"),
            // // roomTypeIds: Yup.string().required('RoomType is required'),
            // // payment: Yup.string().required('Payment Type is required'),
            // // checkout_by: Yup.string().required('Service Member is required'),
            // // roomIds: Yup.string().required('Room No is required'),
            // // remarks: Yup.string().required("Please Enter Your Remarks"),
            // normaltariff: Yup.string().required('Tariff is required'),
            // advance_Amt: Yup.string().required('Advance Amount is required')
        }),
        onSubmit: (values) => {
            dispatch(post_Service(values));
        }
    });

    const post_Service = (values) => {
        const data = JSON.stringify({
            ItemDetails: formData,
            Remarks: values.remarks,
            Payment: values.payment,

            hotelId: hid,
            dbName: dbName,
            BookingId: bookingId,


        });
        console.log(data)
        post_service_inventory(data).then((res) => {
            if (res.data.success) {
                setResponse(messages.success.createdMessage(masterName))
                setAlertModal(true);
            }
        }).catch(err => {

            setResponse(messages.failed.createFailed())
            setErrorAlertModal(true)

        });

    };

    const [formData, setFormData] = useState([]);
    const [quantity, setQuantity] = useState('');
    const handleAdd = () => {
        if (selectedTest && quantity) {
            const itemCost = InventoryList.find((item) => item.name === selectedTest.label).cost;
            const newItem = {
                item: selectedTest.label,
                quantity,
                serviceDate,
                itemId: selectedTest.value,
                cost: itemCost * quantity,
            };
            setFormData((prevFormData) => [...prevFormData, newItem]);


        }
        setSelectedTest(null);
        setQuantity('');
    };

    useEffect(() => {
        console.log(formData);
    }, [formData]);


    return (
        <React.Fragment>
            <AlertModal
                show={alertModal}
                onOkClick={() => {
                    getSalesDetails(dbName, bookingId, hid).then((res) => {
                        setServiceList(res.data.data);
                    });

                    setAlertModal(false);
                    setFormData([]); // clear formData
                    validation.setFieldValue('payment', ''); // clear payment
                    validation.setFieldValue('remarks', '');
                }}
                onCloseClick={() => setAlertModal(false)}
                res={response}
            />
            <ErrorAlertModal
                show={errorAlertModal}
                onOkClick={() => { getSalesDetails(dbName, bookingId, hid), setErrorAlertModal(false) }}
                onCloseClick={() => setErrorAlertModal(false)}
                res={response}
            />

            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Bookings" breadcrumbItem="Manage Room Service" />
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            // console.log("Form Submitted");
                            validation.handleSubmit();
                            return false;
                        }}
                    >


                        <Row>
                            <Row>
                                <Col lg={12}>
                                    <Card>

                                        <CardBody>
                                            <div className="mb-2">
                                                <Row className="align-items-center">
                                                    <Col lg={4} md={4} sm={4}>
                                                        <strong>Guest Name :</strong>
                                                        <span> {GuestDetails.guestName}</span>
                                                        <span style={{ display: 'none' }}> {GuestDetails.guestId}</span>
                                                    </Col>
                                                    <Col lg={3} md={3} sm={3}>
                                                        <strong>Mobile No :</strong>
                                                        <span> {GuestDetails.mobileNo}</span>
                                                    </Col>

                                                    <Col lg={3} md={3} sm={3}>
                                                        <strong>Checkin Date :</strong><span> {GuestDetails.checkInDate ? GuestDetails.checkInDate.slice(0, 16) : ''}</span>
                                                    </Col>

                                                    <Col lg={2} md={2} sm={2}>
                                                        <strong>Room No :</strong><span> {GuestDetails.roomNo}</span>
                                                        <span style={{ display: 'none' }}> {GuestDetails.roomId}</span>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>

                            <Row>

                                <Col lg={6}>
                                    <Card>
                                        <CardHeader><h5>Inventory Stock List</h5></CardHeader>
                                        <CardBody>
                                            <Table bordered responsive>
                                                <thead>
                                                    <tr>
                                                        <th className="text-center">S.No.</th>
                                                        <th className="text-center">Items</th>
                                                        <th className="text-center">Available</th>
                                                        <th className="text-center">Cost</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {InventoryList.map((item, index) => (
                                                        <tr key={index}>
                                                            <td className="text-center">{index + 1}</td>
                                                            <td className="text-center">{item.name}</td>
                                                            <td className="text-center">{parseInt(item.availableQuantity, 10)}</td>
                                                            <td className="text-center">{item.cost}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>


                                        </CardBody>
                                    </Card>
                                    <Card>
                                        <CardHeader><h5>View Services : {GuestDetails.guestName} {GuestDetails.roomNo}</h5></CardHeader>
                                        <CardBody>
                                            <Table bordered responsive>
                                                <thead>
                                                    <tr>
                                                        <th className="text-center">Date.</th>
                                                        <th className="text-center">Delivered Items</th>
                                                        <th className="text-center">Cash</th>
                                                        <th className="text-center">Room</th>
                                                        <th className="text-center">Total Cost</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {serviceList.map((item, index) => (
                                                        <tr key={index}>
                                                            <td className="text-center">{item.transactionDate}</td>
                                                            <td className="text-center">{item.inventoryType}</td>
                                                            <td className="text-center">
                                                                {parseInt(item.paymentMode === "By Cash" ? item.requiredQuantity : 0, 10)}
                                                            </td>
                                                            <td className="text-center">
                                                                {parseInt(item.paymentMode === "By Room" ? item.requiredQuantity : 0, 10)}
                                                            </td>
                                                            <td className="text-center">{item.inventoryCash}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th colSpan="2" className="text-center">Total:</th>
                                                        <th className="text-center">{serviceList.reduce((acc, item) => acc + (item.paymentMode === "By Cash" ? item.inventoryCash : 0), 0).toFixed(0)}</th>
                                                        <th className="text-center">{serviceList.reduce((acc, item) => acc + (item.paymentMode === "By Room" ? item.inventoryCash : 0), 0).toFixed(0)}</th>
                                                        <th className="text-center">{serviceList.reduce((acc, item) => acc + item.inventoryCash, 0).toFixed(0)}</th>
                                                    </tr>
                                                </tfoot>
                                            </Table>

                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col lg={6}>
                                    <Card>
                                        <CardHeader><h5 className="">Room Service</h5></CardHeader>
                                        <CardBody>
                                            <div className="mb-2">
                                                <Row>
                                                    <Row className="align-items-center">
                                                        <Col xs={6}>
                                                            <div className="mb-3">
                                                                <Label className="form-label"> Date</Label>
                                                                <DatePicker
                                                                    name="serviceDate"
                                                                    selected={serviceDate}
                                                                    onChange={(date) => setServiceDate(date)}
                                                                    className="form-control"
                                                                    dateFormat="d-MM-yyyy"
                                                                    placeholderText="Select date and time"
                                                                    timeFormat="HH:mm"
                                                                    timeIntervals={1}
                                                                />
                                                            </div>
                                                        </Col>

                                                        <Col lg={6} md={6}>
                                                            <div className="mb-3">
                                                                <FormGroup>
                                                                    <Label className="form-label">Item Name</Label>
                                                                    <Select
                                                                        name="itemName"
                                                                        placeholder="Select Item "
                                                                        classNamePrefix="react-select"
                                                                        className={`react-select-container ${validation.touched.itemName && validation.errors.itemName
                                                                            ? 'is-invalid'
                                                                            : ''
                                                                            }`}

                                                                        options={InventoryList.map(inven => ({ value: inven.id, label: inven.name }))}
                                                                        value={selectedTest}

                                                                        onChange={handleItemName}

                                                                        styles={{
                                                                            menu: (provided) => ({
                                                                                ...provided,
                                                                                top: "100%",
                                                                                position: "absolute",
                                                                                width: "100%",
                                                                                zIndex: 999,
                                                                            }),
                                                                        }}
                                                                        isClearable
                                                                        isSearchable
                                                                    />
                                                                    {validation.touched.itemName &&
                                                                        validation.errors.itemName ? (
                                                                        <FormFeedback type="invalid">
                                                                            {validation.errors.itemName}
                                                                        </FormFeedback>
                                                                    ) : null}
                                                                </FormGroup>
                                                            </div>
                                                        </Col>
                                                        <Col lg={6} md={6}>
                                                            <div className="mb-3">
                                                                <Label className="form-label">Quantity</Label>
                                                                <Input
                                                                    name="quantity"
                                                                    type="text"
                                                                    placeholder="Enter Quantity"
                                                                    onChange={(e) => setQuantity(e.target.value)}
                                                                    onBlur={validation.handleBlur}
                                                                    value={quantity}
                                                                    invalid={
                                                                        validation.touched.quantity &&
                                                                            validation.errors.quantity
                                                                            ? true
                                                                            : false
                                                                    }
                                                                />
                                                                {validation.touched.quantity &&
                                                                    validation.errors.quantity ? (
                                                                    <FormFeedback type="invalid">
                                                                        {validation.errors.quantity}
                                                                    </FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                        <Button onClick={handleAdd} color="primary" className="mx-2 button_add_width" type="button">Add</Button>
                                                        <div className="mt-4">
                                                            <table className="table">
                                                                <thead>
                                                                    <tr>

                                                                        <th>Item Name</th>
                                                                        <th>Quantity</th>
                                                                        <th>Cost</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {formData.length > 0 && formData.map((data, index) => (
                                                                        <tr key={index}>

                                                                            <td>{data.item}</td>
                                                                            <td>{data.quantity}</td>
                                                                            <td>
                                                                                {InventoryList.find((item) => item.name === data.item) &&
                                                                                    (InventoryList.find((item) => item.name === data.item).cost *
                                                                                        data.quantity).toFixed(2)}
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                    </Row>


                                                    <Row>
                                                        <Col lg={6} md={6}>
                                                            <div className="mb-3">
                                                                <label className="form-label" htmlFor="autoSizingSelect">Select Payment</label>
                                                                <Input
                                                                    type="select"
                                                                    name="payment"
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.payment || ""}
                                                                    invalid={
                                                                        validation.touched.payment && validation.errors.payment
                                                                    }
                                                                >
                                                                    <option value="">Select an option</option>
                                                                    <option value="By Room">By Room</option>
                                                                    <option value="By Cash">By Cash</option>

                                                                </Input>
                                                            </div>
                                                        </Col>



                                                        <Col lg={6} md={6}>
                                                            <div className="mb-3">
                                                                <Label className="form-label">Remarks</Label>
                                                                <Input
                                                                    name="remarks"
                                                                    type="text"
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.remarks || ""}
                                                                    invalid={
                                                                        validation.touched.remarks &&
                                                                            validation.errors.remarks
                                                                            ? true
                                                                            : false
                                                                    }
                                                                />
                                                                {validation.touched.remarks &&
                                                                    validation.errors.remarks ? (
                                                                    <FormFeedback type="invalid">
                                                                        {validation.errors.remarks}
                                                                    </FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={12}>
                                                            <div className="d-flex justify-content-center">
                                                                <Button color="btn btn-success" type="submit" className="mx-2">
                                                                    Save
                                                                </Button>
                                                                <Button color="secondary" className="mx-2" onClick={() => handleBackClick()}>
                                                                    Back
                                                                </Button>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Row>

                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>

                        </Row>
                    </Form>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default ManageRoomService;
