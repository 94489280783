const InventryName = (cell) => {
    return cell.value ? cell.value : '';
};
const AvailableQuantity = (cell) => {
    return cell.value ? cell.value : '';
};
const ReOrderQuantity = (cell) => {
    return cell.value ? cell.value : '';
};
const Price = (cell) => {
    return cell.value ? cell.value : '';
};
const Cost = (cell) => {
    return cell.value ? cell.value : '';
};

export {
    InventryName,
    AvailableQuantity,
    ReOrderQuantity,
    Price,
    Cost
};