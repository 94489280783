import * as moment from "moment";

const PlanName = (cell) => {
    return cell.value ? cell.value : '';
};

const Description = (cell) => {
    return cell.value ? cell.value : '';
};

const Modules = (cell) => {
    return cell.value ? cell.value : '';
};


const MonthlyCost = (cell) => {
    return cell.value ? cell.value : '';
};
const YearlyCost = (cell) => {
    return cell.value ? cell.value : '';
};

const Status = (cell) => {
    return cell.value ? "Active" : 'Inactive';
};



export {
    PlanName,
    Description,
    Modules,
    MonthlyCost,
    YearlyCost,
    Status
};