import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody
} from "reactstrap";


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";
import RightSidebar from "layouts/CommonForBoth/RightSidebar";


const Reservation = props => {
  const [showSideBar, setShowSideBar] = useState(false);
  const [subscribemodal, setSubscribemodal] = useState(false);

  const { chartsData } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData
  }));
  // const token = props.location.state.token;
  const [token, setToken] = useState(
    localStorage.getItem('token')
);

 const showRightSidebar = () => {
    setShowSideBar(true);
    }

  useEffect(() => {
    setTimeout(() => {
      setSubscribemodal(true);
    }, 2000);
  }, []);

  const [periodData, setPeriodData] = useState([]);
  const [periodType, setPeriodType] = useState("yearly");

  useEffect(() => {
    setPeriodData(chartsData);
  }, [chartsData]);

  //meta title
  document.title = "StayPro | Reservation";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs breadcrumbItem={props.t("Reserve Rooms")} />

          <Row>
            <Col xl="12">
              <Card>
                <CardHeader></CardHeader>
                <CardBody>
                  <button
                    onClick={() => showRightSidebar()}
                    className="btn-label btn btn-info"
                  >
                    <i className="bx bx-home-cog label-icon"></i>
                    Open Side Bar
                  </button>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {showSideBar ? (
            <>
              <div className="right-bar big" id="right-bar"></div>
              <div className="rightbar-overlay"></div>
            </>
          ) : null}
        </Container>
      </div>
    </React.Fragment>
  )
};

Reservation.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
};

export default withTranslation()(Reservation);
