import React from "react"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Col } from "reactstrap"
import logodark from "../../assets/images/logo.png"
import { Link } from "react-router-dom"

const CarouselPage = () => {
  return (
    <React.Fragment>
      <Col xl={9}>
        <div className="auth-full-bg pt-lg-5 p-4">
          <div className="w-100">

            <div className="bg-overlay"></div>
            <div className="d-flex h-100 flex-column">
            <div className="m-2 text-center">
              <Link to="/" className="d-block auth-logo">
                <img
                  src={logodark}
                  alt=""
                  height="40"
                  className="logo-dark-element"
                />
              </Link>
            </div>
              <div className="p-4 mt-auto">
                <div className="row justify-content-center">
                  <div className="col-lg-7">
                    <div className="text-center">
                      <h4 className="mb-3">
                        <i className="bx bxs-quote-left text-primary h2 align-middle me-2"></i>
                        <span className="text-primary">100</span>+ Satisfied
                        clients
                      </h4>
                      <div dir="ltr">
                        <Carousel
                          className="owl-carousel owl-theme auth-review-carousel slider_css"
                          id="auth-review-carousel"
                          showThumbs={false}
                        >
                          <div>
                            <div className="item">
                              <div className="pb-5 pt-3">
                                <p className="font-size-16 mb-4">
                                  &quot;Fantastic theme with a ton of options.
                                  If you just want the HTML to integrate with
                                  your project, then this is the package. You
                                  can find the files in the &apos;dist&lsquo;
                                  folder...no need to install git and all the
                                  other stuff the documentation talks about.
                                  &ldquo;
                                </p>

                                <div>
                                  <h4 className="font-size-16 text-primary">
                                    Srinath
                                  </h4>
                                  <p className="font-size-14 mb-0">
                                    Aira Park, Salem, India
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="item">
                              <div className="pb-5 pt-3">
                                <p className="font-size-16 mb-4">
                                  &quot;If Every Vendor on Envato are as
                                  supportive as Themesbrand, Development with be
                                  a nice experience. You guys are Wonderful.
                                  Keep us the good work. &ldquo;
                                </p>

                                <div>
                                  <h4 className="font-size-16 text-primary">
                                    Chandrasekar
                                  </h4>
                                  <p className="font-size-14 mb-0">
                                    Hote KC, Yercaud, India
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Carousel>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </React.Fragment>
  )
}
export default CarouselPage
