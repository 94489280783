import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../../components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { userRegister, usersRead, deleteUser} from "../../../middleware/backent_helper";
import { updateUser } from "../../../api/index"
import { Name, Email, MobileNo, Status } from "./userslistCol";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import DeleteModal from "../../../components/Common/DeleteModal";
import AlertModal from "../../../components/Common/alertModal";
import ErrorAlertModal from "../../../components/Common/errorAlertModal";

//redux
import { useSelector, useDispatch } from "react-redux";

const UsersList = props => {

  //meta title
  document.title = "User List | HMS ";

  const dispatch = useDispatch();
  const [Users, setUsers] = useState();

  const [userList, setUserList] = useState([]);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [errorAlertModal, setErrorAlertModal] = useState(false);
  const [response, setResponse] = useState(false);
  const [cid, setCid] = useState(
    localStorage.getItem('companyId')
    
  );
  console.log(cid)

  const [isActive, setIsActive] = useState(true);
  useEffect(() => {
    setIsActive(isActive);
  }, [isActive]);
  const toggleactive = () => {
    setIsActive(prevState => !prevState);
  };
  const handleCheckboxChange = () => {
    toggleactive();
  };


  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },

      {
        Header: "Name",
        accessor: "name",
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Email",
        accessor: "email",
        filterable: true,
        Cell: cellProps => {
          return <Email {...cellProps} />;
        },
      },
     
      {
        Header: "Mobile No",
        accessor: "mobileNo",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              {" "}
              <MobileNo {...cellProps} />{" "}
            </>
          );
        },
      },
      {
        Header: "Active Status",
        accessor: "isActive",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              {" "}
              <Status {...cellProps} />{" "}
            </>
          );
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  onClickDelete(userData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    finalList()
  }, [])

  const finalList = () => {
    const companyId = cid.replace(/"/g, '');
    usersRead(companyId)
    .then((res) => {
      setUserList(res.data.data);
    });
  };

  const clearFormValues = () => {
    validation.resetForm();
  };


  const toggle = () => {
    if (modal) {
      setModal(false);
      setUsers(null);
      clearFormValues();
    } else {
      setModal(true);
    }
  };

  const handleUserClick = arg => {
    const user = arg;

    setUsers({
      id: user. _id,
      name: user.name,
      email: user.email,
      password: user.password, 
      confirmPassword:  user.password, 
      mobileno: user.mobileNo,
      isActive: true,
      cid:cid

    });
    setIsEdit(true);

    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };


  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = users => {
    setUsers(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
   
    deleteUser(Users).then( usersRead() , finalList())
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
   // setUserList([]);
    setIsEdit(false);
    toggle();
  };

  const keyField = "id";

  const validation = useFormik({
   
    initialValues: {
      name: Users ? Users.name : "",
      email: Users ? Users.email : "",
      password: "",
      confirmPassword: "",
      mobileno: Users ? Users.mobileno : "",
      isActive: Users ? Users.isActive : true,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Your Name"),
      email: Yup.string().matches(
            /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
             "Please Enter Valid Email"
           ).required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
      confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Please confirm your new password'),
      mobileno: Yup.string()
           .matches(/^\d{10}$/, 'Mobile number must be 10 digits')
           .required('Please Enter Your Mobile No'),
     isActive: Yup.boolean().oneOf([true], 'Please confirm if the user is active'),
    }),
    onSubmit: (values) => {
   
      if (isEdit) {
       
        const updatedUserData = { ...Users, ...values };

        updateUser(updatedUserData)
          .then((res) => {
            setResponse("User Updated Sucessfully")
            setAlertModal(true)
          })
          .catch((err) => {
            // console.log(updatedUserData)
            setResponse("Failed to Update User")
            setErrorAlertModal(true)
          });
          
          toggle();
      } else {
      console.log("values", values);
      dispatch(POST_REGISTER(values));
    }}
  });
  const POST_REGISTER = (values) => {
    const data = JSON.stringify({
      name:values.name,
      email: values.email,
      password: values.password,
      mobileNo :values.mobileno,
      role: "user",
      companyId: cid,
      isActive: isActive,
      labName: values.name,
    });
    userRegister(data).then((res) => {
      if (res.data.success) {
        setResponse("User Registered Sucessfully")
        setAlertModal(true)
      } 
    }).catch(err => {
      
      //setResponse(err.response.data.error)
      setResponse("User Already Exist")
      setErrorAlertModal(true)
    });
    usersRead()
    
    toggle();
  };

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <AlertModal 
       show={alertModal}
       onOkClick={() =>{ finalList() ,setAlertModal(false) }}
       onCloseClick={() => setAlertModal(false)}
       res={response}
      />
      <ErrorAlertModal
       show={errorAlertModal}
       onOkClick={() =>{ finalList() ,setErrorAlertModal(false) }}
       onCloseClick={() => setErrorAlertModal(false)}
       res={response}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Users" breadcrumbItem="User List" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={userList}
                    isGlobalFilter={true}
                    isAddUserList={true}
                    handleUserClick={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Edit User" : "Add User"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                         onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>
                            <div className="mb-3">
                              <Label className="form-label">Name</Label>
                              <Input
                                name="name"
                                type="text"
                                placeholder="Insert Name"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ""}
                                invalid={
                                  validation.touched.name &&
                                    validation.errors.name
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.name &&
                                validation.errors.name ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.name}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">Email</Label>
                              <Input
                                name="email"
                                label="Email"
                                type="email"
                                placeholder="Insert Email"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email || ""}
                                invalid={
                                  validation.touched.email &&
                                    validation.errors.email
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.email &&
                                validation.errors.email ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.email}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Label className="form-label"> Password</Label>
                              <Input
                                name="password"
                                className="form-control"
                                placeholder="Enter new password"
                                type="password"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.password || ""}
                                invalid={validation.touched.password && validation.errors.password}
                              />
                              {validation.touched.password && validation.errors.password ? (
                                <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">Confirm Password</Label>
                              <Input
                                name="confirmPassword"
                                className="form-control"
                                placeholder="Confirm new password"
                                type="text" // Changed type to "text" to make the password visible
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.confirmPassword || ""}
                                invalid={validation.touched.confirmPassword && validation.errors.confirmPassword}
                              />
                              {validation.touched.confirmPassword && validation.errors.confirmPassword ? (
                                <FormFeedback type="invalid">{validation.errors.confirmPassword}</FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">Mobile No</Label>
                              <Input
                                name="mobileno" // Ensure that the name attribute matches the key in initialValues and validationSchema
                                label="Mobile no"
                                type="tel" // Use type="tel" for mobile numbers
                                placeholder="Insert mobile no"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.mobileno || ""}
                                invalid={
                                  validation.touched.mobileno && validation.errors.mobileno
                                }
                                minLength={10}
                                maxLength={10}
                              />
                              {validation.touched.mobileno && validation.errors.mobileno ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.mobileno}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="form-check form-switch form-switch-md mb-3">
                              <input
                                type="checkbox"
                                name="isActive"
                                className="form-check-input"
                                id="isActive"
                                checked={isActive}
                                onChange={handleCheckboxChange}
                                onClick={toggleactive}
                              />
                              <label className="form-check-label" htmlFor="customSwitchsizemd">
                                Active
                              </label>
                              </div>


                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Save
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UsersList);
