import React, { useEffect, useState, useRef } from "react";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Label,
    Table
  } from "reactstrap";
import withRouter from "../../../components/Common/withRouter";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { DateRangePicker } from 'react-date-range';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import { startOfMonth, endOfMonth, format } from 'date-fns';
import { getRoomSalesDetails } from "../../../middleware/backent_helper";

const AllRoomsSales = props => {

    const [hid, setHid] = useState(() => {
        const storedHotelId = localStorage.getItem('hotelId')
        return storedHotelId ? storedHotelId.replace(/"/g, '') : '';
    });
    const [dbName, setDbName] = useState(
        localStorage.getItem('dbName')
    );
    const [initialLoad, setInitialLoad] = useState(true);
    const columns = ['Details', 'Income', 'Expense'];
    const sections = [
        {
            title: 'Room Based',
            rows: [
                { id: 1, label: 'Room Check-in Count', },
                { id: 2, label: 'Room Check-out Count' },
                { id: 3, label: 'No. Of Days Count' },
                { id: 4, label: 'Room Received Cash' },
                { id: 5, label: 'Room Received Card' },
                { id: 6, label: 'Room Received Online' },
                { id: 7, label: 'Room Based Total Income' },
            ]
        },
        {
            title: 'Inventory Based',
            rows: [
                { id: 8, label: 'Inventory Based Income' }
            ]
        },
        {
            title: 'Amount To Owner',
            rows: [
                { id: 9, label: 'Amount To Owner' }
            ]
        },
        {
            title: 'Petty Cash',
            rows: [
                { id: 10, label: 'Petty Cash Income' },
                { id: 11, label: 'Petty Cash Expense' }
            ]
        },
        {
            title: 'Over All Details',
            rows: [
                { id: 12, label: 'Total Income Of this Month' },
                { id: 13, label: 'Total Expense Of this Month' }
            ]
        }
    ];

    const [datePickerOpen, setDatePickerOpen] = useState(false);
    const [selectedDates, setSelectedDates] = useState([]);
    const [formattedDateRange, setFormattedDateRange] = useState('');
    const datePickerRef = useRef(null);
    const [roomSalesList, setRoomSalesList] = useState([]);

    useEffect(() => {
        // Calculate the start and end dates of the current month
        const startDate = startOfMonth(new Date());
        const endDate = endOfMonth(new Date());
        // Set default date range for current month
        const defaultRange = {
          startDate: startDate,
          endDate: endDate,
          key: 'selection'
        };

        setSelectedDates([defaultRange]);
        // Format date range for display
        setFormattedDateRange(`${format(startDate, 'MMM dd, yyyy')} - ${format(endDate, 'MMM dd, yyyy')}`);
        fetchYourData(startDate, endDate);
    }, []);

    const handleDateRangeChange = (ranges) => {
        const { selection } = ranges;
        setSelectedDates([selection]);
        // Format the new date range for display
        setFormattedDateRange(`${format(selection.startDate, 'MMM dd, yyyy')} - ${format(selection.endDate, 'MMM dd, yyyy')}`);
        setInitialLoad(false);
    };

    useEffect(() => {
        // Only fetch data if not initial load
        if (!initialLoad) {
          const { startDate, endDate } = selectedDates[0];
          fetchYourData(startDate, endDate);
        }
      }, [selectedDates]);

    const handleInputClick = () => {
        setDatePickerOpen(!datePickerOpen);
    };

    const handleSearch = (startDate, endDate) => {
        fetchYourData(startDate, endDate);
    };

    const fetchYourData = (startDate, endDate) => {
        const formattedFromDate = formatDate(startDate);
        const formattedToDate = formatDate(endDate);
        getRoomSalesDetails(dbName, hid, formattedFromDate, formattedToDate)
        .then((res) => {
          setRoomSalesList(res.data.data);
        });
    };

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const handleClickOutside = (event) => {
        if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
            setDatePickerOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const getDetail = (label) => {
        if (roomSalesList != null) {
            const data = roomSalesList;
            switch (label) {
                // Room Based
                case 'Room Check-in Count':
                    return data.isCheckedIn || '-';
                case 'Room Check-out Count':
                    return data.isCheckedOut || '-';
                // case 'No. Of Days Count':
                //     return data.isCheckedOut || '-';
                // case 'Room Received Cash':
                //     return data.isCheckedIn || '-';
                // case 'Room Received Card':
                //     return data.isCheckedOut || '-';
                // case 'Room Received Online':
                //     return data.isCheckedOut || '-';
                // case 'Room Based Total Income':
                //     return data.isCheckedOut || '-';
                // Inventory Based
                // case 'Inventory Based Income':
                //     return data.isCheckedIn || '-';
                // Amount To Owner
                // case 'Amount To Owner':
                //     return data.isCheckedOut || '-';
            }
        }
        return '-';
    };

    const getIncome = (label) => {
        if (roomSalesList != null) {
            const data = roomSalesList;
            switch (label) {
                // Petty Cash
                case 'Petty Cash Income':
                    return data.pettyCashIncome && data.pettyCashIncome !== '0' ? `${data.hotelCurrency} ${data.pettyCashIncome}` : '-';
                case 'Inventory Based Income':
                    return data.inventorySales && data.inventorySales !== '0' ? `${data.hotelCurrency} ${data.inventorySales}` : '-';
                default:
                    return '-';
            }
        }
    }

    const getExpense = (label) => {
        if (roomSalesList != null) {
            const data = roomSalesList;
            switch (label) {
                // Petty Cash
                case 'Petty Cash Expense':
                    return data.pettyCashExpense && data.pettyCashExpense !== '0' ? `${data.hotelCurrency} ${data.pettyCashExpense}` : '-';
                default:
                    return '-';
            }
        }
    }

    return (
        <div className="page-content">
            <Container fluid>
                <Card>
                    <CardBody>
                        <Breadcrumbs title="Over All Room Sales Report" />
                        <Row className="justify-content-center row-gap">
                            <Col sm={12} md={8} lg={4} className="d-flex flex-column align-items-start">
                                <Label className="form-label">Select Month</Label>
                                <div className="input-group">
                                    <div className="input-group-text">
                                    <span className="input-group-prepend">From - To</span>
                                    </div>
                                    <input
                                    type="text"
                                    value={formattedDateRange}
                                    readOnly
                                    onClick={handleInputClick}
                                    placeholder="Select Date Range"
                                    className="form-control"
                                    />
                                    <label className="input-group-text" htmlFor="inputGroupFile01">
                                    <FontAwesomeIcon icon={faCalendarAlt} />
                                    </label>
                                </div>
                                {datePickerOpen && (
                                    <div ref={datePickerRef}>
                                    <DateRangePicker
                                        onChange={handleDateRangeChange}
                                        showSelectionPreview={true}
                                        moveRangeOnFirstSelection={false}
                                        retainEndDateOnFirstSelection={false}
                                        ranges={selectedDates}
                                        direction="horizontal"
                                        className="date-range-picker-dropdown"
                                    />
                                    </div>
                                )}
                            </Col>
                            <Col  sm={12} md={4} lg={3} className="d-flex align-items-center justify-content-left" style={{top: '12px'}}>
                                <button
                                    className="btn btn-success"
                                    onClick={() => handleSearch(selectedDates[0].startDate, selectedDates[0].endDate)}
                                >
                                    <span className="fas fa-search" /> Search
                                </button>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <div className="table-responsive">
                                    <Table className="table-striped table-bordered mb-0">
                                        <thead className="text-center">
                                            <tr>
                                                <th colSpan={5}>Over All Room Sales Report for Jul-2024 at 31-07-2024 11:57</th>
                                            </tr>
                                            <tr>
                                                <th scope="col">S.No</th>
                                                <th scope="col">Particulars</th>
                                                {columns.map((col, index) => (
                                                    <th key={index} scope="col">{col}</th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {sections.map((section, sectionIndex) => (
                                                <React.Fragment key={sectionIndex}>
                                                    <tr>
                                                        <td colSpan={5}>
                                                            <table className="table mb-0">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col">{section.title}</th>
                                                                    </tr>
                                                                </thead>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                    {section.rows.map(row => (
                                                        <tr key={row.id}>
                                                            <th scope="row">{row.id}</th>
                                                            <td>{row.label}</td>
                                                            {columns.includes('Details') && <td>{getDetail(row.label)}</td>}
                                                            {columns.includes('Income') && <td>{getIncome(row.label)}</td>}
                                                            {columns.includes('Expense') && <td>{getExpense(row.label)}</td>}
                                                        </tr>
                                                    ))}
                                                </React.Fragment>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Container>
        </div>
    )
}
export default withRouter(AllRoomsSales);