import PropTypes from "prop-types"
import React, { useEffect, useState, useRef } from "react"
import { Container, Row, Col, Button, Card, CardBody} from "reactstrap"
import {format, startOfWeek,
        startOfMonth, endOfMonth, endOfWeek,
        addDays, isSameDay, isSameMonth,
        parse, parseISO
        } from "date-fns";
import { useNavigate } from "react-router-dom"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
//i18n
import { withTranslation } from "react-i18next"
//redux
import { useSelector } from "react-redux"
import { getAllReservation, cancelReservation } from "../../middleware/backent_helper";
import CancelReservation from "../../components/Common/CancelReservation";
import AlertModal from "../../components/Common/alertModal";
import messages from '../../utils/constants/messages';
import "./reservation.css";
import moment from 'moment';
import ViewReservationModal from "./viewReservationModal";

const ReservationView = props => {
  const navigate = useNavigate();
  const [modal, setmodal] = useState(false);
  const [subscribemodal, setSubscribemodal] = useState(false);
  const { chartsData } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData,
  }));

  const [token, setToken] = useState(localStorage.getItem("token"));
  const [hid, setHid] = useState(() => {
    const storedHotelId = localStorage.getItem('hotelId');
    return storedHotelId ? storedHotelId.replace(/"/g, '') : '';
  });
  const [dbName, setDbName] = useState(localStorage.getItem('dbName'));
  const [masterName, setMasterName] = useState("Reservation");
  const [reservationList, setReservationList] = useState([]);
  const [clickedDate, setClickedDate] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(new Date());  // New state for the current month
  const [popovertop, setpopovertop] = useState(false);
  const [targetRef, setTargetRef] = useState(null);
  const [clickedDateReservations, setClickedDateReservations] = useState(null);
  const [dropdownVisibleIndex, setDropdownVisibleIndex] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);
  const [reservationData, setReservationData] = useState([]);
  const [cancelModal, setCancelModal] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [errorAlertModal, setErrorAlertModal] = useState(false);
  const [response, setResponse] = useState(false);
  const [showViewReservation, setShowViewReservation] = useState(false);
  const [viewReservationData, setViewReservationData] = useState();

  useEffect(() => {
    setTimeout(() => {
      setSubscribemodal(true);
    }, 2000);
  }, []);

  const [periodData, setPeriodData] = useState([]);
  const [periodType, setPeriodType] = useState("yearly");
  const ref = useRef(null)

  useEffect(() => {
    setPeriodData(chartsData);
  }, [chartsData]);

  document.title = "StayPro | Check In/Check Out";

  const onBadgeClick = (day, event) => {
    setClickedDate(clickedDate === day ? null : day);
    setpopovertop(true);
    setTargetRef(event.currentTarget);
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setClickedDate(null);
      setpopovertop(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const nextMonth = () => {
    setCurrentMonth(addDays(currentMonth, 30));
  };

  const prevMonth = () => {
    setCurrentMonth(addDays(currentMonth, -30));
  };

  const onDateClick = (date) => {
    const [day, month, year] = date.split('/');
    const formattedClickedDate = `${year}-${month}-${day}`;

    const reservationDetails = reservationList.filter(reservation =>
      moment(reservation.checkInDate).format('YYYY-MM-DD') === formattedClickedDate
    );
    setSelectedDay(date);
    setClickedDateReservations(reservationDetails.length > 0 ? reservationDetails : null);
    
  };

  useEffect(() => {
    getReservationList();
  }, []);

  const getReservationList = () => {
    getAllReservation(hid, dbName)
    .then((res) => {
      setReservationList(res.data.data);
    });
  };

  const onCreateReservation = () => {
    navigate(-1);
    navigate('./add-reservation')
  };

  const onPrintClick = (date) => {
    console.log("Print button has Been Clicked");
  };

  const onConvertReservation = (data) => {
    navigate('/convert-reservation', { state: { reservationData: data }, replace: true });
    
  };

  const ViewReservation = (data) => {
    setViewReservationData(data)
    setShowViewReservation(true)
   
  };

  const handleCancelClick = data => {
    setReservationData(data);
    setCancelModal(true);
  };

  const onCancelReservation = () => {
    cancelReservation(hid, dbName, reservationData)
    .then((res) => {
        getReservationList()
        setResponse(messages.success.CancelSuccessFull(masterName))
        setAlertModal(true)
    })
    .catch((err) => {
        if(err.response.status == 400) {
          setResponse(messages.failed.CancelCannotBeDone(masterName))
        } else {
          setResponse(messages.failed.CancelFailed(masterName))
        }
        setErrorAlertModal(true)
    });
    setCancelModal(false);
    setReservationData(null);
  };

  // Rendering the calendar header
  const renderHeader = () => {
    return (
      <Row>
        <Col className="text-center" xs={1}>
          <Button className="btn btn-info" onClick={prevMonth}>
            {"<"}
          </Button>
        </Col>
        <Col xs={10} className="text-center">
          <span className="fs-4 text fw-bold">
            {format(currentMonth, 'MMMM yyyy').toUpperCase()}
          </span>
        </Col>
        <Col className="text-center" xs={1}>
          <Button className="btn btn-info" onClick={nextMonth}>
            {">"}
          </Button>
        </Col>
      </Row>
    );
  };

  const renderDays = () => {
    const days = [];
    let startDate = startOfWeek(currentMonth); // Use currentMonth

    for (let i = 0; i < 7; i++) {
      days.push(
        <Col className="text-center fs-5 border text p-2" key={i}>
          {format(addDays(startDate, i), 'eeee')}
        </Col>
      );
    }
    return <Row className="text-center mt-4">{days}</Row>;
  };

  const handleReservationClick = (index) => {
    setDropdownVisibleIndex(index === dropdownVisibleIndex ? null : index);
  };


  const renderReservationDetails = () => {
    if (!clickedDateReservations || clickedDateReservations.length === 0) {
      return <div>No reservations for the selected date.</div>;
    }

    return (
      <div>
        {clickedDateReservations.map((reservation, index) => (
          <div key={index} className="reservation-details mb-3">
            <p>
              <strong>{reservation.title.toUpperCase()}</strong> has been reserved by <strong>{reservation.GuestName}</strong>
              <Button
                className="action-button btn btn-link p-0"
                onClick={() => handleReservationClick(index)}
              >
                {dropdownVisibleIndex === index ? 'Hide Actions' : 'Show Actions'}
              </Button>
            </p>

            {/* Dropdown for action buttons */}
            {dropdownVisibleIndex === index && (
              <div className="actions d-grid">
                <Button className="btn btn-success w-md me-3" onClick={() => {
                  onPrintClick(reservation);
                }}>
                  <i className="action-icons mdi mdi-printer-settings" /> Print
                </Button>
                <Button className="btn btn-secondary w-md me-3" onClick={() => {
                  onConvertReservation(reservation);
                }}>
                  <i className="action-icons mdi mdi-pencil" /> Convert
                </Button>
                <Button className="btn btn-info w-md me-3" onClick={() => {
                  ViewReservation(reservation);
                }}>
                  <i className="action-icons mdi mdi-eye" /> View
                </Button>
                <Button className="btn btn-danger w-md me-3" onClick={() => {
                    handleCancelClick(reservation);
                  }}
                >
                  <i className="action-icons mdi mdi-cancel" /> Cancel
                </Button>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  const renderCells = () => {
    const monthStart = startOfMonth(currentMonth);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart);
    const endDate = endOfWeek(monthEnd);

    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = "";

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, 'd');
        const cloneDay = format(day, 'dd/LL/yyyy');

        // Find reservations for this particular day
        const reservationForTheDay = reservationList.filter(reservation => {
          return format(parseISO(reservation.checkInDate), 'dd/LL/yyyy') === cloneDay;
        });

        const reservedRoomsCount = reservationForTheDay.length;

        days.push(
          <Col
            className={`p-2 text-wrap cell ${
              !isSameMonth(day, monthStart)
                ? "disabled"
                : isSameDay(day, new Date()) ? "selected" : ""
            }`}
            key={day}
            onClick={() => onDateClick(cloneDay)}
            style={{ position: 'relative' }}
          >
            <div className="circular-date">{formattedDate}</div>
            {reservedRoomsCount > 0 && (
              <div
                className="badge bg-primary text-wrap m-1"
                onClick={(event) => onBadgeClick(cloneDay, event)}
                style={{ cursor: 'pointer', position: 'relative' }}
              >
                {reservedRoomsCount} Room{reservedRoomsCount > 1 ? 's' : ''} have been reserved
              </div>
            )}
          </Col>
        );
        day = addDays(day, 1);
      }
      rows.push(
        <Row key={day}>
          {days}
        </Row>
      );
      days = [];
    }
    return <div className="calendar">{rows}</div>;
  };

  return (
    <React.Fragment>
      <CancelReservation
        show={cancelModal}
        onDeleteClick={onCancelReservation}
        onCloseClick={() => {setCancelModal(false)}}
      />
      <ViewReservationModal 
       show={showViewReservation}
       viewReservationData={viewReservationData}
       onCloseClick={() => setShowViewReservation(false)}
      
      />
      <AlertModal
        show={alertModal}
        onOkClick={() => {
          setReservationData(null), setDropdownVisibleIndex(null), setClickedDateReservations(null),
          setAlertModal(false)
        }}
        onCloseClick={() => {setAlertModal(false)}}
        res={response}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs breadcrumbItem={props.t("Current Booking")} />
          <Card>
            <CardBody>
              <Row>
                <Col lg={2}>
                  <Card>
                    <CardBody>
                      <div className="d-grid">
                        <Button
                          className="btn btn-danger w-md me-3"
                          onClick={() => {
                            onCreateReservation();
                          }}>
                          <i className="mdi mdi-plus-circle-outline me-1" />
                          Create New Reservation
                        </Button>
                      </div>

                      <div id="reservation-details" className="mt-4">
                        {selectedDay ? (
                          renderReservationDetails()
                        ) : (
                          <p className="text-muted text-center">Select a Date to view the Reservations</p>
                        )}
                      </div>

                      <Row className="justify-content-center mt-5">
                        <img  alt="" className="img-fluid d-block" />
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={10}>
                {renderHeader()}
                {renderDays()}
                {renderCells(onDateClick)}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

ReservationView.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
};

export default withTranslation()(ReservationView);
