import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, Container, Input, Button } from "reactstrap";
// import { forgotVerifyOTP } from '../../api/index';
import {forgotVerifyOTP } from "../../middleware/backent_helper";
import { toast } from 'react-toastify';
import { Link, useNavigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import profile from "../../assets/images/profile-img.png";

function ForgotOTPComponent({ }) {
  const navigate = useNavigate();
  const [otp, setOTP] = useState(['', '', '', '']);
  const [isValid, setIsValid] = useState(false);
  const email = localStorage.getItem('email');

  useEffect(() => {
    // Check if all OTP fields are filled
    setIsValid(otp.every(digit => digit !== ''));
  }, [otp]);

  const handleChange = (index, value) => {
    const newOTP = [...otp];
    newOTP[index] = value;
    setOTP(newOTP);
  };

  const handleVerifyOTP = () => {
    const enteredOTP = otp.join('');
    const data = JSON.stringify({
      otp: enteredOTP,
      email: email
    })
    forgotVerifyOTP(data)
      .then(response => {
        toast.success(response.data.data, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
        navigate(`/Recover-password`);
      })
      .catch(error => {
        toast.error('Failed to verify OTP. Please try again.', { autoClose: 3000 });
      });
};

  const moveNext = (index, value) => {
    if (value === '' && index > 0) {
      document.getElementById(`otp_input_${index - 1}`).focus();
    } else if (index < 3 && value !== '') {
      document.getElementById(`otp_input_${index + 1}`).focus();
    }
  };

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block"></div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-softbg-soft-primary">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to Hotel.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="p-2">
                    <h2>Enter OTP</h2>
                    <div>
                      {otp.map((digit, index) => (
                        <Input
                          key={index}
                          id={`otp_input_${index}`}
                          type="text"
                          maxLength={1}
                          value={digit}
                          onChange={(e) => {
                            handleChange(index, e.target.value);
                            moveNext(index, e.target.value);
                          }}
                          style={{ width: '40px', marginRight: '5px', display: 'inline-block', textAlign: 'center' }}
                        />
                      ))}
                    </div>
                    <div style={{ marginTop: '25px' }}>
                      <Button
                        color="primary"
                        block
                        onClick={handleVerifyOTP}
                        disabled={!isValid}
                      >
                        Verify OTP
                      </Button>
                    </div>
                  </div>
                </CardBody>
                <div className="mt-5 text-center">
                <p>
                  Resend the OTP ?{" "}
                  <Link
                    to="/forgot-password"
                    className="fw-medium text-primary"
                  >
                    {" "}
                    Click now{" "}
                  </Link>{" "}
                </p>
                {/* <p>
                  © {new Date().getFullYear()} Skote. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by Themesbrand
                </p> */}
              </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default ForgotOTPComponent;
