import React, { useEffect, useState, useRef, useMemo } from "react"
import { Link } from "react-router-dom"
import withRouter from "../../../components/Common/withRouter"
import TableContainer from "../../../components/Common/TableContainer"
import {
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap"
import * as Yup from "yup"
import Select from "react-select"
import { useFormik } from "formik"
import {
  createPurchase,
  getPurchase,
  updatePurchase,
  deletePurchase,
  updateExistingPurchase,
} from "../../../middleware/backent_helper"
import { InventryName, AvailableQuantity, ReOrderQuantity, Price, Cost} from "./PurchaseMasterCol"
import messages from '../../../utils/constants/messages';
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import DeleteModal from "../../../components/Common/DeleteModal"
import AlertModal from "../../../components/Common/alertModal"
import ErrorAlertModal from "../../../components/Common/errorAlertModal"
//redux
import { useSelector, useDispatch } from "react-redux"

const PurchaseMaster = props => {
  //meta title
  document.title = "Purchase | Settings "

  const dispatch = useDispatch()
  const [Inventorys, setInventorys] = useState()
  const [selectedTest, setSelectedTest] = useState(null)
  const [InventoryList, setInventoryList] = useState([])

  const [modal, setModal] = useState(false)
  const [existingModal, setExistingModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [alertModal, setAlertModal] = useState(false)
  const [errorAlertModal, setErrorAlertModal] = useState(false)
  const [masterName, setMasterName] = useState("Purchase");
  const [response, setResponse] = useState(false)
  const [hid, setHid] = useState(() => {
    const storedHotelId = localStorage.getItem('hotelId')
    return storedHotelId ? storedHotelId.replace(/"/g, '') : '';
  });
  const [dbName, setDbName] = useState(
    localStorage.getItem('dbName')
  )

  const handleRoomChange = selectedOption => {
    setSelectedTest(selectedOption)
    Exisitingvalidation.setFieldValue(
      "name",
      selectedOption ? selectedOption.value : ""
    )
  }

  const columns = useMemo(
    () => [
      {
        Header: "S.No",
        Cell: ({ row, rows }) => {
          return (
            <div  className="table-cell text-center">
              {rows.length - row.index}
            </div>
          )
        },
      },

      {
        Header: "Item Name",
        accessor: "name",
        filterable: true,
        Cell: cellProps => {
          return (
            <div  className="table-cell text-center">
              <InventryName {...cellProps} />
            </div>
          )
        },
      },

      {
        Header: "Available Quantity",
        accessor: "availableQuantity",
        filterable: true,
        Cell: cellProps => {
          return (
            <div  className="table-cell text-center">
              <AvailableQuantity {...cellProps} />
            </div>
          )
        },
      },

      {
        Header: "Re Order Quantity",
        accessor: "reOrderQuantity",
        filterable: true,
        Cell: cellProps => {
          return (
            <div  className="table-cell text-center">
              <ReOrderQuantity {...cellProps} />
            </div>
          )
        },
      },

      {
        Header: "Buy Price",
        accessor: "price",
        filterable: true,
        Cell: cellProps => {
          return (
            <div  className="table-cell text-center">
              <Price {...cellProps} />
            </div>
          )
        },
      },

      {
        Header: "Cost",
        accessor: "cost",
        filterable: true,
        Cell: cellProps => {
          return (
            <div  className="table-cell text-center">
              <Cost {...cellProps} />
            </div>
          )
        },
      },

      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div  className="table-body actions">
              <div className="actions">
                <Link
                  to="#"
                  className="text-success"
                  onClick={() => {
                    const RoomsData = cellProps.row.original
                    handleRoomClick(RoomsData)
                  }}
                >
                  <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </Link>
                <Link
                  to="#"
                  className="text-danger"
                  onClick={() => {
                    const userData = cellProps.row.original
                    onClickDelete(userData)
                  }}
                >
                  <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </div>
            </div>
          )
        },
      },
    ],
    []
  )

  useEffect(() => {
    finalList()
  }, [])

  const finalList = () => {
    getPurchase(hid,dbName).then(res => {
      setInventoryList(res.data.data)
    })
  }

  const clearFormValues = () => {
    validation.resetForm()
  }

  const clearFormValuesExisting = () => {
    Exisitingvalidation.resetForm()
  }

  const toggle = () => {
    if (modal) {
      setModal(false)
      setInventorys(null)
      clearFormValues()
    } else {
      setModal(true)
    }
  }

  const existingToggle = () => {
    if (existingModal) {
      setExistingModal(false)
      setSelectedTest(null)
      clearFormValuesExisting()
    } else {
      setExistingModal(true)
    }
  }

  const handleRoomClick = arg => {
    const Purchase = arg

    setInventorys({
      id: Purchase.id,
      name: Purchase.name,
      availableQuantity: Purchase.availableQuantity,
      reOrderQuantity: Purchase.reOrderQuantity,
      price: Purchase.price,
      cost: Purchase.cost,
      hotelId:hid,
      dbName:dbName
    })
    setIsEdit(true)
    toggle()
  }

  //last two digit fix function
  const formatToTwoDecimalPlaces = value => {
    const number = parseFloat(value)
    return isNaN(number) ? "" : number.toFixed(2)
  }

  const handleBlur = event => {
    validation.handleBlur(event)
    const formattedValue = formatToTwoDecimalPlaces(event.target.value)
    validation.setFieldValue(event.target.name, formattedValue)
    Exisitingvalidation.setFieldValue(event.target.name, formattedValue)
  }

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = users => {
    setInventorys(users)
    setDeleteModal(true)
  }

  const handleDeleteUser = () => {
    deletePurchase(Inventorys , dbName)
    .then((res) => {
      getPurchase(hid , dbName), finalList()
      setResponse(messages.success.deleteDetails(masterName))
      setAlertModal(true)
    })
    .catch((err) => {
      if(err.response.status == 400) {
        setResponse(messages.failed.cannotbeDeleted(masterName))
      } else {
        setResponse(messages.failed.updateFailed(masterName))
      }
      setErrorAlertModal(true)
    });
    onPaginationPageChange(1)
    setDeleteModal(false);
    setInventorys(null);
  }

  const handlePurchaseClick = () => {
    setIsEdit(false)
    toggle()
  }

  const handleexistClick = () => {
    existingToggle()
  }

  const validation = useFormik({
    initialValues: {
      name: Inventorys ? Inventorys.name : "",
      availableQuantity: Inventorys ? Inventorys.availableQuantity : "",
      reOrderQuantity: Inventorys ? Inventorys.reOrderQuantity : "",
      price: Inventorys ? Inventorys.price : "",
      cost: Inventorys ? Inventorys.cost : "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Item Name"),
      availableQuantity: Yup.string().required(
        "Please Enter Available Quantity"
      ),
      reOrderQuantity: Yup.string().required("Please Enter Re Order Quantity"),
      price: Yup.string().required("Please Enter Buy Price"),
      cost: Yup.string().required("Please Enter Cost"),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updatedInventoryData = { ...Inventorys, ...values }
        updatePurchase(updatedInventoryData)
          .then(res => {
            setResponse(messages.success.updatedDetails(masterName))
            setAlertModal(true)
          })
          .catch((err) => {
            if (err.response.status === 401) {
              setResponse(messages.failed.alreadyInUse(masterName))
            } else {
              setResponse(messages.failed.updateFailed(masterName))
            }
            setErrorAlertModal(true)
          });
        toggle()
      } else {
        dispatch(create_Purchase(values))
      }
    },
  });

  const create_Purchase = values => {
    const data = JSON.stringify({
      name: values.name,
      availableQuantity: values.availableQuantity,
      reOrderQuantity: values.reOrderQuantity,
      price: values.price,
      cost: values.cost,
      hotelId: hid,
      dbName:dbName
    })
    createPurchase(data)
      .then(res => {
        if (res.data.success) {
          setResponse(messages.success.createdMessage(masterName))
          setAlertModal(true)
        }
      }).catch(err => {
        if(err.response.status === 404) {
          setResponse(messages.failed.AlreadyExists(masterName))
        } else {
          setResponse(messages.failed.createFailed(masterName))
        }
        setErrorAlertModal(true)
      });
    toggle()
  }

  const Exisitingvalidation = useFormik({
    initialValues: {
      name: "",
      availableQuantity: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Item Name"),
      availableQuantity: Yup.string().required("Please Enter Available Quantity"),
      // isActive: Yup.boolean().oneOf([true], 'Please confirm if the user is active'),
    }),
    onSubmit: values => {
      Existing_Purchase(values)
    },
  });

  const Existing_Purchase = values => {
    const data = JSON.stringify({
      name: values.name,
      availableQuantity: values.availableQuantity,
      hotelId:hid,
      dbName:dbName
    })
    updateExistingPurchase(data)
      .then(res => {
        if (res.data.success) {
          setResponse(messages.success.updatedDetails(masterName))
          setAlertModal(true)
        }
      })
      .catch(err => {
        setResponse(messages.failed.updateFailed(masterName))
        setErrorAlertModal(true)
      })
    existingToggle()
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => {setInventorys(null), setDeleteModal(false)}}
      />
      <AlertModal
        show={alertModal}
        onOkClick={() => {
          finalList()
          setAlertModal(false)
        }}
        onCloseClick={() => setAlertModal(false)}
        res={response}
      />
      <ErrorAlertModal
        show={errorAlertModal}
        onOkClick={() => {
          finalList(), setErrorAlertModal(false)
        }}
        onCloseClick={() => setErrorAlertModal(false)}
        res={response}
      />

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Settings" breadcrumbItem="Purchase" />
          <Row>
            <Col lg="12">
              <div className="text-sm m-2 ml-3">
                <button className="btn-label btn btn-info" onClick={handleexistClick}>
                <i className="bx bx-plus-circle label-icon"></i>
                Update Existing Item
              </button>
              </div>

                  <TableContainer
                    columns={columns}
                    data={InventoryList}
                    isGlobalFilter={true}
                    isAddPurchaseList={true}
                    handlePurchaseClick={handlePurchaseClick}
                    customPageSize={10}
                    className="custom-header-css"
                  />

                  <Modal className="modal-lg" isOpen={modal} toggle={toggle} backdrop="static" keyboard={false}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Edit Item" : "Add Item"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault()
                          validation.handleSubmit()
                          return false
                        }}
                      >
                        <Row>
                          <Col xs={12}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Item Name
                              </Label>
                              <Input
                                name="name"
                                type="text"
                                placeholder="Item Name"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ""}
                                invalid={
                                  validation.touched.name &&
                                  validation.errors.name
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.name &&
                              validation.errors.name ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.name}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Available Quantity
                              </Label>
                              <Input
                                name="availableQuantity"
                                type="number"
                                placeholder="Available Quantity"
                                onChange={validation.handleChange}
                                onBlur={handleBlur}
                                value={
                                  validation.values.availableQuantity || ""
                                }
                                invalid={
                                  validation.touched.availableQuantity &&
                                  validation.errors.availableQuantity
                                    ? true
                                    : false
                                }
                                disabled={isEdit}
                                min="0"
                              />
                              {validation.touched.availableQuantity &&
                              validation.errors.availableQuantity ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.availableQuantity}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col xs={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Re Order Quantity
                              </Label>
                              <Input
                                name="reOrderQuantity"
                                type="number"
                                placeholder="Re Order Quantity"
                                onChange={validation.handleChange}
                                onBlur={handleBlur}
                                value={validation.values.reOrderQuantity || ""}
                                invalid={
                                  validation.touched.reOrderQuantity &&
                                  validation.errors.reOrderQuantity
                                    ? true
                                    : false
                                }
                                min="0"
                              />
                              {validation.touched.reOrderQuantity &&
                              validation.errors.reOrderQuantity ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.reOrderQuantity}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={6}>
                            <div className="mb-3">
                              <Label className="form-label">Buy Price</Label>
                              <Input
                                name="price"
                                type="number"
                                placeholder="Buy Price"
                                onChange={validation.handleChange}
                                onBlur={handleBlur}
                                value={validation.values.price || ""}
                                invalid={
                                  validation.touched.price &&
                                  validation.errors.price
                                    ? true
                                    : false
                                }
                                min="0"
                              />
                              {validation.touched.price &&
                              validation.errors.price ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.price}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col>
                            <div className="mb-3">
                              <Label className="form-label">Cost</Label>
                              <Input
                                name="cost"
                                type="number"
                                placeholder="Cost"
                                onChange={validation.handleChange}
                                onBlur={handleBlur}
                                value={validation.values.cost || ""}
                                invalid={
                                  validation.touched.cost &&
                                  validation.errors.cost
                                    ? true
                                    : false
                                }
                                min="0"
                              />
                              {validation.touched.cost &&
                              validation.errors.cost ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.cost}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <div className="text-end">

                            <button type="submit" className="btn btn-primary w-md me-3">
                                {isEdit ? 'Update' : 'Save'}
                            </button>
                              <button
                                type="button"
                                className="btn btn-secondary w-md "
                                onClick={toggle}
                              >
                                Cancel
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                  <Modal
                    className="modal-lg"
                    isOpen={existingModal}
                    existingToggle={existingToggle}
                    backdrop="static" keyboard={false}
                  >
                    <ModalHeader existingToggle={existingToggle} tag="h4">
                      Add Existing Item
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          Exisitingvalidation.handleSubmit();
                          return false
                        }}
                      >
                        <Row>
                          <Col xs={6}>
                            <div
                              className="mb-3"
                              style={{ position: "relative" }}
                            >
                              <label htmlFor="testName" className="form-label">
                                Item Name
                              </label>
                              <Select
                                id="name"
                                name="name"
                                placeholder="Item Name"
                                className={`react-select-container ${Exisitingvalidation.touched.name && Exisitingvalidation.errors.name
                                  ? 'is-invalid'
                                  : ''
                                }`}
                                options={InventoryList.map(menu => ({
                                  value: menu.name,
                                  label: menu.name,
                                }))}
                                value={selectedTest}
                                onChange={handleRoomChange}
                                isClearable
                                isSearchable
                                classNamePrefix="custom-select"
                                styles={{
                                  menu: provided => ({
                                    ...provided,
                                    top: "100%",
                                    position: "absolute",
                                    width: "100%",
                                    zIndex: 999,
                                  }),
                                }}
                              />
                              {Exisitingvalidation.touched.name &&
                                Exisitingvalidation.errors.name && (
                                  <div className="text-danger">
                                    {Exisitingvalidation.errors.name}
                                  </div>
                                )}
                            </div>
                          </Col>

                          <Col xs={6}>
                            <div className="mb-3">
                              <Label className="form-label">Quantity</Label>
                              <Input
                                name="availableQuantity"
                                type="number"
                                placeholder="Quantity"
                                onChange={Exisitingvalidation.handleChange}
                                onBlur={handleBlur}
                                value={
                                  Exisitingvalidation.values
                                    .availableQuantity || ""
                                }
                                invalid={
                                  Exisitingvalidation.touched.availableQuantity &&
                                  Exisitingvalidation.errors.availableQuantity
                                    ? true
                                    : false
                                }
                                min="0"
                              />
                              {Exisitingvalidation.touched.availableQuantity &&
                              Exisitingvalidation.errors.availableQuantity ? (
                                <FormFeedback type="invalid">
                                  {Exisitingvalidation.errors.availableQuantity}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">

                              <button
                                type="submit"
                                className="btn btn-primary save-user w-md me-3"
                              >
                                Update
                              </button>
                              <button
                                type="button"
                                className="btn btn-secondary w-md "
                                onClick={existingToggle}
                              >
                                Cancel
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>

            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(PurchaseMaster)
