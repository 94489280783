const MenuName = (cell) => {
    return cell.value ? cell.value : '';
};

const Url = (cell) => {
    return cell.value ? cell.value : '';
}

const TopLevel = (cell) => {
    return cell.value ? cell.value : '';
}


const Status = (cell) => {
    return cell.value ? "Active" : 'Inactive';

};

export {
    MenuName,
    Url,
    Status,
    TopLevel
};