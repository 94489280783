import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { planRegister, getAllPlan, updatePlan, moduleGet, deletePlans } from "../../../middleware/backent_helper";
import { PlanName, Description, Modules, MonthlyCost, YearlyCost, Status } from "./PlanListCol.js";
import messages from '../../../utils/constants/messages';
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import AlertModal from "components/Common/alertModal";
import ErrorAlertModal from "components/Common/errorAlertModal";
import Select from 'react-select';
//redux
import { useDispatch } from "react-redux";

const Plan = props => {
  //meta title
  document.title = "Hotel | Masters";

  const dispatch = useDispatch();
  const [Plans, setPlans] = useState();
  const [planList, setPlanList] = useState([]);
  const [ModuleList, setModuleList] = useState([]);
  const [selectedModule, setSelectedModule] = useState(null);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [errorAlertModal, setErrorAlertModal] = useState(false);
  const [masterName, setMasterName] = useState("Plan");
  const [response, setResponse] = useState(false);
  const [module, setModule] = useState([]);
  const [toggleStates, setToggleStates] = useState({}); // State to store toggle states
  const [isActive, setIsActive] = useState(true);
  const [validationError, setValidationError] = useState('');

  // Function to handle toggle state change
  const handleToggleChange = (id) => {
    setToggleStates(prevState => {
      const newState = {
        ...prevState,
        [id]: !prevState[id]
      };

      // Clear validation error when a module is selected
      const selectedModules = Object.keys(newState).filter(key => newState[key]);
      if (selectedModules.length > 0) {
        setValidationError('');
      }

      return newState;
    });
  }



  useEffect(() => {
    if (isEdit && Plans && typeof Plans.isActive !== 'undefined') {
      setIsActive(Plans.isActive);
    }
  }, [isEdit, Plans]);

  const toggleactive = () => {
    const updatedIsActive = !isActive;
    setIsActive(updatedIsActive);
    validation.setFieldValue('isActive', updatedIsActive);
  };

  const handleCheckboxChange = () => {
    toggleactive();
  };

  const columns = useMemo(
    () => [
      {
        Header: "S.No",
        Cell: ({ row, rows }) => {
          return (
            <div className="table-cell text-center">
              {rows.length - row.index}
            </div>
          );
        },
      },

      {
        Header: "Plan Name",
        accessor: "planName",
        filterable: true,
        Cell: cellProps => {
          return (
            <div className="table-cell text-center">
              <PlanName {...cellProps} />
            </div>
          );

        },
      },

      {
        Header: "Description",
        accessor: "description",
        filterable: true,
        Cell: cellProps => {
          return (
            <div className="table-cell text-center">
              <Description {...cellProps} />
            </div>
          );

        },
      },
      {
        Header: "Modules",
        accessor: "moduleName",
        filterable: true,
        Cell: cellProps => {
          return (
            <div className="table-cell text-center">
              {cellProps.value.map(module => module).join(", ")}
            </div>
          );

        },
      },

      {
        Header: "Yearly Cost",
        accessor: "yearlyCost",
        filterable: true,
        Cell: cellProps => {
          return (
            <div className="table-cell text-center">
              {cellProps.value}
            </div>
          );
        },
      },
      {
        Header: "Monthly Cost",
        accessor: "monthlyCost",

        filterable: true,
        Cell: cellProps => {

          return (
            <div className="table-cell text-center">
              {cellProps.value}
            </div>
          );
        },
      },

      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="table-body actions">
              <div className="actions">
                <Link
                  to="#"
                  className="text-success"
                  onClick={() => {
                    const planData = cellProps.row.original;
                    handlePlanClick(planData);
                  }}
                >
                  <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </Link>
                <Link
                  to="#"
                  className="text-danger"
                  onClick={() => {
                    const userData = cellProps.row.original;
                    onClickDelete(userData);
                  }}
                >
                  <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </div>
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    moduleGet()
      .then((res) => {
        setModuleList(res.data.data);
      });
  }, [])

  useEffect(() => {
    finalList()
  }, [])

  const finalList = () => {
    getAllPlan()
      .then((res) => {
        setPlanList(res.data.data);
      });

  };

  const clearFormValues = () => {
    validation.resetForm();
  };

  const toggle = () => {
    if (modal) {
      setModal(false);
      setPlans(null);
      setValidationError('');
      resetModuleNameField();
      clearFormValues();
    } else {
      setModal(true);
    }
  };

  const handlePlanClick = arg => {
    const plan = arg;
    setPlans({
      id: plan.id,
      planName: plan.planName,
      description: plan.description,
      moduleId: plan.moduleId,
      yearlyCost: plan.yearlyCost,
      monthlyCost: plan.monthlyCost,
      isActive: plan.isActive,
    });

    const initialToggleStates = {};
    plan.modules.forEach((module) => {
      initialToggleStates[module.moduleId] = true; // Set initial state based on plan's moduleId
    });
    setToggleStates(initialToggleStates);
    setIsEdit(true);
    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = users => {
    setPlans(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    deletePlans(Plans)
    .then((res) => {
      getAllPlan(), finalList()
      setResponse(messages.success.deleteDetails(masterName))
      setAlertModal(true)
    })
    .catch((err) => {
      if(err.response.status == 400) {
        setResponse(messages.failed.cannotbeDeleted(masterName))
      } else {
        setResponse(messages.failed.updateFailed(masterName))
      }
      setErrorAlertModal(true)
    });
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handlePlanClicks = () => {
    setIsEdit(false);
    setIsActive(true);
    toggle();
  };

  const validation = useFormik({

    initialValues: {
      planName: Plans ? Plans.planName : "",
      description: Plans ? Plans.description : "",
      moduleId: Plans ? Plans.moduleId : "",
      yearlyCost: Plans ? Plans.yearlyCost : "",
      monthlyCost: Plans ? Plans.monthlyCost : "",
      isActive: Plans ? Plans.isActive : "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      planName: Yup.string().required("Please Enter Your Plan Name"),
      description: Yup.string().required("Please Enter Your Description"),
      yearlyCost: Yup.string().required("Please Enter Your Yearly Amount"),
      monthlyCost: Yup.string().required("Please Enter Your Monthly Amount"),
      isActive: Yup.boolean().oneOf([true, false], 'Please confirm if the user is active'),
    }),
    onSubmit: (values) => {
      const selectedModules = Object.keys(toggleStates).filter(key => toggleStates[key]);

    if (selectedModules.length === 0) {
      setValidationError("Please select at least one module.");
      return;
    }

    setValidationError('');
    values.moduleId = selectedModules;
      if (isEdit) {
        if (toggleStates != true) {
          const ids = Object.keys(toggleStates).filter(key => toggleStates[key] === true);
          values.moduleId = ids;
        }
        const updatedPlanData = { ...Plans, ...values };
        updatePlan(updatedPlanData)
          .then((res) => {
            setResponse(messages.success.updatedDetails(masterName))
            setAlertModal(true)
          })
          .catch((err) => {
            if (err.response.status === 401) {
              setResponse(messages.failed.alreadyInUse(masterName))
            } else {
              setResponse(messages.failed.updateFailed(masterName))
            }
            setErrorAlertModal(true)
          });
        toggle();
      } else {
        dispatch(POST_PLAN_REGISTER(values));
      }
    }
  });
  const POST_PLAN_REGISTER = (values) => {
    let ids = Object.keys(toggleStates).filter(key => toggleStates[key]);

    const data = JSON.stringify({
      planName: values.planName,
      description: values.description,
      yearlyCost: values.yearlyCost,
      monthlyCost: values.monthlyCost,
      isActive: isActive,
      moduleId: ids,

    });
    planRegister(data).then((res) => {
      if (res.data.success) {
        setResponse(messages.success.createdMessage(masterName))
        setAlertModal(true)
      }
    }).catch(err => {
      if(err.response.status === 404) {
        setResponse(messages.failed.AlreadyExists(masterName))
      } else {
        setResponse(messages.failed.createFailed(masterName))
      }
      setErrorAlertModal(true)
    });
    getAllPlan()
    setToggleStates({});
    toggle();
  };

  const handleModuleChange = selectedOption => {
    setSelectedModule(selectedOption);
    validation.setFieldValue('module', selectedOption ? selectedOption.value : '');
  };

  const resetModuleNameField = () => {
    setSelectedModule(null);
    validation.setFieldValue('testname', '');
  };
  //last two digit fix function
  const formatToTwoDecimalPlaces = (value) => {
    const number = parseFloat(value);
    return isNaN(number) ? "" : number.toFixed(2);
  };

  const handleBlur = (event) => {
    validation.handleBlur(event);
    const formattedValue = formatToTwoDecimalPlaces(event.target.value);
    validation.setFieldValue(event.target.name, formattedValue);
  };

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => {setPlans(null), setDeleteModal(false) }}
      />
      <AlertModal
        show={alertModal}
        onOkClick={() => { finalList(), setAlertModal(false) }}
        onCloseClick={() => setAlertModal(false)}
        res={response}
      />
      <ErrorAlertModal
        show={errorAlertModal}
        onOkClick={() => { finalList(), setErrorAlertModal(false) }}
        onCloseClick={() => setErrorAlertModal(false)}
        res={response}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Masters" breadcrumbItem="Plan Details " />
          <Row>
            <Col lg="12">
                  <TableContainer
                    columns={columns}
                    data={planList}
                    isGlobalFilter={true}
                    isAddPlanList={true}
                    handlePlanClicks={handlePlanClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />

                  <Modal className="modal-lg" isOpen={modal} toggle={toggle} backdrop="static" keyboard={false}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Edit Plan" : "Add Plan"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>
                            <div className="mb-3">
                              <Label className="form-label">Plan Name</Label>
                              <Input
                                name="planName"
                                type="text"
                                placeholder="Insert Name"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.planName || ""}
                                invalid={
                                  validation.touched.planName &&
                                    validation.errors.planName
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.planName &&
                                validation.errors.planName ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.planName}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">Description</Label>
                              <Input
                                name="description"
                                type="textarea"
                                placeholder="Insert Name"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.description || ""}
                                invalid={
                                  validation.touched.description &&
                                    validation.errors.description
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.description &&
                                validation.errors.description ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.description}
                                </FormFeedback>
                              ) : null}
                            </div>
                            {!isEdit ? (

                            <div className="mb-3" >
                              <Label className="form-label">Modules</Label>
                              <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>

                                {/* Map over the itemList and render a toggle button for each item */}
                                {ModuleList.map(item => (
                                  <div key={item.id} className="form-check form-switch form-switch-lg mb-3" style={{ marginRight: '20px' }}>
                                    <input
                                      type="checkbox"
                                      name={`toggle-${item.id}`}
                                      className="form-check-input form-control"
                                      id={`toggle-${item.id}`}
                                      // checked={toggleStates[item.id] || false} // Use toggleStates to set the initial state
                                      onChange={() => handleToggleChange(item.id)} // Pass item id to handleToggleChange

                                    />
                                    <label className="form-check-label" htmlFor={`toggle-${item.id}`}>
                                      {item.moduleName}
                                    </label>
                                  </div>
                                ))}
                                 {validationError && (
                            <FormFeedback className="d-block" type="invalid">
                              {validationError}
                            </FormFeedback>
                          )}
                              </div>

                              </div>
                               ) : (
                                <div className="mb-3">
                                <Label className="form-label">Modules</Label>
                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>

                                {ModuleList.map(item => (
                                  <div key={item.id} className="form-check form-switch form-switch-lg mb-3" style={{ marginRight: '20px' }}>
                                    <input
                                      type="checkbox"
                                      name={`toggle-${item.id}`}
                                      className="form-check-input form-control"
                                      id={`toggle-${item.id}`}
                                      checked={toggleStates[item.id] || false }
                                      onClick={() => {handleToggleChange(item.id)}}
                                    />
                                    <label className="form-check-label" htmlFor={`toggle-${item.id}`}>
                                      {item.moduleName}
                                    </label>
                                  </div>
                                ))}
                                  {validationError && (
                            <FormFeedback className="d-block" type="invalid">
                              {validationError}
                            </FormFeedback>
                          )}
                                </div></div>
                               )}
                            <Row>
                              <Col md={6}>
                                <div className="mb-3">
                                  <Label className="form-label">Monthly Cost</Label>
                                  <Input
                                    name="monthlyCost"
                                    className="form-control"
                                    placeholder="Insert Cost"
                                    type="number"
                                    onChange={validation.handleChange}
                                    onBlur={handleBlur}
                                    value={validation.values.monthlyCost || ""}
                                    invalid={validation.touched.monthlyCost && validation.errors.monthlyCost}
                                    min="0"
                                  />
                                  {validation.touched.monthlyCost && validation.errors.monthlyCost ? (
                                    <FormFeedback type="invalid">{validation.errors.monthlyCost}</FormFeedback>
                                  ) : null}
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="mb-3">
                                  <Label className="form-label"> Yearly Cost</Label>
                                  <Input
                                    name="yearlyCost"
                                    className="form-control"
                                    placeholder="Insert Cost"
                                    type="number"
                                    onChange={validation.handleChange}
                                    onBlur={handleBlur}
                                    value={validation.values.yearlyCost || ""}
                                    invalid={validation.touched.yearlyCost && validation.errors.yearlyCost}
                                    min="0"
                                  />
                                  {validation.touched.yearlyCost && validation.errors.yearlyCost ? (
                                    <FormFeedback type="invalid">{validation.errors.yearlyCost}</FormFeedback>
                                  ) : null}
                                </div>

                              </Col>
                            </Row>
                            <div className="form-check form-switch form-switch-lg mb-3">
                              <input
                                type="checkbox"
                                name="isActive"
                                className="form-check-input"
                                id="isActive"
                                checked={isActive}
                                onChange={handleCheckboxChange}
                                onClick={isEdit ? toggleactive : () => {}}
                              />
                              <label className="form-check-label" htmlFor="customSwitchsizemd">
                                {isActive ? 'Active' : 'Inactive'}
                              </label>
                            </div>


                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-primary save-user w-md me-3"
                              >
                                {!!isEdit ? "Update" : "Save"}
                              </button>
                              <button
                                type="button"
                                className="btn btn-secondary w-md "
                                onClick={toggle}
                              >
                                Cancel
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>

            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Plan);
