import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody, FormGroup, Label, Input, Button, CardHeader, Form, FormFeedback, Modal, ModalHeader, ModalBody, ModalFooter, Table } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import BlockModal from "components/Booking/block";
import { useNavigate } from 'react-router-dom';
import Select from "react-select";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from "react-redux";
import { getGuestDetils, getWithoutCheckinRoomsAndType, getRoomService, getIdentity, getPaymentTypes, getRooms, post_ChangeRoomData, getAdvanceById, getSameRoomsAndType } from "../../../middleware/backent_helper";
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";


const ShiftRoom = () => {
  document.title = "StayPro | Shift Room";
  const [shitingDate, setStartDate] = useState(new Date());
  const [proofTypes, setProofTypes] = useState([]);
  const [selectedProofType, setselectedProofType] = useState('');
  const [selectedPayment, setselectedPayment] = useState('');
  const [paymentTypeList, setPaymentTypes] = useState([]);
  const [roomsList, setRoomsList] = useState([]);
  const [selectedWifi, setSelectedWifi] = useState(null);
  const [roomsAndtypeList, setRoomAndTypeList] = useState([]);
  const [sameroomsAndtypeList, setSameRoomAndTypeList] = useState([]);
  const [selectedRoomType, setSelectedRoomType] = useState(null);
  const [filteredRoomTypes, setFilteredRoomTypes] = useState([]);
  const [selectedRoomNo, setSelectedRoomNo] = useState(null);
  const [roomsServiceList, setRoomServiceList] = useState([]);
  const [selectedcheckoutBy, setselectedCheckouBy] = useState('');
  const [GuestDetails, setGuestDetails] = useState([]);
  const [advanceDetails, setAdvanceDetails] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const advanceAmount = Number(advanceDetails.AdvanceAmount) || 0;
  const guestAdvanceAmount = Number(GuestDetails.advanceAmount) || 0;
  const totalAdvanceAmount = advanceAmount + guestAdvanceAmount;

  const [singleTariff1, setSingleTariff] = useState('');
  const [normalTariff1, setNormalTariff] = useState('');
  const [extraPersonTariff1, setExtraPersonTariff] = useState('');
  const [tariff, setTariff] = useState(0);
  const [hid, setHid] = useState(() => {
    const storedHotelId = localStorage.getItem('hotelId')
    return storedHotelId ? storedHotelId.replace(/"/g, '') : '';
  });
  const [dbName, setDbName] = useState(
    localStorage.getItem('dbName')
  )
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const handleBackClick = () => {

    navigate(`/booking`);
  };

  // Handle room number change
  const handleRoomNoChange = (selectedRoom) => {
    setSelectedRoomNo(selectedRoom);
    
    const selectedRoomData = roomsAndtypeList.find(room => room.roomIds === selectedRoom.value);
    if (selectedRoomData) {
      setFilteredRoomTypes(selectedRoomData.roomTypeTitles);
   
    } else {
      setFilteredRoomTypes([]);
    }
    validation.setFieldValue('roomIds', selectedRoom ? selectedRoom.value : '');
    validation.setFieldValue('roomNo', selectedRoom ? selectedRoom.label : '');
  };

  const handleRoomTypeChange = (selectedType) => {
    setSelectedRoomType(selectedType);
    validation.setFieldValue('roomTypeIds', selectedType ? selectedType.value : '');
  
    const roomTypeList = isChecked ? sameroomsAndtypeList.flatMap(room => room.roomTypeTitles) : filteredRoomTypes;
  
    if (selectedType) {
     
      const selectedRoom = roomTypeList.find(type => type.roomTypeId === selectedType.value);
  
      setSingleTariff(selectedRoom ? selectedRoom.singleTariff : 'N/A');
      setNormalTariff(selectedRoom ? selectedRoom.normalTariff : 'N/A');
      setExtraPersonTariff(selectedRoom ? selectedRoom.extraPersonTariff : 'N/A');
    } else {
      // Reset tariffs if no room type is selected
      setSingleTariff('N/A');
      setNormalTariff('N/A');
      setExtraPersonTariff('N/A');
    }
  };
  

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const bookingId = queryParams.get('bookingId');
  useEffect(() => {
    getGuestDetils(hid, dbName, bookingId)
      .then((res) => {

        setGuestDetails(res.data.data);
      });
  }, [])

  const handleCheckboxChange = (event) => {
    const isCheckedNow = event.target.checked;
    setIsChecked(isCheckedNow);
    if (isCheckedNow) {
      setSelectedRoomNo(null);
      setSelectedRoomType(null);
      validation.setFieldValue('roomIds', '');
      validation.setFieldValue('roomTypeIds', '');
    }
  }

  useEffect(() => {
    const roomId = GuestDetails.roomId;

    if (isChecked && roomId) {
      // If checkbox is checked, fetch from getSameRoomsAndType
      getSameRoomsAndType(hid, dbName, roomId)
        .then((res) => {
         
          setSameRoomAndTypeList(res.data.data);
        })
        .catch((error) => {
          console.error("Error fetching getSameRoomsAndType data", error);
        });
    } else {
      // If unchecked, fetch from getWithoutCheckinRoomsAndType
      getWithoutCheckinRoomsAndType(hid, dbName)
        .then((res) => {
          
          setRoomAndTypeList(res.data.data);
        })
        .catch((error) => {
          console.error("Error fetching getWithoutCheckinRoomsAndType data", error);
        });
    }
  }, [isChecked, hid, dbName, GuestDetails.roomId]);



  useEffect(() => {
    getRoomService(hid, dbName)
      .then((res) => {
        setRoomServiceList(res.data.data);
      });
  }, [])



  const handleProofTypeChange = selectedOption => {
    setselectedProofType(selectedOption)
    validation.setFieldValue('proofType', selectedOption ? selectedOption.value : '');

  }

  const handlePaymentTypeChange = selectedOption => {
    setselectedPayment(selectedOption)
    validation.setFieldValue('payment', selectedOption ? selectedOption.value : '');

  }

  const handleCheckoutByChange = selectedOption => {
    setselectedCheckouBy(selectedOption)
    validation.setFieldValue('checkout_by', selectedOption ? selectedOption.value : '');

  }

  useEffect(() => {
    getPaymentTypes(hid, dbName)
      .then((res) => {
        setPaymentTypes(res.data.data);
      });
  }, [])

  useEffect(() => {

    getIdentity(hid, dbName)
      .then((res) => {
        setProofTypes(res.data.data)
      })
  }, [])

  useEffect(() => {
    getRooms(hid, dbName)
      .then((res) => {

        setRoomsList(res.data.data);
      });
  }, [])

  useEffect(() => {
    getAdvanceById(hid, dbName, bookingId)
      .then((res) => {
        setAdvanceDetails(res.data.data);
      });
  }, [])

  const handlewifiChange = selectedOption => {
    setSelectedWifi(selectedOption);
    validation.setFieldValue('wifi', selectedOption ? selectedOption.value : '');
  };

  const calculateDaysBetween = (checkInDate, checkOutDate) => {
    const checkIn = new Date(checkInDate);
    const checkOut = new Date(checkOutDate);

    // Calculate the difference in time (milliseconds)
    const diffTime = Math.abs(checkOut - checkIn);

    // Convert milliseconds to days
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays;
  };


  const calculateTotalTariff = (tariff, checkInDate, checkOutDate) => {
    const days = calculateDaysBetween(checkInDate, checkOutDate);
    const totalDays = days === 0 ? 1 : days;
    return tariff * totalDays;
  };


  const validation = useFormik({
    initialValues: {
      shitingDate: shitingDate || new Date(),
      checkout_by: '',
      roomIds: '',
      roomNo:'',
      mobile_no: "",
      guest_name: "",
      childrenAges: "",
      no_of_male: "",
      no_of_female: "",
      no_of_child: "",

      roomTypeIds: '',
      payment: '',
      remarks: '',
      normaltariff: '',
      advance_Amt: ''

    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      shitingDate: Yup.date().required("Please Select Date"),
      roomTypeIds: Yup.string().required('RoomType is required'),
      payment: Yup.string().required('Payment Type is required'),
      checkout_by: Yup.string().required('Service Member is required'),
      roomIds: Yup.string().when('isChecked', {
        is: false, 
        then: Yup.string().required('Room No is required'),
        otherwise: Yup.string()
      }),
      // roomIds: Yup.string().required('Room No is required'),
      remarks: Yup.string().required("Please Enter Your Remarks"),
      normaltariff: Yup.string().required('Tariff is required'),
      advance_Amt: Yup.string().required('Advance Amount is required')
    }),
    onSubmit: (values) => {
      console.log('Form Values:', values);
      dispatch(post_ChangeRoom(values));
    }
  });

  const post_ChangeRoom = (values) => {
    const data = JSON.stringify({
      GuestsId: GuestDetails.guestId,
      oldRoomId: GuestDetails.roomId,
      changeRoomId: values.roomIds,
      newRoomNo: values.roomNo,
      oldRoomTypeId: GuestDetails.Room_typesId,
      newRoomTypeId: values.roomTypeIds,
      oldBookingId: bookingId,
      remarks: values.remarks,
      tariff: values.normaltariff,
      advanceAmount: values.advance_Amt,
      shitingDateTime: values.shitingDate,
      checkInDate: GuestDetails.checkInDate,
      checkOutDate: GuestDetails.checkOutDate,
      ServicePersonId: values.checkout_by,
      no_of_male: values.no_of_male,
      no_of_female: values.no_of_female,
      no_of_child: values.no_of_child,
      child_ages: values.childrenAges.length > 0 ? values.childrenAges : 0,
      Payment_typesId: values.payment,
      hotelId: hid,
      dbName: dbName,
      isChangedRoom: 1,

    });
    post_ChangeRoomData(data).then((res) => {
      if (res.data.success) {
        navigate('/booking');
        // setResponse(messages.success.createdMessage(masterName))
        // setAlertModal(true)
      }
    }).catch(err => {
      //   setResponse(messages.failed.createFailed(masterName))
      // setErrorAlertModal(true)
    });

  };



  const showCommonError = () => {

    const { no_of_male, no_of_female, no_of_child } = validation.values;
    const touchedFields = validation.touched.no_of_male && validation.touched.no_of_female && validation.touched.no_of_child;

    if (touchedFields && !no_of_male && !no_of_female && !no_of_child) {
      return 'Please enter a Gender Count .';
    }
    return '';
  };

  // Function to extract minimum value from the tariff range string
  const extractMinTariff = (tariffRange) => {
    if (!tariffRange) return 0;
    const rangeParts = tariffRange.split('-').map(part => parseInt(part.replace(/[^0-9]/g, ""), 10)); // Extract numbers from the string
    return rangeParts[0]; // Return the first value, which is the minimum
  };

  

 useEffect(() => {
    // Function to calculate tariff with child age consideration
    const calculateTariff = (noOfMale, noOfFemale, childrenAges) => {
      const totalGuests = noOfMale + noOfFemale + childrenAges.filter(age => age >= 14).length;
      let totalTariff = 0;

      // Extract minimum tariff from the range values
      const singleTariff = extractMinTariff(singleTariff1);
      const normalTariff = extractMinTariff(normalTariff1);
      const extraPersonTariff = extractMinTariff(extraPersonTariff1);
      const extraChildTariff = extractMinTariff(extraPersonTariff1);

      

      // Determine the base tariff based on the number of guests
      if (totalGuests === 1) {
        totalTariff = singleTariff || 0; // Single Tariff for 1 guest
      } else if (totalGuests === 2) {
        totalTariff = normalTariff || 0; // Normal Tariff for 2 guests
      } else if (totalGuests > 2) {
        totalTariff = normalTariff + extraPersonTariff * (totalGuests - 2); // Extra Person Tariff for additional guests
      }

      totalTariff;
      setTariff(totalTariff);
      validation.setFieldValue("normaltariff", totalTariff);
      validation.setFieldValue("advance_Amt", totalTariff);
    };
    const noOfMale = validation.values.no_of_male || 0;
    const noOfFemale = validation.values.no_of_female || 0;
    const childrenAges = validation.values.childrenAges || [];

  
      calculateTariff(Number(noOfMale), Number(noOfFemale), childrenAges);
   
  }, [
    validation.values.no_of_male,
    validation.values.no_of_female,
    validation.values.no_of_child,
    validation.values.childrenAges,
   
  ]);

  // Function to dynamically render child age inputs
  const renderChildrenAges = () => {
    return Array.from({ length: validation.values.no_of_child }).map((_, i) => (
      <div key={i} className="mb-3">
        <label className="form-label" htmlFor={`childrenAges[${i}]`}>
          Child {i + 1} Age
        </label>
        <Input
          type="number"
          name={`childrenAges[${i}]`}
          placeholder="Enter age"
          value={
            validation.values.childrenAges && validation.values.childrenAges[i] !== undefined
              ? validation.values.childrenAges[i]
              : ""
          }
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          min={0}
          invalid={
            validation.touched.childrenAges &&
            validation.touched.childrenAges[i] &&
            validation.errors.childrenAges &&
            validation.errors.childrenAges[i]
          }
        />
        {validation.errors.childrenAges && validation.errors.childrenAges[i] && (
          <FormFeedback>{validation.errors.childrenAges[i]}</FormFeedback>
        )}
      </div>
    ));
  };


  return (
    <React.Fragment>
      <BlockModal />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Bookings" breadcrumbItem="Manage Shift Room" />
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >


            <Row>
              <Row>
                <Col lg={12}>
                  <Card>

                    <CardBody>
                      <div className="mb-2">
                        <Row className="align-items-center">
                          <Col lg={4} md={4} sm={4}>
                            <strong>Guest Name :</strong>
                            <span> {GuestDetails.guestName}</span>
                            <span style={{ display: 'none' }}> {GuestDetails.guestId}</span>
                          </Col>
                          <Col lg={3} md={3} sm={3}>
                            <strong>Mobile No :</strong>
                            <span> {GuestDetails.mobileNo}</span>
                          </Col>

                          <Col lg={3} md={3} sm={3}>
                            <strong>Checkin Date :</strong><span> {GuestDetails.checkInDate ? GuestDetails.checkInDate.slice(0, 16) : ''}</span>
                          </Col>

                          <Col lg={2} md={2} sm={2}>
                            <strong>Room No :</strong><span> {GuestDetails.roomNo}</span>
                            <span style={{ display: 'none' }}> {GuestDetails.roomId}</span>
                          </Col>
                        </Row>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col lg={6}>
                  <Card>
                    <CardHeader><h5 className="">Change Room</h5></CardHeader>
                    <CardBody>
                      <div className="mb-2">
                        <Row>
                          <Row className="align-items-center">
                            <Col xs={12}>
                              <div className="mb-3">
                                <Label className="form-label">Shiting Date / Time</Label>
                                <DatePicker
                                  name="shitingDate"
                                  selected={shitingDate}
                                  onChange={(date) => setStartDate(date)}
                                  className="form-control"
                                  dateFormat="d-MM-yyyy h:mm aa"
                                  placeholderText="Select date and time"
                                  showTimeSelect
                                  timeFormat="HH:mm"
                                  timeIntervals={1}
                                  value={validation.values.shitingDate || ""}
                                  invalid={
                                    validation.touched.shitingDate &&
                                      validation.errors.shitingDate
                                      ? true
                                      : false
                                  }

                                />
                                <FontAwesomeIcon icon={faCalendarAlt}
                                  className="position-absolute"
                                  style={{ top: '56%', right: '23px', transform: 'translateY(-50%)', color: '#6c757d' }}
                                />
                                {validation.touched.shitingDate &&
                                  validation.errors.shitingDate ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.shitingDate}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>

                          </Row>

                          <Row className="form-check mt-0">
                            <Col lg={12} md={12}>
                              <div className="form-check pb-4">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="selectRoomWithOtherRoomType"
                                  value={isChecked}

                                  onChange={handleCheckboxChange}
                                />
                                <label className="form-check-label" htmlFor="selectRoomWithOtherRoomType">
                                  Same Room With Other Room Type
                                </label>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={6} md={6}>
                              <div className="mb-3">
                                <FormGroup>
                                  <Label className="form-label">Select Room No</Label>
                                  <Select
                                    name="roomIds"
                                    placeholder="Select Room No"
                                    classNamePrefix="react-select"
                                    className={`react-select-container ${validation.touched.roomIds && validation.errors.roomIds
                                      ? 'is-invalid'
                                      : ''
                                      }`}

                                    options={roomsAndtypeList.map(room => ({ value: room.roomIds, label: room.roomNo }))}
                                    value={selectedRoomNo}
                                    isDisabled={isChecked}
                                    onChange={handleRoomNoChange}


                                    styles={{
                                      menu: (provided) => ({
                                        ...provided,
                                        top: "100%",
                                        position: "absolute",
                                        width: "100%",
                                        zIndex: 999,
                                      }),
                                    }}
                                    isClearable
                                    isSearchable
                                  />
                                  {validation.touched.roomIds &&
                                    validation.errors.roomIds ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.roomIds}
                                    </FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </div>
                            </Col>

                            <Col lg={6} md={6}>
                              <div className="mb-3" style={{ position: 'relative' }}>
                                <label htmlFor="testName" className="form-label"> Select Room Type </label>
                                <Select

                                  name="roomTypeIds"
                                  className={`react-select-container ${validation.touched.roomTypeIds && validation.errors.roomTypeIds
                                    ? 'is-invalid'
                                    : ''
                                    }`}

                                  options={isChecked
                                    ? sameroomsAndtypeList.flatMap((room) =>
                                      room.roomTypeTitles.map((type) => ({
                                        value: type.roomTypeId,
                                        label: type.title,
                                      }))
                                    )
                                    : filteredRoomTypes.map((type) => ({
                                      value: type.roomTypeId,
                                      label: type.title,
                                    }))
                                  }

                                  value={selectedRoomType}
                                  onChange={handleRoomTypeChange}
                                  isDisabled={!selectedRoomNo && !isChecked}

                                  placeholder="Select a Room Type"

                                  isClearable
                                  isSearchable
                                  classNamePrefix="react-select"

                                  styles={{
                                    menu: provided => ({ ...provided, top: '100%', position: 'absolute', width: '100%', zIndex: 999 }),
                                  }}
                                  onBlur={validation.handleBlur}
                                  invalid={
                                    validation.touched.roomTypeIds && validation.errors.roomTypeIds
                                  }
                                />
                                {validation.touched.roomTypeIds && validation.errors.roomTypeIds && (
                                  <div className="text-danger">{validation.errors.roomTypeIds}</div>
                                )}
                              </div>
                            </Col>

                          </Row>
                          <Row className="mb-2">
                        <Col lg={4} md={4} sm={4}>
                          <strong>Single:</strong>{singleTariff1}
                        </Col>

                        <Col lg={4} md={4} sm={2}>
                          <strong>Normal:</strong>{normalTariff1}
                        </Col>
                        <Col lg={4} md={4} sm={2}>
                          <strong>Extra Person:</strong> {extraPersonTariff1}
                        </Col>

                      </Row>
                          <Row>
                            <Col lg={4} md={4}>
                              <div className="mb-3">
                                <label className="form-label" htmlFor="Male">Male</label>
                                <Input
                                  type="number"
                                  name="no_of_male"
                                  placeholder="Count"
                                  min={0}
                                  value={validation.values.no_of_male || ""}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  invalid={validation.touched.no_of_male && validation.errors.no_of_male}
                                />
                              </div>
                            </Col>
                            <Col lg={4} md={4}>
                              <div className="mb-3">
                                <label className="form-label" htmlFor="FeMale">Female</label>
                                <Input
                                  type="number"
                                  name="no_of_female"
                                  placeholder="Count"
                                  min={0}
                                  value={validation.values.no_of_female || ""}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  invalid={validation.touched.no_of_female && validation.errors.no_of_female}
                                />
                              </div>
                            </Col>
                            <Col lg={4} md={4}>
                              <div className="mb-3">
                                <label className="form-label" htmlFor="Male">Child</label>
                                <Input
                                  type="number"
                                  name="no_of_child"
                                  placeholder="Count"
                                  min={0}
                                  value={validation.values.no_of_child || ""}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  invalid={validation.touched.no_of_child && validation.errors.no_of_child}
                                />
                              </div>
                            </Col>
                            {showCommonError() && (
                              <FormFeedback className="d-block text-danger text-center">
                                {showCommonError()}
                              </FormFeedback>
                            )}
                           {validation.values.no_of_child > 0 && renderChildrenAges()}
                          </Row>
                          <Row>
                            <Col lg={6} md={6}>
                              <div className="mb-3">
                                <label className="form-label" htmlFor="tariff">Tariff</label>
                                <Input
                                  type="number"
                                  name="normaltariff"
                                  placeholder="Insert Tariff"
                                  min={0}
                                  value={validation.values.normaltariff || ""}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  invalid={validation.touched.normaltariff && validation.errors.normaltariff}
                                />
                              </div>
                            </Col>
                            <Col lg={6} md={6}>
                              <div className="mb-3">
                                <label className="form-label" htmlFor="AdvanceAmt">Advance Amount</label>
                                <Input
                                  type="number"
                                  name="advance_Amt"
                                  placeholder=" Amount"
                                  min={0}
                                  value={validation.values.advance_Amt || ""}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  invalid={validation.touched.advance_Amt && validation.errors.advance_Amt}
                                />
                              </div>
                            </Col>

                          </Row>
                          <Row>
                            <Col lg={6} md={6}>
                              <div className="mb-3" style={{ position: 'relative' }}>
                                <label htmlFor="testName" className="form-label">Select Payment Type </label>
                                <Select
                                  name="payment"
                                  placeholder="Select Payment Type"
                                  className={`react-select-containe ${validation.touched.payment && validation.errors.payment
                                    ? 'is-invalid'
                                    : ''
                                    }`}
                                  value={selectedPayment}
                                  onChange={handlePaymentTypeChange}
                                  options={paymentTypeList.map((payment) => ({
                                    label: payment.paymentType,
                                    value: payment.id
                                  }))}
                                  isClearable
                                  isSearchable
                                  styles={{
                                    menu: (provided) => ({
                                      ...provided,
                                      top: "100%",
                                      position: "absolute",
                                      width: "100%",
                                      zIndex: 999,
                                    }),
                                  }}
                                />
                                {validation.touched.payment &&
                                  validation.errors.payment ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.payment}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col lg={6} md={6}>
                              <div className="mb-3">
                                <FormGroup>
                                  <Label className="form-label">Wifi Password</Label>
                                  <Select
                                    id="wifi"
                                    name="wifi"
                                    options={roomsList.map(type => ({ value: type.wifi, label: type.wifi }))}
                                    placeholder="Select a Room Type"
                                    value={selectedWifi}
                                    onChange={handlewifiChange}
                                    isClearable
                                    isSearchable
                                    classNamePrefix="custom-select"
                                    styles={{
                                      menu: provided => ({ ...provided, top: '100%', position: 'absolute', width: '100%', zIndex: 999 }),
                                    }}
                                    onBlur={validation.handleBlur}
                                    invalid={
                                      validation.touched.wifi && validation.errors.wifi
                                    }
                                  />
                                  {validation.touched.wifi && validation.errors.wifi && (
                                    <div className="text-danger">{validation.errors.wifi}</div>
                                  )}
                                </FormGroup>
                              </div>
                            </Col>

                          </Row>
                          <Row>
                            <Col lg={6} md={6}>
                              <div className="mb-3" style={{ position: 'relative' }}>
                                <label htmlFor="testName" className="form-label">CheckOut By </label>
                                <Select
                                  name="checkout_by"
                                  placeholder="Select Service Member"
                                  className={`react-select-containe ${validation.touched.checkout_by && validation.errors.checkout_by
                                    ? 'is-invalid'
                                    : ''
                                    }`}
                                  value={selectedcheckoutBy}
                                  onChange={handleCheckoutByChange}
                                  options={roomsServiceList.map((CheckOutby) => ({
                                    label: CheckOutby.name,
                                    value: CheckOutby.id
                                  }))}
                                  isClearable
                                  isSearchable
                                  styles={{
                                    menu: (provided) => ({
                                      ...provided,
                                      top: "100%",
                                      position: "absolute",
                                      width: "100%",
                                      zIndex: 999,
                                    }),
                                  }}
                                />
                                {validation.touched.checkout_by &&
                                  validation.errors.checkout_by ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.checkout_by}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col lg={6} md={6}>
                              <div className="mb-3">
                                <FormGroup>
                                  <Label className="form-label">GST Number</Label>
                                  <Input
                                    name="gst_no"
                                    type="text"
                                    placeholder={"GST Number"}
                                    onChange={(e) => {
                                      e.target.value = e.target.value.toUpperCase();
                                      validation.handleChange(e);
                                    }}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.gst_no || ""}
                                    invalid={
                                      validation.touched.gst_no &&
                                        validation.errors.gst_no
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.gst_no &&
                                    validation.errors.gst_no ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.gst_no}
                                    </FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </div>
                            </Col>

                          </Row>
                          <Row>
                            <Col lg={12} md={12}>
                              <div className="mb-3">
                                <Label className="form-label">Remarks</Label>
                                <Input
                                  name="remarks"
                                  type="text"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.remarks || ""}
                                  invalid={
                                    validation.touched.remarks &&
                                      validation.errors.remarks
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.remarks &&
                                  validation.errors.remarks ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.remarks}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12}>
                              <div className="d-flex justify-content-center">
                                <Button color="btn btn-success" type="submit" className="mx-2">
                                  Shift Room
                                </Button>
                                <Button color="secondary" className="mx-2" onClick={() => handleBackClick()}>
                                  Back
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </Row>

                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={6}>
                  <Card>
                    <CardHeader><h5>Advance Paid</h5></CardHeader>
                    <CardBody>
                      <Table bordered responsive>
                        <thead>
                          <tr>
                            <th className="text-center">Advance No.</th>
                            <th className="text-center">Date Time</th>
                            <th className="text-center">Type</th>
                            <th className="text-center">Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                        {advanceDetails && advanceDetails.length > 0 ? (
                        advanceDetails.map((detail, index) => (
                          <tr key={index}>
                            <td className="text-center">{index + 1}</td>
                            <td className="text-center">{detail.ArrivalDateTime}</td>
                            <td className="text-center">{detail.PaymentType}</td>
                            <td className="text-center">{detail.AdvanceAmount}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td className="text-center" colSpan="4">
                            No Data
                          </td>
                        </tr>
                      )}
                          
                        </tbody>
                      </Table>


                    </CardBody>
                  </Card>
                  <Card>
                    <CardHeader><h5>Services Details</h5></CardHeader>
                    <CardBody>
                      <Table bordered>
                        <tbody>
                          <tr>
                            <th>Room Charges (Tariff)</th>
                            <td className="text-end">{GuestDetails.
                              hotelCurrency}.{GuestDetails.checkInDate && GuestDetails.checkOutDate
                                ? `${calculateTotalTariff(GuestDetails.tariff, GuestDetails.checkInDate, GuestDetails.checkOutDate)}`
                                : '0'}</td>
                          </tr>
                          <tr>
                            <th>Room No.</th>
                            <td className="text-end">{GuestDetails.roomNo} <strong>({GuestDetails.roomType ? GuestDetails.roomType.toUpperCase() : ''})</strong> </td>
                          </tr>
                          <tr>
                            <th>Stay Duration</th>
                            <td className="text-end">{GuestDetails.checkInDate?.slice(0, 16)} - {GuestDetails.checkOutDate?.slice(0, 16)} =  {GuestDetails.checkInDate && GuestDetails.checkOutDate
                              ? calculateDaysBetween(GuestDetails.checkInDate, GuestDetails.checkOutDate)
                              : "N/A"} Days</td>
                          </tr>
                          <tr>
                            <th>Room Services</th>
                            <td className="text-end">{GuestDetails.hotelCurrency}.0.00</td>
                          </tr>
                          <tr>
                            <th>Gross Amount</th>
                            <td className="text-end">{GuestDetails.
                              hotelCurrency}.{GuestDetails.checkInDate && GuestDetails.checkOutDate
                                ? `${calculateTotalTariff(GuestDetails.tariff, GuestDetails.checkInDate, GuestDetails.checkOutDate)}`
                                : '0'}</td>
                          </tr>
                          <tr>
                            <th>Advance Amount</th>
                            <td className="text-end">{GuestDetails.hotelCurrency}.{totalAdvanceAmount}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default ShiftRoom;
