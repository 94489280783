import React, { useState, useEffect } from 'react';
// import { verifyOTP } from '../../api/index';
import { verifyOTP } from "../../middleware/backent_helper";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom"

function OTPComponent({ email }) {
  const navigate = useNavigate();
  const [otp, setOTP] = useState(['', '', '', '']);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    // Check if all OTP fields are filled
    setIsValid(otp.every(digit => digit !== ''));
  }, [otp]);

  const handleChange = (index, value) => {
    const newOTP = [...otp];
    newOTP[index] = value;
    setOTP(newOTP);
  };

  const handleVerifyOTP = () => {
    const enteredOTP = otp.join('');
    const data = JSON.stringify({
      otp: enteredOTP,
      email: email
    })
    verifyOTP(data)
      .then(response => {
        toast.success(response.data.data, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
          onClose: () => {
            navigate('/login');
          }
        });
      })
      .catch(error => {
        toast.error('Failed to verify OTP. Please try again.', { autoClose: 3000 });
        // Handle error, show error message to the user, etc.
      });
  };

  const moveNext = (index, value) => {
    if (value === '' && index > 0) {
      document.getElementById(`otp_input_${index - 1}`).focus();
    } else if (index < 3 && value !== '') {
      document.getElementById(`otp_input_${index + 1}`).focus();
    }
  };

  return (
    <div>
      <h2>Enter OTP</h2>
      <div>
        {otp.map((digit, index) => (
          <input
            key={index}
            id={`otp_input_${index}`}
            type="text"
            maxLength={1}
            value={digit}
            onChange={e => {
              handleChange(index, e.target.value);
              moveNext(index, e.target.value);
            }}
            style={{ width: '40px', marginRight: '5px', display: 'inline-block', textAlign: 'center' }}
          />
        ))}
      </div>
      <div style={{ marginTop: '25px' }}>
        <button className="btn btn-primary btn-block" onClick={handleVerifyOTP} disabled={!isValid}>
          Verify OTP
        </button>
      </div>
    </div>
  );
}

export default OTPComponent;
