const TransactionDate = (cell) => {
    return cell.value ? cell.value : '';
};

const Expenses = (cell) => {
    return cell.value ? cell.value : '';
};

const Remarks = (cell) => {
    return cell.value ? cell.value : '';
};


export {
    TransactionDate,
    Expenses,
    Remarks,
};