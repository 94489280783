import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Collapse, Card, CardBody, Button, Input ,InputGroup,InputGroupText} from "reactstrap";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp, faCalendarAlt,faSearch } from "@fortawesome/free-solid-svg-icons";
import { get_Activity_logs } from "../../middleware/backent_helper";
import Breadcrumbs from "components/Common/Breadcrumb";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
 

const ActivityLog = () => {
  const [selectedDates, setSelectedDates] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection"
    }
  ]);
  const [logList, setLogList] = useState([]);
  const [filteredLogList, setFilteredLogList] = useState([]);
  const [openLogId, setOpenLogId] = useState(null);
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [formattedDateRange, setFormattedDateRange] = useState(`${format(new Date(), "dd-MM-yyyy")} / ${format(new Date(), "dd-MM-yyyy")}`);
  const [hotelSearch, setHotelSearch] = useState('');

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [logsPerPage] = useState(10);

  const handleDateRangeChange = (ranges) => {
    setSelectedDates([ranges.selection]);
    setStartDate(ranges.selection.startDate);
    setEndDate(ranges.selection.endDate);
    setFormattedDateRange(`${format(ranges.selection.startDate, "dd-MM-yyyy")} / ${format(ranges.selection.endDate, "dd-MM-yyyy")}`);
    setDatePickerOpen(false);
  };

  const handleInputClick = () => {
    setDatePickerOpen(true);
  };

  const handleSearch = () => {
    if (!startDate || !endDate) return;
    const formattedFromDate = formatDate(startDate);
    const formattedToDate = formatDate(endDate);
    get_Activity_logs(formattedFromDate, formattedToDate)
      .then((res) => {
        const fetchedLogs = res.data.data;
        setLogList(fetchedLogs);
        setFilteredLogList(filterLogs(fetchedLogs, hotelSearch));
        setCurrentPage(1); 
      })
      .catch((err) => console.error("Error fetching logs:", err));
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const handleToggle = (logId) => {
    setOpenLogId(openLogId === logId ? null : logId);
  };

  const filterLogs = (logs, searchTerm) => {
    if (!searchTerm) return logs;
    return logs.filter(log => log.dbName.toLowerCase().includes(searchTerm.toLowerCase()));
  };

  const handleHotelSearchChange = (e) => {
    const searchTerm = e.target.value;
    setHotelSearch(searchTerm);
    setFilteredLogList(filterLogs(logList, searchTerm));
    setCurrentPage(1); // Reset to first page on search
  };

  useEffect(() => {
    handleSearch();
  }, [startDate, endDate]);

  // Pagination logic
  const totalLogs = filteredLogList.length;
  const totalPages = Math.ceil(totalLogs / logsPerPage);

  const paginateLogs = (logs) => {
    const startIndex = (currentPage - 1) * logsPerPage;
    const endIndex = startIndex + logsPerPage;
    return logs.slice(startIndex, endIndex);
  };

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handlePageInputChange = (e) => {
    const pageNumber = parseInt(e.target.value, 10);
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const PaginationControls = () => (
    <div className="pagination-controls ">
    <Button
      disabled={currentPage === 1}
      onClick={() => handlePageChange(1)}
      className="pagination-button"
    >
      &lt;&lt;
    </Button>
    <Button
      disabled={currentPage === 1}
      onClick={() => handlePageChange(currentPage - 1)}
      className="pagination-button"
    >
      &lt;
    </Button>
    Page
    <Input
      type="number"
      min="1"
      max={totalPages}
      value={currentPage}
      onChange={handlePageInputChange}
      className="page-input"
    />
    <Button
      disabled={currentPage === totalPages}
      onClick={() => handlePageChange(currentPage + 1)}
      className="pagination-button"
    >
      &gt;
    </Button>
    <Button
      disabled={currentPage === totalPages}
      onClick={() => handlePageChange(totalPages)}
      className="pagination-button"
    >
      &gt;&gt;
    </Button>
  </div>
  );

  const node = useRef();
  const handleClickOutside = (event) => {
    if (node.current && !node.current.contains(event.target)) {
      setDatePickerOpen(false);
    }
  };

  useEffect(() => {
    if (datePickerOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [datePickerOpen]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Activity Logs" breadcrumbItem="Activity Logs Details" />
          <Row className="mb-3">
            <Col lg="6" xs="12" md="6">
              <div className="input-group">
                <div className="input-group-text">
                  <span className="input-group-prepand">From - To</span>
                </div>
                <input
                  type="text"
                  value={formattedDateRange}
                  readOnly
                  onClick={handleInputClick}
                  placeholder="Select Date Range"
                  className="form-control date-picker-input"
                />
                <label className="input-group-text" htmlFor="inputGroupFile01">
                  <FontAwesomeIcon icon={faCalendarAlt} />
                </label>
              </div>
              {datePickerOpen && (
                <div ref={node} className="date-range-picker-dropdown mt-1">
                  <DateRangePicker
                    onChange={handleDateRangeChange}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={2}
                    ranges={selectedDates}
                    direction="horizontal"
                    className="custom-date-range-picker"
                  />
                </div>
              )}
            </Col>
           
              <Col lg="2" md="2" xs="12" className="mt-3 mt-md-0">
              <InputGroup>
                <InputGroupText>
                  <FontAwesomeIcon icon={faSearch} />
                </InputGroupText>
                <Input
                  type="text"
                  placeholder="Search by Hotel"
                  value={hotelSearch}
                  onChange={handleHotelSearchChange}
                  className="search-input"
                />
              </InputGroup>
            </Col>
            <Col lg="4" xs="12" md="4" className="mt-3 mt-md-0 text-md-left">
              {/* <Button color="success" onClick={handleSearch} className="search-button">
                Search
              </Button> */}
               <PaginationControls />
            </Col>
            {/* <PaginationControls /> */}
          </Row>
          <Container fluid style={{ backgroundColor: "white" }}>
            <Row className="mt-4">
              <Col lg="12"  xs="12" >
              {filteredLogList.length === 0 ? (
                  <div className="empty-state">
                    <p>No logs available for the selected date range.</p>
                  </div>
                ) : (
                paginateLogs(filteredLogList).map((log) => (
                  <Card key={log.id} className="log-card">
                    <div className="log-card-header" onClick={() => handleToggle(log.id)}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <FontAwesomeIcon
                          icon={openLogId === log.id ? faChevronUp : faChevronDown}
                          className="log-card-icon"
                        />
                        <span><b>{log.dbName}</b> has {log.message} form via Web</span>
                      </div>
                      <span>{format(new Date(log.dateTime), 'dd-MM-yyyy HH:mm:ss')}</span>
                    </div>
                    <Collapse isOpen={openLogId === log.id}>
                      <CardBody className="log-card-content">
                        <div><strong>Method:</strong> {log.method}</div>
                        <div><strong>Path:</strong> {log.path}</div>
                      </CardBody>
                    </Collapse>
                  </Card>
                ))
              )}
              </Col>
            </Row>
         
          </Container>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ActivityLog;
