import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { Link, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
// import { Col, Container, Form, Row, Input, Label, FormFeedback } from "reactstrap";
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label, Progress, Spinner,
} from "reactstrap"
// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"
import withRouter from "../../components/Common/withRouter"
// import images
import logodark from "../../assets/images/logo.png"
import CarouselPage from "./CarouselPage";
import { loginUser } from "../../store/actions"
import { verifyToken } from "../../middleware/backent_helper"

const Login = props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [passwordShow, setPasswordShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const hasValidatedToken = useRef(false);

  document.title = "Login | StayPro"

  useEffect(() => {
    const handleStorageEvent = (event) => {
      if (event.key === 'token' && event.newValue) {
        const data = JSON.stringify({
          token: event.newValue
        });
        verifyToken(data)
          .then((res) => {
            if (res.data.valid) {
              hasValidatedToken.current = true;
              navigate('/dashboard');
            }
          })
          .catch((error) => {
            console.error('Token validation failed', error);
          });
      }
    };

    const checkToken = () => {
      const token = localStorage.getItem('token');
      if (token && !hasValidatedToken.current) {
        const data = JSON.stringify({
          token: token
        });
        verifyToken(data)
          .then((res) => {
            if (res.data.valid) {
              hasValidatedToken.current = true;
              navigate('/dashboard');
            }

          })
          .catch((error) => {
            console.error('Token validation failed', error);
          });
      }
    };
    // Check the token when the component mounts
    checkToken();
    // Listen for storage events
    window.addEventListener('storage', handleStorageEvent);
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('storage', handleStorageEvent);
    };
  }, [navigate]);

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    initialValues: {
      email: "",
      password: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      email: Yup.string().required("Please enter your email."),
      password: Yup.string().required("Please enter your password."),
    }),
    onSubmit: (values) => {
      setLoading(true);
      dispatch(loginUser(values, props.router.navigate))
        .then(() => {
          setLoading(false); // On successful login

        })
        .catch(() => {
          setLoading(false); // On login failure
        });
    }
  })

  const { error } = useSelector(state => ({
    error: state.Login.error,
  }))

  useEffect(() => {
    if (error) {
      setLoading(false);
      validation.setFieldValue('password', '');
    }

  }, [error]);


  const [hotelId, setHotelId] = useState(localStorage.getItem("hotelId"))

  return (
    <React.Fragment>
      {/* <div className="account-pages my-5 pt-sm-5">
        <div className="text-center justify-content-center">
          <img src={logodark} alt="" height="50" className="mb-4" />
        </div> */}
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPage />
            <Col xl={3}>
              <div className="auth-full-page-content p-md-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="my-auto">
                      <div>
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p className="text-muted">Sign in to continue.</p>
                      </div>

                      <div className="mt-4">
                        <Form
                          className="form-horizontal"
                          onSubmit={e => {
                            e.preventDefault()
                            validation.handleSubmit()
                          }}
                        >
                          {error && <Alert color="danger">{error}</Alert>}
                          <div className="mb-3">
                            <Label className="form-label">Email</Label>
                            <Input
                              name="email"
                              className="form-control"
                              placeholder="Enter email"
                              type="email"
                              autoComplete="username"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email &&
                                !!validation.errors.email
                              }
                            />
                            {validation.touched.email &&
                              validation.errors.email && (
                                <FormFeedback>
                                  {validation.errors.email}
                                </FormFeedback>
                              )}
                          </div>

                          <div className="mb-3">
                            <Label className="form-label">Password</Label>
                            <Input
                              name="password"
                              value={validation.values.password || ""}
                              type={passwordShow ? "text" : "password"}
                              autoComplete="current-password"
                              placeholder="Enter Password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.password &&
                                !!validation.errors.password
                              }
                            />
                            {validation.touched.password &&
                              validation.errors.password ? (
                              <FormFeedback type="invalid">
                                {validation.errors.password}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="form-check">
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              id="auth-remember-check"
                            />
                            <Label
                              className="form-check-label"
                              htmlFor="auth-remember-check"
                            >
                              Remember me
                            </Label>
                            <div className="float-end">
                              <Link
                                to="/forgot-password"
                                className="text-muted"
                              >
                                Forgot password?
                              </Link>
                            </div>
                          </div>

                          <div className="mt-3 d-grid">
                            <button type="submit" className="btn btn-primary btn-block">
                              {loading && (
                                <>
                                  <Spinner size="sm" className="align-middle me-2" />
                                  {" "}
                                </>
                              )}
                              {!loading && (
                                "Log In"
                              )}
                            </button>
                          </div>
                        </Form>


                        <div className="mt-5 text-center">
                          <p>
                            Don&apos;t have an account?
                            <Link
                              to="/register"
                              className="fw-medium text-primary"
                            >
                              Signup now
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        &copy; {new Date().getFullYear()} StinSoft Technologies.
                        {/* ©  */}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
