import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../../components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";

import { useFormik } from "formik";
import { addGensetDetails,getGenset  } from "../../../middleware/backent_helper";
import { FuelBuyDate,TotalHours } from "./GensetDetailMasterCol";
import messages from '../../../utils/constants/messages';
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import DeleteModal from "../../../components/Common/DeleteModal";
import AlertModal from "../../../components/Common/alertModal";
import ErrorAlertModal from "../../../components/Common/errorAlertModal";
//Date Picker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "assets/scss/datatables.scss";
//New
import { DateRangePicker } from "react-date-range";
import { format } from "date-fns";
import { enUS } from 'date-fns/locale';
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
//redux
import { useSelector, useDispatch } from "react-redux";

const GensetDetailMaster = props => {
  //meta title
  document.title = "Genset | Utility ";

  const dispatch = useDispatch();
  const today = new Date();

  const [selectedDates, setSelectedDates] = useState([
    {
      startDate: today,
      endDate: today,
      key: "selection",
    },
  ]);

  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [totalRunnableHours, setTotalRunnableHours] = useState(0);
  const [GensetList, setGensetList] = useState([]);
  const [GensetAccount, setGensetAccount] = useState();
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [errorAlertModal, setErrorAlertModal] = useState(false);
  const [masterName, setMasterName] = useState("Genset Detail");
  const [response, setResponse] = useState(false);
  const [hid, setHid] = useState(() => {
    const storedHotelId = localStorage.getItem('hotelId')
    return storedHotelId ? storedHotelId.replace(/"/g, '') : '';
  });
  const [dbName, setDbName] = useState(
    localStorage.getItem('dbName')
  )

  const formattedDateRange = `${format(selectedDates[0].startDate, "dd-MM-yyyy")} / ${format(selectedDates[0].endDate, "dd-MM-yyyy")}`;

  const handleDateRangeChange = (ranges) => {
    setSelectedDates([ranges.selection]);
    setDatePickerOpen(false);
  };

  const handleInputClick = () => {
    setDatePickerOpen(true);
  };

  const columns = useMemo(
    () => [
      {
        Header: "S.No",
        Cell: ({ row, rows }) => {
          return (
            <div  className="table-cell text-center">
              {rows.length - row.index}
            </div>
          );
        },
      },

      {
        Header: "Fuel Buy Date",
        accessor: "startDate",
        filterable: true,
        Cell: cellProps => {
          return (
            <div  className="table-cell text-center">
              <FuelBuyDate {...cellProps} />
            </div>
          );

        },
      },

      {
        Header: "Total Hours",
        accessor: "totalHours",
        filterable: true,
        Cell: cellProps => {
          return (
            <div  className="table-cell text-center">
                {cellProps.value}

            </div>
          );

        },
      },
    ],
  []);

  useEffect(() => {
    finalList();
  }, [])

  const finalList = () => {
    handleSearch(fromDate, toDate)
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const handleSearch = (startDate, endDate) => {
    if (!startDate || !endDate) return;
    const hotelId = hid.replace(/"/g, '');
    const formattedFromDate = formatDate(startDate);
    const formattedToDate = formatDate(endDate);
    getGenset(hotelId, formattedFromDate, formattedToDate , dbName)
        .then((res) => {
          setGensetList(res.data.data);
          // Calculate total hours
        const totalHours = res.data.data.reduce((acc, curr) => {
          return acc + parseFloat(curr.totalHours);
        }, 0);
        setTotalRunnableHours(totalHours);
      })
      .catch((err) => {
        console.error("Error fetching Genset data:", err);
      });
  };

  const clearFormValues = () => {
    validation.resetForm();
  };

  const toggle = () => {
    if (modal) {
      setModal(false);
      setGensetAccount(null);
      clearFormValues();
    } else {
      setModal(true);
    }
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = users => {
    setGensetAccount(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    deleteEbAccount(GensetAccount).then( finalList())
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleGensetDetailsClick = () => {
    setIsEdit(false);
    toggle();
  };

  const validation = useFormik({

    initialValues: {
      startDate: startDate || new Date(),
      totalHours: GensetAccount ? GensetAccount.totalHours : "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      startDate: Yup.date().required("Date and Time is required"),
      totalHours: Yup.string().required("Please Enter  Total Hours"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        // const updateGensetAccountData = { ...GensetAccount, ...values };
        // updateGensetAccount(updateGensetAccountData)
        //   .then((res) => {
        //     setResponse("GensetAccount Details Updated Sucessfully")
        //     setAlertModal(true)
        //   })
        //   .catch((err) => {
        //     setResponse("Failed to Update GensetAccount Details")
        //     setErrorAlertModal(true)
        //   });
        // toggle();
      } else {
        dispatch(post_Genset(values));
      }
    }
  });

  const post_Genset = (values) => {
    const data = JSON.stringify({
      startDate: values.startDate,
      totalHours: values.totalHours,
      hotelId: hid,
      dbName:dbName
    });
    addGensetDetails(data).then((res) => {
      if (res.data.success) {
        setResponse(messages.success.createdMessage(masterName))
        setAlertModal(true)
      }
    }).catch(err => {
      setResponse(messages.failed.createFailed(masterName))
      setErrorAlertModal(true)
    });
    toggle();
  };

  //last two digit fix function
  const formatToTwoDecimalPlaces = (value) => {
    const number = parseFloat(value);
    return isNaN(number) ? "" : number.toFixed(2);
  };

  const handleBlur = (event) => {
    validation.handleBlur(event);
    const formattedValue = formatToTwoDecimalPlaces(event.target.value);
    validation.setFieldValue(event.target.name, formattedValue);
  };

  const handleFromDateChange = (date) => {
    setFromDate(date);
  };

  const handleToDateChange = (date) => {
    setToDate(date);
  };

  useEffect(() => {
    if (fromDate && toDate) {
      handleSearch(fromDate, toDate);
    }
  }, []);

  const handleClickOutside = (event) => {
    if (node.current && !node.current.contains(event.target)) {
      setDatePickerOpen(false);
    }
  };

  useEffect(() => {
    if (datePickerOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [datePickerOpen]);

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <AlertModal
        show={alertModal}
        onOkClick={() => {
          finalList();
          setAlertModal(false);
        }}
        onCloseClick={() => setAlertModal(false)}
        res={response}
      />
      <ErrorAlertModal
       show={errorAlertModal}
       onOkClick={() =>{ finalList() ,setErrorAlertModal(false) }}
       onCloseClick={() => setErrorAlertModal(false)}
       res={response}
      />

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Utility" breadcrumbItem="Manage Generator Details" />
          <div className="mb-3">
          <Row>
              <Col lg="6" xs="12" md="6">
                <div className="input-group">
                  <div className="input-group-text">
                    <span className="input-group-prepand">From - To</span>
                  </div>
                  <input
                    type="text"
                    value={formattedDateRange}
                    readOnly
                    onClick={handleInputClick}
                    placeholder="Select Date Range"
                    className="form-control"
                  />
                  <label className="input-group-text" htmlFor="inputGroupFile01">
                    <FontAwesomeIcon icon={faCalendarAlt} />
                  </label>
                </div>

              </Col>
              <Col lg="6" xs="6" md="4" className="mt-3 mt-md-0  text-md-left">
                <button
                  className="btn btn-success"
                  onClick={() => handleSearch(selectedDates[0].startDate, selectedDates[0].endDate)}
                >
                  <span className="fas fa-search" /> Search
                </button>
              </Col>
            </Row>
            {datePickerOpen && (
          <div ref={node} className="date-range-picker-dropdown mt-1">
            <DateRangePicker
              onChange={handleDateRangeChange}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={2}
              ranges={selectedDates}
              direction="horizontal"
              className="date-range-picker-dropdown"

            />
          </div>
        )}
        </div>


          <Row className="mt-3">
            <Col lg="12">

                  <TableContainer
                    columns={columns}
                    data={GensetList}
                    isGlobalFilter={true}

                    isAddGensetDetailsList={true}
                    handleGensetDetailsClicks={handleGensetDetailsClick}
                    customPageSize={10}
                    className="custom-header-css"
                  />

                  <Modal  isOpen={modal} toggle={toggle} backdrop="static"  keyboard={false}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Edit Genset Details" : "Add Genset Details"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>
                            <div className="mb-3">
                              <h5 className="me-1 text-success text-center">
                                Total Runnable Hours: <span className="badge-soft-danger ms-1 badge bg-secondary rounded-pill">
                                {totalRunnableHours.toFixed(2)}</span>
                              </h5>
                              <Label className="form-label">Date and Time</Label>
                              <DatePicker
                                name="startDate"
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                className="form-control"
                                dateFormat="d-MM-yyyy h:mm aa"
                                placeholderText="Select date and time"
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={1}
                                value={validation.values.startDate || ""}
                                invalid={
                                  validation.touched.startDate &&
                                  validation.errors.startDate
                                  ? true
                                  : false
                                }
                              />
                              {validation.touched.startDate &&
                                validation.errors.startDate ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.startDate}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12}>
                            <div className="mb-3">
                              <Label className="form-label">Total Hours</Label>
                              <Input
                                name="totalHours"
                                type="text"
                                placeholder="Total Hours"
                                onChange={validation.handleChange}
                                onBlur={handleBlur}
                                value={validation.values.totalHours || ""}
                                invalid={
                                  validation.touched.totalHours &&
                                    validation.errors.totalHours
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.totalHours &&
                                validation.errors.totalHours ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.totalHours}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                          <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-primary save-user w-md me-3"
                              >
                                {!!isEdit ? "Update" : "Save"}
                              </button>
                              <button
                                type="button"
                                className="btn btn-secondary w-md "
                                onClick={toggle}
                              >
                                Cancel
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>

            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};


export default withRouter(GensetDetailMaster);
