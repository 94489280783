import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../../components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { addModules, getModules, updateModules ,deleteModules ,menuRead , getAllMenus} from "../../../middleware/backent_helper";
import  {ModuleName,Description, Menus, Status } from "./ModulesCol";
import Select from 'react-select';
import messages from '../../../utils/constants/messages';
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import DeleteModal from "../../../components/Common/DeleteModal";
import AlertModal from "../../../components/Common/alertModal";
import ErrorAlertModal from "../../../components/Common/errorAlertModal";
//redux
import { useSelector, useDispatch } from "react-redux";

const Modules = props => {
  //meta title
  document.title = "Modules | Masters ";

  const dispatch = useDispatch();
  const [ModuleData, setModuleData] = useState();
  const [ModuleList, setModuleList] = useState([]);
  const [MenuDataList, setMenuDataList] = useState([]);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [errorAlertModal, setErrorAlertModal] = useState(false);
  const [response, setResponse] = useState(false);
  const [masterName, setMasterName] = useState("Module");
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    if (isEdit && ModuleData && typeof ModuleData.isActive !== 'undefined') {
      setIsActive(ModuleData.isActive);
    }
  }, [isEdit, ModuleData]);

  const toggleactive = () => {
    const updatedIsActive = !isActive;
    setIsActive(updatedIsActive);
    validation.setFieldValue('isActive', updatedIsActive);
  };

  const handleCheckboxChange = () => {
    toggleactive();
  };

  const columns = useMemo(
    () => [
      {
        Header: "S.No",
        Cell: ({ row, rows }) => {
          return (
            <div className="table-cell text-center">
              {rows.length - row.index}
            </div>
          );
        },
      },

      {
        Header: "Module Name",
        accessor: "moduleName",
        filterable: true,
        Cell: cellProps => {
          return (
            <div className="table-cell text-center">
              <ModuleName {...cellProps} />
            </div>
          );
        },
      },
      {
        Header: "Description",
        accessor: "description",
        filterable: true,
        Cell: cellProps => {
          return (
            <div className="table-cell text-center">
              <Description {...cellProps} />
            </div>
          );
        },
      },

      {
        Header: "Menus",
        accessor: "menus",
        filterable: true,
        Cell: cellProps => {
          const menuList = cellProps.row.original.menus;
          return (
            <div className="table-cell text-center">
              {menuList ? menuList.join(", ") : ""}
            </div>
          );
        },
      },

      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="table-body actions">
              <div className="actions">
                <Link
                  to="#"
                  className="text-success"
                  onClick={() => {
                    const ModuleData = cellProps.row.original;
                    handleModuleDataClick(ModuleData);
                  }}
                >
                  <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </Link>
                <Link
                  to="#"
                  className="text-danger"
                  onClick={() => {
                    const userData = cellProps.row.original;
                    onClickDelete(userData);
                  }}
                >
                  <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </div>
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    getAllMenus()
      .then((res) => {
        setMenuDataList(res.data.data);
      });
  }, [])

  useEffect(() => {
    finalList()
  }, [])

  const finalList = () => {
    getModules()
      .then((res) => {
        setModuleList(res.data.data);
      });
  };

  const clearFormValues = () => {
    validation.resetForm();
  };

  const toggle = () => {
    if (modal) {
      setModal(false);
      setModuleData(null);
      clearFormValues();
    } else {
      setModal(true);
    }
  };

  const handleModuleDataClick = arg => {
    const module = arg;
    const transformedSubTest = module.menus.map((sub) => ({
      value: sub,
      label: sub,
    }));

    setModuleData({
      id: module.id,
      moduleName: module.moduleName,
      description: module.description,
      menus:transformedSubTest,
      isActive: module.isActive,
    });
    setIsEdit(true);
    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = users => {
    setModuleData(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    deleteModules(ModuleData)
    .then((res) => {
      getModules(), finalList(), clearFormValues()
      setResponse(messages.success.deleteDetails(masterName))
      setAlertModal(true)
    })
    .catch((err) => {
      if(err.response.status == 400) {
        setResponse(messages.failed.cannotbeDeleted(masterName))
      } else {
        setResponse(messages.failed.updateFailed(masterName))
      }
      setErrorAlertModal(true)
    });
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleModuleClick = () => {
    setIsEdit(false);
    setIsActive(true);
    toggle();
  };

  const validation = useFormik({
    initialValues: {
      moduleName: ModuleData ? ModuleData.moduleName : "",
      description: ModuleData ? ModuleData.description : "",
      menus: ModuleData ? ModuleData.menus.map((sub) => sub.value) : [],
      isActive: ModuleData ? ModuleData.isActive : "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      moduleName: Yup.string().required("Please Enter Your Name"),
      description: Yup.string().required("Please Enter Your Description"),
      isActive: Yup.boolean().oneOf([true, false], 'Please confirm if the user is active'),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updatedModuleData = { ...ModuleData, ...values };
        updateModules(updatedModuleData)
          .then((res) => {
            setResponse(messages.success.updatedDetails(masterName))
            setAlertModal(true)
          })
          .catch((err) => {
            if (err.response.status === 401) {
              setResponse(messages.failed.alreadyInUse(masterName))
            } else {
              setResponse(messages.failed.updateFailed(masterName))
            }
            setErrorAlertModal(true)
          });
        toggle();
      } else {
        dispatch(Module(values));
      }
    }
  });

  const Module = (values) => {
    const data = JSON.stringify({
      moduleName: values.moduleName,
      description: values.description,
      menus: values.menus,
      isActive: isActive,
    });
   addModules(data).then((res) => {
      if (res.data.success) {
        setResponse(messages.success.createdMessage(masterName))
        setAlertModal(true)
      }
    }).catch(err => {
      if(err.response.status === 404) {
        setResponse(messages.failed.AlreadyExists(masterName))
      } else {
        setResponse(messages.failed.createFailed(masterName))
      }
      setErrorAlertModal(true)
    });
    toggle();
  };

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => {setModuleData(null), setDeleteModal(false) }}
      />
      <AlertModal
        show={alertModal}
        onOkClick={() => {
          finalList();
          setAlertModal(false);
        }}
        onCloseClick={() => setAlertModal(false)}
        res={response}
      />
      <ErrorAlertModal
       show={errorAlertModal}
       onOkClick={() =>{ finalList() ,setErrorAlertModal(false) }}
       onCloseClick={() => setErrorAlertModal(false)}
       res={response}
      />

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Masters" breadcrumbItem="Modules" />
          <Row>
            <Col lg="12">
                  <TableContainer
                    columns={columns}
                    data={ModuleList}
                    isGlobalFilter={true}
                    isAddModuleList={true}
                    handleModuleClick={handleModuleClick}
                    customPageSize={10}
                    className="custom-header-css"
                  />

                  <Modal className="modal-lg" isOpen={modal} toggle={toggle} backdrop="static" keyboard={false}>
                    <ModalHeader toggle={toggle} tag="h4">
                     <h5 className="modal-title mt-0">
                     {!!isEdit ? "Edit Module" : "Add Module"}
                     </h5>
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>
                            <div className="mb-3">
                              <Label className="form-label">Module Name</Label>
                              <Input
                                name="moduleName"
                                type="text"
                                placeholder="Insert Name"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.moduleName || ""}
                                invalid={
                                  validation.touched.moduleName &&
                                    validation.errors.moduleName
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.moduleName &&
                                validation.errors.moduleName ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.moduleName}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <Row>
                              <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">Description</Label>
                              <Input
                                name="description"
                                type="text"
                                placeholder="Insert description"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.description || ""}
                                invalid={
                                  validation.touched.description &&
                                    validation.errors.description
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.description &&
                                validation.errors.description ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.description}
                                </FormFeedback>
                              ) : null}
                            </div>
                            </Col>
                            <Col md={6}>
                            <div className="mb-3" style={{ position: 'relative' }}>
                              <label htmlFor="testName" className="form-label">Menu </label>
                              <Select
                                id="menus"
                                name="menus"
                                options={MenuDataList.map(menu => ({ value: menu.menuName, label: menu.menuName }))}
                                placeholder="Select a menu"
                                isMulti
                                isClearable
                                isSearchable
                                classNamePrefix="custom-select"
                                value={validation.values.menus.map(menuu => ({ value: menuu, label: menuu }))}
                                onChange={(selectedOptions) => {
                                 validation.setFieldValue('menus', selectedOptions ? selectedOptions.map(option => option.value) : []);
                                 }}

                                styles={{
                                  menu: provided => ({ ...provided, top: '100%', position: 'absolute', width: '100%', zIndex: 999 }),
                                }}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.menus && validation.errors.menus
                                }

                              />
                              {validation.touched.menus && validation.errors.menus && (
                                <div className="text-danger">{validation.errors.menus}</div>
                              )}
                            </div>
                            </Col>
                            </Row>
                            <div className="form-check form-switch form-switch-lg mb-3">
                              <input
                                type="checkbox"
                                name="isActive"
                                className="form-check-input"
                                id="isActive"
                                checked={isActive}
                                onChange={handleCheckboxChange}
                                onClick={isEdit ? toggleactive : () => {}}
                              />
                              <label className="form-check-label" htmlFor="customSwitchsizemd">
                                {isActive ? 'Active' : 'Inactive'}
                              </label>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-primary save-user w-md me-3"
                              >
                                Save
                              </button>
                              <button
                                type="button"
                                className="btn btn-secondary w-md "
                                onClick={toggle}
                              >
                                Cancel
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>

            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};


export default withRouter(Modules);
