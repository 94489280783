import PropTypes from "prop-types";
import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom"
import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody, FormGroup, Label, Input, FormFeedback, InputGroup, Form
} from "reactstrap";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { roomTypeRead, getCountries, getStates, getCities, getRoomWithoutCheckin, getIdentity, getPaymentTypes, CreateReservation, GetMobilenoDetails, GetguestDetails } from "../../middleware/backent_helper";
import messages from '../../utils/constants/messages';
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//i18n
import { withTranslation } from "react-i18next";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

import ErrorAlertModal from "../../components/Common/errorAlertModal";
import Select from "react-select";
//Date Picker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "assets/scss/datatables.scss";
import { DateRangePicker } from "react-date-range";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import "../../assets/scss/roomTypeCard.scss"
import { faEdit } from "@fortawesome/free-solid-svg-icons";

const Create_Reservation = props => {

    const [currentTime, setCurrentTime] = useState(new Date());
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const [datePickerOpen, setDatePickerOpen] = useState(false);
    const [customers, setCustomers] = useState([]);
    const [showSideBar, setShowSideBar] = useState(false);
    const [subscribemodal, setSubscribemodal] = useState(false);
    const [countriesList, setCountries] = useState([]);
    const [proofTypes, setProofTypes] = useState([]);
    const [proofImages, setproofImages] = useState([]);
    const [selectedProofType, setselectedProofType] = useState('');
    const [selectedPayment, setselectedPayment] = useState('');
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [statesList, setStatesList] = useState([]);
    const [selectedState, setSelectedState] = useState('');
    const [getstateKey, setstateKey] = useState([])
    const [citiesList, setCitiesList] = useState([]);
    const [selectedCities, setSelectedCities] = useState([]);
    const [deleteModal, setDeleteModal] = useState(false);
    const [imageDeletion, setImageDeletion] = useState([]);
    const [alertModal, setAlertModal] = useState(false);
    const [errorAlertModal, setErrorAlertModal] = useState(false);
    const [response, setResponse] = useState(false);
    const [roomTypeList, setroomTypeList] = useState([]);
    const [selectedRoomType, setSelectedRoomType] = useState(null);
    const [paymentTypeList, setPaymentTypes] = useState([]);
    const [RoomList, setRoomList] = useState([]);
    const [selectedGender, setSelectedGender] = useState('');
    const [masterName, setMasterName] = useState("Guest");
    const [mobileSearch, setMobileSearch] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const [guestDetails, setGuestDetails] = useState(null);

    const [hid, setHid] = useState(() => {
        const storedHotelId = localStorage.getItem('hotelId')
        return storedHotelId ? storedHotelId.replace(/"/g, '') : '';
    });
    const [selectedNationality, setSelectedNationality] = useState('Indian');


    const navigate = useNavigate();
    const [dbName, setDbName] = useState(
        localStorage.getItem('dbName')
    )

    const handleClickOutside = (event) => {
        if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
            setDatePickerOpen(false);
        }
    };


    const handleCountryChange = (selectedOption) => {
        setSelectedCountry(selectedOption);
        if (selectedOption && selectedOption.label) {
            validation.setFieldValue('country', selectedOption.label);
        } else {
            validation.setFieldValue('country', '');
        }
    };

    // Fetch city when a state is selected
    useEffect(() => {
        if (selectedState && selectedState.label) {
            const fetchCities = async () => {
                try {
                    const stateKey = getStateKey(selectedState);
                    setstateKey(stateKey)

                    getCities(stateKey)
                        .then((res) => {
                            setCitiesList(res.data.data);
                            if (customers && customers.city) {
                                const foundCity = res.data.data.find(city => city.name === customers.city);
                                if (foundCity) {
                                    setSelectedCities({ label: foundCity.name, value: foundCity });
                                    validation.setFieldValue('city', foundCity.name);
                                }
                            }
                        })
                } catch (error) {
                    console.error('Error fetching city:', error);
                }
            };
            fetchCities();
        }
    }, [selectedState]);

    useEffect(() => {
        if (customers) {
            setproofImages(customers.proof_photo)
        }
        getIdentity(hid, dbName)
            .then((res) => {
                setProofTypes(res.data.data)
            })

        getPaymentTypes(hid, dbName)
            .then((res) => {
                setPaymentTypes(res.data.data);
            });

        if (selectedCountry != null) {
            const fetchStates = async () => {
                try {
                    const stateKey = getCountryKey(selectedCountry);
                    getStates(stateKey).then((res) => {
                        setStatesList(res.data.data);
                        if (customers && customers.state) {
                            const foundState = res.data.data.find(state => state.name === customers.state);
                            if (foundState) {
                                setSelectedState({ label: foundState.name, value: foundState });
                                validation.setFieldValue('state', foundState.name);
                            }
                        }
                    });
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            }
            fetchStates();
        }
    }, [selectedCountry, customers])

    const getStateKey = (stateName) => {
        const state = statesList.find(s => s.name === stateName.label);
        return state ? state.name : null;
    };

    const getCountryKey = (CountryKey) => {
        const state = countriesList.find(s => s.name === CountryKey.label);
        return state ? state.iso2 : null;
    }


    const handleStateChange = (selectedOption) => {
        setSelectedState(selectedOption)
        if (selectedOption && selectedOption.label) {
            validation.setFieldValue('state', selectedOption.label);
        } else {
            validation.setFieldValue('state', '');
        }
    }

    const handleCityChange = (selectedOption) => {
        setSelectedCities(selectedOption)
        if (selectedOption && selectedOption.label) {
            validation.setFieldValue('city', selectedOption.label);
        } else {
            validation.setFieldValue('city', '');
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    useEffect(() => {
        getRoomTypeList();



    }, [])

    const getRoomTypeList = () => {
        roomTypeRead(hid, dbName)
            .then((res) => {
                setroomTypeList(res.data.data);
                console.log("roomType", roomTypeList)
            });
    };

    const datePickerRef = useRef(null);
    const today = new Date();
    const [selectedDates, setSelectedDates] = useState([
        {
            startDate: today,
            endDate: today,
            key: "selection",
        },
    ]);
    const formattedDateRange = `${format(selectedDates[0].startDate, "dd-MM-yyyy")} / ${format(selectedDates[0].endDate, "dd-MM-yyyy")}`;

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        return `${year}-${month}-${day}`;
    };

    const DateTime = (date) => {

        const hours = ('0' + date.getHours()).slice(-2);
        const minutes = ('0' + date.getMinutes()).slice(-2);
        const seconds = ('0' + date.getSeconds()).slice(-2);
        return `${hours}:${minutes}:${seconds}`;
    };

    const genderOptions = [
        { value: 'Male', label: 'Male' },
        { value: 'Female', label: 'Female' }
    ];

    const NationalityOptions = [
        { value: 'Indian', label: 'Indian' },
        { value: 'Others', label: 'Others' }
    ];

    const handleProofTypeChange = selectedOption => {
        setselectedProofType(selectedOption)
        validation.setFieldValue('proofType', selectedOption ? selectedOption.value : '');

    }

    const handleGenderChange = selectedOption => {
        setSelectedGender(selectedOption);
        validation.setFieldValue('gender', selectedOption ? selectedOption.value : '');
    };

    const handlePaymentTypeChange = selectedOption => {
        setselectedPayment(selectedOption)
        validation.setFieldValue('payment', selectedOption ? selectedOption.value : '');

    }

    useEffect(() => {

        if (guestDetails && guestDetails.gender) {
    
          const matchedGenderOption = genderOptions.find(
            (option) => option.value === guestDetails.gender
          );
          setSelectedGender(matchedGenderOption || null);
          validation.setFieldValue('gender', guestDetails.gender);
        }
      }, [guestDetails]);
    
      useEffect(() => {
        if (guestDetails && guestDetails.IdentitiesId) {
          // Find the corresponding proof type based on identitiesId
          const selectedProof = proofTypes.find(
            (proof) => proof.id === guestDetails.IdentitiesId
    
          );
    
          if (selectedProof) {
            setselectedProofType({
              label: selectedProof.identityName,
              value: selectedProof.id,
            });
    
            validation.setFieldValue('proofType', selectedProof.id);
          }
        }
    
      }, [guestDetails, proofTypes]);
    
      useEffect(() => {
        if (guestDetails?.country) {
          const selectedCountry = countriesList.find(
            (country) => country.name === guestDetails.country
          );
      
          if (selectedCountry) {
            const countryOption = {
              label: selectedCountry.name,
              value: selectedCountry,
            };
      
            setSelectedCountry(countryOption);
      
            // Update the validation field value with the label of the selected country
            validation.setFieldValue('country', selectedCountry.name);
          } else {
            // If no country is found, you might want to handle this case
            setSelectedCountry(null);
            validation.setFieldValue('country', '');
          }
        }
      }, [guestDetails, countriesList]);
      
    
    // Handle State Selection
useEffect(() => {
    if (guestDetails?.state) {
      const selectedState = statesList.find(
        (state) => state.name === guestDetails.state
      );
  
      if (selectedState) {
        const stateOption = {
          label: selectedState.name,
          value: selectedState,
        };
  
        setSelectedState(stateOption);
  
        // Update the validation field value with the state's name
        validation.setFieldValue('state', selectedState.name);
      } else {
        // Handle the case where the state is not found
        setSelectedState(null);
        validation.setFieldValue('state', '');
      }
    }
  }, [guestDetails, statesList]);
  
  // Handle City Selection
  useEffect(() => {
    if (guestDetails?.city) {
      const selectedCity = citiesList.find(
        (city) => city.name === guestDetails.city
      );
  
      if (selectedCity) {
        const cityOption = {
          label: selectedCity.name,
          value: selectedCity,
        };
  
        setSelectedCities(cityOption);
  
        // Update the validation field value with the city's name
        validation.setFieldValue('city', selectedCity.name);
      } else {
        // Handle the case where the city is not found
        setSelectedCities(null);
        validation.setFieldValue('city', '');
      }
    }
  }, [guestDetails, citiesList]);
  

    useEffect(() => {
        getCountries()
            .then((res) => {
                setCountries(res.data.data)

                if (customers && customers.country) {
                    const foundCountry = res.data.data.find(country => country.name === customers.country);
                    if (foundCountry) {
                        setSelectedCountry({ label: foundCountry.name, value: foundCountry });
                        validation.setFieldValue('country', foundCountry.name);
                    }
                }
            })
    }, [])


   

    const handleNationality = selectedOption => {
        setSelectedNationality(selectedOption);
        validation.setFieldValue('nationality', selectedOption ? selectedOption.value : '');
    };

    //meta title
    document.title = "StayPro | Reservation";


    const [showInput, setShowInput] = useState({});
    const [count, setCount] = useState({});
    const [submittedCount, setSubmittedCount] = useState({});
    const [numRoom, setNumRoom] = useState(0);
    const [editingRoomTypeId, setEditingRoomTypeId] = useState(null);


    const handleCountClick = (id) => {
        setShowInput(prev => ({ ...prev, [id]: !prev[id] }));
    };

    const handleCountChange = (id, value) => {
        // Ensure the value is a number and valid
        const numericValue = value ? Math.max(0, Number(value)) : ''; // Allow empty value

        setCount(prev => {
            // Create a new count object
            const newCount = { ...prev };

            // Clear the count of the previously edited room type
            if (editingRoomTypeId !== null && editingRoomTypeId !== id) {
                newCount[editingRoomTypeId] = '';
            }
            setNumRoom(numericValue);
            // Set the count for the current room type
            newCount[id] = numericValue;

            return newCount;
        });

        setValidationErrors(prevErrors => ({
            ...prevErrors,
            [id]: ''
        }));

        // Update the current room type being edited
        setEditingRoomTypeId(id);
    };



    const handleSubmitCount = (id) => {

        const roomNo = count[id] || 0;
        setNumRoom(roomNo) // Default to 0 if not set
        setSubmittedCount(prevSubmittedCount => ({
            ...prevSubmittedCount,
            [id]: roomNo
        }));
        handleCountClick(id);
        setCount(prevCount => ({
            ...prevCount,
            [id]: ''
        }))

        setValidationErrors(prevErrors => ({
            ...prevErrors,
            [id]: ''
        }));// Hide the input after submission
    };




    const handleCancelButton = () => {
        setSelectedRoomTypeId(null);
        setCount(0);
        setShowForm(false)
    };

    const [showRoomType, setShowRoomType] = useState(false);
    const [showForm, setShowForm] = useState(false);


    const [selectedRoomTypeId, setSelectedRoomTypeId] = useState(null);



    useEffect(() => {

    }, [selectedRoomTypeId]);

    const [validationErrors, setValidationErrors] = useState({});

    const handleSelectButtonClick = (type) => {
        // Validate room count
        if (!count[type.id] || count[type.id] <= 0) {
            setValidationErrors(prevErrors => ({
                ...prevErrors,
                [type.id]: 'Choose the room count'
            }));
        } else {
            setValidationErrors(prevErrors => ({
                ...prevErrors,
                [type.id]: ''
            }));
            handleSelectCard(type); // Proceed with the selection
        }

    };

    const handleSelectCard = (roomType) => {
        setShowForm(true);
        setSelectedRoomTypeId((prevSelectedId) => {
            // If the same room type is clicked, deselect it
            if (prevSelectedId === roomType.id) {
                return null;
            }
            // Otherwise, select the new room type
            return roomType.id;
        });
    };

    const handleMobileSearch = async (e) => {
        const re = /^[0-9\b]+$/;
        const value = e.target.value;

        if (value === "" || re.test(value)) {
            validation.handleChange(e);
            setMobileSearch(value);

            if (value.length >= 3) {

                try {
                    GetMobilenoDetails(hid, dbName, value).then((res) => {


                        setSuggestions(res.data.data);
                    });

                } catch (error) {
                    console.error("Error fetching mobile numbers:", error);
                }
            } else {
                setSuggestions([]); // Clear suggestions if less than 3 characters
            }
        }
    };



    const handleSuggestionClick = async (mobileNo) => {

        setMobileSearch(mobileNo);
        validation.setFieldValue('mobile_no', mobileNo ? mobileNo : '');

        // Set selected mobile number in the input
        setSuggestions([]); // Clear suggestions

        // Fetch guest details by the selected mobile number
        try {
            GetguestDetails(hid, dbName, mobileNo).then((res) => {
                const data = res.data.data[0]
                setGuestDetails(data);
                console.log("api data", guestDetails)

                validation.setFieldValue('guest_name', data.GuestName || '');
                validation.setFieldValue('email', data.email || '');
                validation.setFieldValue('age', data.age || '');
                validation.setFieldValue('address', data.address || '');
                validation.setFieldValue('proof_no', data.identityNo || '');
                validation.setFieldValue('vehicle_no', data.vehicleNo || '');
                validation.setFieldValue('gst_no', data.taxNumber || '');
                validation.setFieldValue('company_name', data.companyName || '');
                validation.setFieldValue('wifi', data.wifi || '');
                validation.setFieldValue('nationality', data.nationality || '');
                validation.setFieldValue('pincode', data.pincode || '');

            });

        } catch (error) {
            console.error("Error fetching guest details:", error);
        }
    };

    const validation = useFormik({
        initialValues: {
            guest_name: customers ? customers.guest_name : "",
            mobile_no: customers ? customers.mobile_no : "",
            // gender: customers ? customers.gender : "",
            nationality: "Indian",
            proof_type: customers ? customers.proof_type : "",
            country: "",
            state: "",
            city: "",
            proofType: customers ? customers.proofType : "",
          
            vehicle_no: customers ? customers.vehicle_no : "",
            address: customers ? customers.address : "",
            email: customers ? customers.email : "",
            company_name: customers ? customers.company_name : "",
            pincode: customers ? customers.pincode : "",
            age: customers ? customers.age : "",
            payment: customers ? customers.paymentType : "",
            advance: customers ? customers.advance : ""
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            guest_name: Yup.string().required("Please Enter Your Name"),
            mobile_no: Yup.string()
                .matches(/^[0-9]{10}$/, 'Mobile number must be exactly 10 digits')
                .required('Please Enter Your Mobile No'),
            country: Yup.string().nullable().required('Country is required'),
            state: Yup.string().required('State is required'),
            payment: Yup.string().required('payment type is required'),
            city: Yup.string().required('City is required'),
            address: Yup.string().required('Please Enter Your Address').max(50, 'Address must not exceed 50 characters'),
            pincode: Yup.string().matches(/^\d+$/, 'Invalid number format').required('Please Enter Your pincode'),
            age: Yup.string().matches(/^\d+$/, 'Invalid number format').required('Please Enter Your Age'),
            country: Yup.string().required('Country is required'),
            advance: Yup.string().required("Please Enter Your Advance Amount"),
            proofType: Yup.string().required("Proof Type is required"),
            gender: Yup.string().required("Gender is required"),
        }),
        onSubmit: (values) => {
            post_Reservation(values)
            validation.resetForm();
        }
    });

    const handleDateRangeChange = (ranges) => {
        setSelectedDates([ranges.selection]);
        setDatePickerOpen(false);
        setFromDate(ranges.selection.startDate)
        setToDate(ranges.selection.endDate)
        // handleSearch(ranges.selection.startDate, ranges.selection.endDate);


    };

    const clearFormValues = () => {
        validation.resetForm()
    }

    const handleInputClick = () => {
        setDatePickerOpen(true);
        clearFormValues();
    };

    const handleRoomChange = selectedOption => {
        setSelectedRoomType(selectedOption);
        validation.setFieldValue('roomTypeId', selectedOption ? selectedOption.value : '');
    };

    const post_Reservation = async (values) => {

        handleSearch();
        const data = JSON.stringify({

            GuestName: values.guest_name,
            mobileNo: values.mobile_no,
            gender: values.gender,
            address: values.address,
            country: values.country,
            state: values.state,
            city: values.city,
            pincode: values.pincode,
            nationality: values.selectedNationality,
            age: values.age,
            email: values.email,
            IdentitiesId: values.proofType,
            identityNo: values.proof_no,
            vehicleNo: values.vehicle_no,
            companyName: values.company_name,
            checkInDate: fromDate,
            checkOutDate: toDate,
            checkInTime: DateTime(currentTime),
            advanceAmount: values.advance,
            Payment_typesId: values.payment,
            remarks: values.remarks,
            Room_typesId: selectedRoomTypeId,
            roomCount: numRoom,
            hotelId: hid,
            db: dbName
        });

        CreateReservation(data).then((res) => {
            if (res.data.success) {
                navigate('/reservation-view');
            }
        }).catch(err => {
            if (err.response.status === 404) {
                setResponse(messages.failed.AlreadyExists(masterName))
            }
            setShowForm(false)
            setErrorAlertModal(true)
        });

    }

    const handleSearch = (startDate, endDate) => {
        setShowRoomType(true)
        if (!startDate || !endDate) return;
        setFromDate(formatDate(startDate));
        setToDate(formatDate(endDate));
        console.log("Dates", fromDate, toDate)
        getRoomWithoutCheckin(hid, dbName, fromDate, toDate)
            .then((res) => {
                console.log("responseuh", res.data.data)
                setRoomList(res.data.data);
            });


    };

    const popupRef = useRef(null);

    // Handle click outside of popup to close it
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                Object.keys(showInput).forEach(id => {
                    if (showInput[id]) handleCountClick(id); // Close popup
                });
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [showInput, handleCountClick]);

    return (
        <React.Fragment>
            <ErrorAlertModal
                show={errorAlertModal}
                onOkClick={() => { getRoomTypeList(), setErrorAlertModal(false) }}
                onCloseClick={() => setErrorAlertModal(false)}
                res={response}
            />
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs breadcrumbItem={props.t("Reservation Booking")} />
                    <Row>
                        <Col xl="12">
                            <div className="mb-3">
                                <Row>
                                    <Col lg="6" xs="12" md="6">
                                        <div className="input-group">
                                            <div className="input-group-text">
                                                <span className="input-group-prepand">Check In / Out</span>
                                            </div>
                                            <input
                                                type="text"
                                                value={formattedDateRange}
                                                readOnly
                                                onClick={handleInputClick}
                                                placeholder="Select Date Range"
                                                className="form-control"
                                            />
                                            <label className="input-group-text" htmlFor="inputGroupFile01">
                                                <FontAwesomeIcon icon={faCalendarAlt} />
                                            </label>
                                        </div>

                                    </Col>
                                    <Col lg="6" xs="6" md="4" className="mt-3 mt-md-0  text-md-left">
                                        <button
                                            className="btn btn-success"
                                            onClick={() => handleSearch(selectedDates[0].startDate, selectedDates[0].endDate)}
                                        >
                                            <span className="fas fa-search" /> Search
                                        </button>
                                    </Col>
                                </Row>
                                {datePickerOpen && (
                                    <div ref={datePickerRef} className="date-range-picker-dropdowns mt-1">
                                        <DateRangePicker
                                            onChange={handleDateRangeChange}
                                            showSelectionPreview={true}
                                            moveRangeOnFirstSelection={false}
                                            months={2}
                                            ranges={selectedDates}
                                            direction="horizontal"
                                            className="date-range-picker-dropdowns"

                                        />
                                    </div>
                                )}
                            </div>
                            {showRoomType && (
                                <CardBody className="room-type-container">
                                    <Row>
                                        <Col xs={12}>
                                            <div className="room-type-cards">
                                                {Array.from(new Set(RoomList.map(type => type.title))).map(title => {
                                                    const filteredTypes = RoomList.filter(type => type.title === title);
                                                    const type = filteredTypes[0]; // Get the first occurrence of the room type

                                                    // Check if the room type ID is selected
                                                    const isSelected = selectedRoomTypeId === type.id;
                                                    const errorMsg = validationErrors[type.id];

                                                    return (
                                                        <div
                                                            key={type.id}
                                                            className={`room-type-card ${isSelected ? 'selected' : ''}`}
                                                        >
                                                            <div className="card-headerss">
                                                                <div className="card-titleNEw">
                                                                    <h3 className="room-type-title">{type.title.toUpperCase()}</h3>
                                                                </div>
                                                            </div>
                                                            <h3 className="room-type-tariff">{type.tariff}</h3>

                                                            <div className="quantity-container text-count">
                                                                <h5 className="room_count">Room count :</h5>
                                                                <input
                                                                    type="number"
                                                                    min="1"
                                                                    value={count[type.id] || ''}
                                                                    onChange={(e) => handleCountChange(type.id, e.target.value)}
                                                                    onFocus={() => handleFocus(type.id)}
                                                                    onBlur={() => handleBlur(type.id)}
                                                                    placeholder="Room count"
                                                                    className="count-input"
                                                                />
                                                                {errorMsg && (
                                                                    <div className="validation-error">
                                                                        {errorMsg}
                                                                    </div>
                                                                )}
                                                            </div>

                                                            <button
                                                                onClick={() => handleSelectButtonClick(type)}
                                                                className={`btn-select ${isSelected ? 'selected' : ''}`}
                                                            >
                                                                {isSelected ? 'Selected' : 'Select'}
                                                            </button>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            )}

                        </Col>
                    </Row>
                    {showForm && (
                        <Row>
                            <div className="right-bar big" id="right-bar">
                                <Form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        validation.handleSubmit();
                                        return false;
                                    }}
                                >
                                    <Row>
                                        <Col xl="12">
                                            <Card style={{ border: '1px solid rgb(210 212 218)', borderRadius: '8px' }}>
                                                <CardHeader>
                                                    <h5 className="card-title">{props.t("Guest Reservation Form")}</h5>
                                                </CardHeader>
                                                <CardBody>
                                                    <Row>
                                                        <Col lg={4}>
                                                            <div className="mb-3">
                                                                <FormGroup>
                                                                    <Label className="form-label">Guest Name</Label>
                                                                    <Input
                                                                        name="guest_name"
                                                                        type="text"
                                                                        placeholder="Name"
                                                                        onChange={validation.handleChange}
                                                                        onBlur={validation.handleBlur}
                                                                        value={validation.values.guest_name || ""}
                                                                        invalid={
                                                                            validation.touched.guest_name &&
                                                                                validation.errors.guest_name
                                                                                ? true
                                                                                : false
                                                                        }
                                                                    />
                                                                    {validation.touched.guest_name &&
                                                                        validation.errors.guest_name ? (
                                                                        <FormFeedback type="invalid">
                                                                            {validation.errors.guest_name}
                                                                        </FormFeedback>
                                                                    ) : null}
                                                                </FormGroup>
                                                            </div>
                                                        </Col>
                                                        <Col lg={4}>
                                                            <div className="mb-3">
                                                                <FormGroup>
                                                                    <Label className="form-label">Mobile No</Label>
                                                                    <Input
                                                                        name="mobile_no"
                                                                        type="text"
                                                                        placeholder="MobileNo"
                                                                        onChange={handleMobileSearch}
                                                                        // onChange={(e) => {
                                                                        //     const re = /^[0-9\b]+$/;
                                                                        //     if (e.target.value === '' || re.test(e.target.value)) {
                                                                        //         validation.handleChange(e);
                                                                        //     }
                                                                        // }}
                                                                        onBlur={validation.handleBlur}
                                                                        value={validation.values.mobile_no || ""}
                                                                        invalid={
                                                                            validation.touched.mobile_no &&
                                                                                validation.errors.mobile_no
                                                                                ? true
                                                                                : false
                                                                        }
                                                                        maxLength={10}
                                                                    />
                                                                    {validation.touched.mobile_no &&
                                                                        validation.errors.mobile_no ? (
                                                                        <FormFeedback type="invalid">
                                                                            {validation.errors.mobile_no}
                                                                        </FormFeedback>
                                                                    ) : null}
                                                                    {suggestions.length > 0 && (
                                                                        <ul className="suggestions-dropdown">
                                                                            {suggestions.map((suggestion, index) => (
                                                                                <li key={index} onClick={() => handleSuggestionClick(suggestion.mobileNo)}>
                                                                                    {suggestion.mobileNo}
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    )}
                                                                </FormGroup>
                                                            </div>
                                                        </Col>
                                                        <Col lg={4}>
                                                            <div className="mb-3">
                                                                <FormGroup>
                                                                    <Label className="form-label">Email</Label>
                                                                    <Input
                                                                        name="email"
                                                                        type="text"
                                                                        placeholder="Email"
                                                                        onChange={validation.handleChange}
                                                                        onBlur={validation.handleBlur}
                                                                        value={validation.values.email || ""}
                                                                        invalid={
                                                                            validation.touched.email &&
                                                                                validation.errors.email
                                                                                ? true
                                                                                : false
                                                                        }
                                                                    />
                                                                    {validation.touched.email &&
                                                                        validation.errors.email ? (
                                                                        <FormFeedback type="invalid">
                                                                            {validation.errors.email}
                                                                        </FormFeedback>
                                                                    ) : null}
                                                                </FormGroup>
                                                            </div>

                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg={4}>
                                                            <div className="mb-3">
                                                                <FormGroup>
                                                                    <Label className="form-label">Gender</Label>
                                                                    <Select
                                                                        name="gender"
                                                                      value={selectedGender}
                                                                        // value={genderOptions.find(option => option.value === selectedGender)}
                                                                        onChange={handleGenderChange}
                                                                        placeholder="Select Gender"
                                                                        classNamePrefix="react-select"
                                                                        className={`react-select-container ${validation.touched.gender && validation.errors.gender
                                                                            ? 'is-invalid'
                                                                            : ''
                                                                            }`}
                                                                        options={genderOptions.map((gender) => ({
                                                                            label: gender.label,
                                                                            value: gender.value,
                                                                        }))}

                                                                        styles={{
                                                                            menu: (provided) => ({
                                                                                ...provided,
                                                                                top: "100%",
                                                                                position: "absolute",
                                                                                width: "100%",
                                                                                zIndex: 999,
                                                                            }),
                                                                        }}
                                                                    />
                                                                    {validation.touched.gender && validation.errors.gender ? (
                                                                        <FormFeedback type="invalid">
                                                                            {validation.errors.gender}
                                                                        </FormFeedback>
                                                                    ) : null}
                                                                </FormGroup>
                                                            </div>
                                                        </Col>
                                                        <Col lg={4}>
                                                            <div className="mb-3">
                                                                <FormGroup>
                                                                    <Label className="form-label">Age</Label>
                                                                    <Input
                                                                        name="age"
                                                                        type="text"
                                                                        placeholder="Age"
                                                                        onChange={validation.handleChange}
                                                                        onBlur={validation.handleBlur}
                                                                        value={validation.values.age || ""}
                                                                        invalid={
                                                                            validation.touched.age &&
                                                                                validation.errors.age
                                                                                ? true
                                                                                : false
                                                                        }
                                                                    />
                                                                    {validation.touched.age &&
                                                                        validation.errors.age ? (
                                                                        <FormFeedback type="invalid">
                                                                            {validation.errors.age}
                                                                        </FormFeedback>
                                                                    ) : null}
                                                                </FormGroup>
                                                            </div>

                                                        </Col>
                                                        <Col lg={4}>
                                                            <div className="mb-3">
                                                                <FormGroup>
                                                                    <Label className="form-label">Address</Label>
                                                                    <Input
                                                                        name="address"
                                                                        type="text"
                                                                        placeholder="Address"
                                                                        onChange={validation.handleChange}
                                                                        onBlur={validation.handleBlur}
                                                                        value={validation.values.address || ""}
                                                                        invalid={
                                                                            validation.touched.address &&
                                                                                validation.errors.address
                                                                                ? true
                                                                                : false
                                                                        }
                                                                    />
                                                                    {validation.touched.address &&
                                                                        validation.errors.address ? (
                                                                        <FormFeedback type="invalid">
                                                                            {validation.errors.address}
                                                                        </FormFeedback>
                                                                    ) : null}
                                                                </FormGroup>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg={4}>
                                                            <div className="mb-3">
                                                                <FormGroup>
                                                                    <Label className="form-label">Country</Label>
                                                                    <Select
                                                                        name="country"
                                                                        placeholder="Select Country"
                                                                        classNamePrefix="react-select"
                                                                        className={`react-select-container ${validation.touched.country && validation.errors.country
                                                                            ? 'is-invalid'
                                                                            : ''
                                                                            }`}
                                                                        options={countriesList.map((country) => ({
                                                                            label: country.name,
                                                                            value: country,
                                                                        }))}
                                                                        value={selectedCountry}
                                                                        onChange={handleCountryChange}
                                                                        isClearable
                                                                        isSearchable
                                                                        styles={{
                                                                            menu: (provided) => ({
                                                                                ...provided,
                                                                                top: "100%",
                                                                                position: "absolute",
                                                                                width: "100%",
                                                                                zIndex: 999,
                                                                            }),
                                                                        }}
                                                                    />
                                                                    {validation.touched.country &&
                                                                        validation.errors.country ? (
                                                                        <FormFeedback type="invalid">
                                                                            {validation.errors.country}
                                                                        </FormFeedback>
                                                                    ) : null}
                                                                </FormGroup>
                                                            </div>
                                                        </Col>
                                                        <Col lg={4}>
                                                            <div className="mb-3">
                                                                <FormGroup>
                                                                    <Label className="form-label">State</Label>
                                                                    <Select
                                                                        name="state"
                                                                        placeholder="Select State"
                                                                        classNamePrefix="react-select"
                                                                        className={`react-select-container ${validation.touched.state && validation.errors.state
                                                                            ? 'is-invalid'
                                                                            : ''
                                                                            }`}
                                                                        value={selectedState}
                                                                        onChange={handleStateChange}
                                                                        options={statesList.map((state) => ({
                                                                            label: state.name,
                                                                            value: state
                                                                        }))}
                                                                        isClearable
                                                                        isSearchable
                                                                        styles={{
                                                                            menu: (provided) => ({
                                                                                ...provided,
                                                                                top: "100%",
                                                                                position: "absolute",
                                                                                width: "100%",
                                                                                zIndex: 999,
                                                                            }),
                                                                        }}
                                                                    />
                                                                    {validation.touched.state &&
                                                                        validation.errors.state ? (
                                                                        <FormFeedback type="invalid">
                                                                            {validation.errors.state}
                                                                        </FormFeedback>
                                                                    ) : null}
                                                                </FormGroup>
                                                            </div>

                                                        </Col>
                                                        <Col lg={4}>
                                                            <div className="mb-3">
                                                                <FormGroup>
                                                                    <Label className="form-label">City</Label>
                                                                    <Select
                                                                        name="city"
                                                                        placeholder="Select City"
                                                                        className={`react-select-containe ${validation.touched.city && validation.errors.city
                                                                            ? 'is-invalid'
                                                                            : ''
                                                                            }`}
                                                                        value={selectedCities}
                                                                        onChange={handleCityChange}
                                                                        options={citiesList.map((city) => ({
                                                                            label: city.name,
                                                                            value: city
                                                                        }))}
                                                                        isClearable
                                                                        isSearchable
                                                                        styles={{
                                                                            menu: (provided) => ({
                                                                                ...provided,
                                                                                top: "100%",
                                                                                position: "absolute",
                                                                                width: "100%",
                                                                                zIndex: 999,
                                                                            }),
                                                                        }}
                                                                    />
                                                                    {validation.touched.city &&
                                                                        validation.errors.city ? (
                                                                        <FormFeedback type="invalid">
                                                                            {validation.errors.city}
                                                                        </FormFeedback>
                                                                    ) : null}
                                                                </FormGroup>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg={4}>
                                                            <div className="mb-3">
                                                                <FormGroup>
                                                                    <Label className="form-label">Nationality</Label>
                                                                    <Select
                                                                        name="nationality"
                                                                        // value={selectedNationality}
                                                                        value={NationalityOptions.find(option => option.value === selectedNationality)}
                                                                        onChange={handleNationality}
                                                                        placeholder="Select Nationality"
                                                                        classNamePrefix="react-select"
                                                                        className={`react-select-container ${validation.touched.nationality && validation.errors.nationality
                                                                            ? 'is-invalid'
                                                                            : ''
                                                                            }`}
                                                                        options={NationalityOptions.map((nationality) => ({
                                                                            label: nationality.label,
                                                                            value: nationality.value,
                                                                        }))}

                                                                        styles={{
                                                                            menu: (provided) => ({
                                                                                ...provided,
                                                                                top: "100%",
                                                                                position: "absolute",
                                                                                width: "100%",
                                                                                zIndex: 999,
                                                                            }),
                                                                        }}
                                                                    />
                                                                    {validation.touched.nationality && validation.errors.nationality ? (
                                                                        <FormFeedback type="invalid">
                                                                            {validation.errors.nationality}
                                                                        </FormFeedback>
                                                                    ) : null}
                                                                </FormGroup>
                                                            </div>
                                                        </Col>
                                                        <Col lg={4}>
                                                            <div className="mb-3">
                                                                <FormGroup>
                                                                    <Label className="form-label">Pin Code</Label>
                                                                    <Input
                                                                        name="pincode"
                                                                        type="text"
                                                                        placeholder="Pin Code"
                                                                        onChange={(e) => {
                                                                            const re = /^[0-9\b]+$/;
                                                                            if (e.target.value === '' || re.test(e.target.value)) {
                                                                                validation.handleChange(e);
                                                                            }
                                                                        }}
                                                                        onBlur={validation.handleBlur}
                                                                        value={validation.values.pincode || ""}
                                                                        invalid={
                                                                            validation.touched.pincode &&
                                                                                validation.errors.pincode
                                                                                ? true
                                                                                : false
                                                                        }
                                                                        maxLength={6}
                                                                    />
                                                                    {validation.touched.pincode &&
                                                                        validation.errors.pincode ? (
                                                                        <FormFeedback type="invalid">
                                                                            {validation.errors.pincode}
                                                                        </FormFeedback>
                                                                    ) : null}
                                                                </FormGroup>
                                                            </div>
                                                        </Col>
                                                        <Col lg={4}>
                                                            <div className="mb-3">
                                                                <FormGroup>
                                                                    <Label className="form-label">Company Name</Label>
                                                                    <Input
                                                                        name="company_name"
                                                                        type="text"
                                                                        placeholder="Company Name"
                                                                        onChange={validation.handleChange}
                                                                        onBlur={validation.handleBlur}
                                                                        value={validation.values.company_name || ""}
                                                                        invalid={
                                                                            validation.touched.company_name &&
                                                                                validation.errors.company_name
                                                                                ? true
                                                                                : false
                                                                        }
                                                                    />
                                                                    {validation.touched.company_name &&
                                                                        validation.errors.company_name ? (
                                                                        <FormFeedback type="invalid">
                                                                            {validation.errors.company_name}
                                                                        </FormFeedback>
                                                                    ) : null}
                                                                </FormGroup>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg={4}>
                                                            <div className="mb-3">
                                                                <FormGroup>
                                                                    <Label className="form-label">Vehicle No.</Label>
                                                                    <Input
                                                                        name="vehicle_no"
                                                                        type="text"
                                                                        placeholder="Vehicle No"
                                                                        onChange={(e) => {
                                                                            e.target.value = e.target.value.toUpperCase(); // Convert to uppercase
                                                                            validation.handleChange(e); // Handle the change with Formik
                                                                        }}
                                                                        onBlur={validation.handleBlur}
                                                                        value={validation.values.vehicle_no || ""}
                                                                        invalid={
                                                                            validation.touched.vehicle_no &&
                                                                                validation.errors.vehicle_no
                                                                                ? true
                                                                                : false
                                                                        }
                                                                    />
                                                                    {validation.touched.vehicle_no &&
                                                                        validation.errors.vehicle_no ? (
                                                                        <FormFeedback type="invalid">
                                                                            {validation.errors.vehicle_no}
                                                                        </FormFeedback>
                                                                    ) : null}
                                                                </FormGroup>
                                                            </div>
                                                        </Col>
                                                        <Col lg={4}>
                                                            <div className="mb-3">
                                                                <FormGroup>
                                                                    <Label className="form-label">Proof Type</Label>
                                                                    <Select
                                                                        name="proofType"
                                                                        placeholder="Select Proof Type"
                                                                        classNamePrefix="react-select"
                                                                        className={`react-select-container ${validation.touched.proofType && validation.errors.proofType
                                                                            ? 'is-invalid'
                                                                            : ''
                                                                            }`}
                                                                        options={proofTypes.map((proof) => ({
                                                                            label: proof.identityName,
                                                                            value: proof.id,
                                                                        }))}
                                                                        value={selectedProofType}
                                                                        onChange={handleProofTypeChange}

                                                                        styles={{
                                                                            menu: (provided) => ({
                                                                                ...provided,
                                                                                top: "100%",
                                                                                position: "absolute",
                                                                                width: "100%",
                                                                                zIndex: 999,
                                                                            }),
                                                                        }}
                                                                    />
                                                                    {validation.touched.proofType &&
                                                                        validation.errors.proofType ? (
                                                                        <FormFeedback type="invalid">
                                                                            {validation.errors.proofType}
                                                                        </FormFeedback>
                                                                    ) : null}
                                                                </FormGroup>
                                                            </div>
                                                        </Col>
                                                        <Col lg={4}>
                                                            <div className="mb-3">
                                                                <FormGroup>
                                                                    <Label className="form-label">Proof No.</Label>
                                                                    <Input
                                                                        name="proof_no"
                                                                        type="text"
                                                                        placeholder={"Proof Number"}
                                                                        onChange={(e) => {
                                                                            e.target.value = e.target.value.toUpperCase(); // Convert to uppercase
                                                                            validation.handleChange(e); // Handle the change with Formik
                                                                        }}
                                                                        onBlur={validation.handleBlur}
                                                                        value={validation.values.proof_no || ""}
                                                                        invalid={
                                                                            validation.touched.proof_no &&
                                                                                validation.errors.proof_no
                                                                                ? true
                                                                                : false
                                                                        }
                                                                    />
                                                                    {validation.touched.proof_no &&
                                                                        validation.errors.proof_no ? (
                                                                        <FormFeedback type="invalid">
                                                                            {validation.errors.proof_no}
                                                                        </FormFeedback>
                                                                    ) : null}
                                                                </FormGroup>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl="12">
                                            <Card style={{ border: '1px solid rgb(210 212 218)', borderRadius: '8px' }}>
                                                <CardHeader>
                                                    <h5 className="card-title">{props.t("Check In Info")}</h5>
                                                </CardHeader>
                                                <CardBody>
                                                    <Row>
                                                        <Col lg={4}>
                                                            <div className="mb-3">
                                                                <FormGroup>
                                                                    <Label className="form-label">Check-In Time</Label>
                                                                    <InputGroup>
                                                                        <Flatpickr
                                                                            className="form-control d-block"
                                                                            placeholder="Select time"
                                                                            options={{
                                                                                enableTime: true,
                                                                                noCalendar: true,
                                                                                dateFormat: "H:i",
                                                                                time_24hr: true
                                                                            }}
                                                                            value={currentTime || ""}
                                                                        />
                                                                        <div className="input-group-append">
                                                                            <span className="input-group-text">
                                                                                <i className="mdi mdi-clock-outline" />
                                                                            </span>
                                                                        </div>
                                                                    </InputGroup>
                                                                </FormGroup>
                                                            </div>
                                                        </Col>
                                                        <Col lg={4}>
                                                            <div className="mb-3">
                                                                <FormGroup>
                                                                    <Label className="form-label">Advance Amount</Label>
                                                                    <Input
                                                                        name="advance"
                                                                        type="number"
                                                                        placeholder="Advance"
                                                                        onChange={validation.handleChange}
                                                                        onBlur={validation.handleBlur}
                                                                        value={validation.values.advance || ""}
                                                                        invalid={
                                                                            validation.touched.advance &&
                                                                                validation.errors.advance
                                                                                ? true
                                                                                : false
                                                                        }
                                                                    />
                                                                    {validation.touched.advance &&
                                                                        validation.errors.advance ? (
                                                                        <FormFeedback type="invalid">
                                                                            {validation.errors.advance}
                                                                        </FormFeedback>
                                                                    ) : null}
                                                                </FormGroup>
                                                            </div>
                                                        </Col>
                                                        <Col lg={4}>
                                                            <div className="mb-3">
                                                                <FormGroup>
                                                                    <Label className="form-label">Payment Type</Label>
                                                                    <Select
                                                                        name="payment"
                                                                        placeholder="Select Payment Type"
                                                                        className={`react-select-containe ${validation.touched.payment && validation.errors.payment
                                                                            ? 'is-invalid'
                                                                            : ''
                                                                            }`}
                                                                        value={selectedPayment}
                                                                        onChange={handlePaymentTypeChange}
                                                                        options={paymentTypeList.map((payment) => ({
                                                                            label: payment.paymentType,
                                                                            value: payment.id
                                                                        }))}
                                                                        isClearable
                                                                        isSearchable
                                                                        styles={{
                                                                            menu: (provided) => ({
                                                                                ...provided,
                                                                                top: "100%",
                                                                                position: "absolute",
                                                                                width: "100%",
                                                                                zIndex: 999,
                                                                            }),
                                                                        }}
                                                                    />
                                                                    {validation.touched.payment &&
                                                                        validation.errors.payment ? (
                                                                        <FormFeedback type="invalid">
                                                                            {validation.errors.payment}
                                                                        </FormFeedback>
                                                                    ) : null}
                                                                </FormGroup>
                                                            </div>

                                                        </Col>
                                                        <Col lg={12}>
                                                            <div className="mb-3">
                                                                <FormGroup>
                                                                    <Label className="form-label">Remarks</Label>
                                                                    <Input
                                                                        name="remarks"
                                                                        type="text"
                                                                        placeholder="Remarks"
                                                                        onChange={validation.handleChange}
                                                                        onBlur={validation.handleBlur}
                                                                        value={validation.values.remarks || ""}
                                                                        invalid={
                                                                            validation.touched.remarks &&
                                                                                validation.errors.remarks
                                                                                ? true
                                                                                : false
                                                                        }
                                                                    />
                                                                    {validation.touched.remarks &&
                                                                        validation.errors.remarks ? (
                                                                        <FormFeedback type="invalid">
                                                                            {validation.errors.remarks}
                                                                        </FormFeedback>
                                                                    ) : null}
                                                                </FormGroup>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <div className="text-end">
                                                                <button type="submit" className="btn btn-primary w-md me-3">
                                                                    Block
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-secondary w-md "
                                                                    onClick={handleCancelButton}
                                                                >
                                                                    Cancel
                                                                </button>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </Row>
                    )}
                </Container>
            </div>
        </React.Fragment>
    )
};

Create_Reservation.propTypes = {
    t: PropTypes.any,
    chartsData: PropTypes.any,
};

export default withTranslation()(Create_Reservation);
