import React from "react";
import { Container, Row, Col, Card, CardBody, CardTitle, CardText, Button } from "reactstrap";


const PlanDetails = () => {
  return (
    <div className="account-pages my-5 pt-sm-5">
      <Container>
        <Row className="justify-content-center">
          <Col md={10}>
            <div className="text-center mb-5">
              <h1 className="display-4 font-weight-bold" style={{ fontFamily: "Arial, sans-serif" }}>Choose Your Plan</h1>
              <p className="text-muted">Select the plan that best suits your needs</p>
            </div>
            <Row className="justify-content-center">
              <Col md={6} lg={4} className="mb-4">
                <Card 
                  className="text-center card-hover" 
                  style={{ 
                    border: "1px solid lightgray", 
                    transition: "all 0.3s", 
                    animation: "popup 0.5s ease-in-out" 
                  }}
                >
                  <CardBody  className="p-4">
                    <CardTitle tag="h5">Plan A</CardTitle>
                    <h2 style={{ fontWeight: "bold" }}>$9.99/month</h2>
                    <CardText>
                      <ul className="list-unstyled">
                        <li> ✔ Amazing benefit</li>
                        <li> ✔ Additional support</li>
                        <li> ✔ Access to premium content</li>
                        <li> ✔ Amazing benefit</li>
                       
                      </ul>
                    </CardText>
                    <Button className="mt-3" style={{ backgroundColor: "#007bff", borderColor: "#007bff", color: "white" }}>Choose Plan A</Button>
                  </CardBody>
                </Card>
              </Col>
              <Col md={6} lg={4} className="mb-4">
                <Card 
                  className="text-center card-hover" 
                  style={{ 
                    border: "1px solid lightgray", 
                    transition: "all 0.3s", 
                    animation: "popup 0.5s ease-in-out" 
                  }}
                >
                  <CardBody  className="p-4" >
                    <CardTitle tag="h5">Plan B</CardTitle>
                    <h2 style={{ fontWeight: "bold" }}>$29.99/yearly</h2>
                    <CardText>
                      <ul className="list-unstyled">
                        <li> ✔ All features in Plan A</li>
                        <li> ✔ Priority support</li>
                        <li> ✔ Early access to new features</li>
                        <li> ✔ All features in Plan A</li>
                        
                      </ul>
                    </CardText>
                    <Button className="mt-3" style={{ backgroundColor: "#007bff", borderColor: "#007bff", color: "white" }}>Choose Plan B</Button>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <style>
        {`
          @keyframes popup {
            0% {
              transform: scale(0.9);
              opacity: 0.7;
            }
            100% {
              transform: scale(1);
              opacity: 1;
            }
          }
          .card-hover:hover {
            background-color: #007bff;
            color: white;
          }
          .card-hover:hover button {
            background-color: white !important;
            color: #007bff !important;
            border-color: white !important;
          }
        `}
      </style>
    </div>
  );
};

export default PlanDetails;
