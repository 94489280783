import React, { useMemo } from 'react';

const Particulars = (cell) => {
    return cell.value ? cell.value : '';
};

const Type = (cell) => {
    return cell.value ? cell.value : '';
};

const Total = ({ categoryName, categoryType, totals }) => {
    const totalCash = totals[categoryName]?.[categoryType] || 0;
    return totalCash.toFixed(2);
  };


export {
    Particulars,
    Type,
    Total,
};