import React from 'react';
import { Link } from 'react-router-dom';
import * as moment from "moment";
import { size, map } from "lodash";

const GuestName = (cell) => {
    return cell.value ? cell.value : '';
};

const MobileNo = (cell) => {
    return cell.value ? cell.value : '';
};

const Address = (cell) => {
    return cell.value ? cell.value : '';
};

const City = (cell) => {
    return cell.value ? cell.value : '';
};

const CompanyName = (cell) => {
    return cell.value ? cell.value : '';
};

const Status = (cell) => {
    return cell.value ? "Active" : 'Inactive';
};



export {
    GuestName,
    MobileNo,
    Address,
    City,
    CompanyName,
    Status
};