import React, { useState } from "react";
import {
    Col,
    Container,
    Row,
    Card,
    CardBody,
} from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Breadcrumbs from "components/Common/Breadcrumb";
import withRouter from "../../../components/Common/withRouter";
import sampleImage from "../../../assets/images/logo.png";
import ConfirmationModal from "../../../components/Common/ConfirmationModal";
import DeleteModal from "../../../components/Common/DeleteModal";
import AlertModal from "components/Common/alertModal";
import { deleteHotel } from "../../../middleware/backent_helper";
import messages from '../../../utils/constants/messages';

const HotelDetail = props => {

    const location = useLocation();
    const navigate = useNavigate();
    const { hotelList } = location.state || {};
    const [masterName, setMasterName] = useState("Hotel");
    const [deleteModal, setDeleteModal] = useState(false);
    const [ConfirmationDeleteModal, setConfirmationDeleteModal] = useState(false);
    const [alertModal, setAlertModal] = useState(false);
    const [response, setResponse] = useState(false);
    if (!hotelList) {
        return <p>No hotel data available</p>;
    }
    const dateObject = new Date(hotelList.createdAt);
    const year = dateObject.getFullYear();
    const monthName = dateObject.toLocaleString('default', { month: 'long' });

    const handleEdit = () => {
        navigate('/edit-hotel', { state: { hotelList } });
    };

    const handleDelete = () => {
        setConfirmationDeleteModal(true);
    };

    const confrimDeleteHotel = () => {
        setConfirmationDeleteModal(false)
        deleteHotel(hotelList).then((res) => {
            if (res.data.success) {
                setResponse(messages.success.deleteDetails(masterName))
                setAlertModal(true)
              }
            }).catch(err => {
              if (err.response.status === 404) {
                setResponse(messages.failed.deleteFailed(masterName))
              } else {
                setResponse(messages.failed.deleteFailed(masterName))
              }
              setErrorAlertModal(true)
            });
    }

    const redirectpage = () => {
        navigate(-1);
    }

    return (
        <React.Fragment>
            <ConfirmationModal
                show={ConfirmationDeleteModal}
                onDeleteClick={confrimDeleteHotel}
                onCloseClick={() => {setConfirmationDeleteModal(false) }}
                hotelList={hotelList}
            />
            <AlertModal
                show={alertModal}
                onOkClick={() => { redirectpage(), setConfirmationDeleteModal(false) }}
                onCloseClick={() => { redirectpage(), setConfirmationDeleteModal(false) }}
                res={response}
            />
            <DeleteModal
                show={deleteModal}
                onDeleteClick={confrimDeleteHotel}
                onCloseClick={() => {setDeleteModal(false) }}
            />
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Hotel" breadcrumbItem="Hotel Detail"/>
                    <Row className="row-gap">
                        <Col xl="3">
                            <Card className="h-100 d-flex flex-column">
                                <CardBody>
                                    <div className="text-center">
                                        <img src={sampleImage} alt="" height="50" className="mx-auto d-block" />
                                        <h5 className="mt-3 mb-1">{hotelList.hotelName}</h5>
                                        <p className="text-muted mb-0">Since {monthName} {year}</p>
                                    </div>

                                    <ul className="list-unstyled mt-4">
                                        <li>
                                            <div className="d-flex">
                                                <i className="bx bx-phone text-primary fs-4"></i>
                                                <div className="ms-3">
                                                    <h6 className="fs-14 mb-2">Phone</h6>
                                                    <p className="text-muted fs-14 mb-0">{hotelList.countryCode}{hotelList.contactNo1}</p>
                                                    <p className="text-muted fs-14 mb-0">{hotelList.countryCode}{hotelList.contactNo2}</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="mt-3">
                                            <div className="d-flex">
                                                <i className="bx bx-mail-send text-primary fs-4"></i>
                                                <div className="ms-3">
                                                    <h6 className="fs-14 mb-2">Email</h6>
                                                    <p className="text-muted fs-14 mb-0">{hotelList.hotelEmail}</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="mt-3">
                                            <div className="d-flex">
                                                <i className="bx bx-globe text-primary fs-4"></i>
                                                <div className="ms-3">
                                                    <h6 className="fs-14 mb-2">Website</h6>
                                                    <p className="text-muted fs-14 text-break mb-0">{hotelList.website}</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="mt-3">
                                            <div className="d-flex">
                                                <i className="bx bx-map text-primary fs-4"></i>
                                                <div className="ms-3">
                                                    <h6 className="fs-14 mb-2">Location</h6>
                                                    <p className="text-muted fs-14 mb-0">{hotelList.address1} {hotelList.address2} {hotelList.address3}</p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl="6">
                            <Card className="h-100 d-flex flex-column">
                                <CardBody>
                                    <h5 className="text-center fw-semibold">General Details</h5>
                                    <Row className="mt-4">
                                        {/* Address Details Section */}
                                        <Col md="6">
                                            <h6 className="fs-16 mb-4">Address Details</h6>
                                            <ul className="list-unstyled">
                                                <li className="mb-3">
                                                    <div className="d-flex">
                                                        <i className="bx bx-map text-primary fs-4"></i>
                                                        <div className="ms-3">
                                                            <h6 className="fs-14 mb-2">Address Line 1</h6>
                                                            <p className="text-muted fs-14 mb-0">{hotelList.address1}</p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="mb-3">
                                                    <div className="d-flex">
                                                        <i className="bx bx-map text-primary fs-4"></i>
                                                        <div className="ms-3">
                                                            <h6 className="fs-14 mb-2">Address Line 2</h6>
                                                            <p className="text-muted fs-14 mb-0">{hotelList.address2}</p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="mb-3">
                                                    <div className="d-flex">
                                                        <i className="bx bx-map text-primary fs-4"></i>
                                                        <div className="ms-3">
                                                            <h6 className="fs-14 mb-2">Address Line 3</h6>
                                                            <p className="text-muted fs-14 mb-0">{hotelList.address3}</p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="mb-3">
                                                    <div className="d-flex">
                                                        <i className="bx bxs-city text-primary fs-4"></i>
                                                        <div className="ms-3">
                                                            <h6 className="fs-14 mb-2">City</h6>
                                                            <p className="text-muted fs-14 mb-0">{hotelList.city}</p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="mb-3">
                                                    <div className="d-flex">
                                                        <i className="bx bx-flag text-primary fs-4"></i>
                                                        <div className="ms-3">
                                                            <h6 className="fs-14 mb-2">Country</h6>
                                                            <p className="text-muted fs-14 mb-0">{hotelList.country}</p>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </Col>

                                        {/* Contact Information Section */}
                                        <Col md="6">
                                            <h6 className="fs-16 mb-4">Contact Information</h6>
                                            <ul className="list-unstyled">
                                                <li className="mb-3">
                                                    <div className="d-flex">
                                                        <i className="bx bx-phone-call text-primary fs-4"></i>
                                                        <div className="ms-3">
                                                            <h6 className="fs-14 mb-2">Primary Phone</h6>
                                                            <p className="text-muted fs-14 mb-0">{hotelList.countryCode}{hotelList.contactNo1}</p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="mb-3">
                                                    <div className="d-flex">
                                                        <i className="bx bx-phone text-primary fs-4"></i>
                                                        <div className="ms-3">
                                                            <h6 className="fs-14 mb-2">Secondary Phone</h6>
                                                            <p className="text-muted fs-14 mb-0">{hotelList.countryCode}{hotelList.contactNo2}</p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="mb-3">
                                                    <div className="d-flex">
                                                        <i className="bx bx-envelope text-primary fs-4"></i>
                                                        <div className="ms-3">
                                                            <h6 className="fs-14 mb-2">Email</h6>
                                                            <p className="text-muted fs-14 mb-0">{hotelList.hotelEmail}</p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="mb-3">
                                                    <div className="d-flex">
                                                        <i className="bx bx-globe text-primary fs-4"></i>
                                                        <div className="ms-3">
                                                            <h6 className="fs-14 mb-2">Website</h6>
                                                            <p className="text-muted fs-14 mb-0 text-break">{hotelList.website}</p>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl="3">
                            <Card className="h-100 d-flex flex-column">
                                <CardBody>
                                <h5 className="text-center fw-semibold">Hotel-User Details</h5>
                                    <ul className="list-unstyled mt-4">
                                        <li className="mb-3">
                                            <div className="d-flex">
                                                <i className="bx bx-user text-primary fs-4"></i>
                                                <div className="ms-3">
                                                    <h6 className="fs-14 mb-2">Name</h6>
                                                    <p className="text-muted fs-14 mb-0">{hotelList.user.name}</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="mb-3">
                                            <div className="d-flex">
                                                <i className="bx bx-envelope text-primary fs-4"></i>
                                                <div className="ms-3">
                                                    <h6 className="fs-14 mb-2">Email</h6>
                                                    <p className="text-muted fs-14 mb-0">{hotelList.user.email}</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="mb-3">
                                            <div className="d-flex">
                                                <i className="bx bx-mobile text-primary fs-4"></i>
                                                <div className="ms-3">
                                                    <h6 className="fs-14 mb-2">Primary Mobile</h6>
                                                    <p className="text-muted fs-14 mb-0">{hotelList.user.mobileNo1}</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="mb-3">
                                            <div className="d-flex">
                                                <i className="bx bx-mobile-alt text-primary fs-4"></i>
                                                <div className="ms-3">
                                                    <h6 className="fs-14 mb-2">Secondary Mobile</h6>
                                                    <p className="text-muted fs-14 mb-0">{hotelList.user.mobileNo2}</p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Card>
                        <CardBody className="d-flex flex-column">
                            <div className="mt-auto d-flex justify-content-end" style={{ gap: '10px' }}>
                                <button
                                    type="submit"
                                    className="btn btn-primary save-user w-md"
                                    size="lg"
                                    onClick={handleEdit}
                                >
                                    Edit hotel
                                </button>
                                <button
                                    type="submit"
                                    className="btn btn-secondary save-user w-md"
                                    size="lg"
                                    onClick={handleDelete}
                                >
                                    Delete
                                </button>
                            </div>
                        </CardBody>
                    </Card>


                </Container>
            </div>
        </React.Fragment>
    );
};

export default withRouter(HotelDetail);
