const RoomType = (cell) => {
    return cell.value ? cell.value : '';
};

const TotalGuest = (cell) => {
    return cell.value ? cell.value : '';
};

const ExtraGuest = (cell) => {
    return cell.value ? cell.value : '';
};

const ExtraChild = (cell) => {
    return cell.value ? cell.value : '';
};

const SingleTariff = (cell) => {
    return cell.value ? cell.value : '';
};

const NormalTariff = (cell) => {
    return cell.value ? cell.value : '';
};

const ExtraPersonTariff = (cell) => {
    return cell.value ? cell.value : '';
};

const Tax = (cell) => {
    return cell.value ? cell.value : '';
};

const Amenities = (cell) => {
    return cell.value ? cell.value : '';
};

export {
    ExtraPersonTariff,
    ExtraChild,
    ExtraGuest,
    RoomType,
    TotalGuest,
    SingleTariff,
    NormalTariff,
    Tax,
    Amenities,
};