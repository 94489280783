const messages = {
    success: {
        createdMessage: (masterName) => `${masterName} Added Sucessfully`,
        updatedDetails: (masterName) => `${masterName} Updated Successfully`,
        imageDeleted: 'Failed to Delete Image',
        deleteDetails: (masterName) => `${masterName} Deleted Successfully`,
        changePassword: (changePassword) => `${changePassword} has been updated`,
        CancelSuccessFull: (masterName) => `${masterName} Cancelled Successfully`,
        CheckOutSuccess: () => `CheckOut Success`,
    },
    failed: {
        createFailed: (masterName) => `Failed to Add ${masterName}`,
        AlreadyExists: (masterName) => `${masterName} Already Exists`,
        alreadyInUse: (masterName) => `${masterName} Already in use`,
        updateFailed: (masterName) => `Failed to Update ${masterName}`,
        cannotbeDeleted: (masterName) => `${masterName} cannot be deleted`,
        imageFailed: 'Image Failed to Delete',
        deleteFailed: (masterName) => `Failed to Delete ${masterName}`,
        passwordNotMatch: (changePassword) => `current password is incorrect ${changePassword}`,
        passwordUpdateFailed: (changePassword) => `${changePassword} update failed`,
        CancelFailed: (masterName) => `${masterName} Cancellation Failed`,
        CancelCannotBeDone: (masterName) => `${masterName} Cannot Be Cancelled`,
        CheckOutDataExists: () => `Checkout cannot be done, Please Check the Details you have Entered`,
        CheckOutFailed: () => `Checkout Failed`,
    }
};

export default messages;