import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import {
  Col,
  Container,
  Row,
} from "reactstrap";
 import {  getHotelUser, getHotelById, updateHotel} from "../../middleware/backent_helper";
 import messages from '../../utils/constants/messages';
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import AlertModal from "components/Common/alertModal";
import ErrorAlertModal from "components/Common/errorAlertModal";
import HotelForm from "../../components/hotel/hotel_form";

const HotelSettings = props => {
  //meta title
  document.title = "Property | Setting";

  const location = useLocation();
  const navigate = useNavigate();
//   const { hotel, HotelUser } = location.state || {};
  const [Hotels, setHotels] = useState([]);
  const [HotelUser, setHotelUser] = useState([]);
  const [hotelList, setHotelList] = useState([]);
  const [isEdit, setIsEdit] = useState(true);
  const [isEditSetting, setIsEditSetting] = useState(true);
  const [currency, setCurrency] = useState(null);
  const [alertModal, setAlertModal] = useState(false);
  const [errorAlertModal, setErrorAlertModal] = useState(false);
  const [response, setResponse] = useState(false);
  const [masterName, setMasterName] = useState("Hotel");
  const [currentStep, setCurrentStep] = useState(0);
  const NavigatedData = location.state || {};
  console.log(NavigatedData.hotelList);

  const [hid, setHid] = useState(() => {
    if (NavigatedData.hotelList && NavigatedData.hotelList.id !== undefined && NavigatedData.hotelList.id !== null) {
      // Convert id to a string to safely use replace
      const id = String(NavigatedData.hotelList.id);
      return id.replace(/"/g, '');
    }
    // Get storedHotelId from localStorage
    const storedHotelId = localStorage.getItem('hotelId');
    return storedHotelId ? storedHotelId.replace(/"/g, '') : '';
  });

  useEffect(() => {
    if (hid) {
    getHotelUser(hid)
        .then(response => {
          const hotelData = response.data.data[0];
          setHotelUser(hotelData);
        })
    }
  }, []);

  useEffect(() => {
    if (hid) {
       getHotelById(hid)
        .then(response => {
          const hotelData = response.data.data;
          setHotelList(hotelData)
          setCurrency(hotelData.currency)
        })
    }
  }, []);

  useEffect(() => {
    if (hotelList && HotelUser) {
      handleHotelData(hotelList , HotelUser);
    }
  }, [hotelList, HotelUser]);

const handleHotelData = async (hotelList , HotelUser) => {

  setHotels({
    id:hotelList.id,
    hotelName: hotelList.hotelName,
    hotelEmail: hotelList.hotelEmail,
    address1: hotelList.address1,
    address2: hotelList.address2,
    address3: hotelList.address3,
    postalCode: hotelList.postalCode,
    contactNo1: hotelList.contactNo1,
    contactNo2: hotelList.contactNo2,
    website: hotelList.website,
    isActive: hotelList.isActive,
    country: hotelList.country,
    state: hotelList.state,
    city: hotelList.city,
    taxId: hotelList.taxId,
    planId: hotelList.planId,
    name: HotelUser.name,
    email: HotelUser.email,
    mobileNo1: HotelUser.mobileNo1,
    mobileNo2: HotelUser.mobileNo2,
  });
};

  const handleNext = () => {
    if (currentStep === 2) {
      handleSubmit();
    } else {
      setCurrentStep((prevStep) => Math.min(prevStep + 1, 2));
    }
  };

  const handlePrevious = () => {
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 0));
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const onClosed = () => {
    setCurrentStep(0)
  }

  const openModal = () => {
    setIsModalOpen(true);
  };

  const createFormData = (values) => {
    const formData = new FormData();
    for (const key in values) {
      if (values.hasOwnProperty(key)) {
        const value = values[key];
        if (value instanceof File) {
          formData.append('logo', value);
        } else {
          formData.append(key, value);
        }
      }
    }
    return formData;
  };

  const handleSubmit = (values) => {
    const updatedHotelData = { ...Hotels, ...values };
    const formData = createFormData(updatedHotelData);
    updateHotel(formData)
      .then((res) => {
        setResponse(messages.success.updatedDetails(masterName))
        setAlertModal(true)
      })
      .catch((err) => {
        setResponse(messages.failed.updateFailed(masterName))
        setErrorAlertModal(true)
      });
    handleClose();
  };

  return (
    <React.Fragment>
      <AlertModal
        show={alertModal}
        onOkClick={() => { navigate('/dashboard'), setAlertModal(false) }}
        onCloseClick={() => { redirectpage(), setAlertModal(false) }}
        res={response}
      />
      <ErrorAlertModal
        show={errorAlertModal}
        onOkClick={() => {  navigate('/dashboard'), setErrorAlertModal(false) }}
        onCloseClick={() => setErrorAlertModal(false)}
        res={response}
      />

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs breadcrumbItem="Property Settings " />
          <Row>
            <Col lg="12">
                <HotelForm
                  currentStep={currentStep}
                  handleNext={handleNext}
                  handlePrevious={handlePrevious}
                  parentHandleSubmit={handleSubmit}
                  hotels={Hotels}
                  HotelUser={HotelUser}
                  isEdit={isEdit}
                  isEditSetting = {isEditSetting}
                  currency = {currency}
                />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(HotelSettings);
