import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "components/Common/TableContainer";
import axios from "axios";
import {
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { MenuName, Url } from "./menusCol";
import messages from '../../../utils/constants/messages';
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import AlertModal from "components/Common/alertModal";
import ErrorAlertModal from "components/Common/errorAlertModal";
import Select from "react-dropdown-select";

//redux
import { useDispatch } from "react-redux";

const Menus = props => {
  //meta title
  document.title = "Menus | Masters ";

  const dispatch = useDispatch();
  const [menuDataList, setMenuDataList] = useState([]);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [errorAlertModal, setErrorAlertModal] = useState(false);
  const [response, setResponse] = useState(false);
  const [menuseData, setMenusData] = useState();
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [masterName, setMasterName] = useState("Menu");
  const [menu, setMenu] = useState([]);

  const [isActive, setIsActive] = useState(true);
  useEffect(() => {
    setIsActive(isActive);
  }, [isActive]);

  const toggleactive = () => {
    setIsActive(prevState => !prevState);
  };

  const handleCheckboxChange = () => {
    toggleactive();
  };

  const columns = useMemo(
    () => [
      {
        Header: "S.No",
        Cell: ({ row, rows }) => {
          return (
            <div className="table-cell text-center">
              {rows.length - row.index}
            </div>
          );
        },
      },
      {
        Header: "Menu Name",
        accessor: "menuName",
        filterable: true,
        Cell: cellProps => {
          return (
            <div className="table-cell text-center">
              <MenuName {...cellProps} />
            </div>
          );
        },
      },
      {
        Header: "Icon",
        accessor: "iconImg",
        filterable: true,
        Cell: cellProps => {
          const resName = cellProps.row.original.icon;
          return resName ? <div className="text-center"><i className={`bx ${resName} custom-icon`} primary ></i></div> : <div className="text-center"><i className='bx bx-window-close custom-icon text-danger'></i></div>;
          // const selectedIcon = options.find(option => option.name === resName);
          // return selectedIcon ? selectedIcon.icon : <i className="mdi mdi-default-icon" />;
        },
      },
      {
        Header: "Icon Name",
        accessor: "icon",
        filterable: true,
        Cell: cellProps => {
          return (
            <div className="table-cell text-center">
              <MenuName {...cellProps} />
            </div>
          );
        },
      },
      {
        Header: "Url",
        accessor: "url",
        filterable: true,
        Cell: cellProps => {
          return (
            <div className="table-cell text-center">
              <Url {...cellProps} />
            </div>
          );
        },
      },


      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="table-body actions">
              <div className="actions">
                <Link
                  to="#"
                  className="text-success"
                  onClick={() => {
                    const data = cellProps.row.original;
                    handleUserClick(data);
                  }}
                >
                  <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </Link>
                <Link
                  to="#"
                  className="text-danger"
                  onClick={() => {
                    const userData = cellProps.row.original;
                    onClickDelete(userData);
                  }}
                >
                  <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </div>
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    getAllMenusFromJSON()
  }, [])

  const getAllMenusFromJSON = async (data) => {
    try {
      const response = await axios.get('http://localhost:3030/menus');
      setMenuDataList(response.data);
      setTopMenus(response.data);
    } catch (error) {
      console.error('Error Fetching menu data:', error);
    }
  };

  const saveMenusToJSON = async (data) => {
    try {
      const response = await axios.post('http://localhost:3030/menus', data);

      setMenuDataList([...menuDataList, response.data]);
      getAllMenusFromJSON();
    } catch (error) {
      console.error('Error saving menu data:', error);
    }
  };

  const updateMenu = (id, data) => {
    // Check if a menu with the same menuName exists before making the request
    const menuNameExists = menuDataList.some(
      (menu) => menu.menuName === data.menuName && menu.id !== id
    );

    if (menuNameExists) {
      return Promise.resolve(false);
    }
    return axios
      .put(`http://localhost:3030/menus/${id}`, data)
      .then((response) => {
        const updatedMenuDataList = menuDataList.map((menu) => {
          if (menu.id === id) {
            return { ...menu, ...data };
          }
          return menu;
        });
        setMenuDataList(updatedMenuDataList);
        return true;
      })
      .catch((error) => {
        console.error('Error updating menu data:', error);
        return false;
      });
  };

  useEffect(() => {
    getAllMenusFromJSON();
  }, []);

  const deleteUser = async (data) => {
    try {
      const response = await axios.delete(`http://localhost:3030/menus/${data.id}`);
      return getAllMenusFromJSON()

    } catch (error) {
      console.error('Error deleting menu:', error);
    }
  };

  const clearFormValues = () => {
    validation.resetForm();
  };

  const toggle = () => {
    if (modal) {
      setModal(false);
      clearFormValues();
    } else {
      setModal(true);
    }
  };

  const [selectedItem, setSelectedItem] = useState(null);
  const handleSelectChange = (selectedOption) => {
    setSelectedItem(selectedOption[0].name);
    validation.setFieldValue('icon', selectedOption ? selectedOption[0].name : '');

  };
  const [topMenus, setTopMenus] = useState([]);


  const handleTopMenuChange = selectedOption => {
    setSelectedMenu(selectedOption);
    validation.setFieldValue('top', selectedOption? selectedOption[0].label : '');
  };

  const handleUserClick = arg => {
    const data = arg;
     setSelectedMenu(data.top);
    setMenusData({
      id: data.id,
      menuName: data.menuName,
      url: data.url,
      icon: data.icon,
      isActive: true,
      identity: data.identity,
      top: data.top
    });

    setIsEdit(true);
    toggle();
  };

  useEffect(() => {
    setSelectedItem(selectedItem)
  }, [selectedItem]);

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = data => {
    setMenusData(data);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    deleteUser({ id: menuseData.id }).then(getAllMenusFromJSON())
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleMenuClicks = () => {
    setIsEdit(false);
    toggle();
  };

  const validation = useFormik({

    initialValues: {
      menuName: menuseData ? menuseData.menuName : "",
      url: menuseData ? menuseData.url : "",
      icon: menuseData ? menuseData.icon : "",
      level: menuseData ? menuseData.topLevel : true,
      identity: menuseData ? menuseData.identity : "",
      isActive: menuseData ? menuseData.isActive : true,
      top: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      menuName: Yup.string().required("Please Enter Your Menu Name"),
      url: Yup.string().required("Please Enter Your Url"),
      identity: Yup.string().required("Please Enter Your Menu Identifier"),
      isActive: Yup.boolean().oneOf([true], 'Please confirm if the Menu is active'),
      top: topMenus.length === 0 ? Yup.string().notRequired() : Yup.string().required("Please choose Your Top Menu"),
    }),
    onSubmit: (values) => {
      const newMenu = {
        menuName: values.menuName,
        url: values.url,
        icon: values.icon,
        topLevel: values.level,
        identity: values.identity,
        top: selectedMenu ? selectedMenu[0].value : '' ,
        isActive: values.isActive,
      };

      if (isEdit) {
        updateMenu(menuseData.id, newMenu).then((success) => {
          if (!success) {
            setResponse(messages.failed.alreadyInUse(newMenu.menuName));
            setErrorAlertModal(true);
          } else {
            setResponse(messages.success.updatedDetails(masterName))
            setAlertModal(true);
          }
        });
        clearFormValues();
      } else {
        saveMenusToJSON(newMenu);
        setResponse("Menu added successfully.");
        setAlertModal(true);
        clearFormValues();
      }
      toggle();
      clearFormValues();
    },
  });



  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => {setMenusData(null), setDeleteModal(false) }}
      />
      <AlertModal
        show={alertModal}
        onOkClick={() => { setAlertModal(false) }}
        onCloseClick={() => setAlertModal(false)}
        res={response}
      />
      <ErrorAlertModal
        show={errorAlertModal}
        onOkClick={() => { setErrorAlertModal(false) }}
        onCloseClick={() => setErrorAlertModal(false)}
        res={response}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Masters" breadcrumbItem="Menus List" />
          <Row>
            <Col lg="12">

                  <TableContainer
                    columns={columns}
                    data={menuDataList}
                    isGlobalFilter={true}
                    isAddMenusList={true}
                    handleMenuClick={handleMenuClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />

                  <Modal className="modal-lg" isOpen={modal} toggle={toggle} backdrop="static" keyboard={false}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Edit Menu" : "Add Menu"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();

                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={6}>
                            <div className="mb-3">
                              <Label className="form-label">Menu Name</Label>
                              <Input
                                name="menuName"
                                type="text"
                                placeholder="Menu Name"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.menuName || ""}
                                invalid={
                                  validation.touched.menuName &&
                                    validation.errors.menuName
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.menuName &&
                                validation.errors.menuName ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.menuName}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col>
                            <div className="mb-3">
                              <Label className="form-label">URL</Label>
                              <Input
                                name="url"
                                label="url"
                                type="text"
                                placeholder="Menu Url"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.url || ""}
                                invalid={
                                  validation.touched.url &&
                                    validation.errors.url
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.url &&
                                validation.errors.url ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.url}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="mb-3">
                              <Label className="form-label">Icon</Label>



                                <Input
                                name="icon"
                                label="iconName"
                                type="text"
                                placeholder="Icon Name"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.icon || ""}
                                invalid={
                                  validation.touched.icon &&
                                    validation.errors.icon
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.icon &&
                                validation.errors.icon ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.icon}
                                </FormFeedback>
                              ) : null}

                            </div>

                          </Col>
                          <Col>
                            <div className="mb-3">
                              <Label className="form-label"> Menu Identifier</Label>
                              <Input
                                name="identity"
                                className="form-control"
                                placeholder="Menu Identifier"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.identity || ""}
                                invalid={validation.touched.identity && validation.errors.identity}
                              />
                              {validation.touched.identity && validation.errors.identity ? (
                                <FormFeedback type="invalid">{validation.errors.identity}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="mb-3">
                              <Label className="form-label"> Top Menu</Label>

                              <Select
                                id="top"
                                name="top"
                                options={topMenus.map(menu => ({ value: menu.menuName, label: menu.menuName }))}
                                placeholder="Choose Top Menu.."
                                isClearable
                                isSearchable
                                classNamePrefix="custom-select"
                                value={selectedMenu}
                                onChange={handleTopMenuChange}
                                styles={{
                                  menu: provided => ({ ...provided, top: '100%', position: 'absolute', width: '100%', zIndex: 999 }),
                                }}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.top && validation.errors.top
                                }

                              />
                              {validation.touched.top && validation.errors.top && (
                                <div className="text-danger">{validation.errors.top}</div>
                              )}
                            </div>
                          </Col>
                          <Col>
                            <div className="mb-3">
                              <label className="form-label" htmlFor="autoSizingSelect">Top Level</label>
                              <Input
                                type="select"
                                name="level"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.level || ""}
                                invalid={
                                  validation.touched.level && validation.errors.level
                                }
                              >
                                <option value="true">True</option>
                                <option value="false">False</option>

                              </Input>
                            </div>
                          </Col>
                        </Row>


                        <div className="form-check form-switch form-switch-lg mb-3">
                          <input
                            type="checkbox"
                            name="isActive"
                            className="form-check-input"
                            id="isActive"
                            checked={isActive}
                            onChange={handleCheckboxChange}
                            onClick={toggleactive}
                          />
                          <label className="form-check-label" htmlFor="customSwitchsizemd">
                            Active
                          </label>
                        </div>



                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-primary save-user w-md me-3"
                              >
                                Save
                              </button>
                              <button
                                type="button"
                                className="btn btn-secondary w-md "
                                onClick={toggle}
                              >
                                Cancel
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>

            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Menus);
