import axios from "axios";
import { Api_url } from "../middleware/helpers/url_helper";
export const login = (user) => {
  // console.log(login);
    return axios.post(`${Api_url}/auth/login`, user,
    {
      headers: {
        'Content-Type': 'application/json',
        'Accept':'*/*,application/json',
      }
    })
      .then(res => {
        // console.log(res);
        return res; // return the response for further processing if needed
      })
      .catch(err => {
        // console.error(err);
        throw err; // throw the error for handling in the component
      });
  };


  export const updateUser = (user) => {

      return axios.post(`${Api_url}/user/edit/account`, user,
      {
        headers: {
          'Content-Type': 'application/json',
          'Accept':'*/*,application/json',
        }
      })
        .then(res => {
          // console.log(res);
          return res; // return the response for further processing if needed
        })
        .catch(err => {
          // console.error(err);
          throw err; // throw the error for handling in the component
        });
    };


    export const updateDoctor = (doctor) => {

      return axios.post(`${Api_url}/doctor/edit/doctor`, doctor,
      {
        headers: {
          'Content-Type': 'application/json',
          'Accept':'*/*,application/json',
        }
      })
        .then(res => {
          return res;
        })
        .catch(err => {
          throw err;
        });
    };

    export const updateTest = (test) => {

      return axios.post(`${Api_url}/mainTestPanel/edit/test`, test,
      {
        headers: {
          'Content-Type': 'application/json',
          'Accept':'*/*,application/json',
        }
      })
        .then(res => {
          // console.log(res);
          return res; // return the response for further processing if needed
        })
        .catch(err => {
          // console.error(err);
          throw err; // throw the error for handling in the component
        });
    };

    export const updatePackage = (testpackage) => {

      return axios.post(`${Api_url}/package/edit/package`, testpackage,
      {
        headers: {
          'Content-Type': 'application/json',
          'Accept':'*/*,application/json',
        }
      })
        .then(res => {
          // console.log(res);
          return res; // return the response for further processing if needed
        })
        .catch(err => {
          // console.error(err);
          throw err; // throw the error for handling in the component
        });
    };
      // Function to send OTP
    export const sendOTP = async (email) => {
      // console.log(email)
      try {
        // Example implementation using fetch
        const response = await fetch(`/sendOTP`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            'Accept':'*/*,application/json',
          },
          body: JSON.stringify({ email })
        });
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Failed to send OTP:", error);
        throw new Error("Failed to send OTP");
      }
    };
// export const verifyOTP = async (userEnteredOTP) => {
//   try {
//     const response = await axios.post(`${Api_url}/auth/verify`, {  userEnteredOTP });
//     // window.location.href = `/signin`;
//     // setMessage(response.data);

//     // alert("Register verified successfully!");
//   } catch (error) {
//     console.error(error);
//     //setMessage('Invalid OTP');
//     // alert("Invalid OTP!");
//   }
// };

//forgot email signin


export const forgotemailSignin = async (email) => {
  try {
    const response = await axios.post(`${Api_url}/auth/forgot_email_signin`, {  email });
    // window.location.href = `/forgot-otp`;
    // setMessage(response.data);

    // alert("OTP verified successfully!");
  } catch (error) {
    console.error(error);
    //setMessage('Invalid OTP');
    // alert("Invalid OTP!");
  }
};

//forgototp

// export const forgotVerifyOTP = async (userEnteredOTP) => {
//   try {
//     const response = await axios.post(`${Api_url}/auth/forgotverify`, {  userEnteredOTP });
//     // window.location.href = `/Recover-password`;
//     // setMessage(response.data);
//     console.log(response.data);

//     // alert("OTP verified successfully!");
//   } catch (error) {
//     // console.error(error);
//     //setMessage('Invalid OTP');
//     alert("Invalid OTP!");
//   }
// };
