import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../../components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { createCategory, getCategory, updateCategory, deleteCategory } from "../../../middleware/backent_helper";
import { CategoryName, Type } from "./CategoryMasterCol";
import messages from '../../../utils/constants/messages';
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import DeleteModal from "../../../components/Common/DeleteModal";
import AlertModal from "../../../components/Common/alertModal";
import ErrorAlertModal from "../../../components/Common/errorAlertModal";
//redux
import { useSelector, useDispatch } from "react-redux";

const CategoryMaster = props => {

  //meta title
  document.title = "Category | Settings ";

  const dispatch = useDispatch();
  const [Category, setCategory] = useState();
  const [CategoryList, setCategoryList] = useState([]);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [errorAlertModal, setErrorAlertModal] = useState(false);
  const [masterName, setMasterName] = useState("Category");
  const [response, setResponse] = useState(false);
  const [hid, setHid] = useState(() => {
    const storedHotelId = localStorage.getItem('hotelId')
    return storedHotelId ? storedHotelId.replace(/"/g, '') : '';
  });

  const [dbName, setDbName] = useState(
    localStorage.getItem('dbName')
  )

   const columns = useMemo(
    () => [
      {
        Header: "S.No",
        Cell: ({ row, rows }) => {
          return (
            <div  className="table-cell text-center">
              {rows.length - row.index}
            </div>
          );
        },
      },

      {
        Header: "Category Name",
        accessor: "name",
        filterable: true,
        Cell: cellProps => {
          return (
            <div  className="table-cell text-center">
              <CategoryName {...cellProps} />
            </div>
          );

        },
      },

      {
        Header: "Type",
        accessor: "type",
        filterable: true,
        Cell: cellProps => {
          return (
            <div  className="table-cell text-center">
              <Type {...cellProps} />
            </div>
          );

        },
      },

      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div  className="table-body actions">
              <div className="actions">
                <Link
                  to="#"
                  className="text-success"
                  onClick={() => {
                    const CategoryData = cellProps.row.original;
                    handle_CategoryClick(CategoryData);
                  }}
                >
                  <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </Link>
                <Link
                  to="#"
                  className="text-danger"
                  onClick={() => {
                    const userData = cellProps.row.original;
                    onClickDelete(userData);
                  }}
                >
                  <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </div>
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    finalList()
  }, [])

  const finalList = () => {
    getCategory(hid , dbName)
      .then((res) => {
        setCategoryList(res.data.data);
    });
  };

  const clearFormValues = () => {
    validation.resetForm();
  };

  const toggle = () => {
    if (modal) {
      setModal(false);
      setCategory(null);
      clearFormValues();

    } else {
      setModal(true);
    }
  };

  const handle_CategoryClick = arg => {
    const cat_type = arg;
    setCategory({
      id: cat_type.id,
      name: cat_type.name,
      type: cat_type.type,
      hotelId: hid,
      dbName:dbName,
    });
    setIsEdit(true);
    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };


  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = category => {
    setCategory(category);
    setDeleteModal(true);
  };

  const handleDeleteCategory = () => {
    deleteCategory(Category , dbName)
    .then((res) => {
      getCategory(hid , dbName), finalList()
      setResponse(messages.success.deleteDetails(masterName))
      setAlertModal(true)
    })
    .catch((err) => {
      if(err.response.status == 400) {
        setResponse(messages.failed.cannotbeDeleted(masterName))
      } else {
        setResponse(messages.failed.updateFailed(masterName))
      }
      setErrorAlertModal(true)
    });
    onPaginationPageChange(1);
    setDeleteModal(false);
    setCategory(null);
  };

  const handleCategoryClick = () => {
    setIsEdit(false);
    toggle();
  };

  const validation = useFormik({

    initialValues: {
      name: Category ? Category.name : "",
      type: Category ? Category.type : "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Category Name"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updatedCategoryData = { ...Category, ...values };

        updateCategory(updatedCategoryData)
          .then((res) => {
            setResponse(messages.success.updatedDetails(masterName))
            setAlertModal(true)
          })
          .catch((err) => {
            if (err.response.status === 401) {
              setResponse(messages.failed.alreadyInUse(masterName))
            } else {
              setResponse(messages.failed.updateFailed(masterName))
            }
            setErrorAlertModal(true)
          });
        toggle();
      } else {
        dispatch(post_Category(values));
      }
    }
  });
  const post_Category = (values) => {
    const data = JSON.stringify({
      name: values.name,
      type: values.type,
      hotelId: hid,
      dbName:dbName,
      isActive:true
    });
    createCategory(data).then((res) => {
      if (res.data.success) {
        setResponse(messages.success.createdMessage(masterName))
        setAlertModal(true)
      }
    }).catch(err => {
      if(err.response.status === 404) {
        setResponse(messages.failed.AlreadyExists(masterName))
      } else {
        setResponse(messages.failed.createFailed(masterName))
      }
      setErrorAlertModal(true)
    });
    toggle();
  };

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteCategory}
        onCloseClick={() => {setCategory(null), setDeleteModal(false) }}
      />
      <AlertModal
        show={alertModal}
        onOkClick={() => {
          finalList();
          setAlertModal(false);
        }}
        onCloseClick={() => setAlertModal(false)}
        res={response}
      />
      <ErrorAlertModal
        show={errorAlertModal}
        onOkClick={() => { finalList(), setErrorAlertModal(false) }}
        onCloseClick={() => setErrorAlertModal(false)}
        res={response}
      />

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Settings" breadcrumbItem="Category" />
          <Row>
            <Col lg="12">

                  <TableContainer
                    columns={columns}
                    data={CategoryList}
                    isGlobalFilter={true}
                    isAddCategoryList={true}
                    handleCategoryClick={handleCategoryClick}
                    customPageSize={10}
                    className="custom-header-css"
                  />

                  <Modal className="modal-lg" isOpen={modal} toggle={toggle} backdrop="static" keyboard={false}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Edit Category" : "Add Category"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >

                        <Row>
                          <Col xs={6}>
                            <div className="mb-3">
                              <Label className="form-label">Category Name</Label>
                              <Input
                                name="name"
                                type="text"
                                placeholder="Category Name"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ""}
                                invalid={
                                  validation.touched.name &&
                                    validation.errors.name
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.name &&
                                validation.errors.name ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.name}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col xs={6}>
                            <div className="mb-3">
                              <label className="form-label" htmlFor="autoSizingSelect">Type</label>
                              <Input
                                type="select"
                                name="type"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.type || ""}
                                invalid={
                                  validation.touched.type && validation.errors.type
                                }
                              >
                                <option value="">Select an option</option>
                                <option value="Expense">Expense</option>
                                <option value="Income">Income</option>

                              </Input>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <div className="text-end">
                            <button type="submit" className="btn btn-primary w-md me-3">
                                {isEdit ? 'Update' : 'Save'}
                            </button>
                              <button
                                type="button"
                                className="btn btn-secondary w-md "
                                onClick={toggle}
                              >
                                Cancel
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>

            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};


export default withRouter(CategoryMaster);
