import React, { useState, useEffect } from "react"
import {
    Modal,
    ModalBody,
    Form,
    Row,
    Col,
    Label,
    Input,
    FormGroup,
    InputGroup, FormFeedback
} from "reactstrap"
import DatePicker from "react-datepicker";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getRoomService, getUpdateRecord } from '../../middleware/backent_helper'
import Select from 'react-select';
import { update_cleaning, update_cleaned } from "../../middleware/backent_helper";


const UpdateModal = ({ show, onCloseClick, updateBookingId, roomNo, onStatus }) => {

    const [hid, setHid] = useState(() => {
        const storedHotelId = localStorage.getItem('hotelId')
        return storedHotelId ? storedHotelId.replace(/"/g, '') : '';
    });
    const [dbName, setDbName] = useState(
        localStorage.getItem('dbName')
    )

    const [updateDate, setUpdateDate] = useState(new Date());
    const [currentTime, setCurrentTime] = useState(new Date());

    const [servicePersonList, setServicePersonList] = useState([]);
    const [selectedTest, setSelectedTest] = useState(null);
    const [updateRecord, setUpdateRecord] = useState({});
    const [updatecleaningId, setUpdatecleaningId] = useState(0);
    const [updateStatus, setUpdateStatus] = useState('');

    const handleServicePerson = selectedOption => {
        setSelectedTest(selectedOption);
        validation.setFieldValue('servicePersonId', selectedOption ? selectedOption.value : '');
    };

    useEffect(() => {
        getRoomService(hid, dbName)
            .then((res) => {
                setServicePersonList(res.data.data);
            });


    }, [])

    const [serviceMemberName, setServiceMemberName] = useState('');

    useEffect(() => {
        if (show) {
            getUpdateRecord(hid, dbName, updateBookingId)
                .then(response => {
                    setUpdateRecord(response.data.data);
                    setUpdatecleaningId(response.data.data.CleaningId);
                    setUpdateStatus(response.data.data.Clean_status);

                    if (response.data.data.Clean_status === "Cleaning") {
                        const serviceMemberId = response.data.data.ServicePersonId;
                        const servicePerson = servicePersonList.find(person => person.id === serviceMemberId);
                        if (servicePerson) {
                            setSelectedTest({ value: servicePerson.id, label: servicePerson.name });
                            validation.setFieldValue('servicePersonId', servicePerson.id);
                        }
                    }

                })
                .catch(error => {
                    console.error(error);
                });
        }
    }, [show, updateBookingId]);

    useEffect(() => {

        setUpdatecleaningId(updateRecord.CleaningId)
        setUpdateStatus(updateRecord.Clean_status);

    }, [updatecleaningId, updateStatus]);


    const validation = useFormik({
        initialValues: {
            servicePersonId: "",
            remarks: "",
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            remarks: Yup.string().required("Please Enter Your remarks"),
            servicePersonId: Yup.string().required("Please Choose service person"),
        }),
        onSubmit: (values) => {
            Update(values)
            validation.resetForm();
            onCloseClick();

        }
    });

    const Update = (values) => {
        if (updateStatus === "Not Ready") {
            const data = JSON.stringify({
                StartDateTime: updateDate,
                ServicePersonId: values.servicePersonId,
                Clean_status: "Cleaning",
                HotelId: hid,
                db: dbName,
                Remarks: values.remarks,
                CleaningId: updatecleaningId

            });

            update_cleaning(data).then((res) => {
                onStatus(updateBookingId);
            })


        }

        else {
            const data = JSON.stringify({
                EndDateTime: updateDate,
                ServicePersonId: values.servicePersonId,
                Clean_status: "Cleaned",
                HotelId: hid,
                db: dbName,
                Remarks: values.remarks,
                CleaningId: updatecleaningId
            });
            update_cleaned(data).then((res) => {
                onStatus(updateBookingId);
            })

        }

    };



    useEffect(() => {
        if (show) {
            setSelectedTest(null)
            setUpdateDate(new Date());
            setCurrentTime(new Date());

        }
    }, [show]);


    return (
        <Modal size="lg" isOpen={show} toggle={onCloseClick} centered={true}>
            <ModalBody className="px-4 py-5">
                <button type="button" onClick={onCloseClick} className="btn-close position-absolute end-0 top-0 m-3"></button>
                <h5 className="mb-4">House Keeping Update</h5>
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}
                >
                    <Row>
                        <Col xs={12}>
                            <div>
                                <h6>Room No: {roomNo}</h6>
                            </div>
                            <br></br>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={8}>
                            <div className="mb-3">
                                <Label className="form-label"> Date</Label>
                                <DatePicker
                                    name="updateDate"
                                    selected={updateDate}
                                    onChange={(date) => setUpdateDate(date)}
                                    className="form-control"
                                    dateFormat="d-MM-yyyy"
                                    placeholderText="Select date and time"
                                    timeFormat="HH:mm"
                                    timeIntervals={1}
                                />
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="mb-3">
                                <FormGroup>
                                    <Label className="form-label">Time</Label>
                                    <InputGroup>
                                        <Flatpickr
                                            value={currentTime}
                                            className="form-control d-block"
                                            placeholder="Select time"
                                            options={{
                                                enableTime: true,
                                                noCalendar: true,
                                                dateFormat: "H:i",
                                                time_24hr: true,
                                            }}
                                            onChange={(date) => setCurrentTime(date[0])}
                                        />
                                        <div className="input-group-append">
                                            <span className="input-group-text">
                                                <i className="mdi mdi-clock-outline" />
                                            </span>
                                        </div>
                                    </InputGroup>
                                </FormGroup>
                            </div>
                        </Col>
                        <Col xs={12}>
                            <div className="mb-3" style={{ position: 'relative' }}>
                                <label htmlFor="testName" className="form-label">Updated By </label>
                                <Select
                                    id="servicePersonId"
                                    name="servicePersonId"
                                    options={servicePersonList.map(type => ({ value: type.id, label: type.name }))}
                                    placeholder="Select a service person "
                                    value={selectedTest}
                                    onChange={handleServicePerson}
                                    isClearable
                                    isSearchable
                                    classNamePrefix="custom-select"
                                    styles={{
                                        menu: provided => ({ ...provided, top: '100%', position: 'absolute', width: '100%', zIndex: 999 }),
                                    }}
                                    onBlur={validation.handleBlur}
                                    invalid={
                                        validation.touched.servicePersonId && validation.errors.servicePersonId
                                    }
                                />
                                {validation.touched.servicePersonId && validation.errors.servicePersonId && (
                                    <div className="text-danger">{validation.errors.servicePersonId}</div>
                                )}
                            </div>
                        </Col>
                        <Col xs={12}>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="autoSizingSelect">Status</label>
                                <Input
                                    type="select"
                                    name="status"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.status || ""}
                                    invalid={
                                        validation.touched.status && validation.errors.status
                                    }
                                    disabled={updateStatus === "Not Ready" || updateStatus === "Cleaning"}
                                >
                                    {updateStatus === "Not Ready" ? (
                                        <option value="Cleaning">Cleaning</option>
                                    ) : updateStatus === "Cleaning" ? (
                                        <option value="Cleaned">Cleaned</option>
                                    ) : (
                                        <React.Fragment>
                                            <option value="Cleaning">Cleaning</option>
                                            <option value="Cleaned">Cleaned</option>
                                        </React.Fragment>
                                    )}
                                </Input>
                            </div>
                        </Col>
                        <Col xs={12}>
                            <div className="mb-4">
                                <Label className="form-label">Remarks</Label>
                                <Input
                                    name="remarks"
                                    type="text"
                                    placeholder="Remarks"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.remarks || ""}
                                    invalid={
                                        validation.touched.remarks &&
                                            validation.errors.remarks
                                            ? true
                                            : false
                                    }
                                />
                                {validation.touched.remarks &&
                                    validation.errors.remarks ? (
                                    <FormFeedback type="invalid">
                                        {validation.errors.remarks}
                                    </FormFeedback>
                                ) : null}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="text-left">
                                <button
                                    type="submit"
                                    className="btn btn-primary w-md me-3"
                                >
                                    {updateStatus === "Not Ready" ? "Update" : updateStatus === "Cleaning" ? "Finish" : "Update"}
                                </button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
        </Modal>
    );
};

export default UpdateModal;