import React from "react";
import { Navigate } from "react-router-dom";

const roleMapping = {
  'Super-Admin': 1,
  'Hotel': 2,
  'User': 3
};

const Authmiddleware = (props) => {
  const { allowedRoles } = props;
  const authUser = localStorage.getItem("authUser");

  if (!authUser) {
    return <Navigate to="/login" />;
  }

  // Parse the user details from localStorage
  const user = JSON.parse(authUser);
  const userRole = user.user.roleId;
  // Ensure allowedRoles is an array
  const rolesArray = Array.isArray(allowedRoles) ? allowedRoles : [allowedRoles];
  // Convert allowedRoles from names to IDs
  const allowedRoleIds = rolesArray.map(roleName => roleMapping[roleName]);

  if (!allowedRoleIds.includes(userRole)) {
    // Redirect to dashboard if the user's role is not allowed
    return <Navigate to="/dashboard" />;
  }

  return (<React.Fragment>
    {props.children}
  </React.Fragment>);
};

export default Authmiddleware;
