import React from 'react';
import * as moment from "moment";


const TaxName = (cell) => {
    return cell.value ? cell.value : '';
};

const Tax  = (cell) => {
    return cell.value ? cell.value : '';
};



export {
    TaxName,
    Tax, 
};