import React, { useState, useEffect } from "react"
import {
    Modal,
    ModalBody,
    Form,
    Row,
    Col,
    Label,
    Input,
    FormGroup,
    InputGroup, FormFeedback
} from "reactstrap"
import { put_Block } from "../../middleware/backent_helper";
import DatePicker from "react-datepicker";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import Breadcrumbs from "components/Common/Breadcrumb";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate } from "react-router-dom";
import { getBlocksForSpecificRoomId } from "../../middleware/backent_helper";


const UnBlockModal = ({ show, onCloseClick, handleSubmit, roomId, bookingId }) => {

    const navigate = useNavigate();
    const [unblockDate, setUnblockDate] = useState(null);
    const [currentTime, setCurrentTime] = useState(null);
    const [blockDetails, setBlockDetails] = useState([]);
    const [remarks, setRemarks] = useState("");
    const [hid, setHid] = useState(() => {
        const storedHotelId = localStorage.getItem('hotelId')
        return storedHotelId ? storedHotelId.replace(/"/g, '') : '';
    });
    const [dbName, setDbName] = useState(
        localStorage.getItem('dbName')
    )

    useEffect(() => {
        if (show) {
            if (!unblockDate) setUnblockDate(new Date());
            if (!currentTime) setCurrentTime(new Date());
            setRemarks("");
        }
    }, [show]);

    useEffect(() => {
        if (show) {
            getBlocksForSpecificRoomId(hid, roomId, dbName, bookingId)
            .then((res) => {
                setBlockDetails(res.data.data[0])
            })
        }
    }, [show])

    const onSubmit = (e) => {
        e.preventDefault();
        handleSubmit({ unblockDate, currentTime, remarks });
    };

    const validation = useFormik({
        initialValues: {
            UnBlockDateTime: unblockDate,
            time: currentTime,
            remarks: "",
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            UnBlockDateTime: Yup.date()
            .required("Please select a date"),
            time: Yup.date()
            .required("Please select a time"),
            remarks: Yup.string(),
        }),
        onSubmit: (values) => {
            Update_Block(values)
            validation.resetForm();
        }
    });

    const Update_Block = (values) => {
        const data = JSON.stringify({
            UnBlockDateTime: values.unblockDate,
            RoomsId: roomId,
            hotelId: hid,
            db: dbName,
            Remarks: values.remarks,
            BlockId: blockDetails.BlockId

        });
        put_Block(data).then((res) => {
            console.log(res)
            if (res.data.success) {
                toggle();
            }
        })
    };

    const toggle = () => {
        setUnblockDate(null);
        setCurrentTime(null);
        setBlockDetails(null);
        setRemarks("");
        onCloseClick();
    }


    return (
        <Modal size="lg" isOpen={show} toggle={onCloseClick} centered={true}>
            <ModalBody className="px-4 py-5">
                <button type="button" onClick={onCloseClick} className="btn-close position-absolute end-0 top-0 m-3"></button>
                <h5 className="mb-4">Maintenance Update</h5>
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}
                >
                    <Row>
                        <Col xs={12}>
                            <div>
                                <h6>Room No: {roomId}</h6>
                            </div>
                            <br></br>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={8}>
                            <div className="mb-3">
                                <Label className="form-label">Un Block Date</Label>
                                <DatePicker
                                    name="UnBlockDateTime"
                                    selected={validation.values.UnBlockDateTime}
                                    onChange={(date) => {
                                        setUnblockDate(date);
                                        validation.setFieldValue("UnBlockDateTime", date);
                                    }}
                                    className="form-control"
                                    dateFormat="d-MM-yyyy"
                                    placeholderText="Select date and time"
                                    timeFormat="HH:mm"
                                    timeIntervals={1}
                                />
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="mb-3">
                                <FormGroup>
                                    <Label className="form-label">Time</Label>
                                    <InputGroup>
                                        <Flatpickr
                                            name="time"
                                            value={validation.values.time}
                                            className="form-control d-block"
                                            placeholder="Select time"
                                            options={{
                                                enableTime: true,
                                                noCalendar: true,
                                                dateFormat: "H:i",
                                                time_24hr: true,
                                            }}
                                            onChange={(date) => {
                                                setCurrentTime(date[0]);
                                                validation.setFieldValue("time", date[0]);
                                            }}                                        />
                                        <div className="input-group-append">
                                            <span className="input-group-text">
                                                <i className="mdi mdi-clock-outline" />
                                            </span>
                                        </div>
                                    </InputGroup>
                                </FormGroup>
                            </div>
                        </Col>
                        <Col xs={12}>
                            <div className="mb-4">
                                <Label className="form-label">Remarks</Label>
                                <Input
                                    name="remarks"
                                    type="text"
                                    placeholder="Remarks"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.remarks || ""}
                                    invalid={
                                        validation.touched.remarks &&
                                            validation.errors.remarks
                                            ? true
                                            : false
                                    }
                                />
                                {validation.touched.remarks &&
                                    validation.errors.remarks ? (
                                    <FormFeedback type="invalid">
                                        {validation.errors.remarks}
                                    </FormFeedback>
                                ) : null}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="text-left">
                                <button
                                    type="submit"
                                    className="btn btn-primary w-md me-3"
                                >
                                    Finish
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-secondary w-md"
                                    onClick={onCloseClick}
                                >
                                    Close
                                </button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
        </Modal>
    );
};


export default UnBlockModal;