import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../../components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { createAmountToOwner, getAllAmountToOwner, deleteAmountToOwner, EditAmountToOwner } from "../../../middleware/backent_helper";
import { TransactionDate, Expenses, Remarks } from "./columnData";
import messages from '../../../utils/constants/messages';
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import DeleteModal from "../../../components/Common/DeleteModal";
import AlertModal from "../../../components/Common/alertModal";
import ErrorAlertModal from "../../../components/Common/errorAlertModal";
//redux
import { useDispatch } from "react-redux";
import { format } from "date-fns";
import DatePicker from "react-datepicker";

const AmountToOwner = props => {
    document.title = "Amount To Owner | Income and Expenses ";

    const dispatch = useDispatch();
    const [transactionDate, settransactionDate] = useState(new Date());
    const [AmountToOwner, setAmountToOwner] = useState([]);
    const [AmountToOwnerList, SetAmountToOwnerList] = useState([]);
    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [alertModal, setAlertModal] = useState(false);
    const [errorAlertModal, setErrorAlertModal] = useState(false);
    const [response, setResponse] = useState(false);
    const [masterName, setMasterName] = useState("AmountToOwner");
    const [hid, setHid] = useState(() => {
      const storedHotelId = localStorage.getItem('hotelId')
      return storedHotelId ? storedHotelId.replace(/"/g, '') : '';
    });
    const [dbName, setDbName] = useState(
      localStorage.getItem('dbName')
    )

    const columns = useMemo(
        () => [
          {
            Header: "S.No",
            Cell: ({ row, rows }) => {
              return (
                <div className="table-body">
                  {rows.length - row.index}
                </div>
              );
            },
          },

          {
            Header: "TransactionDate",
            accessor: "transactionDate",
            filterable: true,
            Cell: cellProps => {
              return (
                <div className="table-body">
                  <TransactionDate {...cellProps} />
                </div>
              );
            },
          },

          {
            Header: "Expenses",
            accessor: "quantity",
            filterable: true,
            Cell: cellProps => {
              return (
                <div className="table-body">
                  <Expenses {...cellProps} />
                </div>
              );
            },
          },

          {
            Header: "Remarks",
            accessor: "Remarks",
            filterable: true,
            Cell: cellProps => {
              return (
                <div className="table-cell text-center">
                  <Remarks {...cellProps} />
                </div>
              );
            },
          },
        ],
      []
    );

    useEffect(() => {
      getAssets()
    }, [])

    const getAssets = () => {
      getAllAmountToOwner(hid, dbName)
      .then((res) => {
        setAmountToOwner(res.data.data);
      });
    };

    const clearFormValues = () => {
      validation.resetForm();
    };

    const toggle = () => {
      if (modal) {
        setModal(false);
        SetAmountToOwnerList(null);
        clearFormValues();
      } else {
       setModal(true);
      }
    };

    const handleAssetsEditClick = arg => {
      const assetsEdit = arg;
      SetAmountToOwnerList({
        id: assetsEdit.id,
        transactionDate: assetsEdit.transactionDate,
        quantity: assetsEdit.quantity,
        Remarks: assetsEdit.Remarks,
        hotelId: hid,
        dbName: dbName,
      });
      setIsEdit(true);
      toggle();
    };

    var node = useRef();
    const onPaginationPageChange = page => {
      if (
        node &&
        node.current &&
        node.current.props &&
        node.current.props.pagination &&
        node.current.props.pagination.options
      ) {
        node.current.props.pagination.options.onPageChange(page);
      }
    };

    //delete AmountToOwner
    const [deleteModal, setDeleteModal] = useState(false);
    const onClickDelete = data => {
      SetAmountToOwnerList(data);
      setDeleteModal(true);
    };

    const handleDelete = () => {
      deleteAmountToOwner(AmountToOwnerList, dbName).then(getAllAmountToOwner(hid), getAssets())
      onPaginationPageChange(1);
      setDeleteModal(false);
    };

    const handleAmountToOwnerClick = () => {
      setIsEdit(false);
      toggle();
    };

    //last two digit fix function
    const formatToTwoDecimalPlaces = (value) => {
    const number = parseFloat(value);
      return isNaN(number) ? "" : number.toFixed(2);
    };

    const handleBlur = (event) => {
      validation.handleBlur(event);
      const formattedValue = formatToTwoDecimalPlaces(event.target.value);
      validation.setFieldValue(event.target.name, formattedValue);
    };

    const handleDateChange = (date) => {
      setTransactionDate(date);
      validation.setFieldValue("transactionDate", date);
    };

    const validation = useFormik({
      initialValues: {
        transactionDate: AmountToOwnerList ? AmountToOwnerList.transactionDate : "",
        quantity: AmountToOwnerList ? AmountToOwnerList.quantity : "",
        Remarks: AmountToOwnerList ? AmountToOwnerList.Remarks : "",
      },
      enableReinitialize: true,
      validationSchema: Yup.object({
        transactionDate: Yup.string().required("Please Enter Asset name"),
        quantity: Yup.string().matches(/^\d+$/, 'Invalid format').required("Please Enter quantity"),
        Remarks: Yup.number().required("Please Enter Remarks"),
      }),
      onSubmit: (values) => {
        if (isEdit) {
          const updatedAssetsData = { ...AmountToOwnerList, ...values };
          EditAmountToOwner(updatedAssetsData)
          .then((res) => {
            setResponse(messages.success.updatedDetails(masterName))
            setAlertModal(true)
          })
          .catch((err) => {
            setResponse(messages.failed.updateFailed(masterName))
            setErrorAlertModal(true)
          });
          toggle();
        } else {
          dispatch(create_assets(values));
        }
      }
    });

    const create_assets = (values) => {
      const data = JSON.stringify({
        transactionDate: values.transactionDate,
        quantity: values.quantity,
        Remarks: values.Remarks,
        hotelId: hid,
        dbName: dbName,
        isActive: true
      });
      createAmount(data).then((res) => {
        if (res.data.success) {
          setResponse(messages.success.createdMessage(masterName))
          setAlertModal(true)
        }
      }).catch(err => {
        if (err.response.status === 404) {
          setResponse(messages.failed.AlreadyExists(masterName))
        } else {
          setResponse(messages.failed.createFailed(masterName))
        }
        setErrorAlertModal(true)
     });
     toggle();
    };

    return (
        <React.Fragment>
          <DeleteModal
            show={deleteModal}
            onDeleteClick={handleDelete}
            onCloseClick={() => setDeleteModal(false)}
          />
          <AlertModal
            show={alertModal}
            onOkClick={() => {
              getAssets();
              setAlertModal(false);
            }}
            onCloseClick={() => setAlertModal(false)}
            res={response}
          />
          <ErrorAlertModal
            show={errorAlertModal}
            onOkClick={() => { getAssets(), setErrorAlertModal(false) }}
            onCloseClick={() => setErrorAlertModal(false)}
            res={response}
          />

          <div className="page-content">
            <Container fluid>
              {/* Render Breadcrumbs */}
              <Breadcrumbs title="Settings" breadcrumbItem="Manage Cash Expense" />
              <Row>
                <Col lg="12">

                      <TableContainer
                        columns={columns}
                        data={AmountToOwner}
                        isGlobalFilter={true}
                        isAddAmountToOwnerClick={true}
                        handleAmountToOwnerClick={handleAmountToOwnerClick}
                        customPageSize={10}
                        className="custom-header-css"
                      />

                      <Modal isOpen={modal} toggle={toggle} backdrop="static" keyboard={false}>
                        <ModalHeader toggle={toggle} tag="h4">
                          {!!isEdit ? "Edit Expense Detail" : "Add Expense Detail"}
                        </ModalHeader>
                        <ModalBody>
                          <Form
                            onSubmit={(e) => {
                              e.preventDefault();
                              validation.handleSubmit();
                              return false;
                            }}
                          >
                            <Row>
                              <Col xs={12}>
                                <div className="mb-3">
                                  <Label className="form-label">Transaction Date</Label>
                                  <DatePicker
                                    name="transactionDate"
                                    selected={transactionDate}
                                    onChange={handleDateChange}
                                    className="form-control"
                                    dateFormat="d-MM-yyyy h:mm aa"
                                    placeholderText="Select date and time"
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={1}
                                    value={transactionDate || ""}
                                    invalid={
                                      validation.touched.transactionDate &&
                                      validation.errors.transactionDate
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.transactionDate &&
                                    validation.errors.transactionDate ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.transactionDate}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                                <div className="mb-3">
                                  <Label className="form-label">To Owner</Label>
                                  <Input
                                    name="quantity"
                                    type="text"
                                    placeholder="quantity"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.quantity || ""}
                                    invalid={
                                      validation.touched.quantity &&
                                        validation.errors.quantity
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.quantity &&
                                    validation.errors.quantity ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.quantity}
                                    </FormFeedback>
                                  ) : null}
                                </div>

                                <div className="mb-3">
                                  <Label className="form-label">Taken Purpose</Label>
                                  <Input
                                    name="Remarks"
                                    type="number"
                                    placeholder="Remarks"
                                    onChange={validation.handleChange}
                                    onBlur={handleBlur}
                                    value={validation.values.Remarks || ""}
                                    invalid={
                                      validation.touched.Remarks &&
                                        validation.errors.Remarks
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.Remarks &&
                                    validation.errors.Remarks ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.Remarks}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div className="text-end">
                                  <button type="submit" className="btn btn-primary w-md me-3">
                                    {isEdit ? 'Update' : 'Save'}
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-secondary w-md "
                                    onClick={toggle}
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </Col>
                            </Row>
                          </Form>
                        </ModalBody>
                      </Modal>

                </Col>
              </Row>
            </Container>
          </div>
        </React.Fragment>
    );
}
export default withRouter(AmountToOwner);