import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react"
import { Modal, ModalBody } from "reactstrap"

const ConfirmationModal = ({ show, onCloseClick, hotelList = {}, onDeleteClick }) => {
    const [hotelName, setHotelName] = useState('');
    const [isMatch, setIsMatch] = useState(false);

    // Reset state when the modal is opened
    useEffect(() => {
        if (show) {
          setHotelName('');
          setIsMatch(null);
        }
    }, [show]);

    const handleInputChange = (e) => {
        const value = e.target.value
        setHotelName(value);

        if (value) {
          const match = hotelList.hotelName.toLowerCase() === value.toLowerCase();
          setIsMatch(match);
        } else {
          setIsMatch(null);
        }
    };

    const handleDeleteClick = () => {
        if (isMatch) {
          onDeleteClick();
        } else {
          console.log('Hotel name is incorrect. Cannot delete.');
        }
    };

    const inputStyle = {
        borderColor: isMatch === null ? '' : (isMatch ? 'green' : 'red'),
    };

      return (
        <Modal size="md" isOpen={show} toggle={onCloseClick} centered={true}>
          <div className="modal-content">
            <ModalBody className="px-4 py-5 text-center">
              <button type="button" onClick={onCloseClick} className="btn-close position-absolute end-0 top-0 m-3"></button>

              {/* <div className="avatar-sm mb-4 mx-auto">
                <div className="avatar-title bg-primary text-primary bg-opacity-10 font-size-20 rounded-3">
                  <i className="mdi mdi-trash-can-outline"></i>
                </div>
              </div> */}

              <p className="text-muted font-size-16 mb-4">Enter hotel name to confirm deletion.</p>

              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Hotel Name"
                  value={hotelName}
                  onChange={handleInputChange}
                  style={inputStyle}
                />
                {isMatch !== null && (
                  <span className="input-group-text">
                    <i className={`mdi ${isMatch ? 'mdi-check-circle-outline text-success' : 'mdi-alert-circle-outline text-danger'}`}></i>
                  </span>
                )}
              </div>

              <div className="hstack gap-2 justify-content-center mb-0">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={handleDeleteClick}
                >
                  Delete Now
                </button>
                <button type="button" className="btn btn-secondary" onClick={onCloseClick}>Close</button>
              </div>
            </ModalBody>
          </div>
        </Modal>
      );
}

ConfirmationModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any
}

export default ConfirmationModal
