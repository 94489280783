import React, { useEffect, useState } from "react";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import NoImage from "../../assets/images/no_image.jpg"
import { Row, Form, Col, FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import { deleteCustomerImage, getCountries, getStates, getCities, getIdentity } from "../../middleware/backent_helper";
import DeleteModal from "../../components/Common/DeleteModal";
import AlertModal from "../../components/Common/alertModal";
import ErrorAlertModal from "../../components/Common/errorAlertModal";
import Select from "react-select";

const CustomerForm = ({ isOpen, onClose, isEdit, parentHandleSubmit, customers, toggle, getAllCutomers }) => {
  if (!isOpen) return null;

  const [countriesList, setCountries] = useState([]);
  const [proofTypes, setProofTypes] = useState([]);
  const [proofImages, setproofImages] = useState([]);
  const [selectedProofType, setselectedProofType] = useState('');
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [statesList, setStatesList] = useState([]);
  const [selectedState, setSelectedState] = useState('');
  const [getstateKey, setstateKey] = useState([])
  const [citiesList, setCitiesList] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [imageDeletion, setImageDeletion] = useState([]);
  const [alertModal, setAlertModal] = useState(false);
  const [errorAlertModal, setErrorAlertModal] = useState(false);
  const [response, setResponse] = useState(false);
  const [hid, setHid] = useState(() => {
    const storedHotelId = localStorage.getItem('hotelId')
    return storedHotelId ? storedHotelId.replace(/"/g, '') : '';
  });
  const [dbName, setDbName] = useState(
    localStorage.getItem('dbName')
  )

  useEffect(() => {
    getCountries()
    .then((res) => {
      setCountries(res.data.data)

      if (customers && customers.country) {
        const foundCountry = res.data.data.find(country => country.name === customers.country);
        if (foundCountry) {
            setSelectedCountry({ label: foundCountry.name, value: foundCountry });
            validation.setFieldValue('country', foundCountry.name);
        }
      }
    })
  }, [])

  useEffect(() => {
    if(customers) {
        setproofImages(customers.proof_photo)
    }
    getIdentity(hid, dbName)
    .then((res) => {
        setProofTypes(res.data.data)
    })

    if (selectedCountry != null) {
      const fetchStates = async () => {
        try {
          const stateKey = getCountryKey(selectedCountry);
          getStates(stateKey).then((res) => {
            setStatesList(res.data.data);
            if (customers && customers.state) {
                const foundState = res.data.data.find(state => state.name === customers.state);
                if (foundState) {
                    setSelectedState({ label: foundState.name, value: foundState });
                    validation.setFieldValue('state', foundState.name);
                }
            }
          });
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
      fetchStates();
    }
  }, [selectedCountry, customers])

    // Fetch city when a state is selected
    useEffect(() => {
        if (selectedState && selectedState.label) {
          const fetchCities = async () => {
            try {
              const stateKey = getStateKey(selectedState);
              setstateKey(stateKey)

              getCities(stateKey)
              .then((res) => {
                setCitiesList(res.data.data);
                if (customers && customers.city) {
                    const foundCity = res.data.data.find(city => city.name === customers.city);
                    if (foundCity) {
                        setSelectedCities({ label: foundCity.name, value: foundCity });
                        validation.setFieldValue('city', foundCity.name);
                    }
                }
              })
            } catch (error) {
              console.error('Error fetching city:', error);
            }
          };
          fetchCities();
        }
    }, [selectedState]);

  const getStateKey = (stateName) => {
     const state = statesList.find(s => s.name === stateName.label);
     return state ? state.name : null;
  };

  const getCountryKey = (CountryKey) => {
    const state = countriesList.find(s => s.name === CountryKey.label);
    return state ? state.iso2 : null;
  }

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    if (selectedOption && selectedOption.label) {
      validation.setFieldValue('country', selectedOption.label);
    } else {
      validation.setFieldValue('country', '');
    }
  };

  const handleStateChange = (selectedOption) => {
    setSelectedState(selectedOption)
    if (selectedOption && selectedOption.label) {
      validation.setFieldValue('state', selectedOption.label);
    } else {
      validation.setFieldValue('state', '');
    }
  }

  const handleCityChange = (selectedOption) => {
    setSelectedCities(selectedOption)
    if (selectedOption && selectedOption.label) {
      validation.setFieldValue('city', selectedOption.label);
    } else {
      validation.setFieldValue('city', '');
    }
  }

  const handleProofTypeChange = async (event) => {
    validation.setFieldValue('proof_type', event.target.value);
    setselectedProofType(event.target.value)
  }

  const handleFileChange = async (event) => {
    const files = event.currentTarget.files;
    if(files) {
        validation.setFieldValue('proof_photo', Array.from(files));
    }
  };

  const openFullscreen = (imgElement) => {
    if (imgElement.requestFullscreen) {
        imgElement.requestFullscreen().catch(err => {
            console.error(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
        });
    } else if (imgElement.mozRequestFullScreen) { // Firefox
        imgElement.mozRequestFullScreen().catch(err => {
            console.error(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
        });
    } else if (imgElement.webkitRequestFullscreen) { // Chrome, Safari and Opera
        imgElement.webkitRequestFullscreen().catch(err => {
            console.error(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
        });
    } else if (imgElement.msRequestFullscreen) { // IE/Edge
        imgElement.msRequestFullscreen().catch(err => {
            console.error(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
        });
    } else {
        console.error('Fullscreen API is not supported.');
    }
};

  const deleteProofImage = (event) => {
    if(event) {
        setImageDeletion(event.src)
        setDeleteModal(true);
    }
  }

  const customerImageDelete = () => {
    const data = JSON.stringify({
        _id: customers.id,
        proof_photo: imageDeletion,
        hotelId: hid,
        dbName: dbName
    })
    deleteCustomerImage(data)
    .then((res) => {
        setResponse("Proof Image Deleted");
        setAlertModal(true);
        setImageDeletion('')
    })
    .catch((err) => {
        setResponse("Failed to Delete Proof Image");
        setErrorAlertModal(true);
    })
    setDeleteModal(false);
  }

  const validation = useFormik({
    initialValues: {
      guest_name: customers ? customers.guest_name : "",
      mobile_no: customers ? customers.mobile_no : "",
      nationality: customers ? customers.nationality : "",
      proof_type: customers ? customers.proof_type : "",
    //   country: customers && customers.country ? customers.country  : (selectedCountry ? selectedCountry.label: ""),
    //   state: selectedState ? selectedState.label : "",
    //   city: selectedCities ? selectedCities.name : "",
      proof_no: customers ? customers.proof_no : "",
      gender: customers ? customers.gender : "",
      vehicle_no: customers ? customers.vehicle_no : "",
      address: customers ? customers.address : "",
      email: customers ? customers.email : "",
      company_name: customers ? customers.company_name : "",
      pincode: customers ? customers.pincode : "",
      age: customers ? customers.age : "",
      proof_photo: customers ? customers.proof_photo : ""
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      guest_name: Yup.string().required("Please Enter Your Name"),
      mobile_no:  Yup.string()
      .matches(/^[0-9]{10}$/, 'Mobile number must be exactly 10 digits')
      .required('Please Enter Your Mobile No'),
      country: Yup.string().nullable().required('Country is required'),
    //   proof_type: Yup.string().required('Proof Type is required'),
      state: Yup.string().required('State is required'),
      proof_no: Yup.string().required('Enter proof no'),
      gender: Yup.string().required('Gender is required'),
      city: Yup.string().required('City is required'),
      // vehicle_no: Yup.string(),
      address: Yup.string().required('Address is required').max(50, 'Address must not exceed 50 characters'),
      email: Yup.string().matches(
        /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
        "Please Enter Valid Email").required('Email is required'),
      // company_name: Yup.string().required('Company name is required'),
      pincode: Yup.string().matches(/^\d+$/, 'Invalid number format').required('pincode is required'),
      age: Yup.string().matches(/^\d+$/, 'Invalid number format').required('Age is required'),
      proof_photo: Yup.array().when([], {
        is: (files) => files && files.length > 0,
        then: Yup.array()
          .of(
            Yup.mixed().test(
              'fileSize',
              'File size is too large. Maximum size is 5MB.',
              (value) => value && value.size <= 5 * 1024 * 1024
            )
          )
          .required('At least one file is required'),
        otherwise: Yup.array().notRequired(),
      }),
      country: Yup.string().required('Country is required'),
    }),
    onSubmit: (values) => {
      parentHandleSubmit(values)
      validation.resetForm();
    }
  });

  const handlePrint = () => {
    window.print();
  };

  return (
    <React.Fragment>
        <DeleteModal
            show={deleteModal}
            onDeleteClick={customerImageDelete}
            onCloseClick={() => setDeleteModal(false)}
        />
        <AlertModal
            show={alertModal}
            onOkClick={() => {
            toggle();
            getAllCutomers();
            setAlertModal(false);
            }}
            onCloseClick={() => setAlertModal(false)}
            res={response}
        />
            <ErrorAlertModal
            show={errorAlertModal}
            onOkClick={() =>{ getAllCutomers() ,setErrorAlertModal(false) }}
            onCloseClick={() => setErrorAlertModal(false)}
            res={response}
        />
        <Form
            onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
            }}
        >
            <div>
                <Row>
                    <Col lg={4}>
                        <div className="mb-3">
                            <FormGroup>
                                <Label className="form-label">Guest Name</Label>
                                <Input
                                    name="guest_name"
                                    type="text"
                                    placeholder="Name"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.guest_name || ""}
                                    invalid={
                                        validation.touched.guest_name &&
                                        validation.errors.guest_name
                                        ? true
                                        : false
                                    }
                                />
                                {validation.touched.guest_name &&
                                    validation.errors.guest_name ? (
                                    <FormFeedback type="invalid">
                                        {validation.errors.guest_name}
                                    </FormFeedback>
                                ) : null}
                            </FormGroup>
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className="mb-3">
                            <FormGroup>
                                <Label className="form-label">Email</Label>
                                <Input
                                    name="email"
                                    type="text"
                                    placeholder="Email"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.email || ""}
                                    invalid={
                                        validation.touched.email &&
                                        validation.errors.email
                                        ? true
                                        : false
                                    }
                                />
                                {validation.touched.email &&
                                    validation.errors.email ? (
                                    <FormFeedback type="invalid">
                                        {validation.errors.email}
                                    </FormFeedback>
                                ) : null}
                            </FormGroup>
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className="mb-3">
                            <FormGroup>
                                <Label className="form-label">Mobile No</Label>
                                <Input
                                    name="mobile_no"
                                    type="text"
                                    placeholder="MobileNo"
                                    onChange={(e) => {
                                        const re = /^[0-9\b]+$/;
                                        if (e.target.value === '' || re.test(e.target.value)) {
                                          validation.handleChange(e);
                                        }
                                      }}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.mobile_no || ""}
                                    invalid={
                                        validation.touched.mobile_no &&
                                        validation.errors.mobile_no
                                        ? true
                                        : false
                                    }
                                    maxLength={10}
                                />
                                {validation.touched.mobile_no &&
                                    validation.errors.mobile_no ? (
                                    <FormFeedback type="invalid">
                                        {validation.errors.mobile_no}
                                    </FormFeedback>
                                ) : null}
                            </FormGroup>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4}>
                        <div className="mb-3">
                            <FormGroup>
                                <Label className="form-label">Address</Label>
                                <Input
                                    name="address"
                                    type="text"
                                    placeholder="Address"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.address || ""}
                                    invalid={
                                        validation.touched.address &&
                                        validation.errors.address
                                        ? true
                                        : false
                                    }
                                />
                                {validation.touched.address &&
                                    validation.errors.address ? (
                                    <FormFeedback type="invalid">
                                        {validation.errors.address}
                                    </FormFeedback>
                                ) : null}
                            </FormGroup>
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className="mb-3">
                            <FormGroup>
                                <Label className="form-label">Country</Label>
                                <Select
                                    name="country"
                                    placeholder="Select Country"
                                    classNamePrefix="react-select"
                                    className={`react-select-container ${
                                        validation.touched.country && validation.errors.country
                                        ? 'is-invalid'
                                        : ''
                                    }`}
                                    options={countriesList.map((country) => ({
                                        label: country.name,
                                        value: country,
                                      }))}
                                    value={selectedCountry}
                                    onChange={handleCountryChange}
                                    isClearable
                                    isSearchable
                                    styles={{
                                      menu: (provided) => ({
                                        ...provided,
                                        top: "100%",
                                        position: "absolute",
                                        width: "100%",
                                        zIndex: 999,
                                      }),
                                    }}
                                />
                                {validation.touched.country &&
                                    validation.errors.country ? (
                                    <FormFeedback type="invalid">
                                        {validation.errors.country}
                                    </FormFeedback>
                                ) : null}
                            </FormGroup>
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className="mb-3">
                            <FormGroup>
                                <Label className="form-label">State</Label>
                                <Select
                                    name="state"
                                    placeholder="Select State"
                                    classNamePrefix="react-select"
                                    className={`react-select-container ${
                                        validation.touched.state && validation.errors.state
                                        ? 'is-invalid'
                                         : ''
                                    }`}
                                    value={selectedState}
                                    onChange={handleStateChange}
                                    options={statesList.map((state) => ({
                                        label: state.name,
                                        value: state
                                    }))}
                                    isClearable
                                    isSearchable
                                    styles={{
                                        menu: (provided) => ({
                                          ...provided,
                                          top: "100%",
                                          position: "absolute",
                                          width: "100%",
                                          zIndex: 999,
                                        }),
                                      }}
                                />
                                {validation.touched.state &&
                                    validation.errors.state ? (
                                    <FormFeedback type="invalid">
                                        {validation.errors.state}
                                    </FormFeedback>
                                ) : null}
                            </FormGroup>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4}>
                        <div className="mb-3">
                            <FormGroup>
                                <Label className="form-label">City</Label>
                                <Select
                                    name="city"
                                    placeholder="Select City"
                                    className={`react-select-containe ${
                                        validation.touched.city && validation.errors.city
                                         ? 'is-invalid'
                                          : ''
                                    }`}
                                    value={selectedCities}
                                    onChange={handleCityChange}
                                    options={citiesList.map((city) => ({
                                        label: city.name,
                                        value: city
                                    }))}
                                    isClearable
                                    isSearchable
                                    styles={{
                                        menu: (provided) => ({
                                          ...provided,
                                          top: "100%",
                                          position: "absolute",
                                          width: "100%",
                                          zIndex: 999,
                                        }),
                                      }}
                                />
                                {validation.touched.city &&
                                    validation.errors.city ? (
                                    <FormFeedback type="invalid">
                                        {validation.errors.city}
                                    </FormFeedback>
                                ) : null}
                            </FormGroup>
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className="mb-3">
                            <FormGroup>
                                <Label className="form-label">Pin Code</Label>
                                <Input
                                name="pincode"
                                type="text"
                                placeholder="Pin Code"
                                onChange={(e) => {
                                    const re = /^[0-9\b]+$/;
                                    if (e.target.value === '' || re.test(e.target.value)) {
                                      validation.handleChange(e);
                                    }
                                  }}
                                onBlur={validation.handleBlur}
                                value={validation.values.pincode || ""}
                                invalid={
                                    validation.touched.pincode &&
                                    validation.errors.pincode
                                    ? true
                                    : false
                                }
                                maxLength={6}
                                />
                                {validation.touched.pincode &&
                                validation.errors.pincode ? (
                                <FormFeedback type="invalid">
                                    {validation.errors.pincode}
                                </FormFeedback>
                                ) : null}
                            </FormGroup>
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className="mb-3">
                            <FormGroup>
                                <Label className="form-label">Gender</Label>
                                <select
                                    name="gender"
                                    className={`form-select form-select-sm${validation.touched.gender && validation.errors.gender ? ' is-invalid' : ''}`}
                                    style={{ height: '38px' }}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.gender || ""}
                                    >
                                    <option value="" disabled label="Select gender" />
                                    <option value="Male" label="Male" />
                                    <option value="Female" label="Female" />
                                </select>
                                {validation.touched.gender && validation.errors.gender ? (
                                <FormFeedback type="invalid">
                                    {validation.errors.gender}
                                </FormFeedback>
                                ) : null}
                            </FormGroup>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4}>
                        <div className="mb-3">
                            <FormGroup>
                                <Label className="form-label">Age</Label>
                                <Input
                                    name="age"
                                    type="text"
                                    placeholder="Age"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.age || ""}
                                    invalid={
                                        validation.touched.age &&
                                        validation.errors.age
                                        ? true
                                        : false
                                    }
                                />
                                {validation.touched.age &&
                                    validation.errors.age ? (
                                    <FormFeedback type="invalid">
                                        {validation.errors.age}
                                    </FormFeedback>
                                ) : null}
                            </FormGroup>
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className="mb-3">
                            <FormGroup>
                                <Label className="form-label">Company Name</Label>
                                <Input
                                    name="company_name"
                                    type="text"
                                    placeholder="Company Name"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.company_name || ""}
                                    invalid={
                                        validation.touched.company_name &&
                                        validation.errors.company_name
                                        ? true
                                        : false
                                    }
                                />
                                {validation.touched.company_name &&
                                    validation.errors.company_name ? (
                                    <FormFeedback type="invalid">
                                        {validation.errors.company_name}
                                    </FormFeedback>
                                ) : null}
                            </FormGroup>
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className="mb-3">
                            <FormGroup>
                                <Label className="form-label">Vehicle No.</Label>
                                <Input
                                    name="vehicle_no"
                                    type="text"
                                    placeholder="Vehicle No"
                                    onChange={(e) => {
                                        e.target.value = e.target.value.toUpperCase(); // Convert to uppercase
                                        validation.handleChange(e); // Handle the change with Formik
                                    }}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.vehicle_no || ""}
                                    invalid={
                                        validation.touched.vehicle_no &&
                                        validation.errors.vehicle_no
                                        ? true
                                        : false
                                    }
                                />
                                {validation.touched.vehicle_no &&
                                    validation.errors.vehicle_no ? (
                                    <FormFeedback type="invalid">
                                        {validation.errors.vehicle_no}
                                    </FormFeedback>
                                ) : null}
                            </FormGroup>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4}>
                        <div className="mb-3">
                            <FormGroup>
                                <Label className="form-label">Proof Type</Label>
                                <select
                                    name="proof_type"
                                    className={`form-select form-select-sm ${validation.touched.proof_type && validation.errors.proof_type ? 'is-invalid' : ''
                                    }`}
                                    style={{ height: '38px' }}
                                    value={validation.values.proof_type || ""}
                                    onChange={(e) => {
                                        validation.handleChange(e);
                                        handleProofTypeChange(e);
                                    }}
                                    onBlur={validation.handleBlur}
                                >
                                    <option value="" disabled>
                                        Select a Proof Type
                                    </option>
                                    {proofTypes.length > 0 ? (
                                        proofTypes.map((proof) => (
                                        <option key={proof._id} value={proof.identityName}>
                                            {proof.identityName}
                                        </option>
                                        ))
                                    ) : (
                                        <option disabled>No Proof Type available</option>
                                    )}
                                </select>
                                {validation.touched.proof_type &&
                                    validation.errors.proof_type ? (
                                    <FormFeedback type="invalid">
                                        {validation.errors.proof_type}
                                    </FormFeedback>
                                ) : null}
                            </FormGroup>
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className="mb-3">
                            <FormGroup>
                                <Label className="form-label">Proof No.</Label>
                                <Input
                                    name="proof_no"
                                    type="text"
                                    placeholder={selectedProofType ? selectedProofType.toUpperCase() : "Proof Number"}
                                    onChange={(e) => {
                                        e.target.value = e.target.value.toUpperCase(); // Convert to uppercase
                                        validation.handleChange(e); // Handle the change with Formik
                                    }}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.proof_no || ""}
                                    invalid={
                                        validation.touched.proof_no &&
                                        validation.errors.proof_no
                                        ? true
                                        : false
                                    }
                                />
                                {validation.touched.proof_no &&
                                    validation.errors.proof_no ? (
                                    <FormFeedback type="invalid">
                                        {validation.errors.proof_no}
                                    </FormFeedback>
                                ) : null}
                            </FormGroup>
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className="mb-3">
                            <FormGroup>
                                <Label className="form-label">Upload Image</Label>
                                <Input
                                    name="proof_photo"
                                    type="file"
                                    accept="image/*"
                                    className={`form-control ${validation.touched.proof_photo && validation.errors.proof_photo ? 'is-invalid' : ''
                                    }`}
                                    onBlur={validation.handleBlur}
                                    onChange={(event) => handleFileChange(event)}
                                    multiple
                                />
                                {validation.touched.proof_photo &&
                                    validation.errors.proof_photo ? (
                                    <FormFeedback type="invalid">
                                        {validation.errors.proof_photo}
                                    </FormFeedback>
                                ) : null}
                            </FormGroup>
                        </div>
                    </Col>
                </Row>
            </div>
            <br></br>
            {/* <img src="https://commondatastorage.googleapis.com/codeskulptor-assets/lathrop/nebula_blue.s2014.png" style={{ width: '100%' }} /> */}
            {isEdit && customers && (
                <Row className="justify-content-center">
                    {proofImages.map((url, index) => (
                        <Col key={index} xs={12} sm={6} className="mb-3">
                            <div className="position-relative">
                                <img id={`image-${index}`}
                                    className="img-thumbnail"
                                    src={url ? url.image : NoImage}
                                    style={{ width: '100%', height: 'auto' }}
                                    alt={`Image ${index}`} />
                                {url && (
                                    <div className="button-container d-flex justify-content-center align-items-center">
                                        <button type="button"
                                                onClick={() => openFullscreen(document.getElementById(`image-${index}`))}
                                                className="btn btn-primary save-user w-sm">
                                            View
                                        </button>
                                        <button type="button"
                                                onClick={() => deleteProofImage(document.getElementById(`image-${index}`))}
                                                className="btn btn-danger save-user w-sm ms-1">
                                            Delete
                                        </button>
                                    </div>
                                )}
                            </div>
                        </Col>
                    ))}
                </Row>
            )}
            <Row>
                <Col>
                    <div className="d-flex justify-content-end mb-3 flex-wrap">
                        {/* {isEdit && (
                            <div className="d-flex">
                                <button
                                    onClick={handlePrint}
                                    className="btn btn-success w-md me-3"
                                >
                                    Print
                                </button>
                            </div>
                        )} */}
                        <div className="text-start ms-auto">
                            <button type="submit" className="btn btn-primary w-md me-3">
                                {isEdit ? 'Update' : 'Save'}
                            </button>
                            <button
                                type="button"
                                className="btn btn-secondary save-user w-md me-3"
                                onClick={toggle}
                            >
                                Cancel
                            </button>

                        </div>
                    </div>
                </Col>
            </Row>

        </Form>
    </React.Fragment>
  )
}

export default CustomerForm;