import React from 'react';
import { Modal, ModalHeader, ModalBody, Button, Row, Col } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import '../../assets/scss/checkOutModal.scss'

const CheckOutModal = ({ ismodalopen, toggleModal, bookingId}) => {
  if (!ismodalopen) return null;

  const navigate = useNavigate();

  const handleNavigation = (path, state) => {
    navigate(path, { state });
  };

  return (
      <Modal className="modal-lg" isOpen={ismodalopen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Check-Out Details</ModalHeader>
        <ModalBody className="p-4">
          <Row  className="mb-3">
            <Col md="3" className="mb-3" >
              <Button className='buttonOness' block onClick={() => handleNavigation(`/check_out?bookingId=${bookingId}`, { bookingId })}>
                Check Out
              </Button>
            </Col>
            <Col md="3" className="mb-3">
              <Button className="buttonTwoss" block onClick={() => handleNavigation(`/shift_room?bookingId=${bookingId}`)}>
              Shift Room
              </Button>
            </Col>
            <Col md="3" className="mb-3">
              <Button className="buttonThreess" block onClick={() => handleNavigation(`/extra_adult?bookingId=${bookingId}`)}>
              Extra Adult
              </Button>
            </Col>
            <Col md="3" className="mb-3">
              <Button className="tonFourss" block onClick={() => handleNavigation(`/advance_pay?bookingId=${bookingId}`)}>
                Advance Pay
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  };

export default CheckOutModal;